import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { AppConstants } from "../appConstants";
import { Appointment, IAppointment } from "../models/appointment";
import { KeyValueExtra } from "../models/keyValue";
import { AppointmentService } from "./globalDataProvider/appointment.service";
import { LocalStorageService } from "./storage/local-storage.service";

@Injectable({
    providedIn: 'root'
})

export class BadgesService {
    public $appointmentBadgeCount = new BehaviorSubject<number>(0 as number);
    public appointments = new Array<IAppointment>();

    constructor(
        private localStorageService: LocalStorageService,
        private appointmentService: AppointmentService
    ) {}

    /**
     * Store moments that appointments have been seen
     */
    public setAppointmentSeen(arrApps: IAppointment[]): Promise<void> {
        // console.info("setAppointmentSeen seen are ", arrApps);
        if (!arrApps.length) {
            return Promise.resolve();
        } else {
            return this.getAppointmentSeen()
            .then((arrKve) => {
                // console.info("setAppointmentSeen all seen in db", arrKve);
                if (!arrKve) arrKve = new Array<KeyValueExtra>();
                const now = moment();
                // find & update appointment
                let appFound = false;
                for (const app of arrApps) {
                    if (Appointment.isPersonalAppointment(app)) continue;   // ignore user personal appointment
                    // if (now.isBefore(app.start, "day")) {// only take future appointments
                    for (const kve of arrKve) {
                        if (kve.key === app._id) {
                            kve.value = app.start;
                            kve.extra = app.modified;
                            appFound = true;
                            break;
                        }
                    }
                    // }
                    // not found yet ?
                    if (!appFound && app._id.length) {
                        // console.info("setAppointmentSeen not found in db", app);
                        arrKve.push(new KeyValueExtra(app._id, app.start, app.modified));
                    }
                }
                // now, store new appointments list but remove passed one
                const newArrKve = new Array<KeyValueExtra>();
                for (const kve of arrKve) {
                    if (now.isSameOrBefore(kve.value, "day") && kve.key) newArrKve.push(kve);
                }
                // console.info("setAppointmentSeen new seen", newArrKve);
                return this.localStorageService.setData(AppConstants.PRM_APPOINTMENT_BADGE, JSON.stringify(newArrKve), false);
            });
        }
    }

    /**
     * Get settings that store moments that appointments have been seen
     */
    public async getAppointmentSeen(): Promise<KeyValueExtra[]> {
        try {
            const value: KeyValueExtra[] = JSON.parse(await this.localStorageService.getData(AppConstants.PRM_APPOINTMENT_BADGE, false));
            return !value.length ? [] : value;
        }
        catch (err) {
            return [];
        }
    }

    /**
   * Return newly modified appointments since last seen
   * @param appointments
   */
    public getNewAppointments(appointments: IAppointment[]): Promise<IAppointment[]> {
        // no appointments
        if (!appointments?.length) {
        return Promise.resolve([]);
        }
        // last time careplan page has been seen
        return this.getAppointmentSeen()
        .then((arrKve) => {
            const arrNewApp = new Array<IAppointment>();
            const now = moment();
            for (const app of appointments) {
            // ignore personal appointment
            if (Appointment.isPersonalAppointment(app)) continue;
            let found = false;
            if (now.isAfter(app.start)) found = true; // only take into account future appointments
            else {
                for (const kve of arrKve) {
                if ((app._id === kve.key) && (app.start === kve.value)) {  // same appointment ?
                    found = true;
                }
                }
            }
            if (!found) {
                // console.info("getNewAppointments not found in db", app);
                arrNewApp.push(app); // this appointment has never been seen
            }
            }
            // console.info("getNewAppointments all not found", arrNewApp);
            return arrNewApp;
        });
    }

    /**
     * Compute badge for appointment
     */
    public async computeAppointmentBadgeCount() {
        const dataReader = this.appointmentService.getDataReader();
        for await (const data of dataReader) {
            this.appointments =  data;
            // no appointments
            if (!this.appointments || this.appointments.length === 0) {
                this.$appointmentBadgeCount.next(0);
            } else {
                // gather all new appointments
                try {
                    const newApps = await this.getNewAppointments(this.appointments);
                    this.$appointmentBadgeCount?.next((!newApps || newApps.length === 0) ? 0 : newApps.length);
                } catch (err) {
                    console.error("computeAppointmentBadgeCount", err);
                }
            }
        }
    }

}
