import { CycleSchema } from "../models/entitylink";
import { ILocalAssociationUserPassword } from "../models/LocalAssociationUserPassword";
import { QueuedRequest } from "../services/globalDataProvider/core/request-sender.service";
import { IKnowMedia } from "./knowledge-helper";

export class ArrayHelper {
    /**
     * This method allows to filter an array to an array with unique values 
     * (basic test with indexOf => for complex objects, we must personalized the test)
     * @param value 
     * @param index 
     * @param array 
     */
    public static onlyUnique<T>(value: T, index: number, array: T[]) {
        return array.indexOf(value) === index;
    }

    /**
     * This method allows to filter an array of IKnowMedia to an array with unique values
     * @param value 
     * @param index 
     * @param array 
     */
    public static onlyUniqueIKnowMedia(value: IKnowMedia, index: number, array: IKnowMedia[]): boolean {
        return array.findIndex((media) => {
            return value.type === media.type &&
                value.importanceLevel === media.importanceLevel &&
                value.category === media.category &&
                value.label === media.label &&
                value.language === media.language &&
                value.content === media.content &&
                value.description === media.description &&
                value.extra === media.extra;
        }) === index;
    }

    /**
     * This method allows to filter an array of IKnowMedia to an array with unique values
     * @param value 
     * @param index 
     * @param array 
     */
    public static onlyUniqueRequestSenderService(value: QueuedRequest, index: number, array: QueuedRequest[]): boolean {
        return array.findIndex((req) => {
            return value.date === req.date &&
                value.params.getUrl === req.params.getUrl &&
                value.params.setUrl === req.params.setUrl &&
                value.params.method === req.params.method &&
                value.params.encrypted === req.params.encrypted &&
                value.params.entityPrefix === req.params.entityPrefix &&
                value.params.entityStoreKey === req.params.entityStoreKey &&
                JSON.stringify(value.data) === JSON.stringify(req.data);
        }) === index;
    }

    /**
     * This method allows to filter an array of objects to an array with unique values
     * @param value 
     * @param index 
     * @param array 
     */
    public static onlyUniqueData(value: any, index: number, array: any[]): boolean {
        const indexSameData = array.findIndex(
            (req) => {
                return JSON.stringify(value) === JSON.stringify(req);
            });
        return indexSameData === index;
    }

    /**
     *  This method allows to filter an array of ILocalAssociationUserPassword to an array with unique values
     * @param value 
     * @param index 
     * @param array 
     */
    public static onlyUniqueLocalAssociationUserPassword(value: ILocalAssociationUserPassword, index: number, array: ILocalAssociationUserPassword[]) {
        return array.findIndex((local) => {
            return value.login === local.login &&
                value.mode === local.mode &&
                value.password === local.password &&
                value.caremateIdentifier === local.caremateIdentifier;
        }) === index;
    }

    /**
     * This method is usefull when you need lenght but return zero instead of null if array doesn't exist
     * @param array 
     */
    public static getLengthOrZero(array: any[]): number {
        return array?.length ? array.length : 0;
    }

    /**
     * This method is usefull when you need to get the sum of length of multiple arrays
     * @param args you can pass all your array as arguments 
     */
    public static sumArrayLength(...args: any[][]) {
        return args.reduce((acc, el) => acc + this.getLengthOrZero(el), 0);
    }
    // Creates an array with all falsey values removed. The values false, null, 0, "", undefined, and NaN are falsey.
    /**
     * To replace the lodash _.compact : Creates an array with all falsey values removed. 
     * The values false, null, 0, "", undefined, and NaN are falsey.
     * @param value 
     * @param index 
     * @param array 
     */
    public static onlyNotFalsey(value: any, index: number, array: any[]) {
        return !([false, null, 0, "", NaN].includes(value));
    }

    public static onlyCycleSchema(value: CycleSchema, index: number, array: CycleSchema[]) {
        return array.findIndex((cycle) => {
            return value.name === cycle.name;
        }) === index;
    }

    /**
     * 
     * @param array a 2D-array
     * @returns the transposition of this array
     */
    public static transpose(array: any[][]): any[][] {
        return array.reduce((prev, next) => next.map((item, i) =>
            (prev[i] || []).concat(next[i])
        ), []);
    }


}
