import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'log' })
export class LogPipe implements PipeTransform {
  constructor() { }

  transform(param1: any, param2?: string): any {
    console.log(param2 ? param2 : '', param1);
    return;
  }
}
