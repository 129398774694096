import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDrugsInfo, IDrugInfo } from '../models/drugsInfo';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SamService {

  constructor(
    private apiService: ApiService
  ) { }

  public getDrugs(keyword: string, lang: string): Observable<IDrugsInfo> {
    return this.apiService.get("/drugsInfo?name=" + keyword.replace("%", "%25") + "&lang=" + lang)
      .pipe(
        map(res => {
          if (res && res.success) {
            return res.data as IDrugsInfo;
          }
          return null;
        }),
        catchError((err) => {
          return of(null);
        })
      );
  }
  public getDrug(keyword: string, lang: string, source?: string): Observable<IDrugInfo> {
    return this.apiService.get("/drugInfo?name=" + keyword.replace("%", "%25") + "&lang=" + lang + "&source=" + source)
      .pipe(
        map(res => {
          if (res && res.success) {
            return res.data as IDrugInfo;
          }
          return null;
        }),
        catchError((err) => {
          return of(null);
        })
      );
  }
}
