import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IKnowledges } from 'src/app/helpers/knowledge-helper';
import { EXTERNAL_RESSOURCE_TYPE } from 'src/app/models/externalRessource';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { ExternalRessourceService } from './external-ressource.service';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class BluetoothKnowledgeService extends BasicSyncService<IKnowledges, IKnowledges[]> {

    private lastValueOfParam = '[]';
    private storageKey = 'BluetoothLastValue';

    constructor(
        protected dataService: DataService,
        private externalResourceService: ExternalRessourceService,
        private localStorage: LocalStorageService
    ) {
        super(dataService);
    }

    public getUrl() {
        return this.defaultDataParameter.getUrl + this.lastValueOfParam;
    }

    protected clearWatch(): void {
        this.data$ = new BehaviorSubject<IKnowledges[]>([]);
    }

    protected initWatch(): void {
        this.data$.next([]);
    }

    protected setupDataParameters(): void {
        this.defaultDataParameter = {
            entityPrefix: 'bluetoothKnwoledges_',
            entityStoreKey: 'list',
            getUrl: '/knowledge?reference=',
            setUrl: '/knowledge',
            expirationDays: 10,
            encrypted: false
        };
    }

    public async init() {
        try {
            super.init();
            this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
        } catch (err) {
            this.lastValueOfParam = '[]';
        }
    }

    public clear() {
        super.clear();
        this.lastValueOfParam = '[]';
    }


    public async *getDataReader(ref?: string[]): AsyncGenerator<IKnowledges[]> {
        try {
            const ERDataReader = this.externalResourceService.getDataReader("", [EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE]);
            const hardwareTitleResult = await ERDataReader.next();
            const hardwareTitleValue = hardwareTitleResult.value.map((re) => re.title).sort();

            const paramObject = Object.assign({}, this.defaultDataParameter);
            const stringifyTitles = JSON.stringify(hardwareTitleValue);
            paramObject.getUrl += stringifyTitles;
            this.lastValueOfParam = stringifyTitles;
            this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

            const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, false, this);
            let d: IKnowledges[] = [];
            for await (const data of dataReader) {
                d = this.processData(data, ref);
                yield d;
            }
            return d;
        } catch (err) {
            console.error("BluetoothKnowledgeService getKnowledgesDrugs()", err);
            yield [];
            return [];
        }
    }

    private processData(dataResult: IKnowledges[], ref?: string[]) {
        if (!ref) { return dataResult; }
        try {
            const processedData = dataResult.filter((k) => ref.includes(k.reference));
            return processedData;
        } catch (err) {
            console.error('Error while processing bluetoothKnowledge Service data: ', err);
            return [];
        }
    }

    /**
     * This will try to get the online data and refresh the service's data.
     * If the online data is not available, it will only return the local.
     */
    public async getFreshestData(ref?: string[]): Promise<IKnowledges[]> {
        const dataReader = this.getDataReader(ref);
        let iterator = await dataReader.next();
        while (!iterator.done) { iterator = await dataReader.next(); }
        return iterator.value;
    }

    /**
     * This will return the local data or the online data if there's
     * no local data (and the online is available).
     */
    public async getFirstDataAvailable(ref?: string[]): Promise<IKnowledges[]> {
        const dataReader = this.getDataReader(ref);
        const iterator = await dataReader.next();
        return iterator.value;
    }

}
