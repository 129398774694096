import { Component } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { GoToPageService } from 'src/app/services/go-to-page.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import { MyProfilModalComponent } from '../my-profil-modal/my-profil-modal.component';
import { SettingsNotifPageModalComponent } from '../settings-notif-page-modal/settings-notif-page-modal.component';
import { SettingsSecurityPageModalComponent } from '../settings-security-page-modal/settings-security-page-modal.component';
import { SettingsExportCalendarPageModalComponent } from '../settings-export-calendar-page-modal/settings-export-calendar-page-modal.component';
import { MyBleDevicesPage } from 'src/app/my-ble-devices/my-ble-devices.page';
import { DeleteAccountComponent } from '../delete-account/delete-account.component';
import { FEATURES } from 'src/environments/features';
import { BasePage } from 'src/app/baseClasses/base-page';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';

@Component({
  selector: 'app-settings-menu-page-modal',
  templateUrl: './settings-menu-page-modal.component.html',
  styleUrls: ['./settings-menu-page-modal.component.scss'],
})
export class SettingsMenuPageModalComponent extends BasePage {

  public FEATURES = FEATURES;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected popover: PopoverController,
    private modalCtrl: ModalController,
    protected goToPageService: GoToPageService,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  /**
   * Display General settings
   */
  public async onGeneralSettings() {
    const res = await this.presentModalSettings();
    if (res) { this.dismiss(); }
  }

  /**
   * display settings view
   */
  public async presentModalSettings(): Promise<boolean> {
    const modal = await this.modalCtrl.create({
      component: SettingsModalComponent
    });
    modal.present();
    const data = await modal.onDidDismiss();
    return data.data as boolean;
  }

  /**
  * Profile page
  */
  public async onProfile() {
    const modal = await this.modalCtrl.create({
      component: MyProfilModalComponent
    });
    modal.present();
  }

  /**
    * Notifications page
    */
  public async onNotifications() {
    const modal = await this.modalCtrl.create({
      component: SettingsNotifPageModalComponent
    });
    modal.present();
  }

  /**
    * Security page
    */
  public async onSecurity() {
    const modal = await this.modalCtrl.create({
      component: SettingsSecurityPageModalComponent
    });
    modal.present();
  }

  public async onExportCalendar() {
    const modal = await this.modalCtrl.create({
      component: SettingsExportCalendarPageModalComponent,
      cssClass: this.isCordova ? "" : "override-width"
    });
    modal.present();
  }

  public async onBleDevices() {
    const modal = await this.modalCtrl.create({
      component: MyBleDevicesPage
    });
    modal.present();
  }

  public async onDeleteAccount() {
    const modal = await this.modalCtrl.create({
      component: DeleteAccountComponent
    });
    await modal.present();
  }

  /**
   *  cancel modal
   */
  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
