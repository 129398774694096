import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/baseClasses/base-component';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { GoToPageService } from 'src/app/services/go-to-page.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { LoaderService } from 'src/app/services/loader.service';
import { LoginService } from 'src/app/services/login.service';
import { ModalService } from 'src/app/services/modal.service';
import { PopupService } from 'src/app/services/popup.service';
import { SynchronisationService, SynchronisationServiceStatus } from 'src/app/services/synchronisation.service';

@Component({
  selector: 'app-extra-menu-pop-over',
  templateUrl: './extra-menu-pop-over.component.html',
  styleUrls: ['./extra-menu-pop-over.component.scss'],
})
export class ExtraMenuPopOverComponent extends BaseComponent {

  constructor(
    protected popover: PopoverController,
    protected popupService: PopupService,
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    public loginService: LoginService,
    protected infoService: InfoAppService,
    protected synchronisationService: SynchronisationService,
    protected loaderService: LoaderService,
    protected goToPageService: GoToPageService,
    protected modalCtrl: ModalService
  ) {
    super(infoService, popupService);
    this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());
  }

  public async onSync() {
    try {
      await this.loaderService.showLoading(true);
      await this.popover.dismiss();
      const synchroStatus = await this.synchronisationService.syncApp();
      await this.loaderService.showLoading(false);
      switch (synchroStatus) {
        case SynchronisationServiceStatus.alreadyInProgress:
            console.info("Sync already in progress");
            break;
        case SynchronisationServiceStatus.noCordovaPlatform:
            console.info("No cordova platform, no sync");
            break;
        case SynchronisationServiceStatus.offline:
            this.popupService.showAlert("application.title", "synchro.error.offline_instead_of_online");
            break;
        case SynchronisationServiceStatus.missingAccountOrToken: // TODO bad token :faire de même
            this.goToPageService.loginPage();
            break;
        case SynchronisationServiceStatus.error:
            this.popupService.showAlert("application.title", "synchro.error.subTitle");
            break;
        case SynchronisationServiceStatus.success:
            console.info("onSync SUCCESS");
            break;
      }
    } catch (error) {
      console.error(error, "ExtraMenuPopOverComponent-onSync");
      await this.loaderService.showLoading(false);
      await this.popover.dismiss();
      await this.popupService.showAlert("application.title", "synchro.error.subTitle");
    }
  }

  /**
   * Display about modal
   */
  public onAbout() {
    this.modalCtrl.presentModalAbout().then(() => { this.popover.dismiss(); });
  }

  /**
  * Display help modal
  */
  public onHelp() {
    this.modalCtrl.presentModalHelp().then(() => { this.popover.dismiss(); });
  }

  /**
   * Display share modal
   */
  public onShare() {
    this.modalCtrl.presentModalShare().then(() => { this.popover.dismiss(); });
  }

  /**
   * Display settings
   */
  public onSettingsMenu() {
    this.modalCtrl.presentModalSettingsMenu().then(() => { this.popover.dismiss(); });
}

}
