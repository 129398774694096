import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'includes' })
export class IncludesPipe implements PipeTransform {
  constructor() { }

  transform(element: any[] | string, el: any): boolean {

    if (element === null || element === undefined) {
      console.warn('element from pipe "includes" is ' + element);
      return false;
    }
    return element.includes(el);
  }
}
