import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ArrayHelper } from 'src/app/helpers/array-helper';
import { FHIR_ActivityHelper } from 'src/app/helpers/fhirActivityHelper';
import { Tools } from 'src/app/helpers/tools-helper';
import { ICareplan } from 'src/app/models/careplan';
import { ICheckSum } from 'src/app/models/checksum';
import { AccountService } from './account.service';
import { ConfigurationService } from './configuration.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { HashService } from '../hash.service';

@Injectable({
  providedIn: 'root'
})
export class CareplanService extends BasicSyncService<ICareplan, ICareplan[]> {

  private currentCareplan$ = new BehaviorSubject<ICareplan | null>(null);

  private lastValueOfParam = '';
  private storageKey = 'careplanLastValue';

  public get entityStoreKey() {
    return super.entityStoreKey + this.lastValueOfParam;
  }

  constructor(
    protected dataService: DataService,
    private configService: ConfigurationService,
    private accountService: AccountService,
    private localStorage: LocalStorageService
  ) {
    super(dataService);
  }

  public getUrl() {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<ICareplan[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'careplan_',
      entityStoreKey: 'list',
      getUrl: '/careplans?language=',
      setUrl: '/careplan',
      expirationDays: 10,
      encrypted: true,
    };
  }

  /**
   * Watch the changes in the currentCareplan
   * @return a observable with the currentCareplan
   */
  public watchCurrentCareplan(): Observable<ICareplan> { return this.currentCareplan$; }
  /**
   * Returns the current state of the currentCareplan
   */
  public peekCurrentCareplan(): ICareplan { return this.currentCareplan$.value; }
  /**
   * Tell all those that watch the currentCareplan that there's a new version
   * @param currentCareplan the new currentCareplan
   */
  public pokeCurrentCareplan(careplan: ICareplan): void { this.currentCareplan$.next(careplan); }

  public async init(): Promise<void> {
    try {
      super.init();
      this.currentCareplan$.next(null);
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, false);
    } catch (err) {
      this.lastValueOfParam = '';
    }
  }

  public clear() {
    super.clear();
    this.currentCareplan$ = new BehaviorSubject<ICareplan | null>(null);
    this.lastValueOfParam = '';
  }

  public async *getDataReader(): AsyncGenerator<ICareplan[], ICareplan[], ICareplan[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }

      this.lastValueOfParam = this.configService.getCurrentLanguage();
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, false);

      const paramObject = Object.assign({}, this.defaultDataParameter);
      paramObject.getUrl += this.configService.getCurrentLanguage();
      paramObject.entityStoreKey += this.configService.getCurrentLanguage();
      const dataReader = this.dataService.readv2<ICareplan, ICareplan[]>(paramObject, false, this);
      let d: ICareplan[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      console.error("CareplanService getDataReader()", err);
      yield [];
      return [];
    }
  }

  /**
   * by default, return the list of the snomed ref of careplans and activities
   * if onlyCareplan = true, return the list of the snomed ref of only careplans
   */
  public async listSnomedRef(onlyCareplan = false): Promise<string[]> {
    const careplans = await this.getFirstDataAvailable();
    return careplans.map((careplan) => {
      const snomedActivities = onlyCareplan ? [] as string[] : careplan.activity.map((activity) => {
        return FHIR_ActivityHelper.getActivitySnomedRef(activity);
      }).reduce((acc, it) => [...acc, ...it], []);
      return snomedActivities.concat(FHIR_ActivityHelper.getCareplanSnomedRef(careplan));
    }).reduce((acc, it) => [...acc, ...it], [])
      .filter(ArrayHelper.onlyUnique)
      .map(Tools.deleteAcccentSpecialcharacter);
  }

  /**
   * return the list of the snomed ref of activities
   */
  public async listSnomedRefActivities(): Promise<string[]> {
    const careplans = await this.getFirstDataAvailable();
    return careplans.map((careplan) => {
      const snomedActivities = careplan.activity.map((activity) => {
        return FHIR_ActivityHelper.getActivitySnomedRef(activity);
      }).reduce((acc, it) => [...acc, ...it], []);
      return snomedActivities;
    }).reduce((acc, it) => [...acc, ...it], [])
      .filter(ArrayHelper.onlyUnique)
      .map(Tools.deleteAcccentSpecialcharacter);
  }

  public async getCareplansIds(): Promise<string[]> {
    const cps = await this.getFreshestData();
    return cps.map(cp => cp.support?.find(s => s.display === 'main careplan reference')?.reference);
  }

  public async checkSum(): Promise<ICheckSum> {
    const paramObject = Object.assign({}, this.defaultDataParameter);
    paramObject.getUrl = this.getUrl();
    paramObject.entityStoreKey = this.entityStoreKey;
    const dataReader = this.dataService.readv2<ICareplan, ICareplan[]>(paramObject, true);
    const localDataIt = await dataReader.next();
    const localData = localDataIt.value;
    return {
      checkSum: HashService.getMd5HashOfObject(localData, ["actionStatus"]),
      nameRoute: paramObject.getUrl
    } as ICheckSum;
  }
}
