import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppVersion } from '@ionic-native/app-version/ngx';

import { IonicStorageModule } from '@ionic/storage-angular';
import { AES256 } from '@ionic-native/aes-256/ngx';

import { Network } from "@ionic-native/network/ngx";
import { CookieService } from 'ngx-cookie-service';
import { ExtraMenuPopOverComponent } from './components/extra-menu-pop-over/extra-menu-pop-over.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';

import { Camera } from '@ionic-native/camera/ngx';
import { MomentModule } from 'ngx-moment';

import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SimpleLoadingStrategy } from './simpleLoadingStrategy';

import { AutoCompleteModule } from 'ionic4-auto-complete';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Health } from '@ionic-native/health/ngx';
import { Market } from '@ionic-native/market/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

import { Deeplinks } from '@ionic-native/deeplinks/ngx';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';

import { SecureStorageEcho } from "@ionic-native/secure-storage-echo/ngx";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './commonModules/pipes.module';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { BluetoothLE } from "@awesome-cordova-plugins/bluetooth-le/ngx";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ExtraMenuPopOverComponent,
    SideNavComponent
  ],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    }),
    IonicModule.forRoot({
      swipeBackEnabled: false
    }),
    IonicStorageModule.forRoot({
      name: "storagecare",
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    }),
    AppRoutingModule,
    MomentModule,
    AutoCompleteModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  providers: [
    StatusBar,
    AppVersion,
    AES256,
    Network,
    CookieService,
    Camera,
    FingerprintAIO,
    InAppBrowser,
    SimpleLoadingStrategy,
    File,
    FileOpener,
    Deeplinks,
    Health,
    Market,
    LocalNotifications,
    SecureStorageEcho,
    Device,
    BluetoothLE,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
