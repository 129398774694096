import { Injectable } from '@angular/core';
import { last, takeLast } from 'rxjs/operators';
import { AppConstants } from 'src/app/appConstants';
import { ServerResponse } from 'src/app/helpers/server-response-helper';
import { ApiService } from '../api.service';
import { GoToPageService } from '../go-to-page.service';
import { InfoAppService } from '../info-app.service';
import { InitService } from '../init.service';
import { LoginService, LOGIN_TYPE } from '../login.service';
import { PopupService } from '../popup.service';
import { SynchronisationService } from '../synchronisation.service';
import { SysAccountService } from '../sys-account.service';
import { LoginPage } from 'src/app/login/login.page';
import { RelatedPatientsService } from '../globalDataProvider/related-patients.service';
import { LoaderService } from '../loader.service';

@Injectable({
  providedIn: 'root'
})
export class SsoService {

  constructor(
    private sysAccountService: SysAccountService,
    private loginService: LoginService,
    private infoAppService: InfoAppService,
    private apiService: ApiService,
    private goToPageService: GoToPageService,
    private popupService: PopupService,
    private initService: InitService,
    private synchroService: SynchronisationService,
    private relatedPatientService: RelatedPatientsService,
    private loaderService: LoaderService
  ) { }

  public async managementSSO(org: string, mode: string, token: string) {
    try {
      // check the param + setAppMode 
      const checkResult = await this.deeplinkCheckParamSSO(org, mode, token);
      if (checkResult) {
        const response = await this.apiService.getWithPromise("/account", token);
        if (ServerResponse.isAuthenticationSuccessful(response)) {
          const data = response.data;
          const caremateIdentifier = data?.account?.caremateIdentifier as string;
          const localSysAccount = await this.sysAccountService.getSysAccount().catch(() => null);
          const alreadyConnected = await this.infoAppService.getSomeoneLogIn();
          if (alreadyConnected && caremateIdentifier && caremateIdentifier === localSysAccount?.name && localSysAccount?.token) {
            console.info("deeplinkManagementSSO", "already connect with the correct account");
            if (data?.token && data?.token !== "") {
              await this.sysAccountService.setRefreshToken(token);
            }
          }
          else {
            await this.loaderService.showLoading(true);
            if (alreadyConnected && localSysAccount?.name && localSysAccount?.token) {
              console.info("deeplinkManagementSSO", "already connect but with a bad account");
              await this.initService.promiseResolveWhenReady();
              await this.synchroService.promiseResolveWhenReady();
              this.relatedPatientService.setRelatedPersonShown(null);
              if (!LoginPage.onLoginPage) {
                await this.loginService.disconnect();
              }
            }
            else {
              console.info("deeplinkManagementSSO", "no account connected");
            }
            this.loginService.authenticate(null, null, response.data).pipe(takeLast(1)).subscribe(
              async (type: LOGIN_TYPE) => {
                switch (type) {
                  case LOGIN_TYPE.SUCCESS:
                    this.goToPageService.homePage({
                      synchro: true
                    });
                    break;
                  default:
                    this.popupService.showAlert("login.refused.title", "login.error.title");
                    break;
                }
                await this.loaderService.showLoading(false);
              },
              async (err: any) => {
                console.error("deeplinkManagementSSO - this.loginService.authenticate", err);
                await this.loaderService.showLoading(false);
              }
            );
          }
        }
        else {
          console.error("GET /account success false", response.message);
        }
      }
      else {
        console.error("deeplinkManagementSSO bad params", org, mode, token);
      }
    } catch (error) {
      console.error(`deeplinkManagementSSO error - ${org}, ${mode}, ${token}`, error);
      await this.loaderService.showLoading(false);
    }
  }

  private async deeplinkCheckParamSSO(org: string, mode: string, token: string): Promise<boolean> {

    if (!mode || !AppConstants.DEEPLINK_VALID_MODE.includes(mode)) {
      return false;
    }
    // setAppMode before to call getOrganizationsSSO
    switch (mode) {
      case AppConstants.DEEPLINK_MODE_DEV:
        await this.infoAppService.setCurrentMode("FORCE_DEV");
        break;
      case AppConstants.DEEPLINK_MODE_TEST:
        await this.infoAppService.setCurrentMode("DEV");
        break;
      case AppConstants.DEEPLINK_MODE_PROD:
        await this.infoAppService.setCurrentMode("PROD");
        break;
    }

    const res = await this.apiService.getWithPromise("/organizationsSSO", token);
    if (!ServerResponse.isAuthenticationSuccessful(res)) {
      return false;
    }
    const orgs: string[] = res.data;
    if (org && orgs.map((o: string) => o.toLowerCase()).includes(org) && token) {
      return true;
    }
    return false;
  }

}
