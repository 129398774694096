import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IVitalProfileDefinition } from "src/app/models/sharedInterfaces";
import { BasicSyncService } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
    providedIn: 'root'
})
export class VitalProfileDefinitionsService extends BasicSyncService<IVitalProfileDefinition, IVitalProfileDefinition[]> {

    constructor(protected dataService: DataService) {
        super(dataService);
    }
  
    protected clearWatch(): void {
      this.data$ = new BehaviorSubject<IVitalProfileDefinition[]>([]);
    }
  
    protected initWatch(): void {
      this.data$.next([]);
    }

    protected setupDataParameters(): void {
      this.defaultDataParameter = {
        entityPrefix: 'vitalProfileDefinitions',
        entityStoreKey: 'list',
        getUrl: '/vitalProfileDefinitions',
        setUrl: null,
        expirationDays: 10,
        encrypted: false,
        };
    }
}
