import { Injectable } from "@angular/core";
import * as moment from "moment";
import { AppConstants } from "src/app/appConstants";
import { Tools } from "src/app/helpers/tools-helper";
import { NOTIFICATION_SYSTEM_STATUS, INotification, NOTIFICATION_TYPE, NOTIFICATION_STATUS } from "../../models/notification";
import { SysAccountService } from "../sys-account.service";
import { NotificationsSaveService } from "./notifications-save.service";
import { NavController } from '@ionic/angular';
import { ILocalNotification, LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { InfoAppService } from '../info-app.service';
import { GoToPageService } from 'src/app/services/go-to-page.service';
import { AccountService } from "../globalDataProvider/account.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsPluginService {
  constructor(
    private localNotifications: LocalNotifications,
    private sysAccountService: SysAccountService,
    private notificationsSaveService: NotificationsSaveService,
    private infoAppService: InfoAppService,
    private goToPageService: GoToPageService,
    private accountService: AccountService
  ) {
  }

  // do not generate more than X days of notifications 
  public static get maxDayGeneration() {
    return 3;
  }

  /**
   * Request plugin to get all local notifications convert to INotification
   */
  public async getAllNotificationsInPlugin(): Promise<INotification[]> {
    const notifs = await this.getAllLocalNotifications();
    return notifs.map((n) => {
      return this.pluginNotificationtoNotification(n);
    });
  }

  /**
   * Request plugin to get all local notifications
   */
  private async getAllLocalNotifications(): Promise<ILocalNotification[]> {
    if (!this.infoAppService.isCordova()) {
      console.log("getAllNotificationsInPlugin", "this platform is not cordova, so not notification plugin");
      return Promise.resolve([]);
    }
    const notif = await this.localNotifications.getAll();
    if (!notif || notif.length === 0) {
      return [];
    }
    else {
      return notif;
    }
  }

  /**
   * Request plugin to get all local notifications with a given type
   * @param type 
   */
  private async getAllLocalNotificationsByType(type: NOTIFICATION_TYPE): Promise<ILocalNotification[]> {
    const notifs = await this.getAllLocalNotifications();
    return notifs.filter(_ => this.pluginNotificationtoNotification(_).ntype === type);
  }

  /**
   * set the local notifications with a given type in the plugin :
   *  - delete all local notifications which are not in the parameters
   *  - update and create the local notification which are in the parameters
   * This method return all local notifications with the given type which must be in the plugin
   * @param notifications 
   * @param type 
   */
  public async setLocalNotificationsByType(notifications: INotification[], type: NOTIFICATION_TYPE): Promise<INotification[]> {
    if (!this.infoAppService.isCordova()) {
      console.log("resetAndSetNotificationsInPlugin", "this platform is not cordova, so no notification plugin");
      return [];
    }

    const notifToSend = this.filterNotificationsToSend(notifications);

    const notifsPlugin = await this.getAllLocalNotificationsByType(type);
    const notifsToAdd = new Array<INotification>();
    const notifsPluginToNotif = notifsPlugin.map(_ => this.pluginNotificationtoNotification(_));
    notifToSend.forEach((n) => {
      if (!this.notificationsSaveService.contain(n, notifsPluginToNotif)) {
        notifsToAdd.push(n);
      }
    });
    const localNotifsToDelete = new Array<ILocalNotification>();
    const localNotifsToKeep = new Array<ILocalNotification>();
    notifsPlugin.forEach((n_1) => {
      const nToNotif = this.pluginNotificationtoNotification(n_1);
      if (!this.notificationsSaveService.contain(nToNotif, notifToSend)) {
        localNotifsToDelete.push(n_1);
      }
      else {
        localNotifsToKeep.push(n_1);
      }
    });
    await this.localNotifications.cancel(localNotifsToDelete.map(__1 => __1.id));
    const notifsSent = this.setNotificationsInPlugin(notifsToAdd);
    return notifsSent.concat(localNotifsToKeep.map(__2 => this.pluginNotificationtoNotification(__2)));
  }

  /**
   * This method update or create the local notifications which are in the paramaters
   * This method return ALL local notifications with the given type which must be in the plugin
   * @param notifications 
   * @param type 
   */
  public async updateOrCreateLocalNotificationsByType(notifications: INotification[], type: NOTIFICATION_TYPE): Promise<INotification[]> {
    if (!this.infoAppService.isCordova()) {
      console.log("updateOrCreateLocalNotificationsByType", "this platform is not cordova, so not notification plugin");
      return Promise.resolve([]);
    }

    const notifToSend = this.filterNotificationsToSend(notifications);

    const notifsPlugin = await this.getAllLocalNotificationsByType(type);
    const notifToUpdate = new Array<ILocalNotification>();
    const localNotifsToKeep = new Array<ILocalNotification>();
    notifsPlugin.forEach((notif) => {
      if (this.notificationsSaveService.containSimilar(this.pluginNotificationtoNotification(notif), notifToSend)) {
        notifToUpdate.push(notif);
      }
      else {
        localNotifsToKeep.push(notif);
      }
    });
    await this.localNotifications.cancel(notifToUpdate.map(_ => _.id));
    const notifsSent = this.setNotificationsInPlugin(notifToSend);
    return notifsSent.concat(localNotifsToKeep.map(__1 => this.pluginNotificationtoNotification(__1)));
  }

  /**
   * This method delete the local notifications associated to a drug (with appId) and an account
   * @param account 
   * @param appId id of a drug
   * @return This method return ALL local notifications of type "drug" which must be in the plugin.
   */
  public async deleteLocalNotificationsOfDrug(account: string, appId: string): Promise<INotification[]> {
    if (!this.infoAppService.isCordova()) {
      console.log("deleteLocalNotificationsOfDrug", "this platform is not cordova, so not notification plugin");
      return Promise.resolve([]);
    }

    const notifsDrugPlugin = await this.getAllLocalNotificationsByType(NOTIFICATION_TYPE.DRUG);
    const notifsDrugToDelete: ILocalNotification[] = [];
    const notifsDrugToKeep: INotification[] = [];
    notifsDrugPlugin.forEach((drugPlugin) => {
      const drugNotif = this.pluginNotificationtoNotification(drugPlugin);
      if (drugNotif.account === account && drugNotif.appId === appId) {
        notifsDrugToDelete.push(drugPlugin);
      }
      else {
        notifsDrugToKeep.push(drugNotif);
      }
    });
    await this.localNotifications.cancel(notifsDrugToDelete.map(_ => _.id));
    return notifsDrugToKeep;

  }

  /**
   * This method delete the local notifications which are in the paramaters.
   * This method return ALL local notifications with the given type which must be in the plugin.
   * @param notifications 
   * @param type 
   */
  public async deleteLocalNotificationsByType(notifications: INotification[], type: NOTIFICATION_TYPE): Promise<INotification[]> {
    if (!this.infoAppService.isCordova()) {
      console.log("deleteLocalNotificationsByType", "this platform is not cordova, so not notification plugin");
      return Promise.resolve([]);
    }

    const notifsPlugin = await this.getAllLocalNotificationsByType(type);
    const notifToDelete = new Array<ILocalNotification>();
    const localNotifsToKeep = new Array<ILocalNotification>();
    notifsPlugin.forEach((notif) => {
      if (this.notificationsSaveService.containSimilar(this.pluginNotificationtoNotification(notif), notifications)) {
        notifToDelete.push(notif);
      }
      else {
        localNotifsToKeep.push(notif);
      }
    });
    await this.localNotifications.cancel(notifToDelete.map(_ => _.id));
    return localNotifsToKeep.map(__1 => this.pluginNotificationtoNotification(__1));
  }

  public async deleteOneNotif(notification: INotification) {
    if (!this.infoAppService.isCordova()) {
      console.log("deleteLocalNotificationsByType", "this platform is not cordova, so not notification plugin");
      return Promise.resolve([]);
    }

    const notifsPlugin = await this.getAllLocalNotificationsByType(notification.ntype);
    const notifFound = notifsPlugin.find((notif) => this.notificationsSaveService.contain(this.pluginNotificationtoNotification(notif), [notification]));
    if (notifFound) {
      return this.localNotifications.cancel(notifFound.id);
    }
    else {
      Promise.resolve();
    }
  }

  public async deleteAllLocalNotifs() {
    await this.localNotifications.cancelAll();
    await this.localNotifications.clearAll();
  }

  /**
   * This method schedule the notifications passed in parameters that can be sent.
   * This method return the notifications actually sent to the plugin. 
   * @param notifications 
   */
  private setNotificationsInPlugin(notifications: INotification[]): INotification[] {

    if (!notifications || notifications.length === 0) {
      return [];
    }

    const arrINotif = new Array<ILocalNotification>();
    notifications.forEach((notif) => {
      notif.systemId = Tools.genIdNumber();
      const nLocal = this.notificationToPluginNotification(notif);
      if (nLocal.trigger) {
        arrINotif.push(nLocal);
      }
    });
    this.localNotifications.schedule(arrINotif);
    return arrINotif.map((n) => {
      return this.pluginNotificationtoNotification(n);
    });
  }

  /**
   * do not generate notification for the past and not for the far futur (max X days)
   * @param notifications 
   */
  private filterNotificationsToSend(notifications: INotification[]): INotification[] {
    return notifications.filter((notif) => moment().isSameOrBefore(notif.time) &&
      moment().add(NotificationsPluginService.maxDayGeneration, "days").isSameOrAfter(notif.time, "day") &&
      notif.status === NOTIFICATION_STATUS.NONE
    );
  }

  /**
   * cast Notification structure to LocalNotification format for Cordova Plugin
   * @param notification 
   */
  private notificationToPluginNotification(notification: INotification): ILocalNotification {
    const message = notification.drugComment ? notification.message + " : " + notification.drugComment : notification.message;
    const notif: ILocalNotification = {
      data: notification,
      id: notification.systemId,
      title: "Comunicare",
      text: notification.time ? moment(notification.time).format("H:mm") + " " + message : message,
      trigger: notification.trigger
    };
    // only on Android device
    if (this.infoAppService.isAndroid()) {
      notif.led = { color: '#34AFBC', on: 500, off: 500 };
      // notif.icon = "res://notif2.png";
      // notif.smallIcon = "res://notif2.png"; // notif2.png is not present
      // notif.color = "FFFFFF";
    }
    return notif;
  }

  /**
   * cast LocalNotification of the Cordova Plugin to Notification
   * @param notification 
   */
  private pluginNotificationtoNotification(notification: ILocalNotification): INotification {
    try {
      // case where the ILocalNotification comes from plugin
      const parseNotif = JSON.parse(notification.data) as INotification;
      return parseNotif;
    }
    catch (err) {
      // console.log("error ", err);
      // normally the case where the ILocalNotification is not yet passed by the plugin
      return notification.data as INotification;
    }
  }

  /**
  * Event trigered notification
  * @param localNotification
  */
  private eventOnTrigger(localNotification: any) {
    console.info("Notif triggered", localNotification);
    const notification = this.pluginNotificationtoNotification(localNotification);

    // mettre à jour, supprimer local storage

    this.notificationsSaveService.updateLocalNotificationSystemStatus(notification, NOTIFICATION_SYSTEM_STATUS.TRIGGERED)
      .then(() => {
        this.sendEventNotifGenerated();
      });
  }

  /**
   *  Event Cleared notification
   * @param localNotification
   */
  private eventOnClear(localNotification: any) {
    console.info("Notif clear", localNotification);
    const notification = this.pluginNotificationtoNotification(localNotification);

    // mettre à jour, supprimer local storage


    this.notificationsSaveService.updateLocalNotificationSystemStatus(notification, NOTIFICATION_SYSTEM_STATUS.CLEARED)
      .then(() => {
        this.sendEventNotifGenerated();
      });
  }

  /**
   *  Event Clicked notification
   * @param localNotification
   */
  private async eventOnClick(localNotification: any) {

    const caremateId = (await this.sysAccountService.getSysAccount()).name;
    await this.accountService.getFirstDataAvailable();

    const notificationData = this.pluginNotificationtoNotification(localNotification);

    switch (notificationData.ntype) {
      case NOTIFICATION_TYPE.DRUG:
        this.goToPageService.homePage({
          account: caremateId,
          highlightNotification: notificationData
        });
        break;
      case NOTIFICATION_TYPE.OBSERVATION:
        this.goToPageService.observationPage({
          account: caremateId,
          notification: notificationData
        }, true);
        break;
      case NOTIFICATION_TYPE.FEELING:
        this.goToPageService.feelingPage({
          account: caremateId,
          notification: notificationData
        }, true);
        break;
      case NOTIFICATION_TYPE.ALERT_OBSERVATION: {
        this.goToPageService.observationPage({
          account: caremateId,
          notification: notificationData
        }, true);
        break;
      }
      default:
        this.goToPageService.homePage({
          account: caremateId,
        });
        break;
    }
  }

  /**
   * Bind Plugin events
   * When app is killed, notification events are not directly transmitted to app
   * System wait for app to be launch again before transmit events
   */
  public bindNotificationEvents() {
    if (!this.infoAppService.isCordova()) {
      console.log("bindNotificationEvents", "this platform is not cordova, so not notification plugin");
      return Promise.resolve([]);
    }

    this.localNotifications.on("trigger").subscribe(
      (notif) => {
        this.eventOnTrigger(notif);
      },
      (err) => {
        console.error("bindNotificationEvents on Trigger failed", err);
      }
    );

    this.localNotifications.on("clear").subscribe(
      (notif) => {
        this.eventOnClear(notif);
      },
      (err) => {
        console.error("bindNotificationEvents on Clear failed", err);
      }
    );

    this.localNotifications.on("click").subscribe(
      (notif) => {
        setTimeout(() => { this.eventOnClick(notif); }, 2000);
      },
      (err) => {
        console.error("bindNotificationEvents on Click failed", err);
      }
    );

    this.localNotifications.fireQueuedEvents();

  }

  /**
   * Send Event "notification updated" but prevent from consecutive sending within same second (it may happens when app starts)
   */
  public sendEventNotifGenerated() {
    console.info("NotificationManager publish EV_NOTIF_UPDATED");
    // this.events.publish(AppConstants.EV_NOTIF_UPDATED); 
  }
}
