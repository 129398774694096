import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IKnowledges } from 'src/app/helpers/knowledge-helper';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { RelatedDrugsService } from './related-drugs.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { Tools } from 'src/app/helpers/tools-helper';
import { RelatedCareplansService } from './related-careplans.service';
import { ArrayHelper } from 'src/app/helpers/array-helper';

@Injectable({
  providedIn: 'root'
})
export class RelatedKnowledgeService extends BasicSyncService<IKnowledges, IKnowledges[]> {
  
  private lastValueOfParam = '[]';
  private storageKey = 'RelatedKnowledgeLastValue';

  private careplanWatch$: Subscription;

  constructor(
    protected dataService: DataService,
    private relatedDrugsService: RelatedDrugsService,
    private relatedCareplansService: RelatedCareplansService,
    private accountService: AccountService,
    private localStorage: LocalStorageService) {
    super(dataService);
    this.careplanWatch$ = this.relatedCareplansService.watchData().subscribe(careplans => {
      this.needRefresh = true;
    });
  }

  public getUrl() {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
    this.careplanWatch$?.unsubscribe();
    this.careplanWatch$ = this.relatedCareplansService?.watchData().subscribe(c => {
      this.needRefresh = true;
    });
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.careplanWatch$?.unsubscribe();
    this.careplanWatch$ = this.relatedCareplansService?.watchData().subscribe(c => {
      this.needRefresh = true;
    });
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'related_knwoledges_',
      entityStoreKey: 'list',
      getUrl: '/knowledge?reference=',
      setUrl: '/knowledge',
      expirationDays: 10,
      encrypted: false
    };
  }

  public getDependentServices(): BasicSyncService<any, any[] | any>[] { 
    return [this.relatedCareplansService]; 
  }

  public async init() {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = '[]';
    }
  }

  public clear() {
    super.clear();
    this.lastValueOfParam = '[]';
  }

  public async *getDataReader(parameters?: string[])
      : AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {

    try {
      if (this.accountService.isOnlyPatient()) { 
        yield [];
        return [];
      }
      let params = [];
      if (parameters) {
        params = parameters.map(value => Tools.deleteAcccentSpecialcharacter(value.toLowerCase()));
      }
      const relatedCareplanSnomed = await this.relatedCareplansService.listSnomedRef();
      const relatedDrugSnomed = await this.relatedDrugsService.listSnomedRef();
      const allSnomed = relatedCareplanSnomed.concat(relatedDrugSnomed).filter(ArrayHelper.onlyUnique).sort();

      const paramObject = Object.assign({}, this.defaultDataParameter);
      const stringifySnomed = JSON.stringify(allSnomed);
      paramObject.getUrl += stringifySnomed;
      // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
      // paramObject.entityStoreKey += stringifySnomed;
      this.lastValueOfParam = stringifySnomed;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);
  
      const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, false, this);
      let d: IKnowledges[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, params);
        yield d;
      }
      return d;
    } catch (err) {
      console.error("RelatedKnowledgeService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], params?: string[]) {
    try {
      const kn = dataResult;
      if (!params) { return kn; }
      return kn.filter((kk) => {
        const parsedRef = Tools.deleteAcccentSpecialcharacter(kk.reference.toLowerCase());
        return params.includes(parsedRef);
      });
    } catch (err) {
      console.error('Error while processing relatedKnowledgeService data: ', err);
      return [];
    }
  }

}
