import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Platform } from "@ionic/angular";
import { AppConstants } from "./app/appConstants";
import { InfoAppService } from "./app/services/info-app.service";
import { LocalStorageService } from "./app/services/storage/local-storage.service";
import { SecureStorageService } from "./app/services/storage/secure-storage.service";

@Injectable({
    providedIn: 'root'
})
export class LoginResolver implements Resolve<any> {

    constructor(
        private localStorageService: LocalStorageService,
        private infoAppService: InfoAppService,
        private secureStorageService: SecureStorageService,
        private platform: Platform
    ) { }

    /**
     * 
     * @returns true if the secure storage is activated, false otherwise
     */
    async resolve(): Promise<boolean> {
        try {
            await this.platform.ready();
            /*
                These lines correctly configure the migration ionic 3 -> ionic 5.
                If the localstorage is empty, indicate that it is not necessary to do the migration and 
                AppConstants.NEW_INSTALLATION_FROM_STORE = true
            */
            const hasLocalData = this.infoAppService.isCordova() ? await this.localStorageService.hasLocalData() : false;
            if (!hasLocalData || !this.infoAppService.isCordova()) {
                await this.localStorageService.setData(AppConstants.NEW_INSTALLATION_FROM_STORE, AppConstants.TRUE, false);
                await this.localStorageService.setData(AppConstants.IONIC5CLEAR, 'done', false);
            }
            return this.secureStorageService.isReady().catch(() => false);
        } catch (err) {
            console.error("LoginResolver", err);
            return false;
        }
    }

}
