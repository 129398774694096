
export enum LocalStorageErrorType {
    NOT_IN_STORAGE,
    STORAGE_NOT_INITIALIZED,
    STORAGE_INITIALIZATION_ERROR,
    CANNOT_STORE_CREDENTIALS,
    CANNOT_DECRYPT_DATA,
    DATA_EXPIRED,
}

export class LocalStorageError extends Error {
    public constructor(message: string, public readonly type: LocalStorageErrorType) {
        super(message);
    }
}
