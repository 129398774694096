import { Component } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/app/appConstants';
import { BasePage } from 'src/app/baseClasses/base-page';
import { IQuestionnaire } from 'src/app/models/questionnaire';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { QuizService } from 'src/app/services/quiz.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent extends BasePage {

  public data: { page: number, title: string }[];
  public quizzes: IQuestionnaire[] = [];
  private mediaId: string;

  constructor(
    protected navParams: NavParams,
    protected popoverCtrl: PopoverController,
    protected quizService: QuizService,
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected infoService: InfoAppService,
    protected popupService: PopupService

  ) {
    super(translateSvc, configService, infoService, popupService);
    this.data = this.navParams.get('data');
    this.quizzes = this.navParams.get('quizzes');
    this.mediaId = this.navParams.get('mediaId');
  }

  /**
   * Close the popover and send the page number to the knowledge modal component
   */
  public goTo(page: number) {
    this.popoverCtrl.dismiss({ data: page });
  }

  /**
   * Handle displaying a quiz
   */
  public async onDisplayQuiz() {
    await this.quizService.onDisplayQuiz(this.mediaId, AppConstants.PAGE_KNOWLEDGE, this.quizzes);
  }
}
