import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ArrayHelper } from 'src/app/helpers/array-helper';
import { EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from 'src/app/models/externalRessource';
import { InfoAppService } from '../info-app.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalRessourceService extends BasicSyncService<IExternalRessource, IExternalRessource[]> {

  constructor(
    protected dataService: DataService,
    private infoAppService: InfoAppService
  ) {
    super(dataService);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'external_resource_',
      entityStoreKey: 'list',
      getUrl: '/external-ressource/list',
      setUrl: undefined,
      expirationDays: 10,
      encrypted: false,
    };
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IExternalRessource[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  public async *getDataReader(search: string = "", type?: number[])
    : AsyncGenerator<IExternalRessource[], IExternalRessource[], IExternalRessource[]> {
    try {
      const dataReader = super.getDataReader();
      let d: IExternalRessource[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, search, type);
        yield d;
      }
      return d;
    } catch (err) {
      console.warn("ExternalRessourceService getDataReader()", err);
      yield [];
      yield [];
    }
  }

  private processData(dataResult: IExternalRessource[],
    search: string = "", type?: number[]) {
    try {
      const filteredData = dataResult.filter((externalRessource: IExternalRessource) => {

        if (type && !type.includes(externalRessource.type)) { return false; }
        if (externalRessource.title.indexOf(search) < 0 || externalRessource.description.indexOf(search) < 0) {
          return false;
        }
        if (externalRessource.type === EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE) {
          /* Some bluetooth devices are not compatible on all platforms. We only look if it is indicated "false". 
             In the other cases, we send back the device*/
          if (this.infoAppService.isAndroid() && externalRessource.meta?.platform?.android === "false") {
            return false;
          }
          if (this.infoAppService.isIOS() && externalRessource.meta?.platform?.ios === "false") {
            return false;
          }
        }
        return true;
      });
      return filteredData;
    } catch (err) {
      console.error('Error while processing externalRessourcesService data: ', err);
    }
    return dataResult;
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(search: string = "", type?: number[]): Promise<IExternalRessource[]> {
    const dataReader = this.getDataReader(search, type);
    let iterator = await dataReader.next();
    while (!iterator.done) { iterator = await dataReader.next(); }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(search: string = "", type?: number[]): Promise<IExternalRessource[]> {
    const dataReader = this.getDataReader(search, type);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  private async lsMeasurable(): Promise<string[]> {
    const btDevices = await this.getFreshestData('', [EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE]);
    return btDevices
      .map((b) => b.meta?.availableLoinc)
      .reduce((l1, l2) => l1.concat(l2), [])
      .filter(ArrayHelper.onlyUnique);
  }

  public async isMeasurable(observationType: string): Promise<boolean> {
    const allMeasurableDevices = await this.lsMeasurable();
    return allMeasurableDevices.includes(observationType);
  }

}
