import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FEATURES } from 'src/environments/features';
import { LoginResolver } from 'src/login.resolver';
import { TranslationLoaderResolver } from 'src/translation.loader.resolver';
import { AuthGuard } from './auth.gard';
import { ConsentGuard } from './consent.gard';
import { FeatureGuard } from './feature.gard';
import { SimpleLoadingStrategy } from './simpleLoadingStrategy';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home', // open directly on home page
    pathMatch: 'full',

  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [ConsentGuard, AuthGuard],
    resolve: {
      TranslationLoaderResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    resolve: {
      secureStorageActive : LoginResolver,
      TranslationLoaderResolver
    }
  },
  {
    path: 'mynotes',
    loadChildren: () => import('./mynotes/mynotes.module').then(m => m.MynotesPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.NOTES}
  },
  {
    path: 'mycare',
    loadChildren: () => import('./mycare/mycare.module').then(m => m.MycarePageModule),
    runGuardsAndResolvers: 'always',
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.CAREPLANS}
  },
  {
    path: 'mycontacts',
    loadChildren: () => import('./mycontacts/mycontacts.module').then( m => m.MycontactsPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.CONTACTS}
  },
  {
    path: 'myreward',
    loadChildren: () => import('./myreward/myreward.module').then(m => m.MyrewardPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.REWARDS}
  },
  {
    path: 'myfeelings',
    loadChildren: () => import('./myfeelings/myfeelings.module').then( m => m.MyfeelingsPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.FEELINGS}
  },
  {
    path: 'myadvices',
    loadChildren: () => import('./myadvices/myadvices.module').then( m => m.MyadvicesPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.ADVICE}
  },
  {
    path: 'myappointments',
    loadChildren: () => import('./myappointments/myappointments.module').then( m => m.MyappointmentsPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.AGENDA}
  },
  {
    path: 'myobservations',
    loadChildren: () => import('./myobservations/myobservations.module').then( m => m.MyobservationsPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.OBSERVATIONS}
  },
  {
    path: 'my-ble-devices',
    loadChildren: () => import('./my-ble-devices/my-ble-devices.module').then( m => m.MyBleDevicesPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.BLEDEVICES}
  },
  {
    path: 'mydrugs',
    loadChildren: () => import('./mydrugs/mydrugs.module').then( m => m.MydrugsPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.DRUGS}
  },
  {
    path: 'myentourage',
    loadChildren: () => import('./myentourage/myentourage.module').then( m => m.MyentouragePageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.RELATED}
  },
  {
    path: 'mymessages',
    loadChildren: () => import('./mymessages/mymessages.module').then( m => m.MymessagesPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.MESSAGES}
  },
  {
    path: 'myhealth',
    loadChildren: () => import('./myhealth/myhealth.module').then( m => m.MyhealthPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.HEALTH}
  },
  {
    path: 'recommandation',
    loadChildren: () => import('./recommandation/recommandation.module').then( m => m.RecommandationPageModule),
    canActivate: [ConsentGuard, AuthGuard, FeatureGuard],
    data: {feature : FEATURES.ADVICE}
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: SimpleLoadingStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
