import * as _ from 'lodash';
import * as moment from 'moment';
import { KeyValue } from '../models/keyValue';
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { AppConstants } from '../appConstants';
import * as CryptoJS from 'crypto-js';

/**
 * Common Tools
 */
export class Tools {
    static mean(arrValues: number[]) {
        if (arrValues?.length) {
            return arrValues.reduce((el, acc) => acc + el, 0) / arrValues?.length;
        } else {
            return 0;
        }
    }

    static uniqCompact(arr: string[]): string[] {
        return _.uniq(_.compact(arr));
    }
    /**
     *  make a deep copy of an object
     */
    public static deepCopy<T>(obj: T): T {
        if (!obj) { return null; }
        return _.cloneDeep(obj);
    }

    /**
     *  replace all values from source to target object
     */
    public static deepReplace(source: any, target: any) {
        _.assignIn(target, source);
    }

    public static isEqual(source1: any, source2: any) {
        return _.isEqual(source1, source2);
    }

    /**
     * The id must be < 2,147,483,647
     */
    public static genIdNumber(): number {
        return Math.random() * 2000000000;
    }

    /**
     * Generate a mongo _id valid
     */
    public static genValidId(): string {
        return this.hex(Date.now() / 1000) + this.genGuid(16);
    }

    /**
     * Transform a value to hexa caracters
     * @param value
     */
    private static hex(value) {
        return Math.floor(value).toString(16);
    }

    /**
     * generate a n-digit number where digit are hexa caracters (for example, si n = 8 : f4ec1b63)
     * @param n
     */
    public static genGuid(n: number) {
        // recall Math.random() belongs to [0, 1)
        return ' '.repeat(n).replace(/./g, () => this.hex((Math.random() * 16)));
    }

    /**
     * convert a moment string into second since Epoch
     *
     */
    public static time2Epoch(time: string): number {
        try {
            return moment(time).unix();
        } catch (err) {
            return moment().unix();
        }
    }

    /**
     * Return true if that day is saturday or sunday
     *
     */
    public static isWeekend(day: moment.Moment): boolean {
        try {
            const d = day.isoWeekday();
            return (d === 6) || (d === 7);
        } catch (err) {
            return false;
        }
    }

    /**
     *  return date string IS08601 now with 0 second set
     */
    public static nowISO8601(): string {
        moment.locale('fr');
        return moment().seconds(0).milliseconds(0).format();
    }

    /**
     * return date Now
     */
    public static getDate(): Date {
        return moment().toDate();
    }

    /**
     * return today (without hours, minutes, seconds,milliseconds)
     */
    public static getToday(): moment.Moment {
        return moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    }

    /**
     * return a day without hours, minutes, seconds,milliseconds
     * If date is not a valid date, return "today"
     */
    public static momentNoHours(day: string): moment.Moment {
        const m = moment(day);
        if (m.isValid()) return m.hours(0).minutes(0).seconds(0).milliseconds(0);
        else return Tools.getToday();
    }

    /**
     *  return tomorrow (without hours, minutes, seconds, milliseconds)
     */
    public static getTomorrow(): moment.Moment {
        return moment().hours(0).minutes(0).seconds(0).milliseconds(0).add(1, "days");
    }

    /**
     * Return a day at defined hour (without hours, minutes, seconds, milliseconds)
     *
     *
     */
    public static dayAtHour(day: moment.Moment, hour: number): moment.Moment {
        return day.clone().hours(hour).minutes(0).seconds(0).milliseconds(0);
    }

    /**
     * return today at defined hour (without hours, minutes, seconds, milliseconds)
     */
    public static todayAtHour(hour: number): moment.Moment {
        return moment().hours(hour).minutes(0).seconds(0).milliseconds(0);
    }

    /**
     * return tomorrow at defined hour (without hours, minutes, seconds, milliseconds)
     */
    public static tomorrowAtHour(hour: number): moment.Moment {
        return moment().add(1, "days").hours(hour).minutes(0).seconds(0).milliseconds(0);
    }

    /**
     * return week days long name (in locale) and day number (1=monday, 7=sunday)
     */
    public static keyDayNames(keyStr?: boolean): any[] {
        const dayNames = new Array<KeyValue>();
        for (let i = 1; i <= 7; i++) {
            if (keyStr) dayNames.push(new KeyValue(i.toString(), moment().isoWeekday(i).format('dddd')));
            else dayNames.push(new KeyValue(i, moment().isoWeekday(i).format('dddd')));
        }
        return dayNames;
    }


    /**
     * Generate random number of "size" digit
     */
    public static randomNumber(size: number): number {
        const minNb = Math.pow(10, size - 1);
        const maxNb = Math.pow(10, size) - 1;
        return _.random(minNb, maxNb);
        // return Math.floor(Math.random() * (maxNb - minNb) + minNb);
    }

    /**
   * Returns a random integer between min (included) and max (excluded)
   */
    public static getRandomInt(min, max): number {
        return _.random(min, max);
        // return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Check if N is a positive Integer
     *
     */
    public static isValidPositiveInteger(n: any): boolean {
        return !_.isNil(n) && _.isNumber(n) && _.isFinite(n) && (n > 0) && _.isInteger(n);
    }

    /**
    * Check if N is a positive Integer
    *
    */
    public static isValidNumber(n: any): boolean {
        return !_.isNil(n) && _.isNumber(n) && _.isFinite(n);
    }

    /**
   * Check if it is a valid phone number
   *
   */
    public static isValidPhoneNumber(phone: string): boolean {
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            const tel = phoneUtil.parse(phone, "BE");
            // console.info(phoneUtil.format(tel, PhoneNumberFormat.E164));
            return phoneUtil.isValidNumber(tel);
        }
        catch (err) {
            console.warn("isValidPhoneNumber", err);
            return false;
        }
    }

    /**
     * correctly format any phone number
     */
    public static getFormattedPhoneNumber(phone: string): string {
        try {
            phone = _.trim(phone);
            if (_.isEmpty(phone) || !_.isString(phone)) return "";

            const phoneUtil = PhoneNumberUtil.getInstance();
            const tel = phoneUtil.parse(phone, "BE");
            const phoneFmt = phoneUtil.format(tel, PhoneNumberFormat.E164);
            // console.info("getFormattedPhoneNumber", phoneFmt);
            return phoneFmt;
        }
        catch (err) {
            console.warn("getFormattedPhoneNumber", err);
            return "";
        }

    }

    /**
     *
     * Check if date year is more than 2000
     */
    public static isValidEndDate(date: Date) {
        if (!date || date.toString() === "Invalid Date") {
            console.warn(`source : isValidEndDate(date: Date) msg : Invalid Date -> ${date}`);
            return false;
        }
        return Number(date.toString()[0]) === 2;
    }

    /**
     * Useful if you need know if boolean value exist or just undefined
     * @param value
     */

    public static isValidBool(value: any) {
        return typeof value === 'boolean';
    }

    public static deleteAcccentSpecialcharacter(words: string): string {
        return words.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("%", "");
    }

    /**
    * Check if it is a valid email
    *
    */
    public static isValidEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    // import last CryptoHelper methods

    /**
    * crypt some data
    *  return the crypted data
    */
    public static cryptItWithKey(data: any, secret: string): string {
        try {
            // secret must 16 length because of Mobile App library (Browserify-aes)
            secret = _.padStart(secret, 16, "0");
            if (secret.length > 16) secret = secret.substring(0, 16);

            const key = CryptoJS.enc.Latin1.parse(secret);
            const iv = CryptoJS.enc.Latin1.parse(AppConstants.CRYPTO_IV);
            const dataParse = CryptoJS.enc.Latin1.parse(data);

            // AES-128
            const enc = CryptoJS.AES.encrypt(dataParse, key, {
                iv: iv,
                mode: CryptoJS.mode.CTR,
                padding: CryptoJS.pad.NoPadding,
            });
            return enc.ciphertext.toString(CryptoJS.enc.Hex);
        }
        catch (err) {
            console.error("cryptItWithKey", err);
            return null;
        }
    }

    /**
     * Compare two dates
     * @param date1
     * @param date2
     * @returns The number of days between two dates
     */
    public static differenceDate(date1: Date, date2: Date) {
        const date1utc = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const date2utc = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        const day = 1000 * 60 * 60 * 24;
        return (date1utc - date2utc) / day;
    }

    /**
     * Check if a value is different of null and undefined
     * @param value
     * @returns
     */
    public static isDefined(value: any) {
        return value !== null && value !== undefined;
    }

    /**
     * Check if a value is equal to null or undefined
     * @param value
     * @returns
     */
    public static isNotDefined(value: any) {
        return !Tools.isDefined(value);
    }

    /**
     *
     * @param m
     * @returns number of minutes since midnight
     */
    public static toMinSinceMidnigth(m: moment.Moment): number {
        return (m.hours() * 60) + m.minutes();
    }

    /**
     * 
     * @param obj 
     * @returns a copy of this object where the keys are sorted alphabetically in a recursive manner
     */
    public static sortObjectByKeys<T extends object>(obj: T): T {
        if (Tools.isNotDefined(obj)) {
            return obj;
        }
        const ordered = Object.keys(obj).sort().reduce(
            (obj2: any, key) => {
                const objKey = (obj as any)[key];
                if (typeof objKey === "object") {
                    obj2[key] = this.sortObjectByKeys(objKey);
                }
                else {
                    obj2[key] = objKey;
                }
                return obj2;
            },
            {}
        );
        return ordered;
    }

    public static wait(milliseconds: number): Promise<{ success: boolean }> {
        return new Promise<{ success: boolean }>((resolve) => {
            setTimeout(() => {
                resolve({ success: true });
            }, milliseconds);
        });
    }
}

