import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QuizModalComponent } from '../components/modals/quiz-modal/quiz-modal.component';
import { QuestionnaireResponse } from '../helpers/questionnaireResponse';
import { Tools } from '../helpers/tools-helper';
import { IQuestionnaire } from '../models/questionnaire';

@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(
    private modalCtrl: ModalController
  ) { }

  /**
   * Display a quiz in a modal view
   */
  public async onDisplayQuiz(mediaId: string, pageName: string, allQuizzes?: IQuestionnaire[], otherQ?: IQuestionnaire[]) {
    const otherQuizzes = otherQ ? otherQ : Tools.deepCopy(allQuizzes).filter((q) => q.mediaId === mediaId);
    const quiz = (otherQuizzes && otherQuizzes.length) ? otherQuizzes.shift() : undefined;
    if (quiz) {
      const result = await this.presentModalQuiz('create', pageName, quiz, otherQuizzes);
      if (result?.data?.anotherQuiz === true) {
        await this.onDisplayQuiz(mediaId, pageName, otherQuizzes);
      }
    }
  }

  /**
   * @param action "create" or "view" mode
   * @param quiz the chosen quiz
   * @param otherQuizzes array of other available quizzes
   * @param quizResponse only when viewing already answered quiz
   * @description display a quiz in a modal view
   */
  public async presentModalQuiz(action: string, pageName: string, quiz: IQuestionnaire, otherQuizzes?: IQuestionnaire[], quizResponse?: QuestionnaireResponse): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: QuizModalComponent,
        componentProps: {
          "action": action,
          "quiz": quiz,
          "otherQuizzes": otherQuizzes,
          "quizResponse": quizResponse,
          "pageName": pageName
        }
      });
      modal.onDidDismiss().then((data) => { resolve(data); });
      await modal.present();
    });
  }
}
