import { Injectable } from "@angular/core";
import * as moment from "moment";
import { NOTIFICATION_SYSTEM_STATUS, INotification, NOTIFICATION_TYPE, NOTIFICATION_STATUS } from "../../models/notification";
import { ACTION_STATUS_ENTITY } from "../../models/sharedInterfaces";
import { LocalStorageService } from "../storage/local-storage.service";
import { NotificationsDrugsIntakeService } from "../globalDataProvider/notifications-drugs-intake.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationsSaveService {
  private keyToPlugin = "notificationsToPlugin";
  private keyGeneral = "notifications";

  constructor(
    private localStorageService: LocalStorageService,
    private notificationsDrugsIntakeService: NotificationsDrugsIntakeService
  ) {
  }

  private similar(notifA: INotification, notifB: INotification): boolean {
    return notifA.account === notifB.account &&
      notifA.ntype === notifB.ntype &&
      notifA.appId === notifB.appId;
  }

  private same(notifA: INotification, notifB: INotification): boolean {
    return this.similar(notifA, notifB) &&
      notifA.at === notifB.at &&
      notifA.start === notifB.start &&
      notifA.end === notifB.end &&
      notifA.language === notifB.language;
  }

  public contain(notifA: INotification, notifs: INotification[]): boolean {
    return this.containPersonalised(notifA, notifs, this.same.bind(this));
  }

  public containSimilar(notifA: INotification, notifs: INotification[]) {
    return this.containPersonalised(notifA, notifs, this.similar);
  }

  private containPersonalised(notifA: INotification, notifs: INotification[], conditionEqual: (n1: INotification, n2: INotification) => boolean) {
    return notifs.findIndex((n) => conditionEqual(n, notifA)) >= 0;
  }

  private keyType(type: NOTIFICATION_TYPE, sentToPlugin: boolean) {
    return sentToPlugin ? this.keyToPlugin + type.toString() : this.keyGeneral + type.toString();
  }

  public async mergeData(notifications: INotification[], type: NOTIFICATION_TYPE): Promise<INotification[]> {
    const notifs = await this.getByType(type, false);
    const merge = new Array<INotification>();
    notifications.forEach((notif) => {
      let index: number;
      switch (notif.ntype) {
        case NOTIFICATION_TYPE.DRUG:
          index = notifs.findIndex((n) => this.similar(n, notif) && n.time === notif.time);
          break;
        default:
          index = notifs.findIndex((n_1) => this.same(n_1, notif));
          break;
      }
      if (index !== -1 && notifs[index].status !== NOTIFICATION_STATUS.NONE) {
        merge.push(notifs[index]);
      }
      else {
        merge.push(notif);
      }
    });
    return merge;
  }

  public async updateOrCreateByType(notifsUpdated: INotification[], type: NOTIFICATION_TYPE, sentToPlugin: boolean, conditionEqual?: (n1: INotification, n2: INotification) => boolean): Promise<any> {
    const notifs = await this.getByType(type, sentToPlugin);
    const notifToUpdate = new Array<INotification>();
    const localNotifsToKeep = new Array<INotification>();
    notifs.forEach((notif) => {
      if (conditionEqual ? this.containPersonalised(notif, notifsUpdated, conditionEqual) : this.containSimilar(notif, notifsUpdated)) {
        notifToUpdate.push(notif);
      }
      else {
        localNotifsToKeep.push(notif);
      }
    });
    const concat = notifsUpdated.concat(localNotifsToKeep);
    return this.setByType(concat, type, sentToPlugin);
  }

  /**
   * This method delete the notifications associated to a drug (with appId) and an account in the storage
   * @param account 
   * @param appId id of a drug
   */
  public async deleteDrug(account: string, appId: string, sentToPlugin: boolean): Promise<any> {
    const notifsDrug: INotification[] = await this.getByType(NOTIFICATION_TYPE.DRUG, sentToPlugin);
    const notifToDelete = notifsDrug.filter((notifDrug) => notifDrug.account === account && notifDrug.appId === appId);
    notifToDelete.forEach((notif) => {
      this.deleteOneNotif(notif, notifsDrug);
    });
    return this.setByType(notifsDrug, NOTIFICATION_TYPE.DRUG, sentToPlugin);
  }

  public async deleteByType(notifsDeleted: INotification[], type: NOTIFICATION_TYPE, sentToPlugin: boolean): Promise<void> {
    const notifs: INotification[] = await this.getByType(type, sentToPlugin);
    notifsDeleted.forEach((notif) => {
      this.deleteOneNotif(notif, notifs);
    });
    await this.setByType(notifs, type, sentToPlugin);
  }

  private deleteOneNotif(notif: INotification, notifs: INotification[]) {
    const index = notifs.findIndex((n) => this.similar(n, notif));
    if (index !== -1) {
      notifs.splice(index, 1);
    }
  }

  public async setByType(notifs: INotification[], type: NOTIFICATION_TYPE, sentToPlugin: boolean = true): Promise<any> {

    switch (type) {
      case NOTIFICATION_TYPE.DRUG:
        // for drug, this is the smartphone which generate the not taking drugs
        // await this.notificationsDrugsIntakeService.setDrugsIntakeWithStatusNone(notifs);
        break;

      default:
        break;
    }

    return this.localStorageService.setData(this.keyType(type, sentToPlugin), JSON.stringify(notifs), true);
  }

  public async getByType(type: NOTIFICATION_TYPE, sentToPlugin: boolean = true): Promise<INotification[]> {
    try {
      const data: string = await this.localStorageService.getData(this.keyType(type, sentToPlugin), true);
      const notifs = JSON.parse(data) as INotification[];
      if (!notifs) {
        return [];
      }
      else {
        switch (type) {
          case NOTIFICATION_TYPE.DRUG: // merge status with drugs intake
            let drugsIntake: INotification[];
            const intake = await this.notificationsDrugsIntakeService.getFreshestData();
            drugsIntake = intake.filter((n) => {
              return moment(n.at).add(3, "days").isAfter(moment()) && n.status !== NOTIFICATION_STATUS.NONE;
            });
            if (drugsIntake && drugsIntake.length > 0) {
              notifs.forEach((n) => {
                const sameIntake = drugsIntake.find((nIntake) => {
                  return this.similar(n, nIntake) && moment(n.time).isSame(moment(nIntake.time));
                });
                if (sameIntake !== undefined) {
                  n.status = sameIntake.status;
                }
              });
            }
            break;
          default: break;
        }
        return notifs;
      }
    } catch (err) {
      // console.error(err);
      return [];
    }
  }

  public async getAll(sentToPlugin: boolean = true): Promise<INotification[]> {
    const arrayPromise = new Array<Promise<INotification[]>>();
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.APPOINTMENT, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.ADVICE, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.DRUG, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.FEELING, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.OBSERVATION, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.ALERT_OBSERVATION, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.RELATED_APPOINTMENT, sentToPlugin));
    arrayPromise.push(this.getByType(NOTIFICATION_TYPE.RELATED_DRUG, sentToPlugin));

    const notifs = await Promise.all(arrayPromise);
    return notifs.reduce((acc, it) => [...acc, ...it], []);
  }

  public async updateNotificationStatus(notification: INotification, status: NOTIFICATION_STATUS, reason?: string): Promise<any> {
    notification.status = status;
    notification.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    notification.comment = reason ? reason : "";

    switch (notification.ntype) {
      case NOTIFICATION_TYPE.DRUG:
        // send to server the drug intake
        await this.notificationsDrugsIntakeService.setDrugsIntake(notification);
        return this.updateOrCreateByType([notification], notification.ntype, false, (n1, n2) => {
          return this.similar(n1, n2) && n1.time === n2.time;
        });
      default:
        return this.updateOrCreateByType([notification], notification.ntype, false);
    }
  }

  public updateLocalNotificationSystemStatus(notification: INotification, systemStatus: NOTIFICATION_SYSTEM_STATUS): Promise<any> {
    return Promise.resolve();

    // find notification in DB, to get uptodate data and only update SystemStatus
    /*
    return this.sqlStorageService.getNotification(notification.account, notification.systemId.toString())
        .then((dbNotification) => {
            if (dbNotification.systemStatus) {
                dbNotification.systemStatus = systemStatus;
            }
            dbNotification.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
            return this.sqlStorageService.setNotification(notification.account, dbNotification, false)
                .then(() => { if (sendInternalEvent) this.sendEventNotifGenerated(); });
        },
            (err) => { console.error("NotificationManager.updateLocalNotificationSystemStatus", "can't find notification " + notification.systemId, err); });
*/
  }
}
