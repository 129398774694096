import { IQuestionnaire } from "../models/questionnaire";
import { IRule, IRuleResult, RESULT_TYPE } from "../models/rule";
import { IRuleAlert, RuleAlert } from "../models/ruleAlert";
import { ITranslation } from "../models/translation";
import { InfoAppService } from "../services/info-app.service";
import { IAccount } from "./account-helper";
import { QuestionnaireResponse } from "./questionnaireResponse";
import { RuleDefinitionHelperQuestionnaireService } from "./rule-definition-helper-questionnaire.service";
import { Tools } from "./tools-helper";

/**
 * Management general of the rules linked to a questionnaire
 */
export class RuleHelperQuestionnaireService {

  public ruleLinked: IRule;

  constructor(public user: IAccount, rules: IRule[], public currentQuestionnaire: IQuestionnaire) {
    this.ruleLinked = rules.find((r) => r.meta.targets.findIndex((t) => t.reference === currentQuestionnaire.identifier[0].value) >= 0);
  }

  /**
   * Indicates if this questionnaire is linked to a rule
   */
  get isLinkedToARule() {
    return Tools.isDefined(this.ruleLinked);
  }

  /**
   * 
   * @param currentQuestionnaireResponse 
   * @returns the rule alert associated to the triggered rule definition. It can be undefined if no rule has been triggered
   */
  public getRuleAlert(currentQuestionnaireResponse: QuestionnaireResponse): IRuleAlert {
    const helper = new RuleDefinitionHelperQuestionnaireService(this.ruleLinked.meta.valueSet, this.currentQuestionnaire, currentQuestionnaireResponse);
    const ruleDef = this.ruleLinked.definitions.find((def) => {
      if (Tools.isDefined(def.triggerFormula)) {
        return helper.triggerFormulaEvaluation(def.triggerFormula);
      }
      else {
        return false;
      }
    });

    if (Tools.isDefined(ruleDef)) {
      return RuleAlert.asRuleAlertForQuestionnaire(this.ruleLinked, ruleDef, currentQuestionnaireResponse, this.user);
    }
    else {
      return null;
    }

  }

  /**
   * 
   * @returns The code of the vital profile associated to this questionnaire (can be undefined)
   */
  public getVitalProfileLinked(): string {
    return this.ruleLinked.meta.targets.find((t) => t.type === "vitalProfile")?.reference;
  }

  /**
   * Display multiple alert in a single popup
   * @param ruleAlerts
   */
  public getMessageForPopup(alert: IRuleAlert, lang: string): string {
    // gather messages
    let messages = "";
    for (const ruleResult of alert.rule.results) {
      const identifierValue = (alert.identifier && alert.identifier.length) ? alert.identifier[0].value : '';
      const msg = this.getAlertMessageTranslated(ruleResult, identifierValue, lang);
      if (msg && msg.length) messages += "<br>" + msg;
    }
    return messages;
  }

  /**
   * Return alert message translated to current language
   */
  private getAlertMessageTranslated(ruleResult: IRuleResult, alertIdentifier: string, lang: string): string {
    // get patient language
    try {
      if (ruleResult.resultType !== RESULT_TYPE.MESSAGE) return ""; // no message "inside" result
      const msgTranslation = (ruleResult.value as ITranslation);
      if (!msgTranslation) return "";
      return InfoAppService.getTranslation(msgTranslation, lang, alertIdentifier);
    } catch (err) {
      console.error("RuleService", "getAlertMessageTranslated", err);
      return alertIdentifier;
    }
  }


}

