import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { last, map } from 'rxjs/operators';
import { IBrusafeURL } from 'src/app/models/IBrusafeURL';
import { AbrumetService, SYNC_BRUSAFE_TYPE } from '../abrumet.service';
import { ApiService } from '../api.service';
import { PopupService } from '../popup.service';

@Injectable({
  providedIn: 'root'
})
export class BrusafeService {

  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    private abrumetService: AbrumetService
  ) { }

  /**
   * From a url, return the query parameters as a IBrusafeURL interface. This method check the parameters.
   * If the url or parameters are not correct, null is returned
   * @param url 
   * @returns 
   */
  public customParseUrl(url: any): IBrusafeURL | null {
    const result = url.fragment.substr(1).split('&').reduce(function (res, item) {
      const parts = item.split('=');
      res[parts[0]] = parts[1];
      return res;
    }, {});
    if (this.isBrusafeURL(result)) {
      return result;
    }
    else {
      return null;
    }
  }

  private isBrusafeURL(result: any): result is IBrusafeURL {
    return result.access_token && result.token_type && result.expires_in;
  }

  /**
   * Save the brusafe token received via the deeplink in our backend
   * @param token 
   * @returns 
   */
  public saveToken(token: IBrusafeURL): Observable<boolean> {
    return this.apiService
      .post("/abrumet/save-token", {
        access_token: token.access_token,
        token_type: token.token_type,
        expires_in: token.expires_in
      })
      .pipe(
        map(rep => {
          // notice : ServerResponse.type(rep) does not work because the response is not a typical response. 
          // It is generated via the abrumet connector
          if ((rep as unknown) === "success") {
            return true;
          }
          return false;
        })
      );
  }

  /**
   * All the flow to save the token received by the deeplink + launch synchro
   * @param abrumetToken 
   */
  public handleBrusafeCallback(abrumetToken: IBrusafeURL) {
    this.saveToken(abrumetToken).pipe(last()).subscribe(
      (success) => {
        if (success) {
          this.syncBrusafe();
        }
        else {
          this.popupService.showAlert(
            "application.title",
            "synchro.error.unreachableServerError"
          );
        }
      },
      (err) => {
        console.error("HOME PAGE - handleBrusafeCallback - error", err);
        this.popupService.showAlert(
          "application.title",
          "synchro.error.unreachableServerError"
        );
      }
    );
  }

  public syncBrusafe() {
    this.abrumetService.syncBrusafe().pipe(last()).subscribe(
      (syncBrusafeType) => {
        switch (syncBrusafeType) {
          case SYNC_BRUSAFE_TYPE.SEND_SUCCESS:
            this.popupService.showAlert("application.title", "share.brusafe.send");
            break;
          case SYNC_BRUSAFE_TYPE.TOKEN_EXPIRED_ERROR:
            this.abrumetService.completeRedirectAbrumetUrl().then((url) => {
              this.popupService.showAlert("Abrumet", "share.tokenExpired").then(() => {
                window.open(url, "_system", "location=no");
              });
            });
            break;
          case SYNC_BRUSAFE_TYPE.UNREACHABLE_SERVER_ERROR:
            this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
            break;
          default:
            this.popupService.showAlert("Abrumet", "error.general");
            break;
        }
      }
    );
  }

}
