export enum MedicalBluetoothErrorType {
    PERMISSION_NOT_GRANTED,
    LOCATION_NOT_ENABLED,
    BLUETOOTH_NOT_ENABLED,
    UNKNOWN_STATUS,
    UNKNOWN,
    DEVICE_DISCONNECTED,
    BONDING_TIMEOUT,
}
export class MedicalBluetoothError extends Error {
    public constructor(message: string, public readonly type: MedicalBluetoothErrorType) {
        super(message);
    }
}
