import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IKnowledges } from "src/app/helpers/knowledge-helper";
import { BasicSyncService } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
    providedIn: 'root'
})
export class KnowledgesObservationService extends BasicSyncService<IKnowledges, IKnowledges[]> {

    constructor(protected dataService: DataService) {
        super(dataService);
    }

    protected clearWatch(): void {
        this.data$ = new BehaviorSubject<IKnowledges[]>([]);
    }

    protected initWatch(): void {
        this.data$.next([]);
    }

    protected setupDataParameters(): void {
        this.defaultDataParameter = {
            entityPrefix: 'knowledges_observation_',
            entityStoreKey: 'list',
            getUrl: '/knowledges/observations',
            setUrl: null,
            expirationDays: 10,
            encrypted: false
        };
    }
}
