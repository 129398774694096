import { Pipe, PipeTransform } from "@angular/core";
import { IObservationDefinitionComponent, OComponent } from "../helpers/observation-helper";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";
import { ObservationDefinitionService } from "../services/globalDataProvider/observation-definition.service";
import { InfoAppService } from "../services/info-app.service";

@Pipe({ name: "getObsComponent" })
export class GetObsComponentPipe implements PipeTransform {
    constructor() { }

    public transform(code: string, components: OComponent[]): OComponent {
        const foundComp = components.find(c => c.code.coding[0].code === code);
        return foundComp;
    }
}

/**
 * Convert a LOINC code into a backend label code (to translate)
 */
@Pipe({ name: "getLabelFromCode" })
export class GetLabelFromCodePipe implements PipeTransform {
    constructor(
        protected configService: ConfigurationService,
        protected obsDefService: ObservationDefinitionService
    ) { }

    public transform(component: IObservationDefinitionComponent): string {
        try {
            if (!component) {
                return "";
            }
            let obsDef: string;
            let translation: string;
            const lang = this.configService.getCurrentLanguage();

            if (component.nameTranslation) {
                translation = InfoAppService.getTranslation(component.nameTranslation, this.configService.getCurrentLanguage(), component.loinc);
            } else {
                // keep retro-compatibility
                translation = this.obsDefService.getTranslatedString(component.name, component.loinc);
            }

            if (lang === 'nl' && component.unit === 'UI') {
                obsDef = translation;
            } else {
                obsDef = translation + (!component.unit ? "" : " (" + component.unit + ")");
            }
            return obsDef;
        } catch (err) {
            console.error('getLabelFromCode', err);
            return component.loinc;
        }
    }
}

@Pipe({ name: "isHidden" })
export class IsHiddenPipe implements PipeTransform {
    constructor() { }

    public transform(code: string, hideLoinc: string[]): boolean {
        return hideLoinc?.includes(code);
    }
}

/**
 * Return translated label for this current range value using the given component definition
 */
@Pipe({ name: "getLabelRangeFromCompDef" })
export class GetLabelRangeFromCompDefPipe implements PipeTransform {
    constructor(protected configService: ConfigurationService) { }

    public transform(index: number, componentDefinition: IObservationDefinitionComponent): string {
        for (let i = componentDefinition.min; i <= componentDefinition.max; i++) {
            // meaning for value ?
            if (componentDefinition.meaning?.length) {
                for (const meaning of componentDefinition.meaning) {
                    if (meaning.value === index) return InfoAppService.getTranslation(meaning.description, this.configService.getCurrentLanguage(), String(meaning.value));
                }
            }
        }
        return (index + "");
    }
}

/**
 * Return translated label for this current range value
 */
@Pipe({ name: "getLabelRangeFromValue" })
export class GetLabelRangeFromValuePipe implements PipeTransform {
    constructor(protected configService: ConfigurationService) { }

    public transform(value: number, componentDefinition: IObservationDefinitionComponent): string {
        // on init, value is not set
        if (!value) {
            value = componentDefinition.min ? componentDefinition.min : 0;
        }
        for (let i = componentDefinition.min; i <= componentDefinition.max; i++) {
            // meaning for value ?
            if (componentDefinition.meaning) {
                for (const meaning of componentDefinition.meaning) {
                    if (Number(meaning.value) === value) {
                        return InfoAppService.getTranslation(meaning.description, this.configService.getCurrentLanguage(), String(meaning.value));
                    }
                }
            }
        }
        return value.toString();
    }
}

/**
 * Compute range array
 */
@Pipe({ name: "getRange" })
export class GetRangePipe implements PipeTransform {
    constructor() { }

    public transform(min: number, max: number): number[] {
        const arrRange: number[] = [];
        for (let i = min; i <= max; i++) {
            arrRange.push(i);
        }
        return arrRange;
    }
}

/**
 * Return translated label for this current range value using the given component definition list
 */
@Pipe({ name: "getLabelRangeFromCompDefList" })
export class GetLabelRangeFromCompDefListPipe implements PipeTransform {
    constructor(protected configService: ConfigurationService) { }

    public transform(component: OComponent, componentDefinitions: IObservationDefinitionComponent[]): string {
        for (const componentDefinition of componentDefinitions) {
            if (componentDefinition.loinc === component.code.coding[0].code) {
                if (componentDefinition.meaning?.length) {
                    for (const meaning of componentDefinition.meaning) {
                        if (meaning?.value === component.valueQuantity.value) {
                            return InfoAppService.getTranslation(meaning.description, this.configService.getCurrentLanguage(), componentDefinition.loinc);
                        }
                    }
                }
                break;
            }
        }
        return (component.valueQuantity.value + ""); // no translation found, return simple value
    }
}
