import { MedicalBluetoothService } from "../services/medical-bluetooth/medical-bluetooth.service";
import { IEntity, ITranslationKv } from "./sharedInterfaces";

export interface IExternalRessourceMeta {
    platform: {
        android: string,
        ios: string,
    };
    availableLoinc: string[];
    force?: boolean;
    noBonding?: boolean;
    medicalBluetoothService: MedicalBluetoothService;
}

export enum EXTERNAL_RESSOURCE_TYPE {
    APP = 1, BLUETOOTH_HARDWARE = 2, URL = 3,
}

export interface IExternalRessource extends IEntity {
    title: string;
    description: string;
    content?: string;
    image?: string;
    categories: string[];
    SCTIDs: string[];
    type: EXTERNAL_RESSOURCE_TYPE;
    reference: string;
    meta?: IExternalRessourceMeta;
    i18n: ITranslationKv[];
}
