import { Component, EventEmitter, Input, OnChanges, Output, Pipe, PipeTransform, SimpleChanges, ViewChild } from '@angular/core';
import { Platform, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { BaseComponent } from 'src/app/baseClasses/base-component';
import { IObservation, IObservationDefinition, Observation, OComponent } from 'src/app/helpers/observation-helper';
import { Tools } from 'src/app/helpers/tools-helper';
import { IObservationParam } from 'src/app/models/configuration';
import { KeyValue } from 'src/app/models/keyValue';
import { IEntity } from 'src/app/models/sharedInterfaces';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { ObservationDefinitionService } from 'src/app/services/globalDataProvider/observation-definition.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { E2E_ID_OBS } from 'test/helpers/selectorIdHelper';

@Pipe({ name: 'isAnswerNull' })
export class IsAnswerNullPipe implements PipeTransform {
  constructor() { }
  transform(component: OComponent[], index: number): boolean {
    if (component.length > 1) {
      if (index === 0) {
        if (component[index + 1]?.valueQuantity?.value === null || component[index]?.valueQuantity?.value === null) {
          return true;
        }
        return false;
      }
      if (component[index - 1]?.valueQuantity?.value === null || component[index + 1]?.valueQuantity?.value === null) {
        return true;
      }
      return false;
    }
    return true;
  }
}

/**
 * Container for observation data in Chart
 */
export class DataChart {
  public data: number[] = [];
  public label = "";
  constructor() { }
}

@Component({
  selector: 'care-observation',
  templateUrl: './observation.component.html',
  styleUrls: ['./observation.component.scss'],
})
export class ObservationComponent extends BaseComponent {
  @Input() definition: IObservationDefinition;
  @Input() param: IObservationParam;
  @Input() view: 'datas' | 'charts' | 'parameters';
  @Input() observations: IObservation[];
  @Input() MAX_CHART_LAST_ITEMS = 999;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onAddObservation = new EventEmitter<IObservationDefinition>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEditObservation = new EventEmitter<IObservation>();
  public isDesktop: boolean;
  showDetails = false;
  validationErrors: { [key: string]: string[] } = {};
  private updateChart = false;
  dayWeekNames = Tools.keyDayNames(true);
  dayMonth: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  freqKv = Array.from({ length: 12 }, (_, i) => i + 1).map(v => {
    return {
      key: v.toString(),
      value: v
    };
  });

  timings = {
    rising: new KeyValue(),
    morning: new KeyValue(),
    noon: new KeyValue(),
    evening: new KeyValue(),
    atBed: new KeyValue(),
    when: new Array<string>(),
  };
  public isAddButtonShowed = false;
  chartLabels: Array<string> = [];
  chartData: DataChart[] = [];
  chartColors: Array<object> = [
    { // dark blue
      backgroundColor: 'rgba(0,95,179,0.2)',
      borderColor: 'rgba(0,95,179,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // orange
      backgroundColor: 'rgba(250,151,0,0.2)',
      borderColor: 'rgba(250,151,0,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {   // green
      backgroundColor: 'rgba(75, 192, 192,0.2)',
      borderColor: 'rgba(75, 192, 192,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    /* {   //  grey
         backgroundColor: 'rgba(95,100,95,0.2)',
         borderColor: 'rgba(95,100,95,1)',
         pointBackgroundColor: 'rgba(148,159,177,1)',
         pointBorderColor: '#fff',
         pointHoverBackgroundColor: '#fff',
         pointHoverBorderColor: 'rgba(148,159,177,0.8)'
     },*/
    {   // yellow
      backgroundColor: 'rgba(245, 240, 30,0.2)',
      borderColor: 'rgba(245, 240, 30,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {   // light blue
      backgroundColor: 'rgba(54, 162, 235,0.2)',
      borderColor: 'rgba(54, 162, 235,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // red
      backgroundColor: 'rgba(255, 99, 132,0.2)',
      borderColor: 'rgba(255, 99, 132,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    {   // purple
      backgroundColor: 'rgba(205,85,230,0.2)',
      borderColor: 'rgba(205,85,230,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  // observations charts parameters
  chartOptions: object = {
    responsive: true,
    maintainAspectRatio: this.platform.is("desktop") ? false : true // avoid chart not resizing correctly on desktop
  };
  chartOptionsBool: object = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
          // min: -1,
          // max: 1,
          // stepSize: 1
        }
      }]
    }
  };

  @ViewChild("baseChart")
  chart: BaseChartDirective;

  public E2E_ID_OBS = E2E_ID_OBS;

  constructor(
    protected translateSvc: TranslateService,
    protected infoService: InfoAppService,
    protected obsDefService: ObservationDefinitionService,
    protected popupService: PopupService,
    protected configService: ConfigurationService,
    protected platform: Platform
  ) {
    super(infoService, popupService);
    this.isDesktop = this.platform.is("desktop");
  }

  /**
   * Change event
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    this.isAddButtonShowed = this.isAddButtonAvailable(this.param);

    if (changes.param && !Tools.isEqual(changes.param.currentValue, changes.param.previousValue)) {
      this.prepareObservationParams();
    }
    if (changes.observations && !Tools.isEqual(changes.observations.currentValue, changes.observations.previousValue)) {
      this.computeGraphData();
      this.updateChart = true;
    }
    this.validate();
  }

  private computeGraphData() {
    const chartLabels = new Array<string>();
    const chartData = new Array<DataChart>();
    if (!this.definition) return;

    for (const observ of this.observations) {
      chartLabels.push(moment(observ.issued).format("DD/MM"));
    }
    chartLabels.reverse();

    for (const component of this.definition.components) {
      const dataChart = new DataChart();
      // dataChart.label = this.translateSvc.instant(Observation.getLabelFromCode(component.loinc || this.definition.loinc));

      if (component.shortnameTranslation) {
        dataChart.label = InfoAppService.getTranslation(component.shortnameTranslation, this.configService.getCurrentLanguage(), component.loinc);
      } else if (component.shortName) { // keep retro-compatibility
        dataChart.label = this.obsDefService.getTranslatedString(component.shortName, component.loinc);
      } else if (component.nameTranslation) {
        dataChart.label = InfoAppService.getTranslation(component.nameTranslation, this.configService.getCurrentLanguage(), component.loinc);
      } else {
        dataChart.label = this.obsDefService.getTranslatedString(component.name, component.loinc);
      }

      for (const observ of this.observations) {
        dataChart.data.push(Observation.getValue(observ, component.loinc));
        if ((this.MAX_CHART_LAST_ITEMS !== 0) && (dataChart.data.length >= this.MAX_CHART_LAST_ITEMS)) {
          break;
        }
      }
      dataChart.data.reverse();
      chartData.push(dataChart);
    }

    this.chartData = chartData;
    this.chartLabels = chartLabels;
  }

  /**
   * Prepare Observation parameters to be displayed
   */
  private prepareObservationParams() {
    // prepare Timing Codes
    const timingCodes = IEntity.toKeyValues(this.param.frequency.timingCode);
    this.timings.rising = timingCodes[0];
    this.timings.morning = timingCodes[1];
    this.timings.noon = timingCodes[2];
    this.timings.evening = timingCodes[3];
    this.timings.atBed = timingCodes[4];
    // prepare timing "when"
    if (this.param.frequency.when?.length) {
      this.timings.when = JSON.parse(this.param.frequency.when);
    }
    else {
      // entry MUST exists, even if empty!
      this.timings.when = [];
    }
    // add observation categories into the list
  }

  public handleClick() {
    if (this.definition.onePerDay && this.observations.length >= 1) {
      const issueDate = moment(this.observations[0].issued);
      if (issueDate.isSame(moment(), 'day')) {
        this.onEditObservation.emit(this.observations[0]);
      } else {
        this.onAddObservation.emit(this.definition);
      }
    } else {
      this.onAddObservation.emit(this.definition);
    }
  }

  /**
   * @param observation
   */
  public handleEdit(observation: IObservation) {
    this.onEditObservation.emit(observation);
  }

  /**
   * Called on a timing change in the parameters form
   */
  public updateTimings() {
    // prepare Timing Code
    let timingCode = "";
    if (this.timings.rising.value) { timingCode += IEntity.TIMING_RISING; }
    if (this.timings.morning.value) { timingCode += IEntity.TIMING_MORNING; }
    if (this.timings.noon.value) { timingCode += IEntity.TIMING_NOON; }
    if (this.timings.evening.value) { timingCode += IEntity.TIMING_EVENING; }
    if (this.timings.atBed.value) { timingCode += IEntity.TIMING_BED; }
    this.param.frequency.timingCode = timingCode;

    // prepare timing "when"
    this.param.frequency.when = null;
    switch (this.param.frequency.periodUnits) {
      case "d":   // nothing to do
        break;
      case "w":
      case "m":
      case "h":
        this.param.frequency.when = JSON.stringify(this.timings.when);
        break;
      case "y": // TODO
        break;
    }
    this.validate();
  }

  /**
   * Called on a bound period change
   */
  public updateBoundPeriods() {
    // prepare bound period (remove hours,minutes, seconds)
    this.param.frequency.boundsPeriod.start = Tools.momentNoHours(this.param.frequency.boundsPeriod.start).format();
    this.param.frequency.boundsPeriod.end = Tools.momentNoHours(this.param.frequency.boundsPeriod.end).format();
    this.validate();
  }

  /**
   * Validate the param
   */
  private validate() {
    const out: { [key: string]: string[] } = {};
    if (["m", "w"].indexOf(this.param.frequency.periodUnits) >= 0) {
      // frequency is not used anymore, replaced by "timingWhen"
      if (!this.timings.when?.length) {
        out.timings_when = [this.translateSvc.instant("mydrugs.errorTiming") /*+ (!obsTypeName ? "" : " (" + JSON.stringify(obsTypeName) + ")")*/];
      }
    }
    else if (this.param.frequency.periodUnits === "h") {
      // frequency is not used anymore, replaced by "timingWhen"
      if (!this.timings.when?.length || this.timings.when.length < 2 || !this.timings.when[0] || !this.timings.when[1]) {
        out.timings_when = [this.translateSvc.instant("mydrugs.errorTiming") /*+ (!obsTypeName ? "" : " (" + JSON.stringify(obsTypeName) + ")")*/];
      }
    }
    this.validationErrors = out;
  }

  private isAddButtonAvailable(observationParams: IObservationParam) {
    if (this.view !== 'datas') return false;
    if (observationParams.limitedCreationByFrequence && new Date(observationParams.frequency.boundsPeriod.end) < new Date()) return false;
    return true;
  }
}
