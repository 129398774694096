import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Account, IAccount } from 'src/app/helpers/account-helper';
import { Tools } from 'src/app/helpers/tools-helper';
import { InfoAppService } from 'src/app/services/info-app.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PopupService } from 'src/app/services/popup.service';
import * as moment from "moment";
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { ServerResponse } from 'src/app/helpers/server-response-helper';
import { VitalProfileDefinitionsService } from 'src/app/services/globalDataProvider/vital-profile-definitions.service';
import { ITranslation } from 'src/app/models/translation';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { VitalSignFormComponent } from '../../form/vitalSignForm/vital-sign-form/vital-sign-form.component';
import { takeUntil } from 'rxjs/operators';
import { IVitalProfileDefinition } from 'src/app/models/sharedInterfaces';
import { BasePage } from 'src/app/baseClasses/base-page';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-my-profil-modal',
  templateUrl: './my-profil-modal.component.html',
  styleUrls: ['./my-profil-modal.component.scss'],
})
export class MyProfilModalComponent extends BasePage {
  @ViewChild(VitalSignFormComponent) vitalSignForm: VitalSignFormComponent;
  public profileTab = "profile";
  private originalAccount: IAccount;
  public account: IAccount;
  private vitalSignRawDatas: { code: string, value?: number, valueArray?: number[][], unit: ITranslation }[]; // This is not a Quantity, unit is an iTranslation
  public isVitalSignFormValid: boolean;
  public missingVitalSignsLoinc: string[];
  public vitalSigns: IVitalProfileDefinition[];

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private modalController: ModalController,
    protected loaderService: LoaderService,
    private accountService: AccountService,
    private vitalProfileDefinitionsService: VitalProfileDefinitionsService,
    protected configService: ConfigurationService,
    protected navParams: NavParams,
    translateService: TranslateService,
  ) {
    super(translateService, configService, infoService, popupService);

    if (this.navParams.get('profileTab')) {
      this.profileTab = this.navParams.get('profileTab');
    }
    if (this.navParams.get('missingVitalSings')) {
      this.missingVitalSignsLoinc = this.navParams.get('missingVitalSings');
    }
    this.account = this.accountService.cachedAccount;
    this.originalAccount = JSON.parse(JSON.stringify(this.account)) as IAccount;
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    Promise.all([this.vitalProfileDefinitionsService.getFreshestData().then((v) => {
      if (this.missingVitalSignsLoinc) {
        this.vitalSigns = this.missingVitalSignsLoinc.map((l) => v.find((vs) => vs.code === l));
      }
      else {
        this.vitalSigns = v;
      }
      this.vitalSigns = this.vitalSigns.filter(vs => !vs.hiddenForMobile);
      
    }).catch(() => Promise.resolve()), Tools.wait(1000)]).then(() => {
        this.pageLoaded = true;
      });
  }

  /**
   *  cancel modal
   */
  public dismiss() {
    this.modalController.dismiss();
  }

  /**
   * Get vitalSigns from vitalSignForm
   */
  public getVitalSigns(event) {
    this.vitalSignRawDatas = event;
    this.isVitalSignFormValid = this.vitalSignForm.form.valid;
  }

  /**
   * Save profile
   */
  public onSave() {
    // fill physiological data
    console.info("MyprofilePage", "onSave", this.account);
    for (const vitalSign of this.vitalSignRawDatas) {
      Account.setVital(this.account, vitalSign.code, vitalSign.value, vitalSign.valueArray, vitalSign.unit as ITranslation, this.configService.getCurrentLanguage());
    }

    this.account.phone = Tools.getFormattedPhoneNumber(this.account.phone);
    // user change his mail => warn him that a reset password will be sent...
    if (this.originalAccount.mail !== this.account.mail) {
      this.popupService.showAlert("application.title", "myprofile.mailUpdate")
        .then(() => { this.onServerSave(); });
    }
    else this.onServerSave();
  }

  /**
   * Save on server then locally
   */
  private onServerSave() {
    // save account
    this.account.modified = moment().format();
    this.accountService.setAccount(this.account)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (acc) => { },
        async (err) => {
          this.loaderService.showLoading(false);
          if (ServerResponse.isAuthenticationError(err) || ServerResponse.isAccessDenied(err)) {
            // TODO
            // this.apiService.handleErrorAndLogout(err);
          }
          console.error("updateAccount", err);
          await this.popupService.showAlert("application.title", "error.update");
          this.dismiss();
        },
        () => { // observable has finished
          this.loaderService.showLoading(false);
          this.dismiss();
        }
      );
  }

}
