import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AccountService } from './globalDataProvider/account.service';
import { ApiSyncService, ApiSyncServiceStatus } from './globalDataProvider/core/api-sync.service';
import { RequestSenderService, RequestSenderServiceSyncStatus } from './globalDataProvider/core/request-sender.service';
import { InfoAppService } from './info-app.service';
import { ConnectionStatus, NetworkService } from './network.service';
import { NotificationsEventsService } from './notificationsService/notifications-events.service';
import { SysAccountService } from './sys-account.service';

export enum SynchronisationServiceStatus {
  offline, alreadyInProgress, success, noCordovaPlatform, missingAccountOrToken, error
}

// TODO gérer lorsque le token est invalide

@Injectable({
  providedIn: 'root'
})
export class SynchronisationService {

  private inProgress = false;
  /* 
    BehaviorSubject to control the synchronisation. 
    When it emit true, there is a synchronisation in progress
    When it emit false, there is no synchronisation
  */ 
  private synchroObs = new BehaviorSubject<boolean>(false);

  private alreadyinitDetectNetworkChange = false;

  constructor(
    private apiSyncService: ApiSyncService,
    private requestSenderService: RequestSenderService,
    private networkService: NetworkService,
    private infoAppService: InfoAppService,
    private accountService: AccountService,
    private sysAccountService: SysAccountService,
    private notificationsEventsService: NotificationsEventsService
  ) { }

  public initDetectNetworkChange() {
    if (!this.alreadyinitDetectNetworkChange) {
      this.alreadyinitDetectNetworkChange = true;
      this.networkService.onNetworkChange().subscribe((connectionStatus) => {
        if (connectionStatus === ConnectionStatus.Online) {
          this.syncApp();
        }
      });
    }
  }

  /**
   * Method to launch a synchro and init the detectNetworkChange.
   */
  public async initSynchro(): Promise<SynchronisationServiceStatus> {
    let result: SynchronisationServiceStatus;
    try {
      // launch a synchro
      result = await this.syncApp();
    } catch (error) {
      console.error(error, "initSynchro");
      result = SynchronisationServiceStatus.error;
    } finally {
      // lauch a synchro when the user go online
      this.initDetectNetworkChange();
      return result;
    }
  }

  public async syncApp(): Promise<SynchronisationServiceStatus> {
    try {

      if (this.inProgress) {
        return SynchronisationServiceStatus.alreadyInProgress;
      }

      this.inProgress = true;
      this.synchroObs.next(true);

      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
        this.inProgress = false;
        this.synchroObs.next(false);
        return SynchronisationServiceStatus.offline;
      }
      if (!this.infoAppService.isCordova()) {
        this.inProgress = false;
        this.synchroObs.next(false);
        return SynchronisationServiceStatus.noCordovaPlatform;
      }

      if (!this.accountService.cachedAccount || !this.sysAccountService.cachedSysAccount || !this.sysAccountService.cachedSysAccount.token) {
        this.inProgress = false;
        this.synchroObs.next(false);
        return SynchronisationServiceStatus.missingAccountOrToken;
      }

      await this.requestSenderService.promiseResolveWhenReady();
      const requestSenderServiceSyncStatus = await this.requestSenderService.sync();

      switch (requestSenderServiceSyncStatus) {
        case RequestSenderServiceSyncStatus.alreadyInProgress:
          return SynchronisationServiceStatus.alreadyInProgress;
        case RequestSenderServiceSyncStatus.error:
          return SynchronisationServiceStatus.error;
        case RequestSenderServiceSyncStatus.offline:
          return SynchronisationServiceStatus.offline;
        default:
          break;
      }
      // here, we are sure that requestSenderServiceSyncStatus is success

      const apiSyncStatus = await this.apiSyncService.sync();

      await this.notificationsEventsService.generateAll();
      this.inProgress = false;
      this.synchroObs.next(false);

      switch (apiSyncStatus) {
        case ApiSyncServiceStatus.success:
          return SynchronisationServiceStatus.success;
        case ApiSyncServiceStatus.alreadyInProgress:
          return SynchronisationServiceStatus.alreadyInProgress;
        default:
          return SynchronisationServiceStatus.error;
      }

    } catch (error) {
      this.inProgress = false;
      this.synchroObs.next(false);
      console.error(error, "SynchronisationService");
      return SynchronisationServiceStatus.error;
    }


  }

  /**
    * Return a promise which is resolve when this service is finish to sync
    */
  public promiseResolveWhenReady(): Promise<void> {
    // this promise is resolve when we can do a requestSender sync
    return new Promise<void>((resolve, reject) => {
      this.synchroObs.pipe(
        first((sync) => !sync) // emits only the first time that sync === false
      ).subscribe((sync) => {
        console.debug("promiseResolveWhenReady : " + sync);
        resolve();
      },
        (err) => {
          console.error("sync requestSenderService.synchroObs error", err);
          resolve();
        });
    });
  }
}
