import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { SamService } from './sam.service';
import { NetworkService, ConnectionStatus } from './network.service';
import { ConfigurationService } from './globalDataProvider/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class CompleteDrugService implements AutoCompleteService {
  public labelAttribute = "officialName";
  public drugs = [];
  public userLang: string;

  private lastResults: { officialName: string, cnk: string, url: string, source: string, atc: string }[] = [];

  constructor(private samService: SamService, private networkService: NetworkService,
    private configService: ConfigurationService) {
    this.userLang = this.configService.getCurrentLanguage();
  }

  public getResults(keyword: string) {
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online &&
      keyword && keyword.length >= 3) {
      this.drugs = [];
      return this.samService.getDrugs(keyword, this.userLang).pipe(map(r => {
        this.lastResults = r.results.slice(0, 8).map(res => {
          return {
            officialName: res[this.userLang] ? res[this.userLang].fullName : res.fr.fullName,
            cnk: res.cnk,
            url: res[this.userLang] ? res[this.userLang].url : res.fr.url,
            source: `${res.country}/${res.dbName}`,
            atc: res.atc?.code
          };
        });
        return this.lastResults;
      }));
    } else {
      return [];
    }
  }

  /**
   * the plugin does not allow to have an event which is emitted just after 
   * the selection and which manages at the same time the click outside the input. 
   * For this reason, we will search for the item in the last visible list via this method.
   * @param name 
   * @returns 
   */
  public find(name: string) {
    return this.lastResults.find((r) => r.officialName === name);
  }
}
