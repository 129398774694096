import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "../appConstants";
import { ApiSyncService } from "./globalDataProvider/core/api-sync.service";
import { RequestSenderService, RequestSenderServiceSyncStatus } from "./globalDataProvider/core/request-sender.service";
import { InfoAppService } from "./info-app.service";
import { LoaderService } from "./loader.service";
import { NotificationsEventsService } from "./notificationsService/notifications-events.service";
import { PopupService } from "./popup.service";
import { LocalStorageService } from "./storage/local-storage.service";

@Injectable({
    providedIn: 'root'
})

export class MigrationIonic5Service {
    constructor(
        protected loaderService: LoaderService,
        protected popupService: PopupService,
        protected translateSvc: TranslateService,
        private localStorageService: LocalStorageService,
        private notificationEventsService: NotificationsEventsService,
        protected apiSync: ApiSyncService,
        private requestSenderService: RequestSenderService,
        private infoAppService: InfoAppService
    ) { }

    /**
     * 
     * @returns true if the migration is already done ; false otherwise
     * @see secureStorageService.hasBeenClearedForIonic5
     */
    public async hasBeenClearedForIonic5(): Promise<boolean> {
        try {
            const ionic = await this.localStorageService.getData(AppConstants.IONIC5CLEAR, false);
            return true;
        } catch (err) {
            console.log('Need to clear for ionic 5');
            return false;
        }
    }

    /**
     * Synchro the queue + empty data
     * @returns true if the migration is ok ; false otherwise
     */
    public async synchroAndClearForIonic5(): Promise<boolean> {
        try {
            await this.loaderService.showLoading(true, 'synchro.updatingApp');
            if (await this.synchroQueue()) {

                // save important data for the next kill of the app
                const lastSecureStorageNotActive = await this.infoAppService.getLastSecureStorageNotActive().catch(() => null);
                const currentMode = await this.infoAppService.getCurrentMode().catch(() => null);
                const dataFingerPrint = await this.infoAppService.getDataAssociatedToFingerprint().catch(() => null);

                await this.emptyDataAndCacheForIonic5();
                await this.localStorageService.setData(AppConstants.IONIC5CLEAR, 'done', false);

                // restore important data for the next kill of the app
                if (lastSecureStorageNotActive !== null && lastSecureStorageNotActive !== undefined) {
                    await this.infoAppService.setLastSecureStorageNotActive(lastSecureStorageNotActive);
                }
                if (currentMode !== null && currentMode !== undefined) {
                    await this.infoAppService.setCurrentMode(currentMode);
                }
                if (dataFingerPrint !== null && dataFingerPrint !== undefined) {
                    await this.infoAppService.setDataAssociatedToFingerprint(dataFingerPrint);
                }

                await this.localStorageService.setData(AppConstants.NEW_INSTALLATION_FROM_STORE, AppConstants.FALSE, false);

                await this.ionic5SynchroSuccess();
                return true;
            }
            else {
                console.error('Error while emptying cache for ionic 5 : synchroQueue failed');
                await this.ionic5SynchroFail();
                return false;
            }
        } catch (err) {
            console.error('Error while emptying cache for ionic 5 ' + JSON.stringify(err));
            await this.ionic5SynchroFail();
            return false;
        }
    }

    /**
     * 
     * @returns true if the synchro of Queue is success, false otherwise
     */
    private async synchroQueue(): Promise<boolean> {
        let requestSenderServiceSyncStatus: RequestSenderServiceSyncStatus = null;
        do {
            await this.requestSenderService.promiseResolveWhenReady();
            requestSenderServiceSyncStatus = await this.requestSenderService.sync();
        }
        while (requestSenderServiceSyncStatus === RequestSenderServiceSyncStatus.alreadyInProgress);
               
        if (requestSenderServiceSyncStatus === RequestSenderServiceSyncStatus.success) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * delete
     * - notifications
     * - localStorage (with cache)
     * - init service
     */
    private async emptyDataAndCacheForIonic5() {
        await this.notificationEventsService.deleteAllFromPlugin();
        await this.localStorageService.clearStorage();
        /*
        Reminder of why not to do a clean of the secure storage : 
        - it contains the encryption keys -> we must absolutely keep them
        - it contains the information associated to the fingerprint -> new with ionic 5
        - it contains the information about offline login -> new with ionic 5
        and that's it. So there's no point in deleting it

        await this.secureStorageService.clear(); 
        */
        this.apiSync.clearServices();
    }

    /**
     * Message success
     */
    private async ionic5SynchroSuccess() {
        await this.loaderService.showLoading(false);
        const msg = this.translateSvc.instant('application.sqlToLocalSuccess');
        const toast = await this.popupService.createToastWithCloseBtn(msg, 'middle');
        toast.present();
    }

    /**
     * Message failed
     */
    private async ionic5SynchroFail() {
        await this.loaderService.showLoading(false);
        const msg = this.translateSvc.instant('synchro.ionic5UpdateFail');
        const toast = await this.popupService.createToastWithCloseBtn(msg, 'bottom');
        toast.present();
      }
}
