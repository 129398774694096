import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Account, IAccount } from 'src/app/helpers/account-helper';
import { IVitalProfileDefinition, Quantity } from 'src/app/models/sharedInterfaces';
import { AccountService } from '../globalDataProvider/account.service';

@Injectable()
export class VitalSignControlService {

  private currentAccount: IAccount;
  private currentVitalSign: Quantity;

  constructor(public accountService: AccountService) {
    this.currentAccount = this.accountService.cachedAccount;
  }


  public toFormGroup(vitalSigns: IVitalProfileDefinition[]) {
    const group: any = {};

    vitalSigns.forEach(vitalSign => {
      if (vitalSign) {
        this.currentVitalSign = Account.getVital(this.currentAccount, vitalSign.code);

        switch (vitalSign.type) {
          case "number":
            group[vitalSign.code] = new FormControl({ value: this.currentVitalSign?.value ? this.currentVitalSign?.value : null, disabled: vitalSign.readOnlyMobile ? true : false }, [Validators.min(vitalSign.min), Validators.max(vitalSign.max)]);
            break;
          case "array":
            const formGroup = {};
            vitalSign.columns.forEach((col, index) => {
              vitalSign.rows.forEach((row, i) => {
                formGroup[col.coding.code + '-' + i] = new FormControl(this.currentVitalSign?.valueArray[i][index], [Validators.min(vitalSign.min), Validators.max(vitalSign.max)]);
              });
            });
            group[vitalSign.code] = new FormGroup(formGroup);
            break;
        }
      }
    });
    return new FormGroup(group);
  }
}
