import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { BackgroundService } from './services/background.service';
import { NotificationsPluginService } from './services/notificationsService/notifications-plugin.service';
import { ActivateDeeplinksService } from './services/deeplinks/activateDeeplinks.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private backgroundService: BackgroundService,
    private notificationPluginService: NotificationsPluginService,
    private deeplinks: ActivateDeeplinksService
  ) {
    this.initializeApp().then(
      () => {
        this.initializeSomeServices();
      },
      (err) => {
        console.error("the application did not initialise correctly", err);
      }
    );
  }

  /**
   * waits for the platform to be ready, manages the style
   */
  private async initializeApp(): Promise<void> {
    await this.platform.ready();
    this.statusBar.styleDefault();
  }

  /**
   * Initialize 
   * - notifications
   * - backgroundSynchro
   * - deeplinks
   * - migration ionic5
   */
  private initializeSomeServices() {
    this.notificationPluginService.bindNotificationEvents();
    this.backgroundService.initBackgroundSynchro();
    this.deeplinks.init();
  }
}
