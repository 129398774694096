import { IEntity, Identifier, Reference, Codes } from "./sharedInterfaces";

export interface IAttachment {
    contentType: string;
    data: string;
    title: string;
    creation: Date;
    url: string;
    size?: number;
    hash: string;
}

export enum IType {
    PDF = "application/pdf",
    JPG = "image/jpeg",
    TIFF = "image/tiff",
    PNG = "image/png"
}

export interface IPayload {
    contentAttachment: IAttachment;
    contentString: string;
    // contentReference: Reference;
}

export interface IAnnotation {
    authorReference: Reference;
    authorString: string;
    time: Date;
    text: string;
}

export enum STATUS_CODE {
    PREPARATION = "preparation",
    IN_PROGRESS = "in-progress",
    NOT_DONE = "not-done",
    SUSPENDED = "suspended",
    ABORTED = "aborted",
    COMPLETED = "completed",
    ENTERED_IN_ERROR = "entered-in-error"
}

export interface ICommunication extends IEntity {
    resourceType: string;
    identifier: Identifier[];
    basedOn?: Reference[];
    // partOf: Reference[];
    // inResponseTo: Reference[];
    // status: STATUS_CODE;
    // statusReason: Codes;
    // category: Codes[];
    // priority: any;
    // medium: Codes[];
    subject: Reference;
    topic: Codes;
    id: number;
    // about: Reference[];
    // encounter: Reference[];
    sent: Date;
    received: Date;
    // recipient: Reference[];
    sender: Reference;
    // reasonCode: Codes[];
    // reasonReference: Reference[];
    payload: IPayload[];
    // note: IAnnotation[];
    records: String[][];
    healthservice?: Reference;
}
