import { Component, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasePage } from 'src/app/baseClasses/base-page';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { RulesAlertService } from 'src/app/services/globalDataProvider/rules-alert.service';
import { IRuleAlert } from 'src/app/models/ruleAlert';
import { IObservationDefinition, IObservationWithoutComponents, OComponent } from 'src/app/helpers/observation-helper';
import { ObservationDefinitionService } from 'src/app/services/globalDataProvider/observation-definition.service';
import * as moment from 'moment';
import { NOTIFICATION_STATUS } from 'src/app/models/notification';
import { ACTION_STATUS_ENTITY } from 'src/app/models/sharedInterfaces';

@Pipe({ name: 'translateAlertName' })
export class TranslateAlertNamePipe implements PipeTransform {
  constructor(protected configService: ConfigurationService) { }
  /**
   * Checks the observations' definitions for the name of an observation and returns it.
   * If there isn't a corresponding definition for this observation, it returns the display
   * of the observation (usually it is the question in the language it was presented to the user).
   * @param element (OComponent) the observation's component (= part of the observation corresponding to one question)
   * @param observation (IObservation) the observation
   * @param allDefinitions (ObservationDefinition[]) list of all the definitions we have for the observations
   * @returns the translated name of the observation
   */
  transform(element: OComponent, observation: IObservationWithoutComponents, allDefinitions: IObservationDefinition[]): string {
    let parentLoinc: string;
    let def: IObservationDefinition;
    if (element && allDefinitions) {
      const elemLoinc = element.code.coding[0].code;
      const elemLoincWithoutSuffix = this.ignoreSuffix(elemLoinc);
      // Let's try to find the corresponding observation's definition!
      if (observation) {
        // this is the new easy way, where we save the observation in the alert
        parentLoinc = observation.code.coding[0].code;
        def = allDefinitions.find(d => d.loinc === parentLoinc);
      } else {
        // this is the bad old way when we did not save the observation in the alert
        // Since that thing is really badly done, we first try to check if there's only
        // one component with the right code in all the def:
        const possibleDefs = allDefinitions.filter(d => d.components.find(c => c.loinc === elemLoinc));
        if (possibleDefs.length === 1) {
          def = possibleDefs[0];
        } else {
          // if there's several... well, we select the one with the same
          // parent's loinc as the component loinc (we just assume it's the
          // most likely one. No garantee)
          def = possibleDefs.find(d => d.loinc === elemLoinc);
          if (!def) {
            // if we have no luck with that we just randomly chose the first one
            def = possibleDefs[0];
          }
        }
      }
      if (def) {
        const componentDef = def.components.find(d => d.loinc === elemLoinc);
        const componentDefWithoutSuffix = def.components.find(d => this.ignoreSuffix(d.loinc) === elemLoincWithoutSuffix);
        if (componentDef) {

          if (componentDef.shortnameTranslation) {
            return InfoAppService.getTranslation(componentDef.shortnameTranslation, this.configService.getCurrentLanguage(), componentDef.loinc);
          }

          return InfoAppService.getTranslation(
            componentDef.nameTranslation,
            this.configService.getCurrentLanguage(),
            componentDef.loinc);

        } else if (componentDefWithoutSuffix) {

          if (componentDefWithoutSuffix.shortnameTranslation) {
            return InfoAppService.getTranslation(
              componentDefWithoutSuffix.shortnameTranslation,
              this.configService.getCurrentLanguage(),
              componentDefWithoutSuffix.loinc);
          }

          return InfoAppService.getTranslation(
            componentDefWithoutSuffix.nameTranslation,
            this.configService.getCurrentLanguage(),
            componentDefWithoutSuffix.loinc);
        }
      }
    }
    // if no definition was found, we simply return the display
    return element.code.coding[0].display;
  }

  private ignoreSuffix(loinc: string): string {
    const arr = loinc.split('-');
    return `${arr[0]}-${arr[1]}`;
  }
}

@Pipe({ name: 'loincAndValueMeaning' })
export class LoincAndValueMeaningPipe implements PipeTransform {
  constructor(protected configService: ConfigurationService) { }
  transform(loinc: string, parentLoinc: string, value: number | string,
    allDefinitions: IObservationDefinition[], withValue = false, noDefault = false): string {
    let def: IObservationDefinition;
    if (parentLoinc) {
      // if we have the parent's loinc, this is easy
      def = allDefinitions.find(d => d.loinc === parentLoinc);
    } else {
      // If not, we first try to check if there's only
      // one component with the right code in all the defs:
      const possibleDefs = allDefinitions.filter(d => d.components.find(c => c.loinc === loinc));
      if (possibleDefs.length === 1) {
        def = possibleDefs[0];
      } else {
        // if there's several... well, we select the one with the same
        // parent's loinc as the component loinc (we just assume it's the
        // most likely one. No garantee)
        def = possibleDefs.find(d => d.loinc === loinc);
        if (!def) {
          // if we have no luck with that we just randomly chose the first one
          def = possibleDefs[0];
        }
      }
    }
    if (def) {
      if (noDefault && (value === undefined || value === null || value === '-')) {
        return '-';
      }
      const targetComponent = def.components.find(c => c.loinc === loinc);
      if (targetComponent) {
        if (targetComponent.meaning && value !== undefined && value !== null && value !== '-') {
          const targetMeaning = targetComponent.meaning.find(m => m.value === value);
          const trans = InfoAppService.getTranslation(targetMeaning?.description, this.configService.getCurrentLanguage(), targetComponent.loinc);
          return trans;
        } else if (targetComponent.unit) {
          return withValue && value !== undefined && value !== null && value !== '-' ?
            value + ' ' + targetComponent.unit : targetComponent.unit;
        } else {
          return withValue && value !== undefined && value !== null && value !== '-' ? value + '' : '-';
        }
      } else {
        console.log('target not found... ' + loinc + ' ' + parentLoinc + ' in ', def);
      }
    }
    if (noDefault) {
      return withValue && value !== undefined && value !== null && value !== '-' ? value + ' -' : '-';
    }
    return withValue && value !== undefined && value !== null && value !== '-' ? value + ' ' + loinc : loinc;
  }
}

@Pipe({ name: "hasBeenReminded" })
export class HasBeenRemindedPipe implements PipeTransform {

    constructor() { }

    public transform(alert: IRuleAlert, hour: number): boolean {
      const today = moment();
      const triggeredTime = moment(alert.creation);
      if (triggeredTime.add(hour, "hours").isAfter(today)) {
        return false;
      } else {
        return true;
      }
    }
}

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.page.html',
  styleUrls: ['./alerts.page.scss'],
})
export class AlertsPage extends BasePage {

  public observationViewType = "alerts";
  public alertRange: "week" | "month" | "3months" = "week";
  public allDefinitions: IObservationDefinition[] = [];
  public alerts: IRuleAlert[] = [];
  public alertStatus = NOTIFICATION_STATUS;

  constructor(
    protected translateService: TranslateService,
    protected configService: ConfigurationService,
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private obsDefService: ObservationDefinitionService,
    protected rulesAlertService: RulesAlertService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    Promise.all([this.setupObsDefinitions(), this.setupAlerts()])
      .then(async () => {
        this.pageLoaded = true;
      })
      .catch((err) => {
        console.error('AlertsPage - Promise.all', JSON.stringify(err));
        this.pageLoaded = true;
      });
  }

  public async setupAlerts() {
    const dataReader = await this.rulesAlertService.getFirstDataAvailable();
    let ruleAlerts: IRuleAlert[] = [];
    let minRange: moment.Moment;
    switch (this.alertRange) {
      case "3months":
        minRange = moment().add(-3, "months");
        break;
      default:
        minRange = moment().add(-1, this.alertRange);
        break;
    }
    ruleAlerts = dataReader.filter((alert) => {
      return moment(alert.creation).isSameOrAfter(minRange);
    });
    this.alerts = ruleAlerts.sort((objA, objB) => {
      return (moment(objA.creation).isSameOrBefore(moment(objB.creation)) ? 1 : -1);
    });
  }

  private async setupObsDefinitions() {
    this.allDefinitions = await this.obsDefService.getFirstDataAvailable();
  }

  public async onAcceptAlert(alert: IRuleAlert) {
    alert.status = NOTIFICATION_STATUS.ACCEPTED;
    alert.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    await this.rulesAlertService.save(alert, true);
  }

  public async onCancelAlert(alert: IRuleAlert) {
    const comment = await this.popupService.showPrompt("application.title", "myobservations.cancelAlert", "notification.reason", "text", true);
    alert.comment = comment;
    alert.status = NOTIFICATION_STATUS.REJECTED;
    alert.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    await this.rulesAlertService.save(alert, true);
  }
}
