import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IKnowledges, IKnowMedia, IMediaFromRule } from 'src/app/helpers/knowledge-helper';
import { AccountService } from './account.service';
import { CareplanService } from './careplan.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { Tools } from 'src/app/helpers/tools-helper';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeService extends BasicSyncService<IKnowledges, IKnowledges[]> {

  public lastGenNotif: string = null;

  private lastValueOfParam = '[]';
  private storageKey = 'KnowledgeLastValue';

  private careplansWatch$: Subscription;

  constructor(
    protected dataService: DataService,
    private careplanService: CareplanService,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private apiService: ApiService
  ) {
    super(dataService);
    this.careplansWatch$?.unsubscribe();
    this.careplansWatch$ = this.careplanService?.watchData().subscribe(c => {
      this.needRefresh = true;
    });
  }

  public getUrl() {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
    this.careplansWatch$?.unsubscribe();
    this.careplansWatch$ = this.careplanService?.watchData().subscribe(c => {
      this.needRefresh = true;
    });
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.careplansWatch$?.unsubscribe();
    this.careplansWatch$ = this.careplanService?.watchData().subscribe(c => {
      this.needRefresh = true;
    });
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'knwoledgesCareplans_',
      entityStoreKey: 'list',
      getUrl: '/knowledge?reference=',
      setUrl: '/knowledge',
      expirationDays: 10,
      encrypted: false
    };
  }

  public getDependentServices(): BasicSyncService<any, any[] | any>[] {
    return [this.careplanService];
  }

  public async init() {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = '[]';
    }
  }

  public clear() {
    super.clear();
    this.lastValueOfParam = '[]';
  }

  public async *getDataReader(parameters?: string[]): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {

    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      let params = [];
      if (parameters) {
        params = parameters.map(value => Tools.deleteAcccentSpecialcharacter(value.toLowerCase()));
      }
      const snomed = await this.careplanService.listSnomedRefActivities();
      const paramObject = Object.assign({}, this.defaultDataParameter);
      const stringifySnomed = JSON.stringify(snomed);
      paramObject.getUrl += stringifySnomed;
      // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
      // paramObject.entityStoreKey += stringifySnomed;
      this.lastValueOfParam = stringifySnomed;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

      const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, false, this);
      let d: IKnowledges[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, params);
        yield d;
      }
      return d;
    } catch (err) {
      console.error("KnowledgeService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], params?: string[]) {
    try {
      const kn = dataResult;
      if (!params) { return kn; }
      const filteredData = kn.filter((kk) => {
        const parsedRef = Tools.deleteAcccentSpecialcharacter(kk.reference.toLowerCase());
        return params.includes(parsedRef);
      });
      return filteredData;
    } catch (err) {
      console.error('Error while processing knowledgeService data: ', err);
      return dataResult;
    }
  }

  public async getMediaFromRule(ruleId: number, vitalProfileCode: string): Promise<IMediaFromRule> {
    const apiResponse = await this.apiService.getWithPromise(`/mediaFromRule?ruleId=${ruleId}&vitalProfileCode=${vitalProfileCode}`);
    return apiResponse.data;
  }

  public async getMedia(mediaId: string, publicationDate: string): Promise<IKnowMedia> {
    const apiResponse = await this.apiService.getWithPromise(`/media?identifier=${mediaId}&publicationDate=${publicationDate.replace('+', '%2b')}`);
    return apiResponse.data;
  }
}
