import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/baseClasses/base-component';
import { BasePage } from 'src/app/baseClasses/base-page';
import { AppointmentService } from 'src/app/services/globalDataProvider/appointment.service';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-settings-export-calendar-page-modal',
  templateUrl: './settings-export-calendar-page-modal.component.html',
  styleUrls: ['./settings-export-calendar-page-modal.component.scss'],
})
export class SettingsExportCalendarPageModalComponent extends BasePage {

  @ViewChild(IonContent) content: IonContent;
  public exportUrl = null;
  public navParam = "main";
  public isDesktop = false;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected translateSvc: TranslateService,
    private modalCtrl: ModalController,
    private appointmentService: AppointmentService,
    configService: ConfigurationService
  ) {
    super(translateSvc, configService, infoService, popupService);
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    this.appointmentService.generateIcalUrl().pipe(takeUntil(this.onDestroy$)).subscribe(
      res => {
        this.exportUrl = res.url;
      },
      error => {
        console.error(error);
      }
    );

    this.isDesktop = !this.isCordova ? true : false;
  }

  public copyUrl() {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.exportUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    this.translateSvc.get("exportCalendar.copyDone").pipe(takeUntil(this.onDestroy$)).subscribe(val => {
      this.popupService.showToast(val, 3000, "bottom");
    });
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  public navChange(navParam: string): void {
    this.navParam = navParam;
    this.content.scrollToTop();
  }

}
