import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, from } from 'rxjs';
import { INotification, NOTIFICATION_STATUS } from 'src/app/models/notification';
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { CustomParamKey } from 'src/app/models/dataParameters';

@Injectable({
  providedIn: 'root'
})
export class NotificationsDrugsIntakeService extends BasicSyncService<INotification, INotification[]> {

  constructor(protected dataService: DataService) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<INotification[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'notificationsdrugsIntake_',
      entityStoreKey: 'list',
      getUrl: '/drugIntake',
      setUrl: '/drugIntake',
      expirationDays: 10,
      encrypted: true,
      customParam: {
        [CustomParamKey.mergeSetRequest]: true
      }
    };
  }

  public setDrugsIntake(notif: INotification): Promise<INotification> {
    if (notif.status === NOTIFICATION_STATUS.NONE) { // do not send the notification with status NONE : the dashboard will calculate them
      return null;
    }
    notif.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    const savePromise = this.dataService.saveInArray(notif, (entity) => entity.time === notif.time &&
      entity.account === notif.account &&
      entity.appId === notif.appId &&
      entity.ntype === notif.ntype &&
      entity.status === notif.status, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.POST
    }).then((n: INotification) => {
      const notifications = this.peekData();
      const i = notifications.findIndex(e => e.time === n.time &&
        e.account === n.account &&
        e.appId === n.appId &&
        e.ntype === n.ntype);
      if (i >= 0 && !n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        notifications[i] = n;
      } else if (i < 0 && !n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        notifications.push(n);
      } else if (i >= 0 && n.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        notifications.splice(i, 1);
      }
      this.pokeData(notifications);
      return n;
    });
    return savePromise;
  }

  /**
  * set the notif with a status none if there is not already any information on its intake
  * @param notif
  * @deprecated do not send the notification with status NONE : the dashboard will calculate them
  */
  public async setDrugsIntakeWithStatusNone(notifs: INotification[]) {
    const notifFilter = notifs.filter((notif) => {
      return notif.status === NOTIFICATION_STATUS.NONE && (moment().isSame(moment(notif.time), "days") || moment().add(1, "days").isSame(moment(notif.time), "days"));
    });

    if (notifFilter.length === 0) { return; }
    notifFilter.forEach((notif) => notif.actionStatus = ACTION_STATUS_ENTITY.MODIFIED);
    let notifToUpdate: INotification[] = null;

    const dataReader = this.getDataReader();
    let iterator = await dataReader.next();
    while (!iterator.done) { iterator = await dataReader.next(); }

    notifToUpdate = notifFilter.filter((notif) => {
      const index = iterator.value.findIndex((entity: INotification) => {
        return entity.time === notif.time &&
          entity.account === notif.account &&
          entity.appId === notif.appId &&
          entity.ntype === notif.ntype;
      });
      return index === -1;
    });

    if (notifToUpdate && notifToUpdate.length > 0) {
      this.dataService.saveArrayInArray(notifToUpdate,
        (entity: INotification, notif: INotification) => entity.time === notif.time &&
          entity.account === notif.account &&
          entity.appId === entity.appId &&
          entity.ntype === entity.ntype, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST
      }).then((n: INotification[]) => {
        this.pokeData(n);
        return n;
      });
    }
  }
}
