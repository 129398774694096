import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from '../appConstants';
import { IDownloadObservations } from '../models/downloadObservations';
import { ApiService } from './api.service';
import { ConfigurationService } from './globalDataProvider/configuration.service';
import { LocalStorageService } from './storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadObservationsService {

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private configService: ConfigurationService
  ) { }

  public getFormsData(): Promise<IDownloadObservations> {
    return this.localStorageService.getData(AppConstants.OBS_EXPORT_DATA, false).then((value) => {
      return JSON.parse(value);
    },
      (err) => {
        console.warn(err);
        return false;
      });
  }

  public setFormsData(param: IDownloadObservations): Promise<IDownloadObservations> {
    return this.localStorageService.setData(AppConstants.OBS_EXPORT_DATA, JSON.stringify(param), false);
  }

  public exportObservations(param: IDownloadObservations): Observable<boolean> {
    const lang = this.configService.getCurrentLanguage();
    const formatStartDate = moment(param.startDate).format("YYYY-MM-DD");
    const formatEndDate = moment(param.endDate).format("YYYY-MM-DD");

    return this.apiService.get('/exportObservations', undefined, {
      lang: lang,
      docType: param.docTypes,
      obsCodes: param.loincs,
      startDate: formatStartDate,
      endDate: formatEndDate,
      mails: param.mails
    }).pipe(
      map(res => {
        if (res && res.success) {
          return true;
        }
      })
    );
  }
}
