import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, from } from 'rxjs';
import { IRuleAlert } from 'src/app/models/ruleAlert';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { IRuleDefinition } from 'src/app/models/rule';
import { LoaderService } from '../loader.service';

@Injectable({
  providedIn: 'root'
})
export class RulesAlertService extends BasicSyncService<IRuleAlert, IRuleAlert[]> {

  constructor(
    protected dataService: DataService,
    private accountService: AccountService,
    private loaderService: LoaderService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRuleAlert[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'rulesalert_',
      entityStoreKey: 'list',
      getUrl: '/rulealertsForPatient',
      setUrl: '/rulesalert',
      expirationDays: 10,
      encrypted: true,
    };
  }

  public async *getDataReader(): AsyncGenerator<IRuleAlert[], IRuleAlert[], IRuleAlert[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IRuleAlert[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      console.error("RulesAlertService getDataReader()", err);
      yield [];
      return [];
    }
  }

  /**
   * Return the last cache alert linked to the identifierRule
   * @param identifierRule
   * @param ruleDefinition
   */
  public getLastCacheAlert(identifierRule: string, ruleDefinition: IRuleDefinition): IRuleAlert {

    const cacheRulesAlert = this.peekData().sort((alert1, alert2) => moment(alert2.creation).diff(moment(alert1.creation)));
    // cache alert is sorted by "most recent first" (on the field "creation"), so the first alert find is the last one
    for (const alert of cacheRulesAlert) {
      if (alert.identifier.findIndex((id) => id.value.startsWith(identifierRule)) !== -1
        // && alert.rule.level === ruleDefinition.level
        // && alert.rule.repeatOnly === ruleDefinition.repeatOnly
        // && alert.rule.results[0].value.fr === ruleDefinition.results[0].value.fr
      ) {
        return alert;
      }
    }
    return null;
  }

  public async save(ra: IRuleAlert, withToast = true): Promise<IRuleAlert> {

    const savePromise = this.dataService.saveInArray(ra, (entity) => entity._id === ra._id, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.PUT
    }).then((savedRA: IRuleAlert) => {
      const rulesAlerts = this.peekData();
      const i = rulesAlerts.findIndex(e => e._id === savedRA._id);
      if (i >= 0 && !savedRA.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rulesAlerts[i] = savedRA;
      } else if (i >= 0 && savedRA.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rulesAlerts.splice(i, 1);
      } else if (i < 0 && !savedRA.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rulesAlerts.push(savedRA);
      }
      this.pokeData(rulesAlerts);
      return savedRA;
    });
    if (withToast) { await this.loaderService.showSavingToast(true); }
    const result = await savePromise;
    if (withToast) { await this.loaderService.showSavingToast(false); }
    return result;
  }

  /*
    public delete(ra: IRuleAlertAlert): Observable<IRuleAlertAlert> {
        IEntity.setDeleted(ra);
        return this.save(ra);
    }
  */
}
