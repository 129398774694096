import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { AppConstants } from '../appConstants';
import { ICareplan } from '../models/careplan';
import { KeyValueExtra } from '../models/keyValue';
import { IFingerprintData } from '../models/IFingerprintData';
import { LocalStorageService } from './storage/local-storage.service';
import { SecureStorageService } from './storage/secure-storage.service';
import { ILocalAssociationUserPassword } from '../models/LocalAssociationUserPassword';
import { ArrayHelper } from '../helpers/array-helper';
import { ITranslation } from '../models/translation';
import { Market } from '@ionic-native/market/ngx';
import { IPlatformInfo } from '../models/platformInfo';
import { Device } from '@awesome-cordova-plugins/device/ngx';

@Injectable({
  providedIn: 'root'
})
export class InfoAppService {

  private _currentMode: "PROD" | "DEV" | "FORCE_DEV" = undefined;
  private _version: string = undefined;
  private _someoneLogIn: boolean = undefined;
  private url_api: string = undefined;

  /**
  * Get current language translation from ITranslation object
  */
  public static getTranslation(definition: ITranslation, lang: string, txtBackup: string): string {

    try {
      if (!definition) return txtBackup;
      if (definition.hasOwnProperty(lang)) {
        return definition[lang];
      } else {
        console.error(`Error on getting ${lang} lang`);
        return txtBackup;
      }
    } catch (err) {
      console.error("getTranslation", err);
      return txtBackup;
    }
  }

  constructor(
    private appVersion: AppVersion,
    protected platform: Platform,
    private localStorageService: LocalStorageService,
    private secureStorageService: SecureStorageService,
    private market: Market,
    private device: Device
  ) {

  }

  public async getDrugPageActiveDrugsOnly(): Promise<boolean> {
    try {
      const activeDrugsOnly = await this.localStorageService.getData(AppConstants.ACTIVE_DRUGS_ONLY, false);
      return JSON.parse(activeDrugsOnly);
    } catch (error) {
      return true;
    }
  }

  public setDrugPageActiveDrugsOnly(activeDrugsOnly: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.ACTIVE_DRUGS_ONLY, JSON.stringify(activeDrugsOnly), false);
  }

  /**
   * 
   * @returns The last attempt to create a secure storage : 
   *  true  -> secure storage not active,
   *  false -> secure storage active
   *  null  -> unknown information (first launch)
   */
  public async getLastSecureStorageNotActive(): Promise<boolean | null> {
    try {
      const lastSecureStorageNotActive = await this.localStorageService.getData(AppConstants.KEY_SECURESTORAGE_NOT_ACTIVE, false);
      return JSON.parse(lastSecureStorageNotActive);
    } catch (error) {
      return null;
    }
  }

  /**
   * Save the last attempt to create a secure storage
   * @param lastSecureStorageNotActive true -> secure storage not active ; false -> secure storage active
   * @returns 
   */
  public setLastSecureStorageNotActive(lastSecureStorageNotActive: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.KEY_SECURESTORAGE_NOT_ACTIVE, JSON.stringify(lastSecureStorageNotActive), false);
  }

  public async getLocalAssociationUserPassword(): Promise<Array<ILocalAssociationUserPassword>> {
    try {
      const data = await this.secureStorageService.get(AppConstants.ASSOCIATION_USER_PASSWORD);
      return JSON.parse(data);
    } catch (error) {
      return [];
    }
  }

  public async addLocalAssociationUserPassword(user_password: ILocalAssociationUserPassword): Promise<boolean> {
    try {
      const data = await this.getLocalAssociationUserPassword();
      data.push(user_password);
      await this.secureStorageService.set(
        AppConstants.ASSOCIATION_USER_PASSWORD,
        JSON.stringify(data.filter(ArrayHelper.onlyUniqueLocalAssociationUserPassword))
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  public async getCurrentMode(): Promise<"PROD" | "DEV" | "FORCE_DEV"> {
    try {
      if (this._currentMode === undefined) {
        this._currentMode = await this.localStorageService.getData(AppConstants.APP_MODE, false).catch(() => "PROD");
      }
      return this._currentMode;
    }
    catch (err) {
      return "PROD";
    }
  }

  public async getUrlAPI(): Promise<string> {
    if (this.url_api === undefined) {
      let url = AppConstants.API_URL_PROD;
      const currentMode = await this.getCurrentMode();
      if (currentMode && currentMode === "DEV") {
        url = AppConstants.LOCAL_DEV_MODE ? AppConstants.API_URL_LOCAL : AppConstants.API_URL_DEV;
      }
      else if (currentMode && currentMode === "FORCE_DEV") {
        // this url can be reach wile we run in local mode.
        // must be useful if we want to siwtch between local and devsrv without rebuild
        url = AppConstants.API_URL_FORCE_DEV;
      }
      this.url_api = url; // obligate to init this.url_api at the last moment in case of demand parallelization
    }
    return this.url_api;
  }

  public setCurrentMode(currentMode: "PROD" | "DEV" | "FORCE_DEV"): Promise<any> {
    this._currentMode = currentMode;
    this.url_api = undefined;
    return this.localStorageService.setData(AppConstants.APP_MODE, currentMode, false);
  }

  public async getSomeoneLogIn(): Promise<boolean> {
    try {
      if (this._someoneLogIn === undefined) {
        this._someoneLogIn = JSON.parse(await this.localStorageService.getData(AppConstants.SOMEONE_LOGIN, false));
      }
      return this._someoneLogIn;
    }
    catch (err) {
      return false;
    }
  }

  public setSomeoneLogIn(logIn: boolean) {
    this._someoneLogIn = logIn;
    return this.localStorageService.setData(AppConstants.SOMEONE_LOGIN, JSON.stringify(logIn), false);
  }

  public setFirstLaunchForActiveAccount(firstLaunch: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_FIRST_LAUNCH, JSON.stringify(firstLaunch), false);
  }

  public getFirstLaunchForActiveAccount(): Promise<boolean> {
    return this.localStorageService.getData(AppConstants.PRM_FIRST_LAUNCH, false).then((value) => {
      return JSON.parse(value);
    },
      (err) => {
        return true;
      });
  }

  public getParamHealth(): Promise<boolean> {

    return this.localStorageService.getData(AppConstants.PRM_READ_GAHDATA, false).then((value) => {
      return JSON.parse(value);
    },
      (err) => {
        // console.warn(err);
        return false;
      });
  }

  public setParamHealth(param: boolean): Promise<boolean> {
    return this.localStorageService.setData(AppConstants.PRM_READ_GAHDATA, JSON.stringify(param), false);
  }

  public async getVersion(): Promise<string> {
    try {
      if (this._version === undefined) {
        this._version = await (!this.isCordova() ? Promise.resolve('Desktop') : this.appVersion.getVersionNumber());
      }
      return this._version;
    }
    catch (error) {
      return 'Desktop';
    }
  }

  public isCordova(): boolean {
    return this.platform ? this.platform.is("cordova") : false;
  }

  public isAndroid(): boolean {
    return this.platform ? this.platform.is("android") : false;
  }

  public isIOS(): boolean {
    return this.platform ? this.platform.is("ios") : false;
  }

  public setLastLogin(login: string): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_LAST_LOGIN, login, false);
  }

  public getLastLogin(): Promise<string> {
    return this.localStorageService.getData(AppConstants.PRM_LAST_LOGIN, false);
  }

  /**
   * Create/update entry in array for Careplan Seen: with careplan modified date and timestamp
   * @param careplan
   */
  public setCareplanSeen(careplan: ICareplan): Promise<void> {
    if (!careplan) {
      return Promise.resolve();
    }
    return this.getCareplanSeen()
      .then((arrKve) => {
        try {
          let cpFound = false;
          if (!arrKve) arrKve = new Array<KeyValueExtra>();
          for (const kve of arrKve) {
            if (kve.key === careplan._id) {
              kve.value = careplan.modified;
              kve.extra = moment().format();
              cpFound = true;
              break;
            }
          }
          // not found yet ?
          if (!cpFound) arrKve.push(new KeyValueExtra(careplan._id, careplan.modified, moment().format()));
          // save new entry or modified one
          return this.localStorageService.setData(AppConstants.PRM_CAREPLAN_BADGE, JSON.stringify(arrKve), true);
        } catch (err) {
          console.error("setCareplanSeen", err);
          return null;
        }
      });
  }

  /**
   * Get settings that store last time careplans have been seen
   */
  public getCareplanSeen(): Promise<KeyValueExtra[]> {
    return this.localStorageService.getData(AppConstants.PRM_CAREPLAN_BADGE, true)
      .then((value: string) => { return (!value ? [] : JSON.parse(value)); },
        (err) => { return []; });
  }

  /**
   * Get settings that store moments that appointments have been seen
   */
  public getAppointmentSeen(): Promise<KeyValueExtra[]> {
    return this.localStorageService.getData(AppConstants.PRM_APPOINTMENT_BADGE, true)
      .then((value: string) => { return (!value ? [] : JSON.parse(value)); },
        (err) => { return []; });
  }

  public getDataAssociatedToFingerprint(): Promise<IFingerprintData> {
    return this.secureStorageService.get(AppConstants.FINGERPRINT_DATA).then((data) => {
      return JSON.parse(data) as IFingerprintData;
    });
  }

  public setDataAssociatedToFingerprint(data: IFingerprintData): Promise<string> {
    return this.secureStorageService.set(AppConstants.FINGERPRINT_DATA, JSON.stringify(data));
  }

  /**
   * Check if Careplan edition module is enabled (and only in development mode)
   */
  public isCareplanEditionModuleEnabled(): boolean {
    return AppConstants.CAREPLAN_EDIT_MODULE && (this._currentMode === "DEV");
  }

  /**
* Does user want no more advice today ?
*/
  public async isNoMoreAdviceToday(): Promise<boolean> {
    try {
      const time = await this.localStorageService.getData(AppConstants.PRM_NO_ADVICE, false);
      if (!time) return false;
      return moment(time).isSame(moment(), "day");
    }
    catch (err) {
      return false;
    }

  }

  /**
 * User cancelled advice notification, it means that he wants no more advice today (only)
 */
  public setNoMoreAdviceToday(): Promise<void> {
    return this.localStorageService.setData(AppConstants.PRM_NO_ADVICE, moment().format(), false);
  }

  /**
   * Open Market to download Comunicare (on Android or IOS)
   * @param closeApp force close application
   */
  public async openMarket(closeApp: boolean): Promise<void> {
    try {
      if (this.isAndroid()) {
        await this.market.open('be.xelink.comunicare');
      }
      else if (this.isIOS()) {
        await this.market.open('id1236573069');       
      }
    } catch (error) {
      console.error("InfoAppService - openMarket", error);
    } finally {
      if (closeApp) return this.closeApp();
    }
  }

  /**
   * Close the application on Cordova platform
   */
  public closeApp() {
    if (this.isCordova()) navigator['app'].exitApp();
  }

  public getPlatformInfo(): IPlatformInfo {
    if (this.isCordova()) {
      return {  
        "x-browser-info": null,
        "x-phone-info": this.device.manufacturer + " " + this.device.model + " - " + this.device.platform + " " + this.device.version	// Ex: "motorola voles - Android 10.0"
      };
    } else {
      return {
        "x-browser-info": navigator.userAgent,		// Ex: "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Mobile Safari/537.36",
        "x-phone-info": null
      };
    }
  }

  public getImgPrefixAccordingToCordova(): string {
    return this.isCordova() ? '' : 'portail_';
  }

}
