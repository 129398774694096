import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IKnowledges, KnowledgeBase } from "src/app/helpers/knowledge-helper";
import { IKeyValue } from "src/app/models/keyValue";
import { KnowledgeKeyValueService } from "../knowledge-key-value.service";
import { AccountService } from "./account.service";
import { BasicSyncService } from "./core/basic-sync.service";
import { DataService } from "./core/data.service";

@Injectable({
    providedIn: 'root'
})
export class KnowledgesFAQService extends BasicSyncService<IKnowledges, IKnowledges[]> {

    constructor(
        protected dataService: DataService,
        protected knKeyValue: KnowledgeKeyValueService,
        private accountService: AccountService
    ) {
        super(dataService);
    }

    protected clearWatch(): void {
        this.data$ = new BehaviorSubject<IKnowledges[]>([]);
    }

    protected initWatch(): void {
        this.data$.next([]);
    }

    protected setupDataParameters(): void {
        this.defaultDataParameter = {
            entityPrefix: 'knowledges_faq_',
            entityStoreKey: 'list',
            getUrl: '/knowledges/faq',
            setUrl: null,
            expirationDays: 10,
            encrypted: false
        };
    }

    public async *getDataReader(): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
        try {
            if (this.accountService.isOnlyRelated) {
                yield [];
                return [];
            }
            const dataReader = super.getDataReader();
            let d: IKnowledges[] = [];
            for await (const data of dataReader) {
                d = this.processData(data);
                yield d;
            }
            return d;
        } catch (err) {
            console.error("KnowledgesFAQService getDataReader()", err);
            yield [];
            return [];
        }
    }

    private processData(dataResult: IKnowledges[]) {
        const kn = dataResult;
        try {
            const filteredData = KnowledgeBase.getKnowledgesOnlyOnce(dataResult);
            return filteredData;
        } catch (err) {
            console.error('Error while processing KnowledgesFAQService data: ', err);
        }
        return kn;
    }

    public async getFreshestKnowledgeFAQToKeyValue(onlyThreeStars = false): Promise<IKeyValue[]> {
        return this.knKeyValue.getFreshestKnowledgeToKeyValue(onlyThreeStars, this.getDataReader());
    }
}
