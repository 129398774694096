import { Pipe, PipeTransform } from "@angular/core";
import { Answer, QRQuestion, QuestionnaireResponse } from "../helpers/questionnaireResponse";
import { Contained } from "../models/questionnaire";
import { Coding } from "../models/sharedInterfaces";

/**
 * Return multiple choice values define in "questionnaire"
 */
@Pipe({ name: "getValueSetChoices" })
export class GetValueSetChoicesPipe implements PipeTransform {
    constructor() { }

    public transform(reference: string, contained: Contained[], isReadonly: boolean, question?: QRQuestion): Coding[] {
        if (contained) {
            for (const element of contained) {
                if ((element.resourceType === "ValueSet") && (element.idSet === reference)) {
                    // This part is basically only for checkbox (mutiple answers) to check if they're checked or not
                    if (isReadonly && question) {
                        element.compose.include[0].concept.forEach((a) => {
                            const i = question.answer.findIndex((q) => q.valueCoding.code === a.code);
                            a.checked = i > -1;
                        });
                    } else {
                        element.compose.include[0].concept.forEach((a) => a.checked = false);
                    }
                    return element.compose.include[0].concept;
                }
            }
        }
        return [];
    }
}

@Pipe({ name: "getValueTxt" })
export class GetValueTxtPipe implements PipeTransform {
    constructor() { }

    public transform(linkId: string, quizResponse: QuestionnaireResponse): string {
        const res = this.getAnswerModel(linkId, quizResponse);
        if (res && res.length) {
            return res[0].valueCoding.display;
        }
        return '';
    }

    /**
     * Return the Answer object related to this "LinkId"
     */
    private getAnswerModel(linkId: string, quizResponse: QuestionnaireResponse): Answer[] {
        if (quizResponse.group.question?.length) {
            for (const question of quizResponse.group.question) {
                if (question.linkId === linkId) return question.answer;
            }
        }
        else if (quizResponse.group.group?.length) {
            for (const subGroup of quizResponse.group.group) {
                for (const question of subGroup.question) {
                    if (question.linkId === linkId) return question.answer;
                }
            }
        }
        return null;
    }
}

@Pipe({ name: "getRangeDisplay" })
export class GetRangeDisplayPipe implements PipeTransform {
    constructor() { }

    public transform(value: any, optionsRef: string, contained: Contained[]): string {
        const choices = optionsRef ? this.getValueSetChoices(optionsRef, contained) : [];
        const code = String(value);
        const display = choices.find((choice: Coding) => choice.code === code)?.display;
        return display ? display : code;
    }

    /**
     * Return multiple choice values define in "questionnaire"
     */
    private getValueSetChoices(reference: string, contained: Contained[]): Coding[] {
        if (contained) {
            for (const element of contained) {
                if ((element.resourceType === "ValueSet") && (element.idSet === reference)) {
                    return element.compose.include[0].concept;
                }
            }
        }
        return [];
    }
}

@Pipe({ name: "questGetValueSetChoices" })
export class QuestGetValueSetChoicesPipe implements PipeTransform {
    constructor() { }

    public transform(reference: string, contained: Contained[], question?: QRQuestion, init = false): Coding[] {
        if (contained) {
            for (const element of contained) {
                if ((element.resourceType === "ValueSet") && (element.idSet === reference)) {
                    // This part is basically only for checkbox (mutiple answers) to check if they're checked or not
                    if (init && question && question.answer) {
                        element.compose.include[0].concept.forEach((a) => {
                            const i = question.answer.findIndex((q) => q.valueCoding.code === a.code);
                            a.checked = i > -1;
                        });
                    } else if (init) {
                        element.compose.include[0].concept.forEach((a) => a.checked = false);
                    }
                    return element.compose.include[0].concept;
                }
            }
        }
        return [];
    }
}
