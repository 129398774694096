import { Pipe, PipeTransform } from "@angular/core";
import { ICommunication } from "../models/communication";

@Pipe({ name: 'getComSender' })
export class GetComSenderPipe implements PipeTransform {
  constructor() { }
  transform(msg: ICommunication): string {
    return msg.basedOn?.find(basedOn => basedOn.type === "careplan") ? msg.healthservice.display : msg.sender.display;
  }
}
