import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, concatMap, last, takeLast } from 'rxjs/operators';
import { IAuthResponse, USER_ROLE } from '../helpers/account-helper';
import { ServerResponse, SERVER_RESPONSE_TYPE } from '../helpers/server-response-helper';
import { SysAccount } from '../models/sysaccount';
import { ApiService } from './api.service';
import { FingerprintService } from './fingerprint.service';
import { AccountService } from './globalDataProvider/account.service';
import { ConfigurationService } from './globalDataProvider/configuration.service';
import { GoToPageService } from './go-to-page.service';
import { InfoAppService } from './info-app.service';
import { ModalService } from './modal.service';
import { PopupService } from './popup.service';
import { SysAccountService } from './sys-account.service';
import { IApiResponse } from '../models/iapi-response';
import { InitService } from './init.service';
import { ApiSyncService } from './globalDataProvider/core/api-sync.service';
import { MigrationIonic5Service } from './migration-ionic5.service';
import { PopoverController } from '@ionic/angular';
export enum LOGIN_TYPE {
  SUCCESS, NEWPASSWORD_REQUIRED, FAILED, DENIED, OTHER, IS2FA, FINGERPRINT_NOT_AVAILABLE, FINGERPRINT_FAILED, SUCCESS_OFFLINE,
  FAILED_OFFLINE, PRACTITIONER_DENIED, MIGRATION_IONIC5_FAILED, TOO_MANY_ATTEMPTS, EXPIRED_PASSWORD
}

export enum ASK_FINGERPRINT {
  NOT_AVAILABLE, SUCCESS, FAILED
}

interface SuccessAuthenticate {
  success: boolean;
  newToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private authState$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private sysAccountService: SysAccountService,
    private accountService: AccountService,
    private configService: ConfigurationService,
    private goToPageService: GoToPageService,
    private modalService: ModalService,
    private popupService: PopupService,
    private fingerService: FingerprintService,
    private infoAppService: InfoAppService,
    private translateSvc: TranslateService,
    private initService: InitService,
    private apiSyncService: ApiSyncService,
    private migrationIonic5: MigrationIonic5Service,
    private popover: PopoverController,

  ) { }

  /**
   * Watch the changes in the authentication
   * @return a observable with the authentication's state
   */
   public watchAuthState(): Observable<boolean> { return this.authState$; }
   /**
    * Returns the current state of the authentication
    */
  public peekAuthState(): boolean { return this.authState$.value; }

  public async disconnect() {
    // await this.sysAccountService.setRefreshToken(null);
    await this.infoAppService.setSomeoneLogIn(false);
    this.goToPageService.loginPage();
    this.authState$.next(false);
    this.apiSyncService.clearServices();
    this.initService.needReinitialization();
    // this.sysAccountService.deleteCachedSysAccount();
  }

  /**
   * Allow to connect a patient/related 
   * 1. user & password encode on the login page, OR
   * 2. IAuthResponse obtain via SSO (or other) 
   * @param user 
   * @param password 
   * @param authResponse 
   */
  public authenticate(user?: string, password?: string, authRep?: IAuthResponse): Observable<LOGIN_TYPE> {

    let obsAuthenticate: Observable<IApiResponse>; 

    if (user && password) {
      const body = { "login": user, "password": password };
      obsAuthenticate = this.apiService.post("/authenticatev2", body);
    }
    else if (authRep) {
      obsAuthenticate = of(ServerResponse.asServerResponse(true, "", authRep, authRep.token));
    }
    else {
      console.error("authenticate bad params", user, password, authRep);
    }

    return obsAuthenticate.pipe(
      concatMap((rep) => {
        const type = ServerResponse.type(rep);

        switch (type) {
          case SERVER_RESPONSE_TYPE.SUCCESS:
            const authResponse = rep.data as IAuthResponse;

            const roles = authResponse.account?.role;
            if (!roles?.includes(USER_ROLE.RELATEDPERSON) && !roles?.includes(USER_ROLE.PATIENT)) {
              return of(LOGIN_TYPE.PRACTITIONER_DENIED);
            }

            const sa = { name: authResponse.account ? authResponse.account.caremateIdentifier : user, token: authResponse.token };
            
            let additionalAction: Observable<SuccessAuthenticate> = of({success: true, newToken: null});
            
            if (authResponse.is2fa) {
              additionalAction = this.prompt2FA(authResponse.token);
            }

            return additionalAction.pipe(
              concatMap((success) => {
                if (!success.success) {
                  return of(LOGIN_TYPE.OTHER);
                }
                else {
                  if (success.newToken && success.newToken.length > 1) {
                    sa.token = success.newToken; // we must use the new token when we use 2FA
                  }
                  return from(this.sysAccountService.setSysAccount(sa)).pipe(
                    concatMap(() => {
                      const p = this.accountService.getFreshestData();
                      return from(p).pipe(
                        concatMap(() => {
                          return from(this.configService.refreshConfiguration()).pipe(
                            concatMap(() => {
                              this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());
                              return this.translateSvc.use(this.configService.getCurrentLanguage()).pipe(
                                last(),
                                concatMap(() => {
                                  if (authResponse.newPasswordRequired) {
                                    return this.promptNewPassword().pipe(
                                      concatMap((newPassword) => {
                                        if (newPassword) {
                                          this.authState$.next(true);
                                          return this.loginSuccess(user, newPassword, sa);
                                        }
                                        else {
                                          return of(LOGIN_TYPE.FAILED);
                                        }
                                      })
                                    );
                                  }
                                  else {
                                    this.authState$.next(true);
                                    return this.loginSuccess(user, password, sa);
                                  }
                                }));
                            })
                          );
                        })
                      );
                    })
                  );
                }
              })
            );
          case SERVER_RESPONSE_TYPE.TOO_MANY_FAIL_CONNECTION:
            return of(LOGIN_TYPE.TOO_MANY_ATTEMPTS);
          case SERVER_RESPONSE_TYPE.EXPIRED_PASSWORD:
            return of(LOGIN_TYPE.EXPIRED_PASSWORD);
          case SERVER_RESPONSE_TYPE.ACCESS_DENIED:
              return of(LOGIN_TYPE.DENIED);
          case SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED:
              return of(LOGIN_TYPE.FAILED);
          case SERVER_RESPONSE_TYPE.SERVER_UNREACHABLE: // Try offline connection !
              return from(this.infoAppService.getCurrentMode()).pipe(
                concatMap((currentMode) => {
                  return from(this.localAuthenticate(user, password, currentMode));
                })
              );
          default:
            return of(LOGIN_TYPE.OTHER);
        }
      })
    );
  }

  private loginSuccess(user: string, password: string, sa: SysAccount): Observable<LOGIN_TYPE> {
    return from(this.infoAppService.setSomeoneLogIn(true)).pipe(
      concatMap(() => {
        this.initService.needReinitialization();
        return from(this.infoAppService.getCurrentMode()).pipe(
          concatMap((mode) => {
            return from(this.infoAppService.addLocalAssociationUserPassword({
              login: user,
              password: password,
              mode: mode,
              caremateIdentifier: sa.name
            })).pipe(
              concatMap(() => {
                return this.checkAndManageMigrationIonic5ThenConnect(user, password);
              })
            );
          })
        );
      })
    );
  }

  private checkAndManageMigrationIonic5ThenConnect(user: string, password: string): Observable<LOGIN_TYPE> {
    return from(this.migrationIonic5.hasBeenClearedForIonic5().catch(() => false)).pipe(
      concatMap((migrationNoNeed) => {
        if (migrationNoNeed) {
          return of(LOGIN_TYPE.SUCCESS);
        }
        return from(this.migrationIonic5.synchroAndClearForIonic5().catch(() => false)).pipe(
          concatMap((success) => {
            if (success) {
              return from(this.disconnect().catch(() => null)).pipe(
                concatMap(() => {
                  return this.authenticate(user, password);
                })
              );
            }
            else {
              return from(this.disconnect().catch(() => null)).pipe(
                concatMap(() => {
                  return of(LOGIN_TYPE.MIGRATION_IONIC5_FAILED);
                })
              );
            }
          })
        );
      })
    );
  }

  /**
   * Return {true, newToken} if success ; {false, null} otherwise
   * @param user 
   * @param password 
   * @param token 
   */
  private prompt2FA(token: string): Observable<SuccessAuthenticate> {
    return this.modalService.presentModal2FA().pipe(
      takeLast(1),
      concatMap((code) => {
        if (code) {
          const body = { "token2fa": token, "code": code };
          return this.apiService.post("/authenticate2fa", body).pipe(
            concatMap((rep) => {
              const type = ServerResponse.type(rep);
              switch (type) {
                case SERVER_RESPONSE_TYPE.SUCCESS:
                  return of({success: true, newToken: rep.data.token});
                default:
                  // the modal is automatically displayed again if a wrong code is encoded
                  return from(this.popupService.showAlert("login.refused.title", "login.refused.wrongmfa", "")).pipe(
                    concatMap(() => {
                      return this.prompt2FA(token);
                    })
                  );
              }
            }),
            catchError((err) => {
              return of({success: false, newToken: null});
            })
          );
        }
        else {
            return of({success: false, newToken: null});
        }
      }),
      catchError((err) => {
        return of({success: false, newToken: null});
      })
    );
  }

  public promptNewPassword(): Observable<string> {
    return this.modalService.presentModalAccount(true).pipe(
      takeLast(1),
      concatMap((newAccount) => {
        if (newAccount) {
          const newPassword = newAccount.newPassword ? newAccount.newPassword : null ;
          this.accountService.setAccount(newAccount);
          return of(newPassword);
        }
        return of(null);
      })
    );
  }

  /**
   * Login to comunicare with the help of biometric parameters
   */
  public async authenticateWithFingerprint(): Promise<LOGIN_TYPE> {
    const fingerprintSuccess = await this.askFingerprint();

    switch (fingerprintSuccess) {
      case ASK_FINGERPRINT.SUCCESS:
        try {
          const dataFinger = await this.infoAppService.getDataAssociatedToFingerprint();
          if (dataFinger && dataFinger.login && dataFinger.mode && dataFinger.password) {
            return new Promise<LOGIN_TYPE>(async (resolve, reject) => {
              let success: LOGIN_TYPE;
              await this.infoAppService.setCurrentMode(dataFinger.mode);
              this.authenticate(dataFinger.login, dataFinger.password).subscribe(
                (result) => {
                  success = result;
                },
                (err) => {
                  reject(err);
                },
                () => {
                  resolve(success);
                }
              );
            });
          }
          else {
            throw new Error("No login or no password associated to fingerprint");
          }
        } catch (error) {
          // first finger : modal for ask login and password ! attention : vérifier qu'ils sont bons !!! sinon redemander
          await this.modalService.presentModalLoginPwdForFingerprint();
          return this.authenticateWithFingerprint();
        }        
      case ASK_FINGERPRINT.NOT_AVAILABLE:
        return LOGIN_TYPE.FINGERPRINT_NOT_AVAILABLE;
      default:
        return LOGIN_TYPE.FINGERPRINT_FAILED;
    } 
  }

  /**
   * Asks the user to identify him/herself with these biometric parameters
   */
  public async askFingerprint(): Promise<ASK_FINGERPRINT> {
    try {
      const avaible = await this.fingerService.isAvailable();
      if (avaible) {
        await this.fingerService.show();
        return ASK_FINGERPRINT.SUCCESS;
      }
      else {
        return ASK_FINGERPRINT.NOT_AVAILABLE;
      }      
    } catch (error) {
      console.error(error);
      return ASK_FINGERPRINT.FAILED;
    }
  }

  /**
   * Try local authenticate : only the last user can offline authenticate 
   * (the one associated with the registered sysaccount)
   * @param user 
   * @param password 
   * @param mode 
   */
  public async localAuthenticate(user: string, password: string, mode: "PROD" | "DEV" | "FORCE_DEV"): Promise<LOGIN_TYPE> {
    try {
      const caremateIdentifier = await this.sysAccountService.getCaremateId();
      const localAssociation = (await this.infoAppService.getLocalAssociationUserPassword())
        .filter((association) => association.login === user && 
                                association.password === password && 
                                association.mode === mode &&
                                association.caremateIdentifier === caremateIdentifier);
      if (localAssociation.length === 1) {
        const accountPromise = this.accountService.getFreshestData();
        await Promise.all([
          this.infoAppService.setSomeoneLogIn(true),
          this.configService.getDataReader().next(),
          accountPromise
        ]);
        this.authState$.next(true);
        return LOGIN_TYPE.SUCCESS_OFFLINE;
      }
      else {
        return LOGIN_TYPE.FAILED_OFFLINE;
      }
    } catch (error) {
      return LOGIN_TYPE.FAILED_OFFLINE;
    }
  }
  
  /**
 * Disconnect user and go to login page after confirmation via popup
 */
  public disconnectAfterConfirmation() {
    this.popupService.showYesNo("application.title", "logout.advert")
      .then((response) => {
        if (response) {
          this.popover.dismiss()
            .then(() => {
              this.disconnect();
            });
        }
      });
  }
}
