import { Injectable } from "@angular/core";
import { IKnowledges } from "../helpers/knowledge-helper";
import { IKeyValue } from "../models/keyValue";
import { ConfigurationService } from "./globalDataProvider/configuration.service";

@Injectable({
    providedIn: 'root'
})
export class KnowledgeKeyValueService {

    constructor(
        private configService: ConfigurationService
    ) { }

    public async *collectKnowledgeToKeyValue(onlyThreeStars = false, data: AsyncGenerator): AsyncGenerator<IKeyValue[], IKeyValue[], IKeyValue[]> {
        try {
            const dataReader = this.collectKnowledge(onlyThreeStars, data);
            // ---- Local data:
            const localDataResult = await dataReader.next();
            const localDataValue = this.processMediaKeyValue(localDataResult.value);
            yield localDataValue;
            const onlineDataResult = await dataReader.next();
            if (onlineDataResult.done) {
                return localDataValue;
            }
            // ---- Possible online data:
            const onlineDataValue = this.processMediaKeyValue(onlineDataResult.value);
            yield onlineDataValue;
            return onlineDataValue;
        } catch (err) {
            console.error("Error in collectKnowledgeToKeyValue", err);
            yield [];
            return [];
        }
    }

    public async *collectKnowledge(onlyThreeStars = false, data: AsyncGenerator): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
        try {
            const userLanguage = this.configService.getCurrentLanguage();
            const dataReader = data;
            // ---- Local data:
            const localDataResult = await dataReader.next();
            const localDataValue = this.processAdvice(localDataResult.value, onlyThreeStars, userLanguage);
            yield localDataValue;
            const onlineDataResult = await dataReader.next();
            if (onlineDataResult.done) {
                return localDataValue;
            }
            // ---- Possible online data:
            const onlineDataValue = this.processAdvice(onlineDataResult.value, onlyThreeStars, userLanguage);
            yield onlineDataValue;
            return onlineDataValue;
        } catch (err) {
            console.error('Error in collectKnowledge: ', err);
            yield [];
            return [];
        }
    }

    private processMediaKeyValue(kvKnowledges: IKnowledges[]): IKeyValue[] {
        return kvKnowledges.map((kv) => kv.knowledges)
            .reduce((acc, it) => [...acc, ...it], [])
            .map((kn) => {
                return kn.medias
                    .map((media) => {
                        return {
                            "key": kn.snomedReference.display,
                            "value": media
                        } as IKeyValue;
                    });
            })
            .reduce((acc, it) => [...acc, ...it], []);
    }

    private processAdvice(kvKnowledges: IKnowledges[], onlyThreeStars: boolean, userLanguage: string) {
        kvKnowledges.forEach((kv, ikv) => {
            kv.knowledges.forEach((knBase, iknBase) => {
                const medias = knBase.medias.filter((media) => {
                    if (onlyThreeStars) {
                        return media.language === userLanguage &&
                            media.importanceLevel === 3 &&
                            media.description &&
                            media.description.length > 0;
                    }
                    return media.language === userLanguage &&
                        media.description &&
                        media.description.length > 0;

                });
                kvKnowledges[ikv].knowledges[iknBase].medias = medias;
            });
        });
        return kvKnowledges;
    }

    public async getFreshestKnowledgeToKeyValue(onlyThreeStars = false, data: AsyncGenerator): Promise<IKeyValue[]> {
        const dataReader = this.collectKnowledgeToKeyValue(onlyThreeStars, data);
        let iterator = await dataReader.next();
        while (!iterator.done) { iterator = await dataReader.next(); }
        return iterator.value;
    }
}
