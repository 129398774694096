import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { Tools } from '../helpers/tools-helper';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private translateSvc: TranslateService,
    private alertCtrl: AlertController,
    private toastController: ToastController
  ) { }

  /**
   * convenient method to display popup message
   * @param text the text to display
   */
  public async showSimpleAlert(text: string): Promise<void> {
    const titleApp = this.translateSvc.instant("application.title");
    const alert = await this.alertCtrl.create({
      header: titleApp,
      message: text,
      buttons: ["OK"]
    });
    return await alert.present();
  }

  /**
   * Display Yes/No popup
   * returns a promise resolve true/false ; reject when closed by back button
   * @param titleKey
   * @param messageKey
   * @param showYesNoData
   */
  public showYesNo(titleKey: string, messageKey: string, showYesNoData?: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const prompt = await this.alertCtrl.create({
        header: this.translateSvc.instant(titleKey) + (showYesNoData ? `(${showYesNoData})` : ''),
        message: this.translateSvc.instant(messageKey),
        buttons: [
          { // YES
            text: this.translateSvc.instant("application.yes"),
            handler: () => { resolve(true); }
          },
          { // NO
            text: this.translateSvc.instant("application.no"),
            handler: () => { resolve(false); }
          }
        ],
        backdropDismiss: false
      });
      // show it
      await prompt.present();
      // closed by back button
      prompt.onDidDismiss().then(() => {
        reject(null);
      });
    }
    );
  }

  /**
 * Display Yes/Later popup
 * returns a promise resolve true/false ; reject when closed by back button
 * @param titleKey
 * @param messageKey
 */
  public showYesOrLater(titleKey: string, messageKey: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const prompt = await this.alertCtrl.create({
        header: this.translateSvc.instant(titleKey),
        message: this.translateSvc.instant(messageKey),
        buttons: [
          { // YES
            text: this.translateSvc.instant("application.yes"),
            handler: () => { resolve(true); }
          },
          { // LATER
            text: this.translateSvc.instant("application.later"),
            handler: () => { resolve(false); }
          }
        ],
        backdropDismiss: false
      });
      // show it
      await prompt.present();
      // closed by back button
      prompt.onDidDismiss().then(() => {
        reject(null);
      });
    }
    );
  }

  /**
   * convenient method to display popup message
   * Resolve when page is closed
   * @param titleKey
   * @param messageKey
   * @param extraMessage
   * @param backdropDismiss
   * @param buttons
   */
  public showAlert(titleKey: string, messageKey: string, extraMessage?: string, backdropDismiss?: boolean, buttons?: (string | AlertButton)[]): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let extraMsg: string = null;
      if (extraMessage) {
        extraMsg = JSON.stringify(extraMessage);
        if (extraMsg.length > 50) {
          extraMsg = extraMsg.substring(0, 50);
        }
      }
      const alert = await this.alertCtrl.create({
        header: this.translateSvc.instant(titleKey),
        message: this.translateSvc.instant(messageKey) + (!extraMsg ? "" : " (" + extraMsg + ")"),
        buttons: buttons ? buttons : ["OK"],
        backdropDismiss: (Tools.isDefined(backdropDismiss) ? backdropDismiss : true)
      });

      await alert.present();

      alert.onDidDismiss().then(() => {
        resolve();
      });
    });
  }

  /**
   * convenient method to display popup message
   * Resolve when page is closed
   * @param titleKey
   * @param messageKey
   * @param extraMessage
   * @param backdropDismiss
   */
  public showAlertWithoutTranslation(titleKey: string, messageKey: string, extraMessage?: string, backdropDismiss?: boolean): Promise<void> {
    return new Promise(async (resolve, reject) => {
      let extraMsg: string = null;
      if (extraMessage) {
        extraMsg = JSON.stringify(extraMessage);
        if (extraMsg.length > 50) {
          extraMsg = extraMsg.substring(0, 50);
        }
      }
      const alert = await this.alertCtrl.create({
        header: titleKey,
        message: messageKey + (!extraMsg ? "" : " (" + extraMsg + ")"),
        buttons: ["OK"],
        backdropDismiss: (backdropDismiss ? backdropDismiss : true)
      });

      await alert.present();

      alert.onDidDismiss().then(() => {
        resolve();
      });
    });
  }

  public showPrompt(
    titleKey: string,
    messageKey: string,
    placeholderKey: string,
    inputType?: "number" | "text" | "date" | "email" | "password" | "search" | "tel" | "url" | "time" | "week" | "month" | "datetime-local" | "checkbox" | "radio" | "textarea",
    dataMandatory: boolean = false,
    dateMin?: string
  ): Promise<string> {
    return new Promise(
      async (resolve, reject) => {
        const prompt = await this.alertCtrl.create({
          header: this.translateSvc.instant(titleKey),
          message: this.translateSvc.instant(messageKey),
          inputs: [{
            name: "valuetext",
            placeholder: this.translateSvc.instant(placeholderKey),
            type: inputType ? inputType : "text",
            min: dateMin
          }],
          buttons: [{ // cancel
            text: this.translateSvc.instant("application.cancel"),
            handler: (data) => { reject(null); }
          },
          {   // validate
            text: this.translateSvc.instant("application.validate"),
            handler: (data) => {
              if (!data.valuetext && dataMandatory) {
                this.showToast("error.mandatory", 10000, 'bottom');
                return false; // to prevent the popup from closing. We cannot use the keywords await/async in this case
              }
              else {
                resolve(data.valuetext);
              }
            }
          }]
        });

        await prompt.present();
        prompt.onDidDismiss().then(() => { reject(null); });
      });


  }

  public showToast(messageKey: string, duration: number, position: "bottom" | "top" | "middle", extraMessage?: String, color?: string): Promise<void> {
    return this.toastController.create({
      message: this.translateSvc.instant(messageKey) + (extraMessage ? extraMessage : ""),
      duration: duration,
      position: position,
      color
    }).then((toast) => {
      return toast.present();
    });
  }

  public async showToastWithCloseBtn(msg: string, position: "bottom" | "top" | "middle", extraMessage?: String): Promise<void> {
    const toast = await this.createToastWithCloseBtn(msg, position, extraMessage);
    return toast.present();
  }

  public async createToastWithCloseBtn(msg: string, position: "bottom" | "top" | "middle", extraMessage?: String) {
    const toast = await this.toastController.create({
      message: msg + (extraMessage ? extraMessage : ""),
      position: position,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: () => { }
        }
      ]
    });
    return toast;
  }

  public async createToast(messageKey: string, position: "bottom" | "top" | "middle", color: string, extraMessage?: String) {
    const toast = await this.toastController.create({
      message: this.translateSvc.instant(messageKey) + (extraMessage ? extraMessage : ""),
      position,
      color
    });
    return toast;
  }
}
