import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { AppConstants } from 'src/app/appConstants';
import { IAccount, USER_ROLE } from 'src/app/helpers/account-helper';
import { InfoAppService } from '../info-app.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { RequestSenderServiceSyncStatus, SYNC_HTTP_METHOD } from './core/request-sender.service';
import { CookieService } from 'ngx-cookie-service';
import { IApiResponse } from 'src/app/models/iapi-response';
import { ApiService } from '../api.service';
import { NeedConsentService } from '../need-consent.service';
import { Tools } from 'src/app/helpers/tools-helper';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BasicSyncService<IAccount, IAccount> {

  private key: string = AppConstants.ACCOUNT;
  public isOnlyRelated: boolean;

  constructor(
    private cookies: CookieService,
    protected dataService: DataService,
    private infoAppVersion: InfoAppService,
    private apiService: ApiService,
    private needConsentService: NeedConsentService
  ) {
    super(dataService);
    if (!this.useLocalStorage && this.cookies.check(this.key)) {
      const accountCached = JSON.parse(this.cookies.get(this.key)) as IAccount;
      this.pokeData(accountCached);
    }
  }

  public clear() {
    super.clear();
    this.deleteCachedAccount();
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IAccount>(null);
  }
  protected initWatch(): void {
    this.data$.next(null);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'account_',
      entityStoreKey: 'list',
      getUrl: '/me',
      setUrl: '/account',
      expirationDays: 10,
      encrypted: true,
    };
  }

  public async *getDataReader(): AsyncGenerator<IAccount, IAccount, IAccount> {
    try {
      const dataReader = this.dataService.readv2<IAccount, IAccount>(this.defaultDataParameter, false, this, false, false);
      let d: IAccount = null;
      for await (const data of dataReader) {
        if (Tools.isDefined(data?.needConsent)) {
          await this.needConsentService.updateNeedConsentStatus(data.needConsent);
        }
        d = this.processData(data);
        this.isOnlyRelated = d?.role?.includes(USER_ROLE.RELATEDPERSON) && !d?.role?.includes(USER_ROLE.PATIENT);
        yield d;
      }
      return d;
    } catch (err) {
      console.error("AccountService getAccount()", err);
      yield null;
      return null;
    }
  }

  private processData(dataResult: IAccount) {
    try {
      if (!this.useLocalStorage) {
        this.cookies.set(this.key, JSON.stringify(dataResult));
      }
    } catch (err) {
      console.error('Error while processing accountService data:', err);
    }
    return dataResult;
  }

  /**
   *
   * @param data
   * @param waitResponse indicates whether to wait for the route's response (and return the status) to release the promise of this method
   * @returns if waitResponse === true, returns the status of the request, otherwise, the account
   */
  public setAccount(data: IAccount, waitResponse = false): Observable<IAccount | RequestSenderServiceSyncStatus> {
    data.modified = moment().format();
    if (!this.useLocalStorage) {
      this.cookies.set(this.key, JSON.stringify(data));
    }
    if (waitResponse) {
      const savePromise = this.dataService.saveWaitResponse(data, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST
      }).then((rep) => {
        if (rep.statusSynchro === RequestSenderServiceSyncStatus.success) {
          this.needConsentService.updateNeedConsentStatus(rep.data.needConsent);
          this.pokeData(rep.data);
        }
        return rep.statusSynchro;
      });
      return from(savePromise);
    }
    else {
      const savePromise = this.dataService.save(data, {
        ...this.defaultDataParameter,
        method: SYNC_HTTP_METHOD.POST
      }).then((d: IAccount) => {
        this.pokeData(d);
        return d;
      });
      return from(savePromise);
    }
  }

  public async checkIfPasswordIsValid(data: IAccount): Promise<IApiResponse> {
    return await this.apiService.postWithPromise(this.defaultDataParameter.setUrl, data);
  }

  public deleteCachedAccount(): void {
    if (!this.useLocalStorage) {
      this.cookies.delete(this.key);
    }
    this.pokeData(null);
  }

  public get cachedAccount(): IAccount {
    return this.peekData();
  }

  public get cachedCaremateId(): string {
    return this.peekData() ? this.peekData().caremateIdentifier : null;
  }

  public get useLocalStorage(): boolean {
    return this.infoAppVersion.isCordova();
  }

  public updateGlobalPoints(score: number) {
    if (this.peekData().globalReawardScore) {
      this.peekData().globalReawardScore += score;
    } else {
      this.peekData().globalReawardScore = score;
    }
    this.setAccount(this.peekData()).subscribe();
  }

  public get globalPoints(): number {
    return this.cachedAccount.globalReawardScore;
  }

  public isNotRelated(): boolean {
    return !this.peekData() ? true : !this.peekData().role.includes(USER_ROLE.RELATEDPERSON);
  }

  public isRelatedAndPatient(): boolean {
    return !this.peekData() ? false : (this.peekData().role.includes(USER_ROLE.RELATEDPERSON) && this.peekData().role.includes(USER_ROLE.PATIENT));
  }

  public isOnlyPatient(): boolean {
    return !this.peekData() ? false : (this.peekData().role.includes(USER_ROLE.PATIENT) && !this.peekData().role.includes(USER_ROLE.RELATEDPERSON));
  }
}


