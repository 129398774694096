import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BasePage } from 'src/app/baseClasses/base-page';
import { Tools } from 'src/app/helpers/tools-helper';
import { CycleSchema } from 'src/app/models/entitylink';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-add-drug-schema-modal',
  templateUrl: './add-drug-schema-modal.component.html',
  styleUrls: ['./add-drug-schema-modal.component.scss'],
})
export class AddDrugSchemaModalComponent extends BasePage {
  @ViewChild('drugSchema') drugSchema: CycleSchema;
  @ViewChild('atcCode') atcCode: string;
  @ViewChild('mode') mode: string;

  public name: string;
  public nbrOfDays: number;
  private displayedColumns: string[] = [];
  public rowOfDisplayedColumns: any = [];
  public intakeDays: boolean[] = [];
  private finalDrugCycle: CycleSchema = {
    startDate: null,
    name: '',
    cycle: []
  };
  public isSamv2Drug = false;
  private eachRowsNumberOfDays: number;
  constructor(
    public modalCtrl: ModalController,
    infoService: InfoAppService,
    popupService: PopupService,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.eachRowsNumberOfDays = Math.floor(window.innerWidth / 32);
    this.isSamv2Drug = this.atcCode ? true : false;
    if (this.isCreation) {
      this.nbrOfDays = 28;
      for (let i = 0; i < this.nbrOfDays; i++) {
        this.intakeDays.push(false);
      }
    } else {
      this.intakeDays = Tools.deepCopy(this.drugSchema.cycle);
      this.name = this.drugSchema.name;
      this.nbrOfDays = this.intakeDays.length;
    }
    this.onNbrOfDaysChange(true);
  }

  public get isCreation() {
    return this.mode === "create";
  }

  public onNbrOfDaysChange(isInit: boolean = false) {
    this.displayedColumns = [];
    const nbrOfDays: number = this.nbrOfDays;
    for (let i = 0; i < nbrOfDays; i++) {
      this.displayedColumns.push(i.toString());
    }
    if (nbrOfDays < this.intakeDays.length) {
      this.intakeDays.splice(nbrOfDays, this.intakeDays.length - nbrOfDays);
    } else {
      const nbrOfDaysSupp = nbrOfDays - this.intakeDays.length;
      for (let i = 0; i < nbrOfDaysSupp; i++) {
        this.intakeDays.push(false);
      }
    }
    this.formatDisplayedColumns();
  }

  private formatDisplayedColumns() {
    this.rowOfDisplayedColumns = [];
    let value = this.displayedColumns.length / this.eachRowsNumberOfDays;
    if (value % 1 > 0) {
      value++;
    }
    value = Math.floor(value);
    for (let i = 0; i < value; i++) {
      this.rowOfDisplayedColumns.push([]);
      this.displayedColumns.forEach((index) => {
        if (+index >= this.eachRowsNumberOfDays * i && +index < this.eachRowsNumberOfDays * (i + 1)) {
          this.rowOfDisplayedColumns[i].push(index);
        }
      });
    }
  }

  public onCellClick(index: number) {
    this.intakeDays.splice(index, 1, this.intakeDays[index] ? false : true);
  }

  public onSave() {
    if (this.name && this.intakeDays && this.intakeDays.length > 0) {
      this.finalDrugCycle.name = this.name;
      this.finalDrugCycle.cycle = this.intakeDays;
      this.modalCtrl.dismiss(this.finalDrugCycle);
    } else {
      return;
    }
  }

}

