import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IKnowledges } from 'src/app/helpers/knowledge-helper';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { DrugService } from './drug.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { SamService } from '../sam.service';
import { NetworkService, ConnectionStatus } from '../network.service';
import { ConfigurationService } from './configuration.service';
import { IDrugInfo } from 'src/app/models/drugsInfo';
import { Tools } from 'src/app/helpers/tools-helper';

@Injectable({
  providedIn: 'root'
})
export class KnowledgeDrugService extends BasicSyncService<IKnowledges, IKnowledges[]> {

  public lastGenNotif: string = null;

  private lastValueOfParam = '[]';
  private storageKey = 'KnowledgeDrugLastValue';

  constructor(
    protected dataService: DataService,
    private drugService: DrugService,
    private accountService: AccountService,
    private localStorage: LocalStorageService,
    private samService: SamService,
    private networkService: NetworkService,
    private configService: ConfigurationService) {
    super(dataService);
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  public getUrl() {
    return this.defaultDataParameter.getUrl + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'knwoledgesDrug_',
      entityStoreKey: 'list',
      getUrl: '/knowledge?reference=',
      setUrl: '/knowledge',
      expirationDays: 10,
      encrypted: false
    };
  }

  public async init() {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
    } catch (err) {
      this.lastValueOfParam = '[]';
    }
  }

  public clear() {
    super.clear();
    this.lastValueOfParam = '[]';
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  public async *getDataReader(parameters?: string[]): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const snomed: string[] = await this.drugService.listSnomedRef();

      const paramObject = Object.assign({}, this.defaultDataParameter);
      let params = [];
      if (parameters) {
        params = parameters.map(value => Tools.deleteAcccentSpecialcharacter(value.toLowerCase()));
      }
      const stringifySnomed = JSON.stringify(snomed);
      paramObject.getUrl += stringifySnomed;
      // do not modify entityStoreKey to allow the patient to have access at knowledge even if there are changes in parameters
      // paramObject.entityStoreKey += stringifySnomed;
      this.lastValueOfParam = stringifySnomed;
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);

      const dataReader = this.dataService.readv2<IKnowledges, IKnowledges[]>(paramObject, false, this);
      let d: IKnowledges[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, params);
        yield d;
      }
      return d;
    } catch (err) {
      console.error("KnowledgeDrugService getKnowledgesDrugs()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], params?: string[]) {
    if (!params) { return dataResult; }
    else {
      try {
        const filteredData = dataResult.filter((kk) => {
          const parsedRef = Tools.deleteAcccentSpecialcharacter(kk.reference.toLowerCase());
          return params.includes(parsedRef);
        });
        return filteredData;
      } catch (err) {
        console.error('Error while processing knowledgeDrugService data: ', err);
      }
      return dataResult;
    }
  }

  public async getSamV2Link(drugName: string, source?: string): Promise<string> {
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
      const userLang = this.configService.getCurrentLanguage();
      const infos: IDrugInfo = await this.samService.getDrug(drugName, userLang, source).toPromise();
      if (!infos) { return ''; }
      return infos[userLang] ? infos[userLang].url : infos.fr.url;
    } else {
      return '';
    }
  }
}
