import { Identifier, IEntity, Reference } from "../models/sharedInterfaces";
import { Tools } from "./tools-helper";

/**
 * Knowledge base category
 */
export enum KNOW_CATEGORY {
    MEDICAL = 1, PATHOLOGY = 2, THERAPY = 3, DRUG = 4, DEVICE = 5, OBSERVATION = 6, CONSENT_PATIENT = 7, CONSENT_RELATED = 8
}

/**
 * Knowledge base document category
 */
export enum KNOW_DOC_CATEGORY {
    DESCRIPTION = 1, RECOMMENDATION = 2, SIDEEFFECT = 3, FAQ = 4, CONSENT = 5
}

/**
 * Knowledge base document category
 */
export enum KNOW_DOC_TYPE {
    TEXT = 1, PICTURE = 2, VIDEO = 3
}

/**
 * media category
 */
export enum MEDIA_CATEGORY {
    NONE = 0, POSITIVE = 1, NEGATIVE = 2
}

/**
 * knowledge base criteria
 */
export interface IKnowCriteria {
    gender?: string; // M,F
    ageGroup?: number[];  // 0:all,1:0-10y,2:11-20y,3:21-30y,4:31-40y,5:41-50y,6:51-60y,7:61-70y,8:+70y
    status?: string;
    condition?: string;
    organizations?: Reference[]; // only visible for patient linked to this organization (usually, mutuals insurance)
}

/**
 * Knowledge base media document
 */
export interface IKnowMedia {
    type: KNOW_DOC_TYPE;
    importanceLevel?: number;   // 1,2,3 stars!
    category: MEDIA_CATEGORY;
    label: string;
    language: string; // fr,nl,en
    content: string;  // URL for VIDEO or PICTURE type (ex:https://www.youtube.com/embed/QU8lsnByJhQ), long description for TEXT
    description?: string; // short description (only for TEXT)
    extra?: string; // extra info, external URL for TEXT
    _id?: string;
    identifier?: Identifier;
    specificQuestionnaire?: string[];    // identifier of each quiz
    isModified?: boolean; // /!\ helper only on frontend, never received from server
}

/**
 * Knowledge base Interface structure
 */
export interface IKnowledgeBase extends IEntity {
    author: Reference;
    organization: Reference;    // author organization
    healthcareservice: Reference[];    // (not in FHIR) linked healthcare services
    snomedReference: Reference;
    category: KNOW_CATEGORY;
    documentCategory: KNOW_DOC_CATEGORY;
    criteria?: IKnowCriteria;
    medias: IKnowMedia[];
    publicationDate?: string;
    identifier?: Identifier;
}


/**
 * Knowledges structure exchanged with clients
 */
export interface IKnowledges {
    reference: string;
    knowledges: IKnowledgeBase[];
}

export interface IMediaFromRule {
    publicationDate: string;
    media: IKnowMedia;
}

/**
 * Knowledge base helper
 */
export class KnowledgeBase {

    /**
     * get media (text) knowledge by reference
     */
    public static getKnowledgeMedia(allKnowledges: IKnowledges[], reference: string, lang: string): IKnowMedia[] {
        for (const knowledges of allKnowledges) { // loop on all Knowledges
            for (const knowledgeBase of knowledges.knowledges) {
                if (knowledgeBase.snomedReference.reference === reference) {
                    return KnowledgeBase.getMediaCurrentLang(knowledgeBase, KNOW_DOC_TYPE.TEXT, lang);
                }
            }
        }
        return null;
    }

    /**
     * Get media of this type and current language
     * @param knowledge
     * @param type
     */
    public static getMediaCurrentLang(knowledge: IKnowledgeBase, type: KNOW_DOC_TYPE, language: string): IKnowMedia[] {
        const medias = new Array<IKnowMedia>();
        if (!knowledge || !knowledge.medias || knowledge.medias.length === 0) return medias;

        for (const media of knowledge.medias) {
            if (media === undefined) {
                return medias;
            }
            if ((media.type === type) && (media.language === language)) {
                medias.push(media);
            }
        }
        return medias;
    }

    /**
     * Filter knowledges by category and eventually patient insurance
     * @param allKnowledges
     * @param category
     */

    private static filterKnowledges(allKnowledges: IKnowledges[], category: KNOW_DOC_CATEGORY, patientInsurance: Reference): IKnowledgeBase[] {
        const knowledgeBases = new Array<IKnowledgeBase>();

        if (!allKnowledges || allKnowledges.length <= 0 || allKnowledges[0].knowledges[0] === undefined) {
            return [];
        }
        else if (allKnowledges.length === 1 && allKnowledges[0].knowledges[0].category === KNOW_CATEGORY.DEVICE) {
            return allKnowledges[0].knowledges;
        }
        for (const knowledges of allKnowledges) { // loop on all Knowledges
            for (const knowledgeBase of knowledges.knowledges) {
                // find same organization criteria
                if (knowledgeBase.documentCategory === category) {
                    if (patientInsurance &&
                        knowledgeBase.criteria &&
                        knowledgeBase.criteria.organizations &&
                        knowledgeBase.criteria.organizations.find((o) => o.reference === patientInsurance.reference) !== undefined
                    ) {
                        knowledgeBases.push(knowledgeBase);
                    }
                    // or no organization criteria
                    else if (!knowledgeBase.criteria || !knowledgeBase.criteria.organizations || knowledgeBase.criteria.organizations.length === 0) {
                        knowledgeBases.push(knowledgeBase);
                    }
                }
            }
        }
        return knowledgeBases;
    }

    /**
     * Return category knowledge: ignore high level description if others exists, also filter on patient insurance
     * @param allKnowledges
     */
    public static getMainKnowledge(allKnowledges: IKnowledges[], category: KNOW_DOC_CATEGORY, patientInsurance?: Reference): IKnowledgeBase {
        if (!allKnowledges || allKnowledges.length === 0) return null;
        // filter for only description
        const allKnowledgeBases = KnowledgeBase.filterKnowledges(allKnowledges, category, patientInsurance);
        if (!allKnowledgeBases || allKnowledgeBases.length === 0) return null;
        const multipleKnowledge = (allKnowledgeBases.length > 1);
        // console.info("getMainKnowledges", "all", category, patientInsurance, allKnowledgeBases);
        // at least fill it with first description (and usually the only one)
        let mainKnowledge: IKnowledgeBase = allKnowledgeBases[0];

        for (const knowledge of allKnowledgeBases) {
            // check if there is organization criteria filter
            if (patientInsurance &&
                knowledge.criteria &&
                knowledge.criteria.organizations &&
                knowledge.criteria.organizations.find((o) => o.reference === patientInsurance.reference) !== undefined
            ) {
                // console.info("getMainKnowledges", "organization", knowledge);
                return knowledge;
            }
            // ignore  high level descriptions if others exists
            else if (multipleKnowledge && !["395082007", "4114003", "257556004", "108290001", "169413002", "76334006"].includes(knowledge.snomedReference.reference)) {
                mainKnowledge = knowledge;
            }
        }
        // console.info("getMainKnowledges", "main", mainKnowledge);
        return mainKnowledge;
    }

    /**
     * This method is used to filter the IKnowledges array of knowledge to get a knowledge only once
     * @param knowledges The IKnowledges list
     * @returns An IKnowledges array with only unique knowledges
     */
    public static getKnowledgesOnlyOnce(knowledges: IKnowledges[]): IKnowledges[] {
        const knIdentifiers = [];
        const newKnowledges = Tools.deepCopy(knowledges);
        for (const kn of knowledges) {
            kn.knowledges.forEach((knBase) => {
                if (!knIdentifiers.includes(knBase.identifier.value)) {
                    knIdentifiers.push(knBase.identifier.value);
                } else {
                    const currentKn = newKnowledges.find((elem) => elem.reference === kn.reference);
                    const elemIndex = currentKn.knowledges.findIndex((elem) => elem.identifier.value === knBase.identifier.value);
                    if (elemIndex !== -1) {
                        currentKn.knowledges.splice(elemIndex, 1);
                    }
                }
            });
        }
        return newKnowledges;
    }
}
