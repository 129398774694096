import { Component, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BasePage } from 'src/app/baseClasses/base-page';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-reward-help',
  templateUrl: './reward-help.component.html',
  styleUrls: ['./reward-help.component.scss'],
})
export class RewardHelpComponent extends BasePage {
  @ViewChild('slides') slides: IonSlides;
  public isBeginning = true;
  public isEnd = false;
  private sliderSub: Subscription;
  public mode = '';
  public swipOpts = {
    autoHeight: true,
    speed: 200,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    }
  };

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private modalCtrl: ModalController,
    protected configService: ConfigurationService,
    translateSvc: TranslateService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.mode = this.infoService.getImgPrefixAccordingToCordova();

  }

  ionViewDidEnter() {
    super.ionViewDidEnter();
    this.slides.update();
    this.initSlidesBool(this.slides);
  }

  ionViewWillLeave(): void {
    super.ionViewWillLeave();
    this.sliderSub?.unsubscribe();
  }

  private async initSlidesBool(slides: IonSlides) {
    this.sliderSub = slides?.ionSlideTransitionEnd?.pipe(takeUntil(this.onDestroy$))?.subscribe(async () => {
      this.isEnd = await slides.isEnd();
      this.isBeginning = await slides.isBeginning();
      await slides.updateAutoHeight(200);
    });
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
