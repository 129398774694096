import { IKeyValue } from "../models/keyValue";
import { Quantity, Reference, STATUS_ENTITY } from "../models/sharedInterfaces";
import { ITranslation } from "../models/translation";
import { Tools } from "./tools-helper";
import { InfoAppService } from "../services/info-app.service";

/**
 * Status of a user in Caremate application
 */
export enum USER_STATUS {
    INACTIVE = 0, ACTIVE = 1, NEW = 2
}

/**
 *  Role of a user in Caremate application
 */
export enum USER_ROLE {
    PATIENT = 1, PRACTITIONER = 2, ADMIN = 3, RELATEDPERSON = 4
}

/**
 * Response interface for authentication
 */
export interface IAuthResponse {
    token?: string;
    is2fa?: boolean;
    newPasswordRequired?: boolean;
    account?: IAccount;
}

/**
 * extra credential info (rsw, abrumet, etc.)
 */
export interface ICredentialInfo {
    ctype: string;
    login: string;
    password?: string;
    parameters?: IKeyValue[];
    lastSync?: IKeyValue[];
}

/**
 * User credential
 */
export interface IUserCredential {
    login: string;
    password: string;
}

export interface IConsent {
    identifier: string; // identifier of the knowledge
    publicationDate: string; // date of publication of the knowledge
    consentDate: string; // date of patient approval of consent
    pdf?: string; // for the TPDA, path to the pdf containing the proof of consent
    electronicSignature?: any; // if needed for the tpda
    qrId: string[];
    language: string;
}

/**
 * Almost same as server IUser interface with limited set of fields and some additional informations from linked patients (managingOrganizations, insurance,...)
 */
export interface IAccount {
    isValid?: boolean;  // internal use
    creation: string;
    modified: string;
    entityStatus: STATUS_ENTITY[];
    name: string;
    firstname: string;
    status: USER_STATUS;
    nationalnumber?: string;
    caremateIdentifier: string;
    newPassword?: string;    // only for update request
    mail: string;
    phone: string;
    birthdate: string;
    photo: string;
    role: USER_ROLE[];
    managingOrganizations: Reference[];
    insurance?: Reference;
    mfaActive?: boolean;
    credentials?: ICredentialInfo[];
    vitalProfile: Quantity[];   // vitals and phisiologicals parameters for a patient (weight, heartrate, ...)
    globalReawardScore?: number;
    consent?: IConsent[];
    needConsent: boolean; // tells the mobile application if (new) consent is required
    referenceCountry?: string; // 'BE', 'FR', 'NL', 'DE', 'LU'
}

/**
 * / User Account model
 */
export class Account {

    public static get LOINC_BODYHEIGHT(): string { return "8302-2"; }
    public static get LOINC_WEIGHT(): string { return "3141-9"; }
    public static get LOINC_SYSTOLIC(): string { return "8480-6"; }
    public static get LOINC_DIASTOLIC(): string { return "8462-4"; }
    public static get LOINC_HEARTRATE(): string { return "8867-4"; }
    public static get LOINC_SPO2(): string { return "20564-1"; }
    public static get LOINC_WALK_DISTANCE(): string { return "41953-1"; }
    public static get LOINC_BLOOD_PRESSURE_GENERAL(): string { return "55284-4"; }
    public static get LOINC_GLUCOSE_GENERAL(): string { return "2339-0-G"; }
    public static get LOINC_GLUCOSE(): string { return "2339-0"; }
    public static get LOINC_INSULIN(): string { return "20448-7"; }
    public static get LOINC_ACTIVITY(): string { return "LG41761-4"; }
    public static get LOINC_STEPS(): string { return "41950-7"; }
    public static get LOINC_TEMPERATURE(): string { return "8310-5"; }

    /**
     * Return account full name
     * @param account
     */
    public static getFullName(account: IAccount): string {
        try {
            return account.name + " " + account.firstname;
        } catch (err) {
            return "";
        }
    }

    /**
     * Convert account to a reference
     * @param acc
     */
    public static account2Reference(acc: IAccount): Reference {
        return {
            "reference": acc.caremateIdentifier,
            "display": Account.getFullName(acc)
        };
    }

    /**
     *  add (or update) credential to IAccount
     */
    public static setCredentials(account: IAccount, credential: ICredentialInfo) {
        let found = false;
        if (!account.credentials) account.credentials = [];
        for (let i = 0; i < account.credentials.length; i++) {
            // found, update it
            if (account.credentials[i].ctype === credential.ctype) {
                found = true;
                account.credentials[i] = credential;
                break;
            }
        }
        // not found, add it
        if (!found) {
            account.credentials.push(credential);
        }
    }

    /**
     *
     */
    public static getVital(account: IAccount, code: string): Quantity {
        if (account.vitalProfile) {
            for (const vital of account.vitalProfile) {
                if (vital.code === code) {
                    return vital;
                }
            }
        } else {
            return null;
        }
    }
    /**
     *
     */
    public static setVital(account: IAccount, code: string, value?: number, valueArray?: number[][], unit?: ITranslation, lang?) {  // @TODO : use unit from form directly (remove switch)

        // keep retrocompatibily with the setVital() in observation-modal ; will be remove later (@TODO)
        if (unit) {
            Account._setVital(account, code, InfoAppService.getTranslation(unit, lang, ""), value, valueArray);
        }
        else {
            switch (code) {
                case Account.LOINC_BODYHEIGHT:
                    Account._setVital(account, Account.LOINC_BODYHEIGHT, "cm", value, valueArray);  // body Height
                    break;
                case Account.LOINC_DIASTOLIC:
                    Account._setVital(account, Account.LOINC_DIASTOLIC, "mmHg", value, valueArray);  // diastolic pressure
                    break;
                case Account.LOINC_HEARTRATE:
                    Account._setVital(account, Account.LOINC_HEARTRATE, "bpm", value, valueArray);  // heart rate
                    break;
                case Account.LOINC_SYSTOLIC:
                    Account._setVital(account, Account.LOINC_SYSTOLIC, "mmHg", value, valueArray);  // systolic Pressure
                    break;
                case Account.LOINC_WEIGHT:
                    Account._setVital(account, Account.LOINC_WEIGHT, "kg", value, valueArray);  // body weight
                    break;
                case Account.LOINC_SPO2:
                    Account._setVital(account, Account.LOINC_SPO2, "%", value, valueArray);  // body weight
                    break;
                case Account.LOINC_WALK_DISTANCE:
                    Account._setVital(account, Account.LOINC_WALK_DISTANCE, "m", value, valueArray);  // body weight
                    break;
                default:
                    console.error("Account.setVital", "unknown loinc code", code);
                    break;
            }
        }
    }

    /**
     * Update/insert physiological data for this account
     */
    public static _setVital(account: IAccount, code: string, unit: string, value?: number, valueArray?: number[][]) {

        if (!account.vitalProfile) {
            account.vitalProfile = [];
        }
        for (const vital of account.vitalProfile) {
            if (vital.code === code) {
                vital.value = value ? value : null;
                vital.valueArray = !this.isEmptyValueArray(valueArray) ? valueArray : null;
                vital.unit = unit;
                return;
            }
        }
        // not found, create entry (if not null value)
        if (value || !this.isEmptyValueArray(valueArray)) {
            account.vitalProfile.push({
                "code": code,
                "value": value ? value : null,
                "valueArray": !this.isEmptyValueArray(valueArray) ? valueArray : null,
                "unit": unit
            });
        }
    }

    private static isEmptyValueArray(valueArray: number[][]): boolean {
        if (!valueArray || valueArray.length <= 0) return true;
        let isEmpty = true;
        valueArray.forEach(array => {
            if (array && array.length > 0) {
                if (array.findIndex(v => (v === 0 || !!v)) !== -1) {
                    // there is atleast one value set
                    isEmpty = false;
                }
            }
        });
        return isEmpty;
    }
}

