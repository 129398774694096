import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({ name: "monthName" })
export class MonthNamePipe implements PipeTransform {

    constructor() { }

    public transform(currentMonth: number): string {
        return moment.months(currentMonth);
    }
}
