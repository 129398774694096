
export interface ICreditAttribution {
    title: string;
    author: string;
    source?: string;
    license: string;
}

export class CreditsHelper {
    public static get ALL_CREDITS(): ICreditAttribution[] {
        return [{
            title: "Smileys",
            author: "OpenMoji",
            source: "https://openmoji.org",
            license: "CC BY-SA 4.0"
        }];
    }
}
