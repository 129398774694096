import { Component, ViewChild } from "@angular/core";
import { NavParams, ModalController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AutoCompleteComponent } from "ionic4-auto-complete";
import { InfoAppService } from "src/app/services/info-app.service";
import { PopupService } from "src/app/services/popup.service";
import { CompleteDrugService } from "src/app/services/complete-drug.service";
import { IEntitylink, EntityDrug, PARENT_TYPE, CycleSchema } from "src/app/models/entitylink";
import { IEntity, ACTION_STATUS_ENTITY } from "src/app/models/sharedInterfaces";
import { Tools } from "src/app/helpers/tools-helper";
import { AppConstants } from "src/app/appConstants";
import { DrugQuantitiesAutoCompleteServiceService } from "src/app/services/drug-quantities-auto-complete-service.service";
import { CameraService } from "src/app/services/camera.service";
import { DrugSchemaService } from "src/app/services/globalDataProvider/drug-schema.service";
import { IDrugInfo } from "src/app/models/drugsInfo";
import { SamService } from "src/app/services/sam.service";
import { ConfigurationService } from "src/app/services/globalDataProvider/configuration.service";
import * as moment from "moment";
import { ArrayHelper } from "src/app/helpers/array-helper";
import { AddDrugSchemaModalComponent } from "../add-drug-schema-modal/add-drug-schema-modal.component";
import { BasePage } from "src/app/baseClasses/base-page";
import { DAYS_OF_MONTH, FREQUENCY_OPTIONS, TimingData, TIMING_CODES, TIMING_OPTIONS } from "../../../models/timingData";
@Component({
  selector: "app-drug-modal",
  templateUrl: "./drug-modal.component.html",
  styleUrls: ["./drug-modal.component.scss"],
})
export class DrugModalComponent extends BasePage {
  @ViewChild("searchbar") searchbar: AutoCompleteComponent;
  @ViewChild("searchbarQuantity") searchbarQuantity: AutoCompleteComponent;
  public drug: IEntitylink;
  public isRelated = false;
  public noEndDate = true;
  public freqOption: string = "fixedFrequency";
  public timingOption: string = "moments";
  public timingWhen: string[] = [];
  public dayMonth: number[] = DAYS_OF_MONTH;
  public dayWeekNames = Tools.keyDayNames();
  public activateScheduleHourTo = false;
  public isLocked = true;

  public timingCodes = TIMING_CODES;
  public freqOptions = FREQUENCY_OPTIONS;
  public timingOptions = TIMING_OPTIONS;
  public fixedFreqOption = FREQUENCY_OPTIONS[0].value;
  public fixedDaysOption = FREQUENCY_OPTIONS[1].value;
  public asNecessaryOption = FREQUENCY_OPTIONS[2].value;
  public momentTimingOption = TIMING_OPTIONS[0].value;
  public fixedHoursTimingOption = TIMING_OPTIONS[1].value;

  public entityDrug: EntityDrug;
  public cycleName = "none";
  public initialDBCycles: CycleSchema[] = [];
  public cycles: CycleSchema[] = [];
  public displayedColumns: string[] = [];
  public rowOfDisplayedColumns: any = [];
  public selectedSchema: CycleSchema;
  public eachRowsNumberOfDays: number;
  private atcCode: string;
  public isPaused = false;
  public pauseDate: Date;
  public isCycle: boolean;
  public isCreation: boolean;
  public isNotCreationAndHaveCycle: boolean;
  public fixedDatesCopyForDatetime: string[] = []; // needed because ion-datetime is stupid and does not work correctly in an ngFor

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected translateSvc: TranslateService,
    private params: NavParams,
    private modalCtrl: ModalController,
    public completeService: CompleteDrugService,
    public drugQuantitiesAutoCompleteService: DrugQuantitiesAutoCompleteServiceService,
    private cameraService: CameraService,
    private drugSchemaService: DrugSchemaService,
    private samService: SamService,
    protected configService: ConfigurationService,
    protected alertCtrl: AlertController
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.drug = this.params.get("drug");
    this.isRelated = this.params.get("isRelated");

    this.entityDrug = Tools.deepCopy(this.drug.entityData);
    // prepare timing "when"
    if (this.entityDrug?.frequency?.when?.length) {
      this.timingWhen = JSON.parse(this.entityDrug.frequency?.when).map((w: any) => String(w)); // backwards compatibility
      this.activateScheduleHourTo = this.timingWhen[1]?.length > 0;
    }
    this.initEndDate(this.drug);
    this.initToggle(this.drug);
    this.initQuantities();
    this.initCycle();
    this.freqOption = TimingData.getFreqOption(this.entityDrug);
    this.timingOption = TimingData.getTimingOption(this.entityDrug);
    this.isLocked = IEntity.isLocked(this.drug) || this.isRelated;
    this.isCreation = this.drug.actionStatus === ACTION_STATUS_ENTITY.CREATED;
    this.fixedDatesCopyForDatetime = Tools.deepCopy(this.entityDrug.frequency.event);
    this.isNotCreationAndHaveCycle =
      this.drug.actionStatus !== ACTION_STATUS_ENTITY.CREATED && this.drug.entityData.cycle && this.drug.entityData.cycle?.cycle?.length;
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.eachRowsNumberOfDays = Math.floor(window.innerWidth / 32);
  }

  /**
   *  Ionic Event: enter in view
   */
  ionViewDidEnter() {
    super.ionViewDidEnter();
    //  Bug Fix for ION-SELECT
    this.fixIonSelectDisplay();
    // Google Analytics
    // this.appService.analyticsTrackView(AppConstants.PAGE_DRUG);
  }

  // -------------------------- INIT FUNCTIONS --------------------------------

  async initCycle() {
    if (this.entityDrug?.cycle && this.entityDrug.cycle.cycle?.length) {
      await this.refreshDrugSchemaList();
      this.initNewSchema(this.entityDrug.cycle);
      this.selectedSchema = this.entityDrug.cycle;
      this.isCycle = true;
      this.entityDrug.frequency.periodUnits = "d";
      if (this.drugSchemaService.isCycleInPause(this.selectedSchema)) {
        const lastDate = new Date(this.selectedSchema.pauseDate[this.selectedSchema.pauseDate.length - 1]);
        this.pauseDate = lastDate;
        this.isPaused = true;
      }
    } else {
      this.isCycle = false;
      await this.getAtcCodeOnline();
      await this.refreshDrugSchemaList(this.atcCode);
    }
  }
  /**
   * Initilialise the endDate only for new drug
   * @param entity the new drug
   */
  private initEndDate(entity: IEntitylink) {
    if (entity.actionStatus === 1) {
      this.entityDrug.frequency.boundsPeriod.end = AppConstants.NO_END_DATE;
    }
  }

  /**
   * Initialise the quantities property for olders drugs
   */
  private initQuantities() {
    if (!this.entityDrug.frequency.quantities) {
      this.entityDrug.frequency.quantities = {
        morning: null,
        rise: null,
        noon: null,
        evening: null,
        bedtime: null,
      };
    }
  }

  /**
   * Initilialise the toggle, useful when we edit drugs
   */
  public initToggle(entity: IEntitylink) {
    // we check the value of the toggle only when we edit a drugs. For new drugs toggle always true
    if (entity.actionStatus !== 1) {
      this.noEndDate = !Tools.isValidEndDate(this.entityDrug.frequency.boundsPeriod.end as any);
    }
    return this.entityDrug.frequency.periodUnits === "h" ? false : this.noEndDate;
  }

  /**
   * Bug Fix for ION-SELECT
   * <select> content is empty at first time
   */
  private fixIonSelectDisplay() {
    try {
      // fix periodUnits
      const periodUnitsOri = this.entityDrug.frequency.periodUnits;
      this.entityDrug.frequency.periodUnits = "";
      // fix Performer
      const performerOri = this.entityDrug.performer.reference;
      this.entityDrug.performer.reference = "";

      setTimeout(() => {
        this.entityDrug.frequency.periodUnits = periodUnitsOri;
        this.entityDrug.performer.reference = performerOri;
      }, 0);
    } catch (err) {
      console.warn("fixIonSelectDisplay", err);
    }
  }

  // --------------------------------------------------------------------------

  public save() {
    const entityDrug = this.drug.entityData as EntityDrug;
    if (!this.isLocked) {
      const keyword = this.searchbar.keyword;
      if (entityDrug.name !== this.entityDrug.name) {
        // if the name is modified, update the fields reference (cnk) and source
        entityDrug.source = this.entityDrug.source;
        entityDrug.reference = this.entityDrug.reference;
      }

      entityDrug.name = this.entityDrug.name;
      // for Frequency to be number because it becomes a "string" (bug ?)
      if (this.freqOption === this.fixedFreqOption) {
        this.entityDrug.frequency.frequency = Number(this.entityDrug.frequency.frequency);
      }
      if (!entityDrug.name) {
        entityDrug.name = keyword;
      }
      if (!entityDrug.name) {
        return;
      }
    }
    if (!this.isCycle && this.freqOption === this.fixedFreqOption) {
      // check hour/week/month validity
      if (["m", "w", "h"].indexOf(this.entityDrug.frequency.periodUnits) >= 0) {
        // frequency is not used anymore, replaced by "timingWhen"
        if (!this.timingWhen?.length) {
          this.popupService.showAlert("application.title", "mydrugs.errorTiming");
          return;
        }
      }
      const isHourSchedule = this.entityDrug.frequency.periodUnits === "h";
      // Check Hour scheduling to
      if (this.activateScheduleHourTo && !this.timingWhen[1] && isHourSchedule) {
        this.popupService.showAlert("application.title", "mydrugs.errorTiming");
        return;
      } else if (!this.activateScheduleHourTo && isHourSchedule) {
        this.timingWhen[1] = undefined;
      }
    }
    // If we choose fixed days, we need at least one date
    if (!this.isCycle && this.freqOption === this.fixedDaysOption && this.entityDrug.frequency.event?.length < 1) {
      this.popupService.showAlert("application.title", "mydrugs.errorTiming");
      return;
    }
    // Timing Code is not used for hour scheduling
    if (
      this.entityDrug.notify &&
      (this.isCycle || (this.freqOption !== this.asNecessaryOption && this.entityDrug.frequency.periodUnits !== "h"))
    ) {
      // If we choose fixed hours, we need at least one hour
      if (this.timingOption === this.fixedHoursTimingOption && this.entityDrug.frequency.timeOfDay?.length < 1) {
        this.popupService.showAlert("application.title", "mydrugs.errorTiming");
        return;
      }
      // If we choose moments, we need at least one moment
      if (this.timingOption === this.momentTimingOption && !this.entityDrug.frequency.timingCode) {
        this.popupService.showAlert("application.title", "mydrugs.errorTiming");
        return;
      }
    }

    // prepare bound period (remove hours,minutes, seconds)
    this.entityDrug.frequency.boundsPeriod.start = Tools.momentNoHours(this.entityDrug.frequency.boundsPeriod.start).format();
    this.entityDrug.frequency.boundsPeriod.end = Tools.momentNoHours(this.entityDrug.frequency.boundsPeriod.end).format();
    entityDrug.frequency = this.entityDrug.frequency;
    entityDrug.comment = this.entityDrug.comment;
    entityDrug.notify = this.entityDrug.notify;
    entityDrug.performer = this.entityDrug.performer;

    // prepare timing "when"
    entityDrug.frequency.when = null;
    if (
      this.timingOption === this.momentTimingOption &&
      (this.isCycle || (this.freqOption !== this.asNecessaryOption && this.entityDrug.frequency.periodUnits !== "h"))
    ) {
      switch (entityDrug.frequency.periodUnits) {
        case "d": // nothing to do
          break;
        case "w":
        case "m":
        case "h":
          entityDrug.frequency.when = JSON.stringify(this.timingWhen);
          break;
        case "y": // TODO
          break;
      }
    }

    if (this.isCycle) {
      if (this.selectedSchema !== entityDrug.cycle) {
        entityDrug.cycle = this.selectedSchema;
        entityDrug.cycle.startDate = new Date();
      }
      if (this.isPaused) {
        if (entityDrug.cycle.pauseDate) {
          entityDrug.cycle.pauseDate.push(new Date(this.pauseDate));
        } else {
          entityDrug.cycle.pauseDate = [new Date(this.pauseDate)];
        }
      }
    }
    // lock drug (from Careplan Activity) can not be fully modified
    if (this.drug.parentType === PARENT_TYPE.ACTIVITY || this.drug.parentType === PARENT_TYPE.ACTIVITY_DRUG) IEntity.setLocked(this.drug);
    // set updated
    this.drug.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    if (entityDrug.frequency.event && entityDrug.frequency.event.length > 1) {
      entityDrug.frequency.event.sort();
    }
    if (entityDrug.frequency.timeOfDay && entityDrug.frequency.timeOfDay.length > 1) {
      entityDrug.frequency.timeOfDay.sort();
    }
    this.modalCtrl.dismiss(this.drug);
  }

  // ----------------------- EDITION FUNCTIONS --------------------------------
  /**
   * delete drug (in fact, set status to delete)
   */
  public deleteDrug() {
    // lock drug (from Careplan Activity) can not be deleted
    if (this.drug.parentType === PARENT_TYPE.ACTIVITY || this.drug.parentType === PARENT_TYPE.ACTIVITY_DRUG) return;
    this.drug.actionStatus = ACTION_STATUS_ENTITY.DELETED;
    this.modalCtrl.dismiss(this.drug);
  }

  /**
   *  cancel edition
   */
  public dismiss() {
    this.modalCtrl.dismiss();
  }

  /**
   * React when no end date toggle change
   */
  public onNoEndDate($event: CustomEvent) {
    const checked = $event.detail.checked;
    this.noEndDate = checked;
    this.adaptStartEndDates(checked);
  }

  // ------------------------ PHOTOS FUNCTIONS --------------------------------
  /**
   * For smartphone, take a picture and save in this drug
   */
  public async onPhoto() {
    try {
      const base64Picture = await this.cameraService.createPictureBase64();
      if (base64Picture) {
        (this.drug.entityData as EntityDrug).photo = base64Picture;
      }
    } catch (error) {
      console.error("onPhoto", error);
    }
  }

  /**
   * Erase the drug picture
   */
  public onDeletePhoto() {
    (this.drug.entityData as EntityDrug).photo = null;
  }

  // ------------------------ TIMING FUNCTIONS --------------------------------

  /**
   * Switch quantities status: checked/unchecked
   */
  public onCheckedTiming($event: CustomEvent, timing: string) {
    let drugTimingCode = this.entityDrug.frequency.timingCode;
    let changedQuantity = this.entityDrug.frequency.quantities ? this.entityDrug.frequency.quantities[timing] : null;
    drugTimingCode = !drugTimingCode ? undefined : drugTimingCode;
    if (this.entityDrug.frequency.periodUnits !== "h") {
      if (!drugTimingCode && $event) {
        drugTimingCode = "";
      }
      if ($event?.detail.checked) {
        drugTimingCode += timing;
      } else {
        drugTimingCode = drugTimingCode.replace(timing, "");
        changedQuantity = null;
      }

      this.entityDrug.frequency.timingCode = drugTimingCode;
      if (this.entityDrug.frequency.quantities) {
        this.entityDrug.frequency.quantities[timing] = changedQuantity;
      }
    }
  }

  public onTimingOptionChange($event: CustomEvent) {
    const option = $event.detail.value;
    switch (option) {
      case this.momentTimingOption:
        this.entityDrug.frequency.timeOfDay = [];
        break;
      case this.fixedHoursTimingOption:
        const hours = this.entityDrug.frequency.timeOfDay;
        const start = moment().format("HH:mm");
        this.entityDrug.frequency.timeOfDay = hours && hours.length > 0 ? hours : [start];
        break;
      default:
        this.entityDrug.frequency.timeOfDay = [];
        break;
    }
  }

  public onFreqOptionChange($event: CustomEvent) {
    const option = $event.detail.value;
    switch (option) {
      case this.fixedFreqOption:
        this.entityDrug.frequency.event = [];
        this.entityDrug.frequency.asNecessary = false;
        break;
      case this.fixedDaysOption:
        this.entityDrug.frequency.asNecessary = false;
        let dates = this.entityDrug.frequency.event;
        if (!dates || dates.length < 1) {
          const start = this.entityDrug.frequency.boundsPeriod ? this.entityDrug.frequency.boundsPeriod.start : moment().format();
          dates = [start];
        }
        this.entityDrug.frequency.event = dates;
        this.fixedDatesCopyForDatetime = Tools.deepCopy(dates);
        if (!this.noEndDate) {
          this.adaptStartEndDates();
        } else {
          this.noEndDate = false;
        }
        break;
      case this.asNecessaryOption:
        this.entityDrug.frequency.asNecessary = true;
        this.entityDrug.frequency.event = [];
        this.entityDrug.frequency.when = null;
        this.entityDrug.frequency.timeOfDay = [];
        break;
      default:
        this.entityDrug.frequency.event = [];
        this.entityDrug.frequency.asNecessary = false;
        break;
    }
  }

  public addFixedDate() {
    if (!this.entityDrug.frequency.event) {
      this.entityDrug.frequency.event = [];
      this.fixedDatesCopyForDatetime = [];
    }
    this.entityDrug.frequency.event.push(moment(this.entityDrug.frequency.boundsPeriod.start).format());
    this.fixedDatesCopyForDatetime.push(moment(this.entityDrug.frequency.boundsPeriod.start).format());
  }

  public removeFixedDate() {
    if (this.entityDrug.frequency.event?.length < 2) {
      return;
    }
    const last = this.entityDrug.frequency.event.length - 1;
    this.entityDrug.frequency.event.splice(last, 1);
    this.fixedDatesCopyForDatetime.splice(last, 1);
  }
  public addFixedHour() {
    if (!this.entityDrug.frequency.timeOfDay) {
      this.entityDrug.frequency.timeOfDay = [];
    }
    this.entityDrug.frequency.timeOfDay.push(moment().format("HH:mm"));
  }

  public removeFixedHour() {
    if (this.entityDrug.frequency.timeOfDay?.length < 2) {
      return;
    }
    const last = this.entityDrug.frequency.timeOfDay.length - 1;
    this.entityDrug.frequency.timeOfDay.splice(last, 1);
  }

  /**
   * Check which moment of the day is selected
   */
  public isTimingChecked(timing: string) {
    if (!this.entityDrug.frequency.timingCode) {
      return false;
    }
    return this.entityDrug.frequency.timingCode.includes(timing);
  }

  public clearQuantityInput(timing: string) {
    this.entityDrug.frequency.quantities[timing] = null;
  }

  public fixedDateChanged($event: CustomEvent, i: number) {
    if ($event && i >= 0 && i < this.entityDrug.frequency.event.length) {
      const newDate = $event.detail.value;
      this.entityDrug.frequency.event[i] = newDate;
      this.adaptStartEndDates();
    }
  }

  public adaptStartEndDates(noEndDate?: boolean) {
    if (this.freqOption === this.fixedDaysOption) {
      const dates = Tools.deepCopy(this.entityDrug.frequency.event);
      const nbDates = dates.length;
      if (nbDates > 0) {
        dates.sort();
        this.entityDrug.frequency.boundsPeriod.start = dates[0];
        this.entityDrug.frequency.boundsPeriod.end = dates[nbDates - 1];
      }
    } else {
      noEndDate = noEndDate === undefined ? this.noEndDate : noEndDate;
      if (noEndDate) {
        this.entityDrug.frequency.boundsPeriod.end = AppConstants.NO_END_DATE;
      } else {
        this.entityDrug.frequency.boundsPeriod.end = this.entityDrug.frequency.boundsPeriod.start;
      }
    }
  }

  // ------------------------ CYCLE FUNCTIONS ---------------------------------

  public findCycle(event) {
    const currentCycle = this.cycles.find((c) => c.name === event.detail.value);
    this.selectedSchema = currentCycle;
    if (event.detail.value !== "none") {
      this.isCycle = true;
      this.entityDrug.frequency.periodUnits = "d";
      this.generateDisplayedColumns(this.selectedSchema.cycle.length);
      this.formatDisplayedColumns();
    } else {
      this.isCycle = false;
    }
  }

  private generateDisplayedColumns(length: number) {
    this.displayedColumns = [];
    for (let i = 0; i < length; i++) {
      this.displayedColumns.push(i.toString());
    }
  }

  private formatDisplayedColumns() {
    this.rowOfDisplayedColumns = [];
    let value = this.displayedColumns.length / this.eachRowsNumberOfDays;
    if (value % 1 > 0) {
      value++;
    }
    value = Math.floor(value);
    for (let i = 0; i < value; i++) {
      this.rowOfDisplayedColumns.push([]);
      this.displayedColumns.forEach((index) => {
        if (+index >= this.eachRowsNumberOfDays * i && +index < this.eachRowsNumberOfDays * (i + 1)) {
          this.rowOfDisplayedColumns[i].push(index);
        }
      });
    }
  }

  public addSchema() {
    this.presentModalDrugSchema(this.selectedSchema, this.atcCode, "create").then((result: CycleSchema) => {
      if (result) {
        this.initNewSchema(result);
      }
    });
  }

  public updateSchema() {
    this.presentModalDrugSchema(this.selectedSchema, this.atcCode, "update").then((result: CycleSchema) => {
      if (result) {
        this.initNewSchema(result);
      }
    });
  }

  private presentModalDrugSchema(selectedSchema: CycleSchema, atcCode: string, mode: string) {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: AddDrugSchemaModalComponent,
        componentProps: {
          drugSchema: selectedSchema,
          atcCode: atcCode,
          mode: mode,
        },
      });
      modal.onDidDismiss().then((data) => {
        resolve(data.data as CycleSchema);
      });
      await modal.present();
    });
  }

  private initNewSchema(element: CycleSchema) {
    this.cycles = Tools.deepCopy(this.initialDBCycles);
    if (this.isNameAlreadyInTheList(element.name)) {
      element.name += " (bis)";
    }
    this.cycles.push(element);
    this.selectedSchema = element;
    this.cycleName = element.name;
    this.generateDisplayedColumns(element.cycle.length);
    this.formatDisplayedColumns();
  }

  public async OnPauseCycle() {
    let isCancelled = true;
    await this.alertCtrl
      .create({
        header: this.translateSvc.instant("drugSchema.title"),
        message: this.translateSvc.instant("drugSchema.pause"),
        inputs: [
          {
            type: "radio",
            label: this.translateSvc.instant("drugSchema.today"),
            value: moment().toDate(),
          },
          {
            type: "radio",
            label: this.translateSvc.instant("drugSchema.tomorrow"),
            value: moment().add(1, "day").toDate(),
          },
        ],
        buttons: [
          {
            text: this.translateSvc.instant("application.cancel"),
            handler: () => {
              isCancelled = true;
            },
          },
          {
            text: this.translateSvc.instant("application.validate"),
            handler: (data: any) => {
              if (!data) {
                this.popupService.showToast("error.mandatory", 10000, "bottom");
                return false;
              } else {
                isCancelled = false;
              }
            },
          },
        ],
      })
      .then((res) => {
        res.present();
        res.onDidDismiss().then((d) => {
          if (!isCancelled && d.data && d.data.values) {
            this.pauseDate = new Date(d.data.values);
            this.isPaused = true;
          }
        });
      });
  }

  /*
   * We need to discuss about this functionality, do we let the user to delete a pause ?
    public onResumeCycle() {
      this.popupService.showYesNo("drugSchema.title", 'drugSchema.playConfirm').then(res => {
        if (res) {
          this.isPaused = false;
        }
      })
    }
  */

  private async refreshDrugSchemaList(atcCode?: string) {
    let schemasSpecific: CycleSchema[] = [];
    if (Tools.isDefined(atcCode)) {
      const cycles = await this.drugSchemaService.listSpecific(atcCode);
      schemasSpecific = cycles.map((c) => {
        return {
          name: c.name,
          cycle: c.cycle,
          startDate: null,
        } as CycleSchema;
      });
    } else if (Tools.isDefined(this.atcCode)) {
      this.cycleName = "none";
    }

    const cyclesDataReader = this.drugSchemaService.getDataReader();
    for await (const cycles of cyclesDataReader) {
      const schemasPublic = cycles.map((c) => {
        return {
          name: c.name,
          cycle: c.cycle,
          startDate: null,
        } as CycleSchema;
      });
      this.initialDBCycles = schemasSpecific.concat(schemasPublic).filter(ArrayHelper.onlyCycleSchema);
      this.cycles = Tools.deepCopy(this.initialDBCycles);
    }
  }

  public async onDrugNameChanged(event?: any) {
    this.entityDrug.name = event;
    const drugInfo = this.completeService.find(this.entityDrug?.name);

    this.entityDrug.source = drugInfo?.source;
    this.entityDrug.reference = drugInfo?.cnk;
    let atcCode = drugInfo?.atc;

    if (this.selectedSchema && !this.atcCode && !this.cycles.find((c) => c.name === this.selectedSchema.name)) {
      this.cycles.push(this.selectedSchema);
      this.cycleName = this.selectedSchema.name;
    } else if (this.selectedSchema && this.atcCode !== undefined) {
      this.selectedSchema = null;
      this.cycleName = "none";
      this.atcCode = undefined;
      atcCode = undefined;
    }

    this.atcCode = atcCode;
    await this.refreshDrugSchemaList(this.atcCode);
  }

  private async getAtcCodeOnline() {
    const drugInfo: IDrugInfo = await this.samService
      .getDrug(this.entityDrug.name, this.configService.getCurrentLanguage(), this.entityDrug.source)
      .toPromise();
    this.atcCode = drugInfo?.atc?.code;
  }

  private isNameAlreadyInTheList(name: string): boolean {
    let result = false;
    this.cycles.forEach((cycle) => {
      if (cycle.name === name) result = true;
    });
    return result;
  }
}
