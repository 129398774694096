import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BasePage } from 'src/app/baseClasses/base-page';
import { Appointment, IAppointment } from 'src/app/models/appointment';
import { KeyValue } from 'src/app/models/keyValue';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { SysAccountService } from 'src/app/services/sys-account.service';

@Component({
  selector: 'app-appoitment-summary-modal',
  templateUrl: './appoitment-summary-modal.component.html',
  styleUrls: ['./appoitment-summary-modal.component.scss'],
})
export class AppoitmentSummaryModalComponent extends BasePage {
  public appointments: IAppointment[];
  public accountId: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected navParams: NavParams,
    private sysAccountService: SysAccountService,
    protected modalCtrl: ModalController,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
    this.appointments = this.navParams.get("appointments");
    this.accountId = this.sysAccountService.cachedCaremateId;

  }


  /**
   * Appointment was created by user
   * @param app
   */
  public isPersonalAppointment(app: IAppointment): boolean {
    return Appointment.isPersonalAppointment(app);
  }

  /**
   * check if current user come with related or not
   * @param app
   */
  public isAccompagnied(app: IAppointment): boolean {
    return Appointment.isAccompagnied(app, this.accountId);
  }

  /**
   * Is patient accompanied by a related ?
   * @param app
   */
  public amIaccompanied(app: IAppointment): boolean {
    return Appointment.amIaccompanied(app, this.accountId);
  }

  /**
  * Do User accompanied a patient ?
  * @param app
  */
  public doIaccompanied(app: IAppointment): boolean {
    return this.isAccompagnied(app);
  }

  /**
   * User sees all appointments: close this modal
   */
  public seenIt() {
    const kv = new KeyValue(KeyValue.KV_ACTION_VIEW, this.appointments);
    this.modalCtrl.dismiss(kv);
  }

  /**
   * Edit 1 appointment
   * @param app
   */
  public gotoAppointment(app: IAppointment) {
    const kv = new KeyValue(KeyValue.KV_ACTION_EDIT, app);
    this.modalCtrl.dismiss(kv);
  }

  /**
   *  cancel edition
   */
  public dismiss() {
    this.modalCtrl.dismiss();
  }

}
