import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { IKnowledges, KNOW_DOC_CATEGORY } from 'src/app/helpers/knowledge-helper';
import { IKeyValue } from 'src/app/models/keyValue';
import { NOTIFICATION_TYPE } from 'src/app/models/notification';
import { KnowledgeKeyValueService } from '../knowledge-key-value.service';
import { NotificationsGeneratedService } from '../notificationsService/notifications-generated.service';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';

@Injectable({
  providedIn: 'root'
})
export class KnowledgesRecommendationService extends BasicSyncService<IKnowledges, IKnowledges[]> {

  public lastGenNotif: string = null;

  constructor(
    protected dataService: DataService,
    protected knKeyValue: KnowledgeKeyValueService,
    private accountService: AccountService,
    private notificationsGeneratedService: NotificationsGeneratedService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IKnowledges[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'knwoledges_careplans_recommendation_',
      entityStoreKey: 'list',
      getUrl: '/knowledges/careplans?docCategory=' + KNOW_DOC_CATEGORY.RECOMMENDATION,
      setUrl: null,
      expirationDays: 10,
      encrypted: false
    };
  }

  public async *getDataReader(noNotifs: boolean = false, careplanRef?: string): AsyncGenerator<IKnowledges[], IKnowledges[], IKnowledges[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IKnowledges[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, careplanRef);
        yield d;
      }
      this.dealWithNotif(noNotifs, d);
      return d;
    } catch (err) {
      console.error("KnowledgeRecommanedationService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IKnowledges[], careplanRef?: string) {
    const kn = dataResult;
    if (careplanRef) {
      try {
        const filteredData = kn.filter(know => know.reference === careplanRef);
        return filteredData;
      } catch (err) {
        console.error('Error while processing knowledgeRecommendationService data: ', err);
      }
    }
    return kn;
  }

  private dealWithNotif(noNotifs: boolean, data: IKnowledges[]) {
    if (!noNotifs && this.needGenNotif()) {
      this.generateNotifsKnowledges(data);
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(noNotifs: boolean = false, careplanRef?: string): Promise<IKnowledges[]> {
    const dataReader = this.getDataReader(noNotifs, careplanRef);
    let iterator = await dataReader.next();
    while (!iterator.done) { iterator = await dataReader.next(); }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(noNotifs: boolean = false, careplanRef?: string): Promise<IKnowledges[]> {
    const dataReader = this.getDataReader(noNotifs, careplanRef);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  public async getFreshestKnowledgeAdvicesToKeyValue(onlyThreeStars = false): Promise<IKeyValue[]> {
    return this.knKeyValue.getFreshestKnowledgeToKeyValue(onlyThreeStars, this.getDataReader());
  }

  private needGenNotif(): boolean {
    return this.needNotifGeneration || !this.lastGenNotif ||
      moment(this.lastGenNotif).add(4, "hours").isBefore(moment());
  }

  public async generateNotifsKnowledges(_knowledges: IKnowledges[]): Promise<void> {
    if (_knowledges && _knowledges.length > 0) {
      try {
        await this.notificationsGeneratedService.generatedNotifications(_knowledges, NOTIFICATION_TYPE.ADVICE);
        this.needNotifGeneration = false;
        this.lastGenNotif = moment().format();
      } catch (err) {
        console.error('generateNotifsKnowledges error: ', err);
      }
    } else {
      try {
        const knowledges = await this.getFreshestData(true);
        await this.notificationsGeneratedService.generatedNotifications(knowledges, NOTIFICATION_TYPE.ADVICE);
        this.needNotifGeneration = false;
        this.lastGenNotif = moment().format();
      } catch (err) {
        console.error('generateNotifsKnowledges error: ', err);
      }
    }
  }
}
