interface Waiter {
    gotTicket: (ticket: Ticket) => void;
    reject: (err: Error) => void;
}

interface Ticket {
    shred(): void;
}

export class Mutex  {

    private waitersList: Array<Waiter> = [];
    private currentTicket: Ticket | undefined;
    private isLocked = false;

    constructor() { }

    public async runExclusively<T>(callback: () => Promise<T> | T): Promise<T> {
        const ticket: Ticket = await this.acquireTicket();
        try {
            return await callback();
        } finally {
            ticket.shred();
        }
    }

    private acquireTicket(): Promise<Ticket> {
        const ticketPromise = new Promise<Ticket>((resolve, reject) => {
            const newWaiter = { gotTicket: resolve, reject };
            this.waitersList.push(newWaiter);
        });
        if (!this.isLocked) this.callNextWaiter();
        return ticketPromise;
    }

    private callNextWaiter(): void {
        const nextWaiter = this.waitersList.shift();
        if (!nextWaiter) return;
        let shredded = false;
        this.currentTicket = {
            shred: () => {
                if (shredded) return;
                shredded = true;
                this.isLocked = false;
                this.callNextWaiter();
            }
        };
        this.isLocked = true; // It's going to be locked until the shred function is called
        nextWaiter.gotTicket(this.currentTicket);
    }
}
