import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppConstants } from 'src/app/appConstants';
import { GetParametersPageService } from 'src/app/services/get-parameters-page.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { HelpService } from 'src/app/services/help.service';
import { Account, ICredentialInfo } from 'src/app/helpers/account-helper';
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { ConnectionStatus, NetworkService } from 'src/app/services/network.service';
import { LoaderService } from 'src/app/services/loader.service';
import { last } from 'rxjs/operators';
import { AbrumetService, SYNC_BRUSAFE_TYPE } from 'src/app/services/abrumet.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { BasePage } from 'src/app/baseClasses/base-page';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent extends BasePage {
  // BRUSAFE/ABRUMET
  public bruCredential: ICredentialInfo;
  public originalBrusafeLogin = '';
  public brusafeLogin = '';
  public brusafeShare: boolean;
  public brusafeShareCareplan: boolean;
  public brusafeShareObservation: boolean;
  public brusafeShareQuestionnaire: boolean;
  public brusafeShareDrug: boolean;
  public isAbrumetEnabled = false;
  public helpSeen = true;
  // RSW
  // TODO
  // this is not working since the api isn't up to date
  public rswCredential: ICredentialInfo;
  public rswShare = false;
  public rswGuid: string;
  public rswShareCareplan = false;
  public rswShareObservation = false;
  public rswShareQuestionnaire = false;
  public rswShareDrug = false;
  public isRswEnabled: boolean;
  public needSync = false;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected getParametersPageService: GetParametersPageService,
    protected accountService: AccountService,
    protected networkService: NetworkService,
    protected loaderService: LoaderService,
    private modalController: ModalController,
    private helpService: HelpService,
    private abrumetService: AbrumetService,
    private iab: InAppBrowser,
    translateService: TranslateService,
    configService: ConfigurationService
    // private rswService: RswService
  ) {
    super(translateService, configService, infoService, popupService);

    // get account save on the phone/ webapp.

    // init rsw
    // this.isRswEnabled = this.rswService.isRSWModuleEnabled();
    /*if (account.credentials) {
      for (const credential of account.credentials) {
       if (credential.ctype === AppConstants.CREDENTIAL_RSW) {
          this.rswCredential = credential;
          this.rswGuid = credential.login;
          this.rswShare = credential.login ? true : false;
          for (const param of credential.parameters) {
            if (param.key === AppConstants.PRM_SHARE_RSW_CAREPLAN) {
              this.rswShareCareplan = param.value;
            }
            if (param.key === AppConstants.PRM_SHARE_RSW_OBSERVATION) {
              this.rswShareObservation = param.value;
            }
            if (param.key === AppConstants.PRM_SHARE_RSW_QUESTIONNAIRE) {
              this.rswShareQuestionnaire = param.value;
            }
            if (param.key === AppConstants.PRM_SHARE_RSW_DRUG) {
              this.rswShareDrug = param.value;
            }
          }
        }
      }
    } */// else {
    // if not exist we Initialise credentials

    // this.initialiseRswCredIfEmpty();
    // }
  }



  ionViewWillEnter() {
    super.ionViewWillEnter();
    this.initToggleAndCredentialsBrusafe();
    // initialize _needSync
    this.getNeedSync();
  }

  ionViewDidEnter() {
    super.ionViewDidEnter();
    // Google Analytics
    // TODO
    // this.appService.analyticsTrackView(AppConstants.PAGE_SHARE);
    this.helpService.isHelpPageSeenOn(AppConstants.PAGE_SHARE).then((seen) => {
      this.helpSeen = seen;
    });

  }

  private initToggleAndCredentialsBrusafe() {
    const p1 = this.abrumetService.getBrusafeLogin();
    const p2 = this.abrumetService.getBrusafeCareplan();
    const p3 = this.abrumetService.getBrusafeObservation();
    const p4 = this.abrumetService.getBrusafeQuestionnaire();
    const p5 = this.abrumetService.getBrusafeDrug();
    Promise.all([p1, p2, p3, p4, p5]).then(
      ([bruLogin, bruCareplan, bruObservation, bruQuest, bruDrug]) => {
        // value in the localStorage
        this.originalBrusafeLogin = bruLogin;
        this.brusafeLogin = bruLogin;
        this.brusafeShare = bruLogin ? true : false;
        this.brusafeShareCareplan = bruCareplan ? bruCareplan : false;
        this.brusafeShareObservation = bruObservation
          ? bruObservation
          : false;
        this.brusafeShareQuestionnaire = bruQuest ? bruQuest : false;
        this.brusafeShareDrug = bruDrug ? bruDrug : false;

        // value in the user document
        const credentialsAbrumet = this.abrumetService.getCredentialsAbrumet();
        if (credentialsAbrumet && credentialsAbrumet.parameters) {
          this.bruCredential = credentialsAbrumet;
          this.brusafeLogin = credentialsAbrumet.login;
          this.brusafeShare = credentialsAbrumet.login ? true : false;

          // PRM_SHARE_BRUSAFE_OBSERVATION : update with the value in db
          const paramShareBrusafeObs = credentialsAbrumet.parameters.find((param) => param.key === AppConstants.PRM_SHARE_BRUSAFE_OBSERVATION);
          if (paramShareBrusafeObs && paramShareBrusafeObs.value) {
            this.brusafeShareObservation = paramShareBrusafeObs.value;
            this.abrumetService.setBrusafeObservation(this.brusafeShareObservation);
          }

          // PRM_SHARE_BRUSAFE_CAREPLAN PRM_SHARE_BRUSAFE_QUESTIONNAIRE PRM_SHARE_BRUSAFE_DRUG not yet implemented
        }

        if (!this.bruCredential) {
          this.initialiseBruCredIfEmpty();
        }
      }
    );

  }

  /**
   * Show help toast
   */
  public showHelp() {
    this.helpSeen = true;
    this.helpService.showHelp(AppConstants.PAGE_SHARE, "help.slide14textShort");
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  /**
   * Save button (used only by RSW)
   */
  public async save() {
    // if toggle rswShare is not true or no RSW ID not set nothing to do
    if (!this.rswShare || !this.rswGuid) {
      return this.dismiss();
    }
    // RSW: check if something changed
    let rswModified = false;
    this.rswGuid = this.rswShare ? this.rswGuid.trim() : '';
    if (this.rswGuid !== this.rswCredential.login) rswModified = true;
    for (const param of this.rswCredential.parameters) {
      if (param.key === AppConstants.PRM_SHARE_RSW_CAREPLAN && this.rswShareCareplan !== param.value) {
        rswModified = true;
      }
      if (param.key === AppConstants.PRM_SHARE_RSW_OBSERVATION && this.rswShareObservation !== param.value) {
        rswModified = true;
      }
      if (param.key === AppConstants.PRM_SHARE_RSW_QUESTIONNAIRE && this.rswShareQuestionnaire !== param.value) {
        rswModified = true;
      }
      if (param.key === AppConstants.PRM_SHARE_RSW_DRUG && this.rswShareDrug !== param.value) {
        rswModified = true;
      }
    }
    // Nothing more to do
    if (!rswModified) return this.dismiss();
    // Network must be available!
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      return this.popupService.showAlert('application.title', 'error.nonetwork');
    }
    // Update credential object
    this.rswCredential.login = this.rswGuid;
    for (const param of this.rswCredential.parameters) {
      if (param.key === AppConstants.PRM_SHARE_RSW_CAREPLAN) {
        param.value = this.rswShareCareplan;
      }
      if (param.key === AppConstants.PRM_SHARE_RSW_OBSERVATION) {
        param.value = this.rswShareObservation;
      }
      if (param.key === AppConstants.PRM_SHARE_RSW_QUESTIONNAIRE) {
        param.value = this.rswShareQuestionnaire;
      }
      if (param.key === AppConstants.PRM_SHARE_RSW_DRUG) {
        param.value = this.rswShareDrug;
      }
    }
    console.info("Save credential", this.rswCredential);
    if (!this.rswCredential.login) {
      return this.saveCredentials();
    }
    // Check if guid is valid
    // await this.loaderService.showLoading(true);
    // TODO CMATE-2332
    // this.rswService.checkRswGuild(this.rswGuid).then(
    //   success => {
    //     this.loaderService.showLoading(false);
    //     // invalid GUID
    //     if (!success) {
    //       return this.popupService.showAlert("application.title", "share.rsw.invalidGuid");
    //     }
    //     else {
    //       // valid guid to save
    //       this.saveCredentials();
    //     }
    //   },
    //   err => {
    //     this.loaderService.showLoading(false);
    //     if (ServerResponse.isAuthenticationError(err) || ServerResponse.isAccessDenied(err)) {
    //       this.rswService.handleErrorAndLogout(err);
    //     } else {
    //       console.error("SharePage", "Unreachable server", err);
    //       this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
    //       this.dismiss();
    //     }
    //   }
    // );
  }

  /**
   * Save account credential (locally and remotely) for RSW
   */
  private saveCredentials() {
    // update locally
    const account = this.accountService.cachedAccount;
    Account.setCredentials(account, this.rswCredential);
    this.accountService.setAccount(account).subscribe(
      (acc) => { },
      (err) => {
        console.error("SharePage", "Error server", err);
        this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
        this.dismiss();
      },
      () => {
        // observable has finished
        // update remotely
        this.abrumetService.postExternalCredential(this.rswCredential)
          .then(() => this.dismiss())
          .catch((err) => {
            console.error("SharePage", "Error server", err);
            this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
            this.dismiss();
          });
      }
    );
  }

  /**
   * Send data to Brusafe
   */
  public syncBrusafe(): void {
    this.needSync = false;
    this.abrumetService.postExternalCredential(this.abrumetService.createCredentiels(this.brusafeLogin, this.brusafeShareObservation))
      .then(() => {
        this.abrumetService.syncBrusafe().pipe(last()).subscribe(
          (syncBrusafeType) => {
            switch (syncBrusafeType) {
              case SYNC_BRUSAFE_TYPE.SEND_SUCCESS:
                this.getNeedSync();
                this.popupService.showAlert("application.title", "share.brusafe.send");
                break;
              case SYNC_BRUSAFE_TYPE.TOKEN_EXPIRED_ERROR:
                this.abrumetService.completeRedirectAbrumetUrl().then((url) => {
                  this.popupService.showAlert("Abrumet", "share.tokenExpired").then(() => {
                    // window.open(url, "_system", "location=no");
                    const browser = this.iab.create(url, '_system', 'location=yes');
                    browser.show();
                  });
                });
                break;
              case SYNC_BRUSAFE_TYPE.UNREACHABLE_SERVER_ERROR:
                this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
                break;
              default:
                this.popupService.showAlert("Abrumet", "error.general");
                break;
            }
          }
        );
      })
      .catch((err) => {
        console.error("syncBrusafe", "Error server", err);
        this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
        this.dismiss();
      });
  }

  /**
   * This method is called every time a toggle in brusafe is changed
   */
  public onToggleChange(): void {
    // if we off the main toggle we off all of them
    if (!this.brusafeShare) {
      this.allToggleOff();
    }
    else {
      this.brusafeLogin = this.accountService.cachedAccount.caremateIdentifier;
    }
    this.saveLocalAllToggle().then(() => {
      this.abrumetService.postExternalCredential(this.abrumetService.createCredentiels(this.brusafeLogin, this.brusafeShareObservation))
        .then(() => this.getNeedSync())
        .catch((err) => {
          console.error("SharePage", "Error server", err);
          this.popupService.showAlert("application.title", "synchro.error.unreachableServerError");
          this.dismiss();
        });
    });
  }

  /**
   * save in local db the value of all the toggle brusafe
   */
  public async saveLocalAllToggle(): Promise<void> {
    await Promise.all([
      this.abrumetService.setBrusafeLogin(this.brusafeShare),
      this.abrumetService.setBrusafeDrug(this.brusafeShareDrug),
      this.abrumetService.setBrusafeCareplan(this.brusafeShareCareplan),
      this.abrumetService.setBrusafeQuestionnaire(this.brusafeShareQuestionnaire),
      this.abrumetService.setBrusafeObservation(this.brusafeShareObservation)
    ]);
  }

  /**
   * close all the toggle and put them value to false
   */
  public allToggleOff(): void {
    this.brusafeLogin = '';
    this.brusafeShare = false;
    this.brusafeShareCareplan = false;
    this.brusafeShareObservation = false;
    this.brusafeShareQuestionnaire = false;
    this.brusafeShareDrug = false;
  }

  public initialiseBruCredIfEmpty(): void {
    if (this.bruCredential === undefined) {
      this.bruCredential = this.abrumetService.createCredentiels(
        this.brusafeLogin,
        this.brusafeShareObservation
      );
    }
  }

  public initialiseRswCredIfEmpty(): void {
    if (!this.rswCredential) {
      this.getRswCred();
    }
  }

  public getRswCred(): ICredentialInfo {
    this.rswCredential = {
      ctype: AppConstants.CREDENTIAL_RSW,
      login: this.rswGuid,
      parameters: [
        {
          key: AppConstants.PRM_SHARE_RSW_CAREPLAN,
          value: this.rswShareCareplan
        },
        {
          key: AppConstants.PRM_SHARE_RSW_OBSERVATION,
          value: this.rswShareObservation
        },
        {
          key: AppConstants.PRM_SHARE_RSW_QUESTIONNAIRE,
          value: this.rswShareQuestionnaire
        },
        {
          key: AppConstants.PRM_SHARE_RSW_DRUG,
          value: this.rswShareDrug
        }
      ]
    };
    return this.rswCredential;
  }

  /**
   * change the value of _needSync for disable the toggle or not
   */
  public async getNeedSync(): Promise<void> {
    try {
      // needSync return a integer (-1 = never Sync with abrumet // 0 = no new data // 1+ = nb of new datas)
      const res = await this.abrumetService.needSync();
      this.isAbrumetEnabled = this.abrumetService.isAbrumetModuleEnabled();
      if (res && res !== 0) {
        this.needSync = true;
      } else {
        this.needSync = false;
      }
    } catch (error) {
      console.error("abrumet - getNeedSync", error);
      // abrumet is not available on the server
      this.isAbrumetEnabled = false;
    }

  }
}
