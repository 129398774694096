import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Careplan, ICareplan } from 'src/app/models/careplan';
import { IServiceTeam } from 'src/app/models/healthcareservice';
import { AccountService } from './account.service';
import { CareplanService } from './careplan.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ContactService extends BasicSyncService<IServiceTeam, IServiceTeam[]> {

    public lastGenNotif: string = null;
    private lastValueOfParam = '[]';
    private storageKey = 'ContactLastValue';

    public get entityStoreKey() {
      return super.entityStoreKey + this.lastValueOfParam;
    }

    constructor(
        protected dataService: DataService,
        private careplanService: CareplanService,
        private accountService: AccountService,
        private localStorage: LocalStorageService) {
        super(dataService);
    }

    public getUrl() {
        return super.getUrl() + this.lastValueOfParam;
    }

    protected clearWatch(): void {
        this.data$ = new BehaviorSubject<IServiceTeam[]>([]);
    }

    protected initWatch(): void {
        this.data$.next([]);
    }

    protected setupDataParameters(): void {
        this.defaultDataParameter = {
            entityPrefix: 'serviceteam_',
            entityStoreKey: 'list',
            getUrl: '/serviceteam?identifiers=',
            setUrl: null,
            expirationDays: 10,
            encrypted: false,
        };
    }

    public async init() {
        try {
            super.init();
            this.lastValueOfParam = await this.localStorage.getData(this.storageKey, true);
        } catch (err) {
            this.lastValueOfParam = '[]';
        }
    }

    public clear() {
        super.clear();
        this.lastValueOfParam = '[]';
    }

    public async *getDataReader(careplans: ICareplan[] = null)
        : AsyncGenerator<IServiceTeam[], IServiceTeam[], IServiceTeam[]> {
        try {
            if (this.accountService.isOnlyRelated) {
                yield [];
                return [];
            }
            const lsCareplans = await this.careplanService.getFirstDataAvailable();

            const allCareplanIdentifiers = JSON.stringify(lsCareplans.map((cp) => Careplan.getCaremateIdentifier(cp)));

            if (!allCareplanIdentifiers || allCareplanIdentifiers.length === 0) {
                yield [];
                return [];
            }

            const paramObject = Object.assign({}, this.defaultDataParameter);
            paramObject.getUrl += allCareplanIdentifiers;
            paramObject.entityStoreKey += allCareplanIdentifiers;
            this.lastValueOfParam = allCareplanIdentifiers;
            this.localStorage.setData(this.storageKey, this.lastValueOfParam, true);


            const dataReader = this.dataService.readv2<IServiceTeam, IServiceTeam[]>(paramObject, false, this);
            let d: IServiceTeam[] = [];
            for await (const data of dataReader) {
              d = this.processData(data, careplans);
              yield d;
            }
            return d;
        } catch (err) {
            console.error("NoteService getDataReader()", err);
            yield [];
            return [];
        }
    }

    private processData(dataResult: IServiceTeam[], careplans: ICareplan[] | null) {
        if (!careplans || careplans.length === 0) { return dataResult; }
        try {
            /*
             The problem with Careplan.getCaremateIdentifier is that return the first identifier.value of the careplan but
             the service.healthService.providedBy.reference is not necessarily this one
            */
            const ids = careplans.map((cp) => {
                return cp.identifier.map((id) => id.value);
            }).reduce((acc, it) => [...acc, ...it], []);

            const filteredData = dataResult.filter((service) => {
                return ids.includes(service.healthService.providedBy.reference);
            });
            return filteredData;
        } catch (err) {
            console.error('Error while processing contactService data: ', err);
        }
        return dataResult;
    }

}
