import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstants } from 'src/app/appConstants';
import { Appointment, IAppointment } from 'src/app/models/appointment';
import { Month, AgendaCell } from 'src/app/models/calendar';
import { NOTIFICATION_TYPE } from 'src/app/models/notification';
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { ApiService } from '../api.service';
import { InfoAppService } from '../info-app.service';
import { NotificationsGeneratedService } from '../notificationsService/notifications-generated.service';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';


@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends BasicSyncService<IAppointment, IAppointment[]> {

  public lastGenNotif: string = null;

  public currentMonth = new Month();
  public set setMonth(value: Month) {
    if (value.currentMonth === this.currentMonth.currentMonth) {
      this.setSelectedDay = null;
    }
    this.currentMonth = value;
  }

  public selectedDay: AgendaCell = null;
  public set setSelectedDay(value: AgendaCell) {
    this.selectedDay = value;
  }

  public currentAppointments: BehaviorSubject<IAppointment[]> = new BehaviorSubject<IAppointment[]>([]);

  constructor(
    protected dataService: DataService,
    private apiService: ApiService,
    private notificationsGeneratedService: NotificationsGeneratedService,
    private accountService: AccountService,
    protected infoAppService: InfoAppService,
    private iab: InAppBrowser
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IAppointment[]>([]);
    this.currentAppointments = new BehaviorSubject<IAppointment[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.currentAppointments.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'appointment_',
      entityStoreKey: 'list',
      getUrl: '/appointments',
      setUrl: '/appointment',
      expirationDays: 1,
      encrypted: true,
    };
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(includeDeleted: boolean = true): IAppointment[] {
    return this.processData(super.peekData(), includeDeleted);
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
  public watchData(includeDeleted: boolean = false): Observable<IAppointment[]> {
    return this.data$.pipe(map((appointments) => {
      return this.processData(appointments, includeDeleted);
    }));
  }

  /**
   * Return a data reader that will first read try to read the local data
   * then try to get the online data.
   */
  public async *getDataReader(includeDeleted: boolean = false, forceGenNotif = false)
    : AsyncGenerator<IAppointment[], IAppointment[], IAppointment[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IAppointment[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, includeDeleted);
        yield d;
      }
      this.dealWithNotif(forceGenNotif, d);
      return d;
    } catch (err) {
      console.error("AppointmentService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IAppointment[], includeDeleted: boolean) {
    try {
      let appointments: IAppointment[] = dataResult;
      // the appointments for which I accompagn are in relatedAppointmentService
      appointments = appointments.filter((a) => !Appointment.isAccompagnied(a, this.accountService.cachedCaremateId));
      // Default sort
      appointments = appointments.sort((a, b) => moment(a.start).diff(moment(b.start)));
      if (!includeDeleted) {
        return appointments.filter((appointment) => {
          return appointment.actionStatus !== ACTION_STATUS_ENTITY.DELETED &&
            !appointment.entityStatus.includes(STATUS_ENTITY.DELETED);
        });
      }
      return appointments;
    } catch (err) {
      console.error('Error while processing appointmentService data: ', err);
      return dataResult;
    }
  }

  private dealWithNotif(forceGenNotif: boolean, data: IAppointment[]) {
    if (forceGenNotif || this.needNotifGeneration || !this.lastGenNotif ||
      moment(this.lastGenNotif).add(4, "hours").isBefore(moment())) {

      const notifications = data.filter((appointment) => {
        return appointment.actionStatus !== ACTION_STATUS_ENTITY.DELETED &&
          !appointment.entityStatus.includes(STATUS_ENTITY.DELETED) &&
          !appointment.entityStatus.includes(STATUS_ENTITY.CANCELLED);
      });

      this.notificationsGeneratedService.generatedNotifications(notifications, NOTIFICATION_TYPE.APPOINTMENT)
        .then(() => { this.lastGenNotif = moment().format(); });
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(includeDeleted: boolean = false, forceGenNotif = false): Promise<IAppointment[]> {
    const dataReader = this.getDataReader(includeDeleted, forceGenNotif);
    let iterator = await dataReader.next();
    while (!iterator.done) { iterator = await dataReader.next(); }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(includeDeleted: boolean = false, forceGenNotif = false): Promise<IAppointment[]> {
    const dataReader = this.getDataReader(includeDeleted, forceGenNotif);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  /**
    * Generate public link to Ical agenda for current user's appointments
    */
  public generateIcalUrl() {
    return this.apiService.get('/appointments/ical-generate-url')
      .pipe(
        map(res => {
          if (res && res.success) {
            return res.data;
          }
          // TODO return this.apiService.handleError(new Response("Cannot parse JSON"));
        })
      );
    // TODO .catch(this.apiService.handleError);
  }

  /**
   * @param app
   * @returns the url of the teleconsultation
   */
  public searchAddressJitsiMeet(app: IAppointment): string {
    if (this.isCallVideo(app)) {
      for (const part of app.participant) {
        for (const coding of part.participantType.coding) {
          if (coding.system === AppConstants.MEETJITSI) {
            return coding.code;
          }
        }
      }
    }
    return null;
  }

  public isCallVideo(app: IAppointment): boolean {
    // TODO : afficher seulement si le rdv est aujourd'hui (dans l'heure ?)

    if (!app.participant) {
      return false;
    }
    return app.participant.some((part) => {
      if (!part.participantType || !part.participantType.coding) {
        return false;
      }
      return part.participantType.coding.some((coding) => coding.system === AppConstants.MEETJITSI && coding.code?.length > 0);
    });
  }

  public runCallVideo(app: IAppointment) {
    const address = this.searchAddressJitsiMeet(app);
    if (this.infoAppService.isCordova()) {
      const browser = this.iab.create(address, '_system', 'location=yes');
      browser.show();
    } else {
      window.open(address, "_blank", "location=no");
    }
  }

  public update(appointment: IAppointment, generateNotif: boolean = true): Observable<IAppointment> {
    appointment.modified = moment().format();
    const savePromise = this.dataService.saveInArray<IAppointment>(
      appointment,
      (entity) => entity._id === appointment._id,
      { ...this.defaultDataParameter, method: SYNC_HTTP_METHOD.PUT }
    ).then((app: IAppointment) => {
      const appointments = this.peekData(true);
      const i = appointments.findIndex(e => e._id === app._id);
      if (i >= 0 && !app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        appointments[i] = app;
      } else if (i >= 0 && app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        appointments.splice(i, 1);
      }
      this.pokeData(appointments);
      if (generateNotif) {
        this.notificationsGeneratedService.updateOrCreateNotification(app, NOTIFICATION_TYPE.APPOINTMENT);
      }
      return app;
    });

    return from(savePromise);
  }

  public delete(appointment: IAppointment): Observable<IAppointment> {
    appointment.entityStatus = [STATUS_ENTITY.DELETED];
    return this.update(appointment);
  }

  public create(appointment: IAppointment): Observable<IAppointment> {
    appointment.modified = moment().format();

    const savePromise = this.dataService.saveInArray<IAppointment>(
      appointment,
      (entity) => entity._id === appointment._id,
      { ...this.defaultDataParameter, method: SYNC_HTTP_METHOD.POST }
    ).then((app: IAppointment) => {
      const appointments = this.peekData(true);
      appointments.push(app);
      this.pokeData(appointments);
      this.notificationsGeneratedService.updateOrCreateNotification(app, NOTIFICATION_TYPE.APPOINTMENT);
      return app;
    });
    return from(savePromise);
  }
}
