import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '../models/careplan';
import { FHIR_ActivityHelper } from '../helpers/fhirActivityHelper';

@Pipe({ name: 'activityName' })
export class ActivityNamePipe implements PipeTransform {
  constructor() { }
  transform(activity: Activity): string {
    return FHIR_ActivityHelper.getName(activity);
  }
}
