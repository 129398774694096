import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { FeatureService } from './services/featureService';

@Injectable({
    providedIn: 'root',
})
export class FeatureGuard implements CanActivate {

    constructor(
        private router: Router,
        private featureService: FeatureService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree { // Promise<boolean | UrlTree>
        if (this.featureService.canActivate(next.data.feature)) {
            return true;
        }
        console.warn("no access to this feature");
        return this.goToHomePage();
    }

    private goToHomePage() {
        return this.router.parseUrl("/home");
    }
}
