import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, last, map } from "rxjs/operators";
import { AppConstants } from "../appConstants";
import { IAccount, ICredentialInfo } from "../helpers/account-helper";
import { ServerResponse, SERVER_RESPONSE_TYPE } from "../helpers/server-response-helper";
import { IApiResponse } from "../models/iapi-response";
import { ApiService } from "./api.service";
import { AccountService } from "./globalDataProvider/account.service";
import { InfoAppService } from "./info-app.service";
import { LocalStorageService } from "./storage/local-storage.service";


export enum SYNC_BRUSAFE_TYPE {
  UNREACHABLE_SERVER_ERROR, SEND_SUCCESS, TOKEN_EXPIRED_ERROR, OTHER_ERROR
}

@Injectable({
  providedIn: 'root'
})
export class AbrumetService {
  // ABRUMET = BRUSAFE

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private infoAppService: InfoAppService,
    private accountService: AccountService
  ) { }

  /**
   * Check token's validity
   */
  public tokenValidity() {
    return this.apiService
      .get("/abrumet/token-validity")
      .pipe(
        map(res => {
          return moment(res as unknown);
        })
      );
  }

  /**
   * Return -1 if never sync / return 0 if no new data to share / return the nb of new data to share
   */
  public async needSync(): Promise<number | null> {
    const res = await this.apiService.getWithPromise("/abrumet/need-synchronization");
    if (Number.isFinite(res)) {
      return Number(res);
    }
    const repType = ServerResponse.type(res);
    if ([SERVER_RESPONSE_TYPE.SERVER_UNREACHABLE, SERVER_RESPONSE_TYPE.OTHER_ERROR].includes(repType)) {
      // abrumet is not available on the server
      throw new Error("SERVER_UNREACHABLE");
    }
    return null;
  }

  /**
   * 
   * @returns The credentiels of Abrumet save in the document user
   */
  public getCredentialsAbrumet(): ICredentialInfo | undefined {
    const account = this.accountService.cachedAccount;
    if (account.credentials && account.credentials.length) {
      return account.credentials.find((cr) => cr.ctype === AppConstants.CREDENTIAL_ABRUMET);
    }
    else {
      return undefined;
    }
  }

  public createCredentiels(
    brusageShareLogin: string,
    // brusafeShareCareplan: boolean, 
    brusafeShareObservation: boolean,
    // brusafeShareQuestionnaire: boolean, 
    // brusafeShareDrug: boolean
  ): ICredentialInfo {
    return {
      ctype: AppConstants.CREDENTIAL_ABRUMET,
      login: brusageShareLogin,
      parameters: [
        {
          key: AppConstants.PRM_SHARE_BRUSAFE_CAREPLAN,
          value: false // brusafeShareCareplan
        },
        {
          key: AppConstants.PRM_SHARE_BRUSAFE_OBSERVATION,
          value: brusafeShareObservation
        },
        {
          key: AppConstants.PRM_SHARE_BRUSAFE_QUESTIONNAIRE,
          value: false // brusafeShareQuestionnaire
        },
        {
          key: AppConstants.PRM_SHARE_BRUSAFE_DRUG,
          value: false // brusafeShareDrug
        }
      ]
    };
  }

  /**
   * The number of documents to be synchronised, provided that the last synchronisation took place more than 24 hours ago, or never took place.
   * Returns null if synchronisation is not required 
   * @returns 
   */
  public async needSyncSinceLastDay(): Promise<number | null> {
    const date = await this.getAbrumetPopupDate();
    let needSynchro = false;
    if (!date || date.toString() === 'Invalid Date') {
      needSynchro = true;
    }
    else { // we check if today minus save date is >= than 24 hours      
      const timeStampOneDay = 24 * 60 * 60 * 1000;
      if (new Date().getTime() - date.getTime() >= timeStampOneDay) {
        needSynchro = true;
      }
    }

    if (needSynchro) {
      const rep = await this.needSync().catch(() => null);
      return rep;
    }
    return null;
  }

  public postSync(account: IAccount) {
    return this.apiService.post("/abrumet/synchronization", account);
  }

  /**
   * Check if abrumet module is enabled
   */
  public isAbrumetModuleEnabled(): boolean {
    return AppConstants.BRUSAFE_MODULE;
  }

  /**
   * Get PRM_SHARE_BRUSAFE_LOGIN from localStorage
   */
  public async getBrusafeLogin(): Promise<string> {
    try {
      const brusafeLogin = await this.localStorageService.getData(AppConstants.PRM_SHARE_BRUSAFE_LOGIN, false);
      return JSON.parse(brusafeLogin);
    } catch (error) {
      return '';
    }
  }

  /**
   * Set localStorage for PRM_SHARE_BRUSAFE_LOGIN
   */
  public setBrusafeLogin(brusafeLogin: any): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_SHARE_BRUSAFE_LOGIN, JSON.stringify(brusafeLogin), false);
  }

  /**
   * Get PRM_SHARE_BRUSAFE_CAREPLAN from localStorage
   */
  public async getBrusafeCareplan(): Promise<boolean> {
    try {
      const brusafeCareplan = await this.localStorageService.getData(AppConstants.PRM_SHARE_BRUSAFE_CAREPLAN, false);
      return JSON.parse(brusafeCareplan);
    } catch (error) {
      return false;
    }
  }

  /**
   * Set localStorage for PRM_SHARE_BRUSAFE_CAREPLAN
   */
  public setBrusafeCareplan(brusafeCareplan: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_SHARE_BRUSAFE_CAREPLAN, JSON.stringify(brusafeCareplan), false);
  }

  /**
   * Get PRM_SHARE_BRUSAFE_OBSERVATION from localStorage
   */
  public async getBrusafeObservation(): Promise<boolean> {
    try {
      const brusafeObservation = await this.localStorageService.getData(AppConstants.PRM_SHARE_BRUSAFE_OBSERVATION, false);
      return JSON.parse(brusafeObservation);
    } catch (error) {
      return false;
    }
  }

  /**
   * Set localStorage for PRM_SHARE_BRUSAFE_OBSERVATION
   */
  public setBrusafeObservation(brusafeObservation: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_SHARE_BRUSAFE_OBSERVATION, JSON.stringify(brusafeObservation), false);
  }

  /**
   * Get PRM_SHARE_BRUSAFE_QUESTIONNAIRE from localStorage
   */
  public async getBrusafeQuestionnaire(): Promise<boolean> {
    try {
      const brusafeQuestionnaire = await this.localStorageService.getData(AppConstants.PRM_SHARE_BRUSAFE_QUESTIONNAIRE, false);
      return JSON.parse(brusafeQuestionnaire);
    } catch (error) {
      return false;
    }
  }

  /**
   * Set localStorage for PRM_SHARE_BRUSAFE_QUESTIONNAIRE
   */
  public setBrusafeQuestionnaire(brusafeQuestionnaire: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_SHARE_BRUSAFE_QUESTIONNAIRE, JSON.stringify(brusafeQuestionnaire), false);
  }

  /**
   * Get PRM_ABRUMET_POPUP_DATE from localStorage
   */
  public async getAbrumetPopupDate(): Promise<Date | null> {
    try {
      const date = await this.localStorageService.getData(AppConstants.PRM_ABRUMET_POPUP_DATE, false);
      return new Date(date);
    } catch (error) {
      return null;
    }
  }

  /**
   * Set localStorage for PRM_ABRUMET_POPUP_DATE
   */
  public setAbrumetPopupDate(date: Date): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_ABRUMET_POPUP_DATE, date.toLocaleDateString(), false);
  }

  /**
   * Get PRM_SHARE_BRUSAFE_DRUG from localStorage
   */
  public async getBrusafeDrug(): Promise<boolean> {
    try {
      const brusafeDrug = await this.localStorageService.getData(AppConstants.PRM_SHARE_BRUSAFE_DRUG, false);
      return JSON.parse(brusafeDrug);
    } catch (error) {
      return false;
    }
  }

  /**
   * Set localStorage for PRM_SHARE_BRUSAFE_DRUG
   */
  public setBrusafeDrug(brusafeDrug: boolean): Promise<any> {
    return this.localStorageService.setData(AppConstants.PRM_SHARE_BRUSAFE_DRUG, JSON.stringify(brusafeDrug), false);
  }

  /**
   * Return url depend of mode
   */
  private async redirectAbrumetUrl(): Promise<string> {
    const mode = await this.infoAppService.getCurrentMode();
    if (mode === "DEV") {
      return "https://auth.qa.brusafe.be/auth/realms/abrumet/protocol/openid-connect/auth?response_type=token&client_id=Comunicare&redirect_uri=";
    } else {
      return "https://auth.brusafe.be/auth/realms/abrumet/protocol/openid-connect/auth?response_type=token&client_id=Comunicare&redirect_uri=";
    }
  }

  /**
   * Send modified notifications to server (create, update, delete)
   */
  public async postExternalCredential(credential: ICredentialInfo): Promise<IApiResponse> {
    const res = await this.apiService.postWithPromise("/externalregistration",
      { "external": credential.login, "ctype": credential.ctype, "password": credential.password, "parameters": credential.parameters });
    const type = ServerResponse.type(res);
    switch (type) {
      case SERVER_RESPONSE_TYPE.SUCCESS:
        // the backend has modified the user document ; we must take the new version
        const dataReader = this.accountService.getDataReader();
        let data = null;
        for await (const d of dataReader) {
          data = d;
        }
        return data;
      default:
        console.error("postExternalCredential in abrumetService");
        return Promise.reject(ServerResponse.SERVER_ERROR);
    }
  }

  /**
   * complete synchronization flow of Brusafe
   */
  public syncBrusafe(): Observable<SYNC_BRUSAFE_TYPE> {
    return this.tokenValidity().pipe(
      last(),
      concatMap(validity => {
        if (validity >= moment()) {
          return this.postSync(this.accountService.cachedAccount).pipe(
            last(),
            map(() => {
              return SYNC_BRUSAFE_TYPE.SEND_SUCCESS;
            }),
            catchError((err) => {
              console.error(err);
              return of(SYNC_BRUSAFE_TYPE.UNREACHABLE_SERVER_ERROR);
            })
          );
        } else {
          return of(SYNC_BRUSAFE_TYPE.TOKEN_EXPIRED_ERROR);
        }
      }),
      catchError(error => {
        console.error("syncBrusafe - error", error);
        return of(SYNC_BRUSAFE_TYPE.OTHER_ERROR);
      })
    );
  }

  public async completeRedirectAbrumetUrl(): Promise<string> {
    const callback =
      "https://www.comunicare.be/abrumet/callback";
    const abrumet_url = await this.redirectAbrumetUrl();
    const url =
      abrumet_url +
      callback +
      "&nonce=0&kc_locale=fr";
    return url;
  }

}
