import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5';
import { AppConstants } from '../appConstants';
import { SysAccount } from '../models/sysaccount';
import { LocalStorageService } from './storage/local-storage.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SysAccountService {
  
  private sysAccountCached: SysAccount;
  private sysKey: string = AppConstants.SYS_ACCOUNT;

  constructor(
    private cookies: CookieService,
    private localStorageService: LocalStorageService,
    protected platform: Platform
  ) { }

  public async getSysAccount(): Promise<SysAccount> {
    try {
      if (this.useLocalStorage) {
        const data = await this.localStorageService.getData(this.sysKey, true);
        this.sysAccountCached = (JSON.parse(data) as SysAccount);
        LocalStorageService.hashCaremateIdentifier = this.sysAccountCached.hashName;  
        return this.sysAccountCached;
      } else {
        const sa = JSON.parse(this.cookies.get(this.sysKey)) as SysAccount;
        if (sa) {
          this.sysAccountCached = sa;
          LocalStorageService.hashCaremateIdentifier = this.sysAccountCached.hashName;
        }
        return this.sysAccountCached;
      }
    } catch (err) {
      console.warn("SysAccountService getSysAccount()", err);
      return Promise.reject(err);
    }
  }

  public get cachedSysAccount(): SysAccount {
    return this.sysAccountCached ? this.sysAccountCached : null;
  }

  public setSysAccount(data: SysAccount):  Promise<any> {
    data.hashName = Md5.hashStr(data.name).toString();
    this.sysAccountCached = data;
    LocalStorageService.hashCaremateIdentifier = this.sysAccountCached.hashName;   
    if (this.useLocalStorage) {
      return this.localStorageService.setData(this.sysKey, JSON.stringify(data), true);
    } else {
      this.cookies.set(this.sysKey, JSON.stringify(data));
      return Promise.resolve();
    }
  }

  public async setRefreshToken(refreshToken: string): Promise<void> {
    if (this.sysAccountCached) {
      this.sysAccountCached.token = refreshToken;
      await this.setSysAccount(this.sysAccountCached);
    }
    return null;
  }

  public get cachedCaremateId(): string {
    return this.sysAccountCached ? this.sysAccountCached.name : null;
  }

  public async getCaremateId(): Promise<string> {
    const sysAccount = await this.getSysAccount();
    return sysAccount.name;
  }

  public deleteCachedSysAccount(): void {
    this.sysAccountCached = null;
    if (!this.useLocalStorage) {
      this.cookies.delete(this.sysKey);
    }
  }

  /**
   * update last synchro for a current active System Account
   */
  public async updateLastSynchro(): Promise<any> {
    const sysAccount = await this.getSysAccount();
    if (sysAccount) {
      sysAccount.lastSynchro = new Date().getTime();
      return this.setSysAccount(sysAccount);
    }
    else {
      return Promise.reject("no active account found");
    }
  }

  public get useLocalStorage(): boolean {
    return this.platform ? this.platform.is("cordova") : false;
  }
}
