import { Component, DoCheck, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { VitalSignControlService } from '../../../../services/formServices/vital-sign-control.service';
import { IVitalProfileDefinition } from 'src/app/models/sharedInterfaces';
import { Subscription } from 'rxjs';
import { ArrayHelper } from 'src/app/helpers/array-helper';
import { BaseComponent } from 'src/app/baseClasses/base-component';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-vital-sign-form',
  templateUrl: './vital-sign-form.component.html',
  providers: [VitalSignControlService]
})
export class VitalSignFormComponent extends BaseComponent implements DoCheck {

  @Input() vitalSigns: IVitalProfileDefinition[] = [];
  @Output() formUpdateEvent = new EventEmitter<any>();

  public form: FormGroup;
  protected formSubscription: Subscription;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private vitalSignControlService: VitalSignControlService
  ) {
    super(infoService, popupService);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.vitalSigns) { this.initForm(); }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formSubscription?.unsubscribe();
  }

  private initForm() {
    this.formSubscription?.unsubscribe();
    this.form = this.vitalSignControlService.toFormGroup(this.vitalSigns);

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      const values = [];
      this.vitalSigns.forEach(vitalSign => {

        switch (vitalSign.type) {
          case "number":
            values.push({ 'code': vitalSign.code, 'value': +this.form.get(vitalSign.code).value, 'unit': vitalSign.unit });
            break;

          case "array":
            const valueArray: number[][] = [];
            vitalSign.columns.forEach((col, index) => {
              valueArray.push([]);
              for (const [key, value] of Object.entries(this.form.get(vitalSign.code).value)) {
                if (key.split("-")[0] === col.coding.code) { // the key is stuctured like this : code+"-"+index; ex: M-0, IC-1, etc.
                  valueArray[index].push(!value && value !== 0 ? null : +value); // warning : index is the index of column !! Transpose below
                }
              }
            });
            values.push({ 'code': vitalSign.code, 'valueArray': ArrayHelper.transpose(valueArray), 'unit': vitalSign.unit });
            break;
        }
      });
      this.formUpdateEvent.emit(values);
    });
  }

  // Add new hook that change Validator here dynamically for verfifications
  ngDoCheck() {
    this.vitalSigns?.forEach((vs) => {
      if (vs) {
        if (vs.type === "array") {
          this.form.controls[vs.code].setValidators(this.checkArrayValidator(vs.code));
        } else {
          this.form.controls[vs.code].setValidators([Validators.min(vs.min), Validators.max(vs.max)]);
        }
      }
    });
  }

  public checkArrayValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const v: number = control.value;
      const isEmptyObject = Object.keys(v);
      // check for null and undefined values for accept 0
      const isEmptyArray = Object.values(v).every((v) => v === null || undefined);
      const currentInput = this.form.controls[controlName];
      // We check each values here (if the values are numbers and zero or positives)
      const isInvalidArray = Object.values(v).some((value) => typeof value !== 'number' || value < 0);

      if (!isEmptyObject.length) {
        return { checkArrayValidator: true, typeError: 'ERROR ON OBJECT - EMPTY' };
      }

      if (isEmptyArray) {
        if (currentInput.dirty) {
          return null;
        }
      }

      if (isInvalidArray) {
        if (currentInput.dirty) {
          return { checkArrayValidator: true, typeError: 'VALUES ARE NOT ALL COMPLETED' };
        }
      }

      return null;
    };
  }

}
