import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NeedConsentService {

    private needConsent: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public onNeedConsentChange(): Observable<boolean> {
        return this.needConsent.asObservable();
    }

    public getNeedConsentStatus(): boolean {
        return this.needConsent.getValue();
    }

    public async updateNeedConsentStatus(needConsent: boolean) {
        this.needConsent.next(needConsent);
    }

}
