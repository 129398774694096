import { NgModule } from "@angular/core";
import { IsAnswerNullPipe } from "../components/observation/observation.component";
import { ActivatedFeaturesPipe } from "../pipes/activated-features";
import { ActivityNamePipe } from "../pipes/activity-name.pipe";
import { ProductDescriptionPipe } from "../pipes/product-description.pipe";
import { CustomTranslatePipe } from "../pipes/custom-translate.pipe";
import { GetComSenderPipe } from "../pipes/getComSender.pipe";
import { GetTranslationPipe } from "../pipes/getTranslation.pipe";
import { MapGetPipe, MapHasPipe } from "../pipes/keyValueMap.pipe";
import { GetFirstImagePipe } from "../pipes/knowledge-media.pipe";
import { LogPipe } from "../pipes/log.pipe";
import { GetRewardScorePercentPipe, GetRewardScoreValuePipe, GetTargetNamePipe, GetTargetValuePipe, RewardScorePipe } from "../pipes/reward-score.pipe";
import { TranslateObservationPipe } from "../pipes/translate-observation.pipe";
import { TruncateFileNamePipe } from "../pipes/truncate-file-name.pipe";
import { ActivityColorPipe } from "../pipes/activity-color.pipe";
import { GetScheduledFrequencyDescriptionPipe } from "../pipes/scheduled-frequency-description.pipe";
import { IsValidDatePipe } from "../pipes/is-valid-date.pipe";
import { GetQuantityPipe, HasQuantityPipe } from "../pipes/drug-quantity.pipe";
import { TranslateDatePipe } from "../pipes/translate-date.pipe";
import { SafePipe } from "../pipes/safe.pipe";
import { IsYoutubePipe } from "../pipes/is-youtube.pipe";
import { GetLabelFromCodePipe, GetLabelRangeFromCompDefListPipe, GetLabelRangeFromCompDefPipe, GetLabelRangeFromValuePipe, GetObsComponentPipe, GetRangePipe, IsHiddenPipe } from "../pipes/observation-component.pipe";
import { GetObsDefPipe, GetObservationsPipe } from "../components/observations-list/observations-list.component";
import { IncludesPipe } from "../pipes/includes.pipe";
import { GetHardwareByCategoryPipe, IsActiveHardwareBondedPipe } from "../pipes/bluetooth-devices.pipe";
import { GetRangeDisplayPipe, GetValueSetChoicesPipe, GetValueTxtPipe, QuestGetValueSetChoicesPipe } from "../pipes/questionnaire.pipe";
import { RangeSmileyPipe } from "../pipes/range-smiley.pipe";
import { IsNewMsgPipe } from "../mymessages/mymessages.page";
import { MonthNamePipe } from "../pipes/month-name.pipe";
import { GetRewardHistoryStringPipe } from "../pipes/reward-history.pipe";
import { GetRewardDefDescriptionPipe, GetRewardDefNamePipe } from "../pipes/reward-definition.pipe";
import { HasBeenRemindedPipe, LoincAndValueMeaningPipe, TranslateAlertNamePipe } from "../myobservations/tabs/alerts/alerts.page";

@NgModule({
    declarations: [
        CustomTranslatePipe,
        IsAnswerNullPipe,
        GetTranslationPipe,
        TranslateObservationPipe,
        ActivatedFeaturesPipe,
        MapHasPipe,
        MapGetPipe,
        LogPipe,
        GetComSenderPipe,
        GetFirstImagePipe,
        TruncateFileNamePipe,
        ActivityNamePipe,
        ProductDescriptionPipe,
        ActivityColorPipe,
        RewardScorePipe,
        GetTargetValuePipe,
        GetTargetNamePipe,
        GetScheduledFrequencyDescriptionPipe,
        IsValidDatePipe,
        HasQuantityPipe,
        GetQuantityPipe,
        TranslateDatePipe,
        SafePipe,
        IsYoutubePipe,
        GetObsComponentPipe,
        GetLabelFromCodePipe,
        IsHiddenPipe,
        GetLabelRangeFromCompDefPipe,
        RangeSmileyPipe,
        GetLabelRangeFromValuePipe,
        GetRangePipe,
        GetLabelRangeFromCompDefListPipe,
        GetObsDefPipe,
        GetObservationsPipe,
        IncludesPipe,
        GetValueSetChoicesPipe,
        GetValueTxtPipe,
        GetRangeDisplayPipe,
        QuestGetValueSetChoicesPipe,
        GetHardwareByCategoryPipe,
        IsActiveHardwareBondedPipe,
        IsNewMsgPipe,
        MonthNamePipe,
        GetRewardHistoryStringPipe,
        GetRewardDefNamePipe,
        GetRewardDefDescriptionPipe,
        GetRewardScorePercentPipe,
        GetRewardScoreValuePipe,
        TranslateAlertNamePipe,
        LoincAndValueMeaningPipe,
        HasBeenRemindedPipe
    ],
    exports: [
        CustomTranslatePipe,
        IsAnswerNullPipe,
        GetTranslationPipe,
        TranslateObservationPipe,
        ActivatedFeaturesPipe,
        MapHasPipe,
        MapGetPipe,
        LogPipe,
        GetComSenderPipe,
        GetFirstImagePipe,
        TruncateFileNamePipe,
        ActivityNamePipe,
        ProductDescriptionPipe,
        ActivityColorPipe,
        RewardScorePipe,
        GetTargetValuePipe,
        GetTargetNamePipe,
        GetScheduledFrequencyDescriptionPipe,
        IsValidDatePipe,
        HasQuantityPipe,
        GetQuantityPipe,
        TranslateDatePipe,
        SafePipe,
        IsYoutubePipe,
        GetObsComponentPipe,
        GetLabelFromCodePipe,
        IsHiddenPipe,
        GetLabelRangeFromCompDefPipe,
        RangeSmileyPipe,
        GetLabelRangeFromValuePipe,
        GetRangePipe,
        GetLabelRangeFromCompDefListPipe,
        GetObsDefPipe,
        GetObservationsPipe,
        IncludesPipe,
        GetValueSetChoicesPipe,
        GetValueTxtPipe,
        GetRangeDisplayPipe,
        QuestGetValueSetChoicesPipe,
        GetHardwareByCategoryPipe,
        IsActiveHardwareBondedPipe,
        IsNewMsgPipe,
        MonthNamePipe,
        GetRewardHistoryStringPipe,
        GetRewardDefNamePipe,
        GetRewardDefDescriptionPipe,
        GetRewardScorePercentPipe,
        GetRewardScoreValuePipe,
        TranslateAlertNamePipe,
        LoincAndValueMeaningPipe,
        HasBeenRemindedPipe
    ]
})
export class PipesModule { }
