import { Injectable } from '@angular/core';
import { ConnectionStatus, NetworkService } from '../../network.service';
import { SysAccountService } from '../../sys-account.service';
import { AccountService } from '../account.service';
import { AppointmentService } from '../appointment.service';
import { BluetoothKnowledgeService } from '../bluetooth-knowledge.service';
import { CareplanService } from '../careplan.service';
import { CommunicationService } from '../communication.service';
import { ConfigurationService } from '../configuration.service';
import { ContactService } from '../contact.service';
import { DrugService } from '../drug.service';
import { ExternalRessourceService } from '../external-ressource.service';
import { KnowledgeDrugService } from '../knowledge-drug.service';
import { KnowledgeService } from '../knowledge.service';
import { NoteService } from '../note.service';
import { NotificationsDrugsIntakeService } from '../notifications-drugs-intake.service';
import { ObservationDefinitionService } from '../observation-definition.service';
import { ObservationService } from '../observation.service';
import { QuestionnaireDefinitionService } from '../questionnaire-definition.service';
import { QuestionnaireService } from '../questionnaire.service';
import { RelatedAppointmentsService } from '../related-appointments.service';
import { RelatedCareplansService } from '../related-careplans.service';
import { RelatedDrugsService } from '../related-drugs.service';
import { RelatedKnowledgeService } from '../related-knowledge.service';
import { RelatedPatientsService } from '../related-patients.service';
import { RelatedPersonsService } from '../related-persons.service';
import { RewardDefinitionsService } from '../reward-definitions.service';
import { RewardScoreService } from '../reward-score.service';
import { RulesAlertService } from '../rules-alert.service';
import { RulesService } from '../rules.service';
import { RequiredSynchroService } from './required-synchro.service';
import { BasicSyncService } from './basic-sync.service';
import { DrugSchemaService } from '../drug-schema.service';
import { VitalProfileDefinitionsService } from '../vital-profile-definitions.service';
import { KnowledgesRecommendationService } from '../knowledges-recommendation.service';
import { KnowledgesDescriptionService } from '../knowledges-description.service';
import { LanguagesService } from '../languagesService';
import { QuizDefinitionService } from '../quiz-definition.service';
import { QuizResponseService } from '../quiz-response.service';
import { KnowledgesFAQService } from '../knowledges-faq.service';
import { KnowledgesObservationService } from '../knowledges-observation.service';

export enum ApiSyncServiceStatus {
  alreadyInProgress, success, error
}

@Injectable({
  providedIn: 'root'
})
export class ApiSyncService {

  private isSynchronizing = false;
  private servicesWave1: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave2: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave3: BasicSyncService<any, any[] | any>[] = [];
  private servicesWave4: BasicSyncService<any, any[] | any>[] = [];
  private dataPatient: BasicSyncService<any, any[] | any>[] = [];

  constructor(
    private networkService: NetworkService,
    private sysAccountService: SysAccountService,
    private requiredSynchroService: RequiredSynchroService,
    // below, the services to synchronize
    externalRessourceService: ExternalRessourceService,
    communicationService: CommunicationService,
    noteService: NoteService,
    configurationService: ConfigurationService,
    accountService: AccountService,
    careplanService: CareplanService,
    knowledgeService: KnowledgeService,
    drugService: DrugService,
    obsDefService: ObservationDefinitionService,
    observationService: ObservationService,
    questionnaireService: QuestionnaireService,
    questionnaireDefService: QuestionnaireDefinitionService,
    rulesService: RulesService,
    rulesAlertService: RulesAlertService,
    notificationsDrugsIntakeService: NotificationsDrugsIntakeService,
    appointmentService: AppointmentService,
    relatedAppointmentsService: RelatedAppointmentsService,
    relatedCareplansService: RelatedCareplansService,
    relatedDrugsService: RelatedDrugsService,
    relatedPatientsService: RelatedPatientsService,
    relatedPersonsService: RelatedPersonsService,
    relatedKnService: RelatedKnowledgeService,
    contactService: ContactService,
    bluetoothKnowledgeService: BluetoothKnowledgeService,
    knowledgesDrugService: KnowledgeDrugService,
    rewardDefinitionService: RewardDefinitionsService,
    rewardScoreService: RewardScoreService,
    drugSchemaService: DrugSchemaService,
    vitalProfileDefinitionsService: VitalProfileDefinitionsService,
    knRecommendationService: KnowledgesRecommendationService,
    knDescriptionService: KnowledgesDescriptionService,
    languagesService: LanguagesService,
    quizDefinitionService: QuizDefinitionService,
    quizResponseService: QuizResponseService,
    KnFAQService: KnowledgesFAQService,
    knObservationService: KnowledgesObservationService
  ) {

    // the order is important : https://comunicare.atlassian.net/wiki/spaces/CPD/pages/1112080385/Synchronisation+avec+le+Global+Data+Provider

    this.servicesWave1 = [
      accountService,
      configurationService,
      externalRessourceService,
      drugService,
      noteService,
      notificationsDrugsIntakeService,
      drugSchemaService,
      vitalProfileDefinitionsService,
      languagesService,
      careplanService
    ];

    this.servicesWave2 = [
      appointmentService,
      communicationService,
      bluetoothKnowledgeService,
      knowledgesDrugService,
      observationService,
      questionnaireDefService,
      questionnaireService,
      rulesService,
      rulesAlertService,
      obsDefService,
      rewardScoreService,
      quizDefinitionService,
      quizResponseService
    ];

    if (!accountService.isNotRelated()) {
      this.servicesWave2.push(relatedPatientsService, relatedCareplansService);
    }

    if (!accountService.isOnlyRelated) {
      this.servicesWave2.push(relatedPersonsService);
    }

    this.servicesWave3 = [
      contactService,
      knowledgeService,
      knRecommendationService,
      knDescriptionService,
      rewardDefinitionService,
      KnFAQService,
      knObservationService
    ];

    if (!accountService.isNotRelated()) {
      this.servicesWave3.push(relatedAppointmentsService, relatedDrugsService);
    }

    this.servicesWave4 = accountService.isOnlyPatient() ? [] : [relatedKnService];

    this.dataPatient = [
      accountService,
      configurationService,
      drugService,
      appointmentService,
      communicationService,
      obsDefService,
      observationService,
      questionnaireDefService,
      questionnaireService,
      rulesService,
      vitalProfileDefinitionsService,
      rulesAlertService
    ];

  }

  public async initServices() {
    const services = this.servicesWave1.concat(this.servicesWave2, this.servicesWave3, this.servicesWave4);
    const initPromises: Promise<void>[] = [];
    for (const service of services) {
      initPromises.push(service.init());
    }
    await Promise.all(initPromises);
  }

  public clearServices() {
    const services = this.servicesWave1.concat(this.servicesWave2, this.servicesWave3, this.servicesWave4);
    services.map((service: BasicSyncService<any, any[] | any>) => service.clear());
  }

  /**
   * Sync all GDP services in online and offline (<-- load in cache)
   */
  public async sync(servicesWave1 = this.servicesWave1, servicesWave2 = this.servicesWave2, servicesWave3 = this.servicesWave3, servicesWave4 = this.servicesWave4): Promise<ApiSyncServiceStatus> {
    try {
      if (this.isSynchronizing) {
        return ApiSyncServiceStatus.alreadyInProgress;
      }

      this.isSynchronizing = true;

      let requiredSynchro = Promise.resolve();
      const allServices = servicesWave1.concat(servicesWave2, servicesWave3, servicesWave4);

      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
        requiredSynchro = new Promise((resolve, reject) => {
          const p = this.requiredSynchroService.run(allServices);
          p.then(obsCheckSumResponse => {
            obsCheckSumResponse.subscribe(
              () => { },
              (err) => { resolve(); },
              () => { resolve(); }
            );
          }).catch(err => { resolve(); });
        });
      }

      await requiredSynchro;

      await Promise.all(servicesWave1.map((s) => {
        return s.initOffline().catch(err => { return err; }); // the "catch" allows not to block the promiseall in the case of a mistake
      }));

      await Promise.all(servicesWave2.map((s) => {
        return s.initOffline().catch(err => { return err; }); // the "catch" allows not to block the promiseall in the case of a mistake
      }));

      await Promise.all(servicesWave3.map((s) => {
        return s.initOffline().catch(err => { return err; }); // the "catch" allows not to block the promiseall in the case of a mistake
      }));

      await Promise.all(servicesWave4.map((s) => {
        return s.initOffline().catch(err => { return err; }); // the "catch" allows not to block the promiseall in the case of a mistake
      }));

      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
        await this.sysAccountService.updateLastSynchro();
      }

      this.isSynchronizing = false;
      return ApiSyncServiceStatus.success;

    } catch (error) {
      this.isSynchronizing = false;
      console.error(error, "ApiSyncService");
      return ApiSyncServiceStatus.error;
    }

  }

  public syncOnlyDataPatient(): Promise<ApiSyncServiceStatus> {
    const servicesDataWave1 = this.servicesWave1.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave2 = this.servicesWave2.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave3 = this.servicesWave3.filter((service) => this.dataPatient.includes(service));
    const servicesDataWave4 = this.servicesWave4.filter((service) => this.dataPatient.includes(service));

    return this.sync(servicesDataWave1, servicesDataWave2, servicesDataWave3, servicesDataWave4);
  }
}
