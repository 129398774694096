import { Pipe, PipeTransform } from "@angular/core";
import { AppConstants } from "../appConstants";
import { EntityDrug } from "../models/entitylink";

/**
 * Returns true if there's a quantity for the timing
 */
@Pipe({ name: "hasQuantity" })
export class HasQuantityPipe implements PipeTransform {

    constructor() { }

    public transform(timing: string, drugQuantities: any): boolean {
        timing = timing === AppConstants.RISING ? 'rise' : timing === AppConstants.BEDING ? 'bedtime' : timing;
        if (drugQuantities && drugQuantities[timing]) {
            return true;
        } else {
            return false;
        }
    }
}

/**
 * Returns the right quantity for each timing
 */
@Pipe({ name: "getQuantity" })
export class GetQuantityPipe implements PipeTransform {

    constructor() { }

    public transform(entityData: EntityDrug, timing: string) {
        let quantity;
        if (timing === AppConstants.RISING) {
            quantity = entityData.frequency.quantities.rise;
        } else if (timing === AppConstants.BEDING) {
            quantity = entityData.frequency.quantities.bedtime;
        } else {
            quantity = entityData.frequency.quantities[timing];
        }
        return this.getKeyFromValue(quantity);
    }

    private getKeyFromValue(value: string) {
        const allQuantities = EntityDrug.QUANTITTY;
        const foundQuantity = allQuantities.find(i => i.value === value);
        if (foundQuantity) {
            return foundQuantity.key;
        } else {
            return value;
        }
    }
}
