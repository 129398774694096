import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConsentModalComponent } from './components/modals/consent-modal/consent-modal.component';
import { ConsentService } from './services/consent.service';
import { InfoAppService } from './services/info-app.service';
import { LoginService } from './services/login.service';
import { ModalService } from './services/modal.service';
import { NeedConsentService } from './services/need-consent.service';
@Injectable({
    providedIn: 'root',
})
export class ConsentGuard implements CanActivate {

    constructor(
        private needConsentService: NeedConsentService,
        private modalService: ModalService,
        private infoService: InfoAppService,
        private loginService: LoginService,
        private modalCtrl: ModalController,
        private consentService: ConsentService,
    ) { }

    async canActivate(): Promise<boolean> {
        if (this.needConsentService.getNeedConsentStatus() === true) {
           this.presentModalConsent();
            return true;
        } else {
            return true;
        }
    }

    public async presentModalConsent(): Promise<void> {
        if (!this.consentService.consentModalIsOpenned) {
          this.consentService.consentModalIsOpenned = true;
          const modal = await this.modalCtrl.create({
            component: ConsentModalComponent,
            backdropDismiss: false
          });
          await modal.present();
          const consented = (await modal.onDidDismiss()).data;
          this.consentService.consentModalIsOpenned = false;
          if (consented) {
            // if it's first launch, we show the help modal when closing the Consent Modal
            const firstLaunch = await this.infoService.getFirstLaunchForActiveAccount();
            if (firstLaunch) {
              this.infoService.setFirstLaunchForActiveAccount(false);
              this.modalService.presentModalHelp(true);
            }
          } else {
            this.loginService.disconnect();
          }
        }
      }
}
