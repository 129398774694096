import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ArrayHelper } from 'src/app/helpers/array-helper';
import { Tools } from 'src/app/helpers/tools-helper';
import { EntityDrug, ENTITY_TYPE, IEntitylink } from 'src/app/models/entitylink';
import { NOTIFICATION_TYPE } from 'src/app/models/notification';
import { IEntity, ACTION_STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { NotificationsGeneratedService } from '../notificationsService/notifications-generated.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { LoaderService } from '../loader.service';

@Injectable({
  providedIn: 'root'
})
export class DrugService extends BasicSyncService<IEntitylink, IEntitylink[]> {

  public needNotifGeneration = true; // this variable is modified by the method this.dataService.read
  public needRefresh = true;
  public lastGenNotif: string = null;

  constructor(
    protected dataService: DataService,
    private notificationGeneratedService: NotificationsGeneratedService,
    private loaderService: LoaderService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IEntitylink[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'entitylinks_drug_',
      entityStoreKey: 'list',
      getUrl: '/entitylinks?ENTITY_TYPE=' + ENTITY_TYPE.DRUG,
      setUrl: '/entity',
      expirationDays: 10,
      encrypted: true
    };
  }

  /**
   * Get the list of drugs for the user
   * @param noNotifs (boolean) whether or not we can generate notifications if needed
   */
  public async *getDataReader(noNotifs: boolean = false): AsyncGenerator<IEntitylink[], IEntitylink[], IEntitylink[]> {
    try {
      const dataReader = super.getDataReader();
      let d: IEntitylink[] = [];
      for await (const data of dataReader) {
        d = this.processData(data);
        yield d;
      }
      this.dealWithNotif(noNotifs, d);
      return d;
    } catch (err) {
      console.error("DrugService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IEntitylink[]) {
    try {
      const filteredData = dataResult.filter((entity) => { return !IEntity.isDeleted(entity); });
      return filteredData;
    } catch (err) {
      console.error('Error while processing drugService data: ', err);
    }
    return dataResult;
  }

  private dealWithNotif(noNotifs: boolean, data: IEntitylink[]) {
    if (!noNotifs && (this.needNotifGeneration || !this.lastGenNotif ||
      moment(this.lastGenNotif).add(4, "hours").isBefore(moment()))) {
      this.generateNotifsDrugs(data);
    }
  }

  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(noNotifs: boolean = false): Promise<IEntitylink[]> {
    const dataReader = this.getDataReader(noNotifs);
    let iterator = await dataReader.next();
    while (!iterator.done) { iterator = await dataReader.next(); }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(noNotifs: boolean = false): Promise<IEntitylink[]> {
    const dataReader = this.getDataReader(noNotifs);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  public async listSnomedRef(): Promise<string[]> {
    try {
      const dataReader = this.getDataReader();
      // we don't care if it's local or online, we just need the first we find:
      const iterator = await dataReader.next();
      const drugs: IEntitylink[] = iterator.value;
      return this.getUniqueDrugsNames(drugs);
    } catch (err) {
      console.error('listSnomedRef error: ', err);
    }
    return [];
  }

  private getUniqueDrugsNames(drugsList: IEntitylink[]): string[] {
    const filteredDrugs = drugsList.filter((drug) => {
      return drug.entityData && (drug.entityData as EntityDrug).name;
    });
    const drugNames = filteredDrugs.map((drug) => {
      return (drug.entityData as EntityDrug).name;
    });
    const uniqueNamesNoSpecialChar = drugNames.filter(ArrayHelper.onlyUnique).map(Tools.deleteAcccentSpecialcharacter);
    return uniqueNamesNoSpecialChar;
  }

  /**
   * Generate the notifications for the drugs passed in parameters.
   * If the drugs list is empty, it will first try to download a new list
   * from the server.
   * @param _drugs the drugs we want to generate notifications for
   */
  public async generateNotifsDrugs(_drugs: IEntitylink[]): Promise<void> {
    if (_drugs && _drugs.length > 0) {
      try {
        await this.notificationGeneratedService.generatedNotifications(_drugs, NOTIFICATION_TYPE.DRUG);
        this.needNotifGeneration = false;
        this.lastGenNotif = moment().format();
        return;
      } catch (err) {
        console.error('generateNotifsDrugs error: ', err);
        return;
      }
    }
    try {
      const d = await this.getFreshestData(true);
      await this.notificationGeneratedService.generatedNotifications(d, NOTIFICATION_TYPE.DRUG);
      this.needNotifGeneration = false;
      this.lastGenNotif = moment().format();
    } catch (err) {
      console.error('generateNotifsDrugs error: ', err);
    }
  }

  public async getOne(id: string): Promise<IEntitylink> {
    // we don't care if it's local or online, we just need the first we find:
    const drugs = await this.getFirstDataAvailable();
    return drugs.find((e: IEntitylink) => e._id === id);
  }

  public async save(drug: IEntitylink, generateNotif = true, withToast = true): Promise<IEntitylink> {
    const savePromise = this.dataService.saveInArray(drug, (entity) => entity._id === drug._id, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.POST
    }).then((d: IEntitylink) => {
      const drugs = this.peekData();
      const i = drugs.findIndex(e => e._id === d._id);
      if (i >= 0 && d.actionStatus === ACTION_STATUS_ENTITY.MODIFIED) {
        drugs[i] = d;
      } else if (i < 0) {
        drugs.push(d);
      }
      this.pokeData(drugs);
      if (generateNotif) {
        this.notificationGeneratedService.updateOrCreateNotification(drug, NOTIFICATION_TYPE.DRUG);
      }
      return d;
    });
    if (withToast) { await this.loaderService.showSavingToast(true); }
    const savedDrug = await savePromise;
    if (withToast) { await this.loaderService.showSavingToast(false); }
    return savedDrug;
  }

  public async delete(drug: IEntitylink, withToast = true): Promise<Boolean> {
    IEntity.setDeleted(drug);
    const savePromise = this.dataService.removeFromArray(drug, (entity) => entity._id === drug._id, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.POST
    }).then((success: Boolean) => {
      if (!success) return false;
      const drugs = this.peekData();
      const i = drugs.findIndex(d => d._id === drug._id);
      if (i >= 0 && drug.actionStatus === ACTION_STATUS_ENTITY.DELETED) {
        drugs.splice(i, 1);
        this.pokeData(drugs);
      }
      return true;
    });
    if (withToast) { await this.loaderService.showSavingToast(true); }
    const isDeleted = await savePromise;
    if (withToast) { await this.loaderService.showSavingToast(false); }
    return isDeleted;
  }
}
