import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { first, last, take } from 'rxjs/operators';
import { ConsentService } from './consent.service';
import { AccountService } from './globalDataProvider/account.service';
import { ConfigurationService } from './globalDataProvider/configuration.service';
import { ApiSyncService } from './globalDataProvider/core/api-sync.service';
import { RequestSenderService, RequestSenderServiceSyncStatus } from './globalDataProvider/core/request-sender.service';
import { InfoAppService } from './info-app.service';
import { ModalService } from './modal.service';
import { SysAccountService } from './sys-account.service';

export enum InitServiceStatus {
  alreadyDone, success, error
}

@Injectable({
  providedIn: 'root'
})
export class InitService {

  private alreadyInit = false;
  // BehaviorSubject to control the initialization of the app. When it emit true, the app is init.
  private finishInit = new BehaviorSubject<boolean>(false);

  constructor(
    protected infoService: InfoAppService,
    protected configService: ConfigurationService,
    protected accountService: AccountService,
    protected sysAccountService: SysAccountService,
    protected apiSync: ApiSyncService,
    protected infoAppService: InfoAppService,
    protected requestSenderService: RequestSenderService,
    protected translateSvc: TranslateService,
    protected modalCtrl: ModalService,
    private consentService: ConsentService
  ) { }

  public needReinitialization() {
    this.alreadyInit = false;
  }
  /**
   * Init app when someone is connected :
   * - init sysAccount
   * - init local account and local config
   * - sync the queue
   * - sync only DataPatient
   * Warning : now the presentModalHelp is managed here to display it more quickly!!
   */
  public async initAppWithSomeoneLogIn(): Promise<InitServiceStatus> {
    try {
      if (this.alreadyInit) {
        console.log('already init');
        return InitServiceStatus.alreadyDone;
      }
      this.alreadyInit = true;
      // load the sysaccount
      await this.sysAccountService.getSysAccount();
      // re-init services as soon as possible
      await this.apiSync.initServices();

      // load in cache the local config and local account
      await this.configService.getFirstDataAvailable();
      await this.accountService.getFreshestData();

      const lang = await this.configService.getCurrentLanguageWithCheck();
      this.translateSvc.setDefaultLang(lang);
      await this.translateSvc.use(lang).pipe(last()).toPromise();


      if (!this.accountService.cachedAccount || !this.sysAccountService.cachedSysAccount || !this.sysAccountService.cachedSysAccount.token) {
        return InitServiceStatus.error;
      }

      // help modal
      const firstLaunch = await this.infoService.getFirstLaunchForActiveAccount();
      if (firstLaunch && !this.consentService.consentModalIsOpenned) {
        this.infoService.setFirstLaunchForActiveAccount(false);
        this.modalCtrl.presentModalHelp(true);
      }
      if (!this.infoAppService.isCordova()) {
        return InitServiceStatus.success;
      }

      // sync the queue
      // await this.requestSenderService.promiseResolveWhenReady();
      const requestSenderServiceSyncStatus = await this.requestSenderService.sync();

      switch (requestSenderServiceSyncStatus) {
        case RequestSenderServiceSyncStatus.alreadyInProgress:
          this.finishInit.next(true);
          return InitServiceStatus.alreadyDone;
        case RequestSenderServiceSyncStatus.authenticationError:
          this.finishInit.next(true);
          return InitServiceStatus.error;
        default:
          break;
      }

      await this.apiSync.syncOnlyDataPatient();
      // await this.apiSync.sync();
      this.finishInit.next(true);
      return InitServiceStatus.success;
    } catch (error) {
      this.alreadyInit = false;
      this.finishInit.next(true);
      console.error("initAppWithSomeoneLogIn", error);
      return InitServiceStatus.error;
    }
  }

  /**
    * Return a promise which is resolve when this service is finish to init
    */
  public promiseResolveWhenReady(): Promise<void> {
    // this promise is resolve when we can do a requestSender sync
    return new Promise<void>((resolve, reject) => {
      this.finishInit.pipe(
        first((sync) => sync) // emits only the first time that sync === false
      ).subscribe((sync) => {
        console.debug("promiseResolveWhenReady : " + sync);
        resolve();
      },
        (err) => {
          console.error("sync requestSenderService.synchroObs error", err);
          resolve();
        });
    });
  }

}
