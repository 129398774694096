import { Component, ViewChild } from '@angular/core';
import { IonContent, IonSlides, ModalController, NavParams, ToastController, ViewDidEnter, ViewWillEnter } from '@ionic/angular';
import { IAccount } from 'src/app/helpers/account-helper';
import { Answer, QRQuestion, QuestionnaireResponse } from 'src/app/helpers/questionnaireResponse';
import { IQuestionnaire, Question } from 'src/app/models/questionnaire';
import { SlideView } from 'src/app/models/slideView';
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { Coding } from 'src/app/models/sharedInterfaces';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { QuizResponseService } from 'src/app/services/globalDataProvider/quiz-response.service';
import { Tools } from 'src/app/helpers/tools-helper';
import { SlideContentGroupQuestion, SlideContentQuestion } from '../questionnaire-modal/questionnaire-modal.component';
import { NetworkService } from 'src/app/services/network.service';
import { BasePage } from 'src/app/baseClasses/base-page';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { AppConstants } from 'src/app/appConstants';


@Component({
  selector: 'app-quiz-modal',
  templateUrl: './quiz-modal.component.html',
  styleUrls: ['./quiz-modal.component.scss'],
})
export class QuizModalComponent extends BasePage implements ViewWillEnter, ViewDidEnter {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('slides') slides: IonSlides;
  public quiz: IQuestionnaire;
  public quizResponse: QuestionnaireResponse;
  public isReadonly: boolean;
  public slideViews: SlideView[] = [];
  private account: IAccount;
  private lockSwipe = true;
  public modeCreate: boolean;
  public action: string;
  public quizzes: IQuestionnaire[];
  public isBeginning = true;
  public swipOpts = {
    autoHeight: true
  };
  public isLoading = true;
  public showAnswerText = false;
  public isCorrect = false;
  public hasAnswered = false;
  public hasSucceeded = false;
  public actualScore = 0;
  public scoreRatio = 0;
  public totalScore = 0;
  public hasMoreQuizzes = false;
  public isFromMedia = false;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected navParams: NavParams,
    private accountService: AccountService,
    protected modalCtr: ModalController,
    protected translateSvc: TranslateService,
    protected toastController: ToastController,
    private quizResponseService: QuizResponseService,
    private networkService: NetworkService,
    configService: ConfigurationService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.account = this.accountService.cachedAccount;
    this.lockSwipe = this.navParams.data.action === "create";
    this.modeCreate = this.navParams.data.action === "create";
    this.action = this.navParams.get("action");
    this.quiz = this.navParams.data.quiz;
    this.quizzes = (this.navParams.data.otherQuizzes && this.navParams.data.otherQuizzes.length) ? this.navParams.data.otherQuizzes : [];
    if (this.quizzes && this.quizzes.length) {
      this.hasMoreQuizzes = true;
    }
    if (this.navParams.data.pageName === AppConstants.PAGE_KNOWLEDGE) {
      this.isFromMedia = true;
    } else {
      this.isFromMedia = false;
    }
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    this.totalScore = this.quiz.successThreshold;
    if (this.lockSwipe) {
      this.slides.lockSwipes(true);
    }

    if (this.action === "create") {
      this.isReadonly = false;
      // build Questionnaire Response based on Quiz template
      this.quizResponse = new QuestionnaireResponse();
      this.quizResponse.fromTemplate(this.quiz, this.account);
      this.slideViews = this.buildSingleSlideMode();
    } else if (this.action === "view") {
      this.isReadonly = true;
      this.quizResponse = this.navParams.get("quizResponse");
      this.slideViews = this.buildSingleSlideMode();
    }
  }

  ionViewDidEnter() {
    super.ionViewDidEnter();
    this.slides.updateAutoHeight().then(() => {
      this.isLoading = false;
      if (this.networkService.isCurrentOffline() && this.quiz.onlyOnline) {
        this.popupService.showToast("myfeelings.cannotSaveOffline", 5000, "bottom");
        this.dismiss();
      }
    });
  }

  /**
   * Dismiss modal
   */
  public dismiss() {
    this.modalCtr.dismiss();
  }

  /**
   * return multiple choice values define in "questionnaire"
   */
  private getValueSetChoices(reference: string, question?: QRQuestion): Coding[] {
    if (this.quiz.contained) {
      for (const element of this.quiz.contained) {
        if ((element.resourceType === "ValueSet") && (element.idSet === reference)) {
          // This part is basically only for checkbox (mutiple answers) to check if they're checked or not
          if (this.isReadonly && question) {
            element.compose.include[0].concept.forEach((a) => {
              const i = question.answer.findIndex((q) => q.valueCoding.code === a.code);
              a.checked = i > -1;
            });
          } else {
            element.compose.include[0].concept.forEach((a) => a.checked = false);
          }
          return element.compose.include[0].concept;
        }
      }
    }
    return [];
  }

  /**
   * Save these responses: create a QuestionaireResponse
   */
  private async onValidate() {
    this.quizResponse.modified = moment().format();
    await this.quizResponseService.save(this.quizResponse);
  }

  /**
   * Response choice has changed: update QuestionnaireResponse
   */
  public async onRadioChange($event: CustomEvent, quiz: Question) {
    if (!$event) return;
    let res = this.getAnswerModel(quiz.linkId);
    if (!res) {
      res = new Array<Answer>();
    }
    this.hasAnswered = true;
    let timeout = 3000;
    const correctAnswers = quiz.answerDisplay.goodAnswers;
    if (correctAnswers.includes($event.detail.value)) {
      this.actualScore++;
      this.isCorrect = true;
      timeout = 3000;
    } else {
      this.isCorrect = false;
      timeout = 10000;
    }
    this.scoreRatio = (this.actualScore / this.slideViews.length) * 100;
    this.scoreRatio = Math.round(this.scoreRatio * 100) / 100;
    res.splice(0); // remove previos value
    const choices: Coding[] = this.getValueSetChoices(quiz.options.reference);
    const code = $event.detail.value;
    const display = choices.find((choice: Coding) => choice.code === code)?.display;
    const answer = new Answer();
    answer.valueCoding = {
      code: code,
      display: display,
      system: null
    };
    res.push(answer);
    this.showAnswerText = true;
    await this.scrollToTop();
    await Tools.wait(100);
    await this.slides.updateAutoHeight();

    // [bugfix] We update here : The next questions still accessible if the response checked modify the height of the questions container.
    setTimeout(() => {
      this.showAnswerText = false;
      this.hasAnswered = false;
      this.nextSlide();
    }, timeout);
  }

  /**
   *  Check where we are on the page and if we are not near the top, scroll to
   *  the top.
   */
  private async scrollToTop() {
    const scrollElement = await this.content.getScrollElement();
    if (scrollElement.scrollTop > 0) {
      this.content.scrollToTop(500);
    }
  }

  /**
   * return the Answer object related to this "LinkId"
   */
  private getAnswerModel(linkId: string): Answer[] {
    if (this.quizResponse.group.question?.length) {
      for (const question of this.quizResponse.group.question) {
        if (question.linkId === linkId) return question.answer;
      }
    }
    else if (this.quizResponse.group.group?.length) {
      for (const subGroup of this.quizResponse.group.group) {
        for (const question of subGroup.question) {
          if (question.linkId === linkId) return question.answer;
        }
      }
    }
    return null;
  }

  public setStringInput($event: CustomEvent, linkId: string) {
    const res = this.getAnswerModel(linkId);
    res.splice(0);
    const answer = new Answer();
    answer.valueCoding = {
      code: $event?.detail.value,
      display: $event?.detail.value,
      system: null
    };
    if (res.length > 0) {
      res[0] = answer;
    } else {
      res.push(answer);
    }
  }

  public async submitTextField() {
    this.hasAnswered = true;
    this.actualScore++;
    this.scoreRatio = (this.actualScore / this.slideViews.length) * 100;
    this.scoreRatio = Math.round(this.scoreRatio * 100) / 100;
    this.nextSlide();
  }

  /**
   * Click on next button
   */
  private async nextSlide() {
    await this.slides.lockSwipeToNext(false);
    await this.slides.slideNext();
    await Tools.wait(100);
    await this.slides.updateAutoHeight();
    this.hasAnswered = false;
    if (this.lockSwipe) {
      await this.slides.lockSwipeToNext(true);
    }
    if (await this.slides.isEnd()) {
      if (this.scoreRatio <= this.totalScore) {
        this.hasSucceeded = false;
      } else {
        this.hasSucceeded = true;
      }
      await this.onValidate();
    }
  }

  public async nextQuiz() {
    const otherQuizzes = Tools.deepCopy(this.quizzes);
    const quiz = (otherQuizzes && otherQuizzes.length) ? otherQuizzes.shift() : undefined;
    if (quiz) {
      this.modalCtr.dismiss({
        anotherQuiz: true
      });
    }
    if (!otherQuizzes.length) {
      this.hasMoreQuizzes = false;
    }
  }

  private buildSingleSlideMode(): SlideView[] {
    const slideViews: SlideView[] = [];
    // multiple groups of questions
    if (QuestionnaireResponse.isMultiGroup(this.quizResponse)) {
      // loop on sub groups
      for (let i = 0; i < this.quiz.group.group.length; i++) {
        const subGroup = this.quiz.group.group[i];
        // loop on questions for each group
        for (let j = 0; j < subGroup.question.length; j++) {
          const question = subGroup.question[j];
          // one slide for 1 content group
          const slideView: SlideView = {
            "identifier": this.quizResponse.identifier.value,
            "content": []
          };
          // one content group for 1 question
          const slideContentGroup: SlideContentGroupQuestion = {
            "title": subGroup.title,
            "description": subGroup.text,
            "questionResponses": []
          };
          const slideContent: SlideContentQuestion = {
            "question": question,
            "response": this.quizResponse.group.group[i].question[j]
          };
          // set question/response to its Content Group
          slideContentGroup.questionResponses = [slideContent];
          // add content group to its slide
          slideView.content = [slideContentGroup];
          // add slide to the list of slides
          slideViews.push(slideView);
        }
      }
    } else {
      // flat questionnaires (only main group with questions)
      // loop on main group questions
      for (let i = 0; i < this.quiz.group.question.length; i++) {
        const question = this.quiz.group.question[i];
        // one slide for 1 content group
        const slideView: SlideView = {
          "identifier": this.quizResponse.identifier.value,
          "content": []
        };
        // one content group for 1 question
        const slideContentGroup: SlideContentGroupQuestion = {
          "title": this.quiz.group.title,
          "description": this.quiz.group.text,
          "questionResponses": []
        };
        const slideContent: SlideContentQuestion = {
          "question": question,
          "response": this.quizResponse.group.question[i]
        };
        // set question/response to its Content Group
        slideContentGroup.questionResponses = [slideContent];
        // add content group to its slide
        slideView.content = [slideContentGroup];
        // add slide to the list of slides
        slideViews.push(slideView);
      }
    }
    return slideViews;
  }

}
