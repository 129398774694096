import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject, from } from 'rxjs';
import { IRewardTooltip } from '../models/rewardTooltips';
import { ConfigurationService } from './globalDataProvider/configuration.service';

const NOT_SHOW_ANYMORE = "NotShowAnymore";

@Injectable({
  providedIn: 'root'
})
export class RewardToastService {
  private options = {
    duration: 3000,
    closeButtonText: 'OK',
    position: 'top' as 'top',
    cssClass: 'customRewardToast',
    buttons: [
      {
        icon: 'eye-off',
        role: NOT_SHOW_ANYMORE,
        handler: () => { }
      }
    ]
  };

  private toastQueue: IRewardTooltip[] = [];
  private $isToastAlreadyShow = new BehaviorSubject<boolean>(false);

  constructor(private toastCtrl: ToastController, private configService: ConfigurationService) {
    this.$isToastAlreadyShow.subscribe((v) => {
      if (!v && this.toastQueue?.length) {
        const nextToast = this.toastQueue.pop();
        this.show(nextToast);
      }
    });
  }

  public async show(params: IRewardTooltip): Promise<void> {
    if (!this.$isToastAlreadyShow.value) {
      this.$isToastAlreadyShow.next(true);
      const message = `${params.textToShow} +${params.pointsWin}pts`;
      const toast = await this.toastCtrl.create({ ...this.options, message });
      toast.present();
      toast.onDidDismiss().then(v => {
        if (v.role === NOT_SHOW_ANYMORE) {
          this.configService.showToast = true;
        }
        this.$isToastAlreadyShow.next(false);
      });
    } else {
      this.toastQueue.push(params);
    }
  }
}
