import { Injectable } from "@angular/core";
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Storage } from '@ionic/storage-angular';
import { LocalStorageError, LocalStorageErrorType } from "./Local-storag-error.service";

/**
 * because of the circular dependencies, when localStorageService can not be called, we can take this service.
 * This service does not decrypt data, nor does it transform a key.
 * (usefull for secureStorageService for example)
 */
@Injectable({
    providedIn: 'root'
})
export class LocalStorageInitService {

    protected static _storage: Storage | null = null;

    constructor(
        private storage: Storage
    ) { }

    private async init() {
        try {
            await this.storage.defineDriver(CordovaSQLiteDriver);
            const storage = await this.storage.create();
            LocalStorageInitService._storage = storage;
        } catch (error) {
            LocalStorageInitService._storage = null;
            throw new LocalStorageError(`Storage is not initialized`, LocalStorageErrorType.STORAGE_NOT_INITIALIZED);
        }
    }

    /**
     * 
     * @param key 
     * @returns the string of the local storage associated to the key. Null if this key is not present
     */
    public async getDataUnencryptedAndWithoutTransformKey(key: string): Promise<string | null> {
        try {
            if (await this.isReady()) {
                return await LocalStorageInitService._storage?.get(key);
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    public async isReady(): Promise<boolean> {
        if (LocalStorageInitService._storage === null) {
            await this.init();
        }
        return LocalStorageInitService._storage !== null;
    }

}

