import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from '../services/popup.service';
import { InfoAppService } from '../services/info-app.service';
import { NetworkService } from '../services/network.service';
import { LoginService, LOGIN_TYPE } from '../services/login.service';
import { last, takeLast } from 'rxjs/operators';
import { ConfigurationService } from '../services/globalDataProvider/configuration.service';
import { BasePage } from '../baseClasses/base-page';
import { GoToPageService } from '../services/go-to-page.service';
import { ModalService } from '../services/modal.service';
import { GetParametersPageService } from '../services/get-parameters-page.service';
import { SERVER_RESPONSE_TYPE } from '../helpers/server-response-helper';
import { MenuController } from '@ionic/angular';
import { LanguagesService } from 'src/app/services/globalDataProvider/languagesService';
import { ITranslation } from '../models/translation';
import { environment } from 'src/environments/environment';
import { E2E_ID_LOGIN } from 'test/helpers/selectorIdHelper';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage extends BasePage {

  public static onLoginPage = false;

  public language = 'fr';
  public isForceDevMode = false;
  public loginFormGroup: FormGroup;
  public hasSecretDevAccess = false;
  private forceDevClickCounter = 0;
  public version: string;
  public errServer: any = null;
  public isLoggingIn = false;
  public activeFingerprint = false;
  public languages: ITranslation[];
  public env;
  public availableFeatures: any;
  public E2E_ID_LOGIN = E2E_ID_LOGIN;

  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected route: ActivatedRoute,
    protected infoAppVersion: InfoAppService,
    protected popupService: PopupService,
    protected networkService: NetworkService,
    protected loginService: LoginService,
    protected goToPageService: GoToPageService,
    protected modalService: ModalService,
    protected infoAppService: InfoAppService,
    protected getParamsPage: GetParametersPageService,
    protected menuCtrl: MenuController,
    protected languagesService: LanguagesService
  ) {
    super(translateSvc, configService, infoAppVersion, popupService);
    this.env = environment;
    this.loginFormGroup = new FormGroup({
      "user": new FormControl("", [Validators.required]),
      "password": new FormControl("", [Validators.required]),
    });
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    this.setupLanguage();
    this.languagesService.getFreshestData().then((l) => { this.languages = l; });
    // disable the root left menu when entring the login page
    this.menuCtrl.enable(false);
    this.menuCtrl.swipeGesture(false);

    LoginPage.onLoginPage = true;
    this.isLoggingIn = false;
    this.infoAppVersion.getCurrentMode().then((appMode) => {
      this.hasSecretDevAccess = (appMode === "DEV");
      this.isForceDevMode = (appMode === "FORCE_DEV");
    });

    this.infoAppService.getVersion().then((_version) => {
      this.version = _version;
    });
    // To be sure, everything is cleared:
    this.loginService.disconnect();

    this.errServer = this.getParamsPage.getValueOfActivePage("error", null);

    this.infoAppVersion.getLastLogin().then(
      (lastLogin) => {
        if (lastLogin) {
          this.loginFormGroup.get("user").setValue(lastLogin);
        }
      }
    );

    this.activeFingerprint = this.route.snapshot.data.secureStorageActive;

  }

  ionViewDidEnter() {
    super.ionViewDidEnter();
    switch (this.errServer) {
      case SERVER_RESPONSE_TYPE.AUTHENTIFICATION_FAILED:
        this.popupService.showAlert("application.title", "login.refused.tokenExp");
        break;

      default:
        break;
    }
  }

  ionViewWillLeave() {
    super.ionViewWillLeave();
    LoginPage.onLoginPage = false;
    this.forceDevClickCounter = 0;
    this.menuCtrl.enable(true);
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
    // enable the root left menu when leaving the login page
    this.menuCtrl.swipeGesture(true);
  }

  private async setupLanguage() {
    const lastLang = await this.languagesService.getLastConfigLanguage();
    if (lastLang) { this.configService.setLastLangUsed(lastLang); }
    this.language = lastLang ? lastLang : await this.configService.getCurrentLanguageWithCheck();
    this.translateSvc.setDefaultLang(this.language);
    await this.translateSvc.use(this.language).pipe(last()).toPromise();
  }

  onLogin() {
    // user/password must be set
    const user = (this.loginFormGroup.get("user").value as string).toLowerCase().trim();
    const password = this.loginFormGroup.get("password").value as string;

    if (!user || !password) {
      this.popupService.showAlert("application.title", "login.error.missUser");
      return;
    }
    this.isLoggingIn = true;
    this.loginService.authenticate(user, password).pipe(takeLast(1)).subscribe(
      (type) => {
        this.isLoggingIn = false;
        switch (type) {
          case LOGIN_TYPE.SUCCESS:
            this.infoAppVersion.setLastLogin(user);
            this.goToPageService.homePage({
              synchro: true
            });
            this.loginFormGroup.get("password").reset();
            break;
          case LOGIN_TYPE.FAILED:
            this.popupService.showAlert("login.refused.title", "login.refused.subTitle");
            break;
          case LOGIN_TYPE.DENIED:
            this.popupService.showAlert("login.refused.title", "login.refused.nonActive");
            break;
          case LOGIN_TYPE.SUCCESS_OFFLINE:
            this.infoAppVersion.setLastLogin(user);
            this.popupService.showAlert("application.title", "login.success.offline");
            this.goToPageService.homePage({
              synchro: false
            });
            this.loginFormGroup.get("password").reset();
            break;
          case LOGIN_TYPE.FAILED_OFFLINE:
            this.popupService.showAlert("login.refused.title", "login.refused.offline");
            break;
          case LOGIN_TYPE.PRACTITIONER_DENIED:
            this.popupService.showAlert("login.refused.title", "error.notPatientOrRelated");
            break;
          case LOGIN_TYPE.MIGRATION_IONIC5_FAILED:
            console.error("MIGRATION_IONIC5_FAILED"); // the popup is already manage in migrationIonic5Service
            break;
          case LOGIN_TYPE.TOO_MANY_ATTEMPTS:
            this.popupService.showAlert("login.refused.title", "login.refused.tooManyAttempts");
            break;
          case LOGIN_TYPE.EXPIRED_PASSWORD:
            this.popupService.showAlert("login.refused.title", "login.refused.expiredPassword");
            break;
          default:
            this.popupService.showAlert("login.refused.title", "login.error.title");
            break;
        }
      }
    );

  }

  onResetPassword() {
    const user = (this.loginFormGroup.get("user").value).toLowerCase().trim();
    this.modalService.presentModalResetPassword(user, this.language);
  }

  async onSecretAccess() {
    try {
      if (!this.hasSecretDevAccess) {
        this.popupService.showYesNo("application.title", "application.devmodeon")
          .then(async (answer) => {
            if (answer) {
              await this.infoAppVersion.setCurrentMode("DEV");
              this.popupService.showToast("application.demoModeToaster", 5000, "bottom");
            } else {
              await this.infoAppVersion.setCurrentMode("PROD");
              this.hasSecretDevAccess = false;
            }
          });
      } else {
        await this.infoAppVersion.setCurrentMode("PROD");
      }
    }
    catch (err) {
      console.error("onSecretAccess", err);
      this.popupService.showAlert("application.title", "error.general");
    }
  }

  async forceDevMode() {
    try {
      this.forceDevClickCounter++;
      if (this.forceDevClickCounter === 6) {
        this.isForceDevMode = !this.isForceDevMode;
        if (this.isForceDevMode) {
          this.popupService.showYesNo("application.title", "application.forcedevmodeon")
            .then(async (answer) => {
              if (answer) {
                await this.infoAppVersion.setCurrentMode("FORCE_DEV");
                this.popupService.showToast("application.devModeToaster", 5000, "bottom");
                this.forceDevClickCounter = 0;
                this.isForceDevMode = true;
              } else {
                await this.infoAppVersion.setCurrentMode(this.hasSecretDevAccess ? "DEV" : "PROD");
                this.popupService.showToast(this.hasSecretDevAccess ? "application.demoModeToaster" : "application.devmodeoff", 5000, "bottom");
                this.forceDevClickCounter = 0;
                this.isForceDevMode = false;
              }
            });
        } else {
          await this.infoAppVersion.setCurrentMode("PROD");
          this.forceDevClickCounter = 0;
        }
      }
    }
    catch (err) {
      console.error("forceDevMode", err);
      this.popupService.showAlert("application.title", "error.general");
    }
  }

  /**
   * User Change language
   */
  public async onChangeLanguage() {
    // this.settingsService.setFirstLoginLanguage(this.language);
    this.translateSvc.setDefaultLang(this.language);
    await this.translateSvc.use(this.language).pipe(last()).toPromise();
  }

  public async onLoginWithFingerprint() {
    this.isLoggingIn = true;
    const success = await this.loginService.authenticateWithFingerprint();
    this.isLoggingIn = false;
    switch (success) {
      case LOGIN_TYPE.SUCCESS:
        this.goToPageService.homePage({
          synchro: true
        });
        break;
      case LOGIN_TYPE.FAILED:
        // login / mdp associé plus valide
        await this.infoAppVersion.setDataAssociatedToFingerprint(null);
        this.popupService.showAlert("login.refused.title", "fingerprint.invalidIdentifier");
        break;
      case LOGIN_TYPE.SUCCESS_OFFLINE:
        this.popupService.showAlert("application.title", "login.success.offline");
        this.goToPageService.homePage({
          synchro: false
        });
        break;
      case LOGIN_TYPE.FAILED_OFFLINE:
        this.popupService.showAlert("login.refused.title", "login.refused.offline");
        break;
      case LOGIN_TYPE.FINGERPRINT_NOT_AVAILABLE:
        this.popupService.showAlert("application.title", "fingerprint.notAvailable");
        break;
      case LOGIN_TYPE.FINGERPRINT_FAILED:
        this.popupService.showAlert("application.title", "fingerprint.failed");
        break;
      default:
        this.popupService.showAlert("login.refused.title", "login.error.title");
        break;
    }
  }

  /* TODO use in preference later
  public async modifAccountAssociatedFingerprint() {
    const fingerprintSuccess = await this.loginService.askFingerprint();
    switch (fingerprintSuccess) {
      case ASK_FINGERPRINT.SUCCESS:
        await this.modalService.presentModalLoginPwdForFingerprint();
        this.popupService.showAlert("application.title", "fingerprint.login.updateData");
        break;
      case ASK_FINGERPRINT.NOT_AVAILABLE:
        this.popupService.showAlert("application.title", "fingerprint.notAvailable");
        break;
      case ASK_FINGERPRINT.FAILED:
        this.popupService.showAlert("application.title", "fingerprint.failed");
        break;
    }
  }
  */

}
