import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IObservation, IObservationDefinition } from 'src/app/helpers/observation-helper';
import { Tools } from 'src/app/helpers/tools-helper';
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import * as moment from 'moment';
import { LoaderService } from '../loader.service';

@Injectable({
    providedIn: 'root'
})
export class ObservationService extends BasicSyncService<IObservation, IObservation[]> {

    constructor(
        protected dataService: DataService,
        private accountService: AccountService,
        private loaderService: LoaderService
    ) {
        super(dataService);
    }

    protected clearWatch(): void {
        this.data$ = new BehaviorSubject<IObservation[]>([]);
    }

    protected initWatch(): void {
        this.data$.next([]);
    }

    protected setupDataParameters(): void {
        this.defaultDataParameter = {
            entityPrefix: 'observations_',
            entityStoreKey: 'list',
            getUrl: '/observations',
            setUrl: '/observation',
            expirationDays: 10,
            encrypted: false,
        };
    }

    /**
     * Returns the current state of the service's data
     */
    public peekData(includeDeleted: boolean = true): IObservation[] {
        return this.processData(super.peekData(), includeDeleted);
    }

    public async *getDataReader(includeDeleted: boolean = false)
        : AsyncGenerator<IObservation[], IObservation[], IObservation[]> {
        try {
            if (this.accountService.isOnlyRelated) {
                yield [];
                return [];
            }
            const dataReader = super.getDataReader();
            let d: IObservation[] = [];
            for await (const data of dataReader) {
                d = this.processData(data, includeDeleted);
                yield d;
            }
            return d;
        } catch (err) {
            console.warn("ObservationService getDataReader()", err);
            yield [];
            return [];
        }
    }

    private processData(dataResult: IObservation[], includeDeleted: boolean) {
        try {
            let observations: IObservation[] = dataResult;
            if (Tools.isNotDefined(observations?.length) || !Array.isArray(observations)) {
                return [];
            }
            if (!includeDeleted) {
                observations = observations.filter((o) => {
                    return o.actionStatus !== ACTION_STATUS_ENTITY.DELETED;
                });
            }
            return observations;
        } catch (err) {
            console.error('Error while processing observationService data: ', err);
            return dataResult;
        }
    }

    /**
     * This will try to get the online data and refresh the service's data.
     * If the online data is not available, it will only return the local.
     */
    public async getFreshestData(includeDeleted: boolean = false): Promise<IObservation[]> {
        const dataReader = this.getDataReader(includeDeleted);
        let iterator = await dataReader.next();
        while (!iterator.done) { iterator = await dataReader.next(); }
        return iterator.value;
    }

    /**
     * This will return the local data or the online data if there's
     * no local data (and the online is available).
     */
    public async getFirstDataAvailable(includeDeleted: boolean = false): Promise<IObservation[]> {
        const dataReader = this.getDataReader(includeDeleted);
        const iterator = await dataReader.next();
        return iterator.value;
    }

    public async save(obs: IObservation, withToast = true): Promise<IObservation> {
        const savePromise = this.dataService.saveInArray(obs, (entity) => entity._id === obs._id, {
            ...this.defaultDataParameter,
            method: SYNC_HTTP_METHOD.PUT
        }).then((o: IObservation) => {
            const observations = this.peekData();
            const i = observations.findIndex(e => e._id === o._id);
            if (i >= 0 && !o.entityStatus.includes(STATUS_ENTITY.DELETED)) {
                observations[i] = o;
            } else if (i >= 0 && o.entityStatus.includes(STATUS_ENTITY.DELETED)) {
                observations.splice(i, 1);
            } else if (i < 0 && !o.entityStatus.includes(STATUS_ENTITY.DELETED)) {
                observations.push(o);
            }
            this.pokeData(observations);
            return o;
        });
        if (withToast) { await this.loaderService.showSavingToast(true); }
        const savedObservation = await savePromise;
        if (withToast) { await this.loaderService.showSavingToast(false); }
        return savedObservation;
    }

    /**
     * Indicates if we must now create or modify an observation from a definition (via the field onePerDay)
     * @param definition
     */
    public async createOrModify(definition: IObservationDefinition): Promise<{ type: ACTION_STATUS_ENTITY, obs?: IObservation }> {
        if (!definition.onePerDay) {
            return { type: ACTION_STATUS_ENTITY.CREATED, obs: null };
        }
        const allObservations = await this.getFirstDataAvailable();
        const now = moment();
        const todayObs = allObservations.find((obs) => moment(obs.issued).isSame(now, 'day') && String(definition.loinc) === obs.code.coding[0].code);
        if (todayObs) {
            return { type: ACTION_STATUS_ENTITY.MODIFIED, obs: todayObs };
        }
        return { type: ACTION_STATUS_ENTITY.CREATED, obs: null };
    }

    /**
     *  Sort observations from older to newer
     *  also clean invalid observations (null)
     */
    public sortObservations(observations: IObservation[]): IObservation[] {
        if (!observations) return [];
        return (
            observations
                .filter(obs => obs && (obs.actionStatus !== ACTION_STATUS_ENTITY.DELETED)) // remove null item content and deleted ones
                .sort((a: IObservation, b: IObservation) => {
                    if (!a) return 1;
                    if (!b) return -1;
                    if (moment(a.issued).isBefore(moment(b.issued))) {
                        return 1;
                    }
                    if (moment(a.issued).isAfter(moment(b.issued))) {
                        return -1;
                    }
                    else {
                        return a._id.localeCompare(b._id);
                    }
                })
        );
    }
}
