import { ChangeDetectorRef, Component } from '@angular/core';
import { AlertController, IonSlides, LoadingController, ModalController, NavController, NavParams, ViewDidEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IKnowledgeBase, IKnowledges, IKnowMedia, KnowledgeBase, KNOW_CATEGORY, KNOW_DOC_CATEGORY, KNOW_DOC_TYPE } from 'src/app/helpers/knowledge-helper';
import { EXTERNAL_RESSOURCE_TYPE, IExternalRessource } from 'src/app/models/externalRessource';
import { BluetoothKnowledgeService } from 'src/app/services/globalDataProvider/bluetooth-knowledge.service';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { ExternalRessourceService } from 'src/app/services/globalDataProvider/external-ressource.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { MedicalBluetooth } from 'src/app/services/medical-bluetooth/medical-bluetooth.service';
import { PopupService } from 'src/app/services/popup.service';
import { GetParametersPageService } from '../services/get-parameters-page.service';
import { SLIDE_OPTS } from './slideOpts';
import { AppConstants } from '../appConstants';
import { HelpService } from '../services/help.service';
import { ModalKnowledgeService } from '../services/modal-knowledge.service';
import { takeUntil } from 'rxjs/operators';
import { BasePage } from '../baseClasses/base-page';

@Component({
  selector: 'app-my-ble-devices',
  templateUrl: './my-ble-devices.page.html',
  styleUrls: ['./my-ble-devices.page.scss'],
})
export class MyBleDevicesPage extends BasePage {
  public ressources: IExternalRessource[] = [];
  public activeSnomed: string[] = [];
  public activeCategory = "";
  public activeHardware: IExternalRessource;
  public selectedHardware: IExternalRessource;
  public helpSeen = false;
  public isLoading = true;

  // Bluetooth
  public bonded = false;
  private deviceFound = false;

  public slideOpts = SLIDE_OPTS;
  public isModal: boolean;
  public i18nCategories: string[] = [];
  public categories: string[] = [];

  constructor(
    protected infoService: InfoAppService,
    protected loadingCtrl: LoadingController,
    protected translateSvc: TranslateService,
    protected alertCtrl: AlertController,
    protected externalRessourceService: ExternalRessourceService,
    protected medicalBluetoothService: MedicalBluetooth,
    protected cdr: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected navParams: NavParams,
    protected getParametersPageService: GetParametersPageService,
    protected navCtrl: NavController,
    protected modalKnowledge: ModalKnowledgeService,
    protected modalCtrl: ModalController,
    protected configService: ConfigurationService,
    protected bluetoothKnowledgeService: BluetoothKnowledgeService,
    protected popupService: PopupService,
    protected helpService: HelpService
  ) {
    super(translateService, configService, infoService, popupService);
    this.isModal = navParams.get('isModal');
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.isLoading = true;
    this.helpService.isHelpPageSeenOn(AppConstants.PAGE_BLUETOOTH).then((seen) => {
      this.helpSeen = seen;
    });
    this.updateList();
  }

  private loadI18nCategories() {
    const categories = this.ressources.reduce((acc: string[], next: IExternalRessource) => {
      let ressourceCategories = next.categories;
      // Search category translations in externalRessource object
      if (this.translateService.getDefaultLang() && next.i18n.length > 0) {
        const i18nLang = next.i18n.find((t) => t.lang === this.translateService.getDefaultLang());
        if (i18nLang) {
          ressourceCategories = next.categories.map((category, index) => {
            const i18nCategories = i18nLang.values.find((t) => t.key === "categories_" + index);
            return (i18nCategories) ? i18nCategories.value : category;
          });
        }
      }
      acc.push(...ressourceCategories);
      return acc;
    }, []);
    this.i18nCategories = Array.from(new Set(categories));
  }

  private loadCategories() {
    const categories = this.ressources.reduce((acc: string[], next: IExternalRessource) => {
      acc.push(...next.categories);
      return acc;
    }, []);
    this.categories = Array.from(new Set(categories));
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  private getHardwareByCategory(category: string) {
    return this.ressources.filter((ressource) => ressource.categories.indexOf(category) > -1);
  }

  private async updateList() {
    const dataReader = this.externalRessourceService.getDataReader("", [EXTERNAL_RESSOURCE_TYPE.BLUETOOTH_HARDWARE]);
    for await (const ressources of dataReader) {
      this.ressources = ressources;
      if (!this.activeCategory) {
        const navParamCategory = this.isModal ? this.navParams.get("category") :
          this.getParametersPageService.getValueOfActivePage("category", undefined);
        if (navParamCategory) {
          this.openCategory(navParamCategory);
        } else if (this.categories.length > 0) {
          this.openCategory(this.categories[0]);
        }
      }
    }
    this.loadCategories();
    this.loadI18nCategories();
    this.isLoading = false;
  }

  public openCategory(category: string) {
    if (category === this.activeCategory) {
      this.activeCategory = "";
      this.activeHardware = undefined;
    } else {
      this.activeCategory = category;

      const hardwares = this.getHardwareByCategory(this.activeCategory);
      if (hardwares.length > 0) {
        this.activeHardware = hardwares[0];
      } else {
        this.activeHardware = undefined;
      }
    }
  }

  public async updateActiveHardware(slides: CustomEvent) {
    const hardwares = this.getHardwareByCategory(this.activeCategory);
    let slideIndex = await (slides.target as any as IonSlides).getActiveIndex();
    if (slideIndex > hardwares.length) {
      slideIndex = hardwares.length;
    }
    else if (slideIndex < 0) {
      slideIndex = 0;
    }
    if (hardwares.length > slideIndex) {
      this.activeHardware = hardwares[slideIndex];
    }
  }

  public async finish() {
    this.selectedHardware = undefined;
    if (this.getParametersPageService.getValueOfActivePage("category", undefined)) {
      this.navCtrl.pop();
    }
  }

  private async initBluetooth() {
    try {
      await this.medicalBluetoothService.ready();
    } catch (error) {
      this.translateService.get([
        "myBleDevices.pleaseActivateBluetooth",
        "myBleDevices.inactiveBluetooth",
      ]).pipe(takeUntil(this.onDestroy$)).subscribe(async (translations) => {
        const alert = await this.alertCtrl.create({
          buttons: ["Ok"],
          subHeader: translations["myBleDevices.pleaseActivateBluetooth"],
          header: translations["myBleDevices.inactiveBluetooth"],
        });
        alert.present();
      });
      this.selectedHardware = undefined;
      throw error;
    }
  }

  public async bondActiveHardware() {
    if (!this.activeHardware) {
      return;
    }

    this.selectedHardware = this.activeHardware;

    try {
      await this.initBluetooth();
    } catch (error) {
      return;
    }

    let scanServices = [];
    if (this.selectedHardware.meta.availableLoinc && this.selectedHardware.meta.availableLoinc.length > 0) {
      scanServices = [this.selectedHardware.meta.medicalBluetoothService];
    }

    this.deviceFound = false;
    this.bonded = false;
    try {
      const scanner = await this.medicalBluetoothService.scan();
      scanner.pipe(takeUntil(this.onDestroy$)).subscribe(async (device) => {
        if (!this.deviceFound && device.name && device.name.indexOf(this.selectedHardware.reference) > -1) {
          this.deviceFound = true;
          this.medicalBluetoothService.stopScan();

          try {
            if (this.selectedHardware.meta.noBonding === true) {
              this.medicalBluetoothService.addBondedDevices({ ...device, bonded: true, services: scanServices });
            } else {
              await this.medicalBluetoothService.bond({ ...device, services: scanServices }, this.selectedHardware.meta.force);
            }
            this.bonded = true;
            this.cdr.detectChanges();
          } catch (error) {
            this.displayBluetoothError();
            this.selectedHardware = undefined;
            this.deviceFound = false;
          }
        }
      });
    } catch (error) {
      this.displayBluetoothError();
      this.selectedHardware = undefined;
    }
  }

  public async unbondActiveHardware() {
    if (!this.activeHardware) {
      return;
    }

    const bondedDevice = this.medicalBluetoothService.bondedDevices.find((device) => {
      return this.activeHardware.reference && device.name.indexOf(this.activeHardware.reference) > -1;
    });

    if (bondedDevice) {
      try {
        await this.initBluetooth();
        const success = await this.medicalBluetoothService.unbond(bondedDevice);
        if (success) {
          this.cdr.detectChanges();
        }
      } catch (error) {
        //
      }
    }
  }

  private displayBluetoothError() {
    this.translateService.get([
      "myBleDevices.cannotConnect",
      "myBleDevices.errorBluetooth",
    ]).pipe(takeUntil(this.onDestroy$)).subscribe(async (translations) => {
      const alert = await this.alertCtrl.create({
        buttons: ["Ok"],
        subHeader: translations["myBleDevices.cannotConnect"],
        header: translations["myBleDevices.errorBluetooth"],
      });
      alert.present();
    });
  }

  /**
  * Show help page
  */
  public showHelp() {
    this.helpSeen = true;
    this.helpService.showHelp(AppConstants.PAGE_BLUETOOTH, "help.bt");
  }

  /**
   * Display activity long description
   */
  public async onDescriptionDetail(name: string) {
    const kn: IKnowledges[] = await this.bluetoothKnowledgeService.getFreshestData([name]);
    const mainKnowledgeDescription = KnowledgeBase.getMainKnowledge(kn, KNOW_DOC_CATEGORY.DESCRIPTION);
    let medias: IKnowMedia[];

    if (mainKnowledgeDescription) {
      medias = KnowledgeBase.getMediaCurrentLang(mainKnowledgeDescription, KNOW_DOC_TYPE.TEXT, this.configService.getCurrentLanguage());
    }
    if (medias?.length) {
      this.showKnownledgeModal(medias[0]);
    }
    else {
      this.translateService.get([
        "myBleDevices.noDescription"
      ]).pipe(takeUntil(this.onDestroy$)).subscribe((translations) => {
        const descriptionMedia: IKnowMedia = {
          type: 1,
          category: 0,
          label: name,
          language: this.configService.getCurrentLanguage(),
          content: translations["myBleDevices.noDescription"],
        };
        this.showKnownledgeModal(descriptionMedia);
      });
    }
  }

  /**
  * Show help-device page
  */
  private showKnownledgeModal(media: IKnowMedia) {
    const knowledgeBase: IKnowledgeBase = {
      _id: null,
      modified: null,
      entityStatus: null,
      author: null,
      organization: null,
      healthcareservice: null,
      snomedReference: null,
      category: KNOW_CATEGORY.DEVICE,
      documentCategory: KNOW_DOC_CATEGORY.DESCRIPTION,
      criteria: null,
      medias: [media]
    };

    this.modalKnowledge.presentModalKnowledge(knowledgeBase.medias[0]?.label, [knowledgeBase], null);
  }
}
