import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IRule } from 'src/app/models/rule';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';

@Injectable({
  providedIn: 'root'
})
export class RulesService extends BasicSyncService<IRule, IRule[]> {

  constructor(
    protected dataService: DataService,
    private accountService: AccountService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRule[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'rules_',
      entityStoreKey: 'list',
      getUrl: '/rules',
      setUrl: null,
      expirationDays: 10,
      encrypted: false,
    };
  }

  public async *getDataReader(): AsyncGenerator<IRule[], IRule[], IRule[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IRule[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      console.error("RulesService getDataReader()", err);
      yield [];
      return [];
    }
  }
}
