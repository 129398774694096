import { Component, ViewChild } from '@angular/core';
import { IonInput, ModalController, NavParams } from '@ionic/angular';
import { IObservationParam } from 'src/app/models/configuration';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { ObservationDefinitionService } from "src/app/services/globalDataProvider/observation-definition.service";
import * as moment from 'moment';
import { Tools } from 'src/app/helpers/tools-helper';
import { DownloadObservationsService } from 'src/app/services/download-observations.service';
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { IDownloadObservations } from 'src/app/models/downloadObservations';
import { BasePage } from 'src/app/baseClasses/base-page';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-download-observations-modal',
  templateUrl: './download-observations-modal.component.html',
  styleUrls: ['./download-observations-modal.component.scss'],
})


export class DownloadObservationsModalComponent extends BasePage {

  @ViewChild("input") mailInput: IonInput;

  public observations: IObservationParam[];
  public allSelected = false;
  public obsSelected = [];
  public indeterminateState: boolean;
  public toDate: string;
  public fromDate: string;
  public format = 'PDF';
  public sendToMe = false;
  public isSendTo = false;
  public sendTo: string;
  public userMail: string;
  public submitButtonDisabled = false;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    private modalCtrl: ModalController,
    protected navParams: NavParams,
    private obsDefService: ObservationDefinitionService,
    private downLoadObsService: DownloadObservationsService,
    private accountService: AccountService,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
    this.observations = this.navParams.get("observations");
  }

  ionViewDidEnter() {
    super.ionViewDidEnter();
    this.userMail = this.accountService.cachedAccount.mail;
    this.observations.forEach(obs => {
      this.obsSelected.push({
        value: this.obsDefService.getTranslatedName(obs.type),
        isItemChecked: false
      });
    });
    this.toDate = new Date().toISOString();
    this.fromDate = moment(new Date()).add(-3, "months").toDate().toISOString();

    this.downLoadObsService.getFormsData().then(data => {
      if (data) {
        this.initLocalStorageData(data);
      }
    });
  }

  private initLocalStorageData(param: IDownloadObservations) {
    this.format = param.docTypes;

    const loincsArray = param.loincs.split(',');
    this.observations.forEach((obs, i) => {
      const loinc = this.obsDefService.getObservationDefinition(obs.type).loinc;
      this.obsSelected[i].isItemChecked = loincsArray.includes(loinc);
    });
    this.allSelected = (this.obsSelected.findIndex(value => value.isItemChecked === false) === -1);

    const mailsArray = param.mails.split(',');
    if (mailsArray.length === 2) {
      this.isSendTo = true;
      this.sendToMe = true;
      this.sendTo = mailsArray[1];
    } else if (mailsArray.length === 1) {
      if (mailsArray[0] === this.userMail) {
        this.sendToMe = true;
      } else {
        this.isSendTo = true;
        this.sendTo = mailsArray[0];
      }
    }
    const period = moment(param.endDate).diff(moment(param.startDate), 'days');
    this.fromDate = moment(this.toDate).add(-period, 'days').toDate().toISOString();
  }

  handleAllSelected() {
    setTimeout(() => {
      this.obsSelected.forEach(item => {
        item.isItemChecked = this.allSelected;
      });
    });
  }
  verifyEvent() {
    const allItems = this.obsSelected.length;
    let selected = 0;
    this.obsSelected.map(item => {
      if (item.isItemChecked) selected++;
    });
    if (selected > 0 && selected < allItems) {
      // One item is selected among all checkbox elements
      this.indeterminateState = true;
      this.allSelected = false;
    } else if (selected === allItems) {
      // All item selected
      this.allSelected = true;
      this.indeterminateState = false;
    } else {
      // No item is selected
      this.indeterminateState = false;
      this.allSelected = false;
    }
  }

  public handleIsSendToChange() {
    if (this.isSendTo) {
      this.sendTo = null;
    } else {
      setTimeout(() => this.mailInput.setFocus(), 0);
    }
  }

  public handleSentToChange() {
    if (this.sendTo && this.sendTo.length > 0) {
      this.isSendTo = true;
    } else {
      this.isSendTo = false;
    }
  }

  public async submit() {
    this.submitButtonDisabled = true;
    if (!this.obsSelected || (this.obsSelected.findIndex(value => value.isItemChecked === true) === -1)) {
      this.popupService.showAlert("application.title", "exportObservationsData.noData");
      this.submitButtonDisabled = false;
      return;
    }
    if (!this.sendToMe && !this.sendTo) {
      this.popupService.showAlert("application.title", "exportObservationsData.noMail");
      this.submitButtonDisabled = false;
      return;
    }
    if (this.sendTo) {
      if (Tools.isValidEmail(this.sendTo) === false) {
        this.popupService.showAlert("application.title", "error.invalidEmail");
        this.submitButtonDisabled = false;
        return;
      }
    }
    let obsCodes = "";
    this.observations.forEach((obsParam, i) => {
      if (this.obsSelected[i].isItemChecked) {
        const loinc = this.obsDefService.getObservationDefinition(obsParam.type).loinc;
        obsCodes += loinc + ",";
      }
    });
    obsCodes = obsCodes.slice(0, -1); // remove last ","

    let mails = "";
    if (this.sendToMe) {
      mails += this.sendTo ? (this.userMail + ",") : this.userMail;
    }
    if (this.sendTo) {
      mails += this.sendTo;
    }

    const param: IDownloadObservations = {
      loincs: obsCodes,
      startDate: this.fromDate,
      endDate: this.toDate,
      mails: mails,
      docTypes: this.format
    };
    this.downLoadObsService.exportObservations(param).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
      if (res) {
        this.downLoadObsService.setFormsData(param);
        this.popupService.showAlert("application.title", "exportObservationsData.success");
        this.dismiss();
        this.submitButtonDisabled = false;
      } else {
        this.popupService.showAlert("application.title", "exportObservationsData.error");
        this.submitButtonDisabled = false;
      }
    }, err => {
      this.popupService.showAlert("application.title", "exportObservationsData.error");
      this.submitButtonDisabled = false;
    });
  }

  /**
   *  cancel
   */
  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
