import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appointment, IAppointment } from 'src/app/models/appointment';
import { NOTIFICATION_TYPE } from 'src/app/models/notification';
import { IRelatedPerson } from 'src/app/models/relatedPerson';
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { NotificationsGeneratedService } from '../notificationsService/notifications-generated.service';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { RelatedPatientsService } from './related-patients.service';

@Injectable({
  providedIn: 'root'
})
export class RelatedAppointmentsService extends BasicSyncService<IAppointment, IAppointment[]> {

  public lastGenNotif: string = null;

  constructor(
    protected dataService: DataService,
    private notificationGeneratedService: NotificationsGeneratedService,
    private relatedPatientsService: RelatedPatientsService,
    private accountService: AccountService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IAppointment[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }
  
  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'relatedAppointments_',
      entityStoreKey: 'list',
      getUrl: '/relatedAppointments',
      setUrl: '/relatedAppointment',
      expirationDays: 10,
      encrypted: true
    };
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(related: IRelatedPerson = null, includeDeleted: boolean = true): IAppointment[] { 
    return this.processData(super.peekData(), related, includeDeleted); 
  }
  /**
   * Watch the changes in the service's data
   * @return a observable with the service's data
   */
   public watchData(related: IRelatedPerson = null, includeDeleted: boolean = false): Observable<IAppointment[]> {
    return this.data$.pipe(map((appointments) => {
      return this.processData(appointments, related, includeDeleted);
    }));
  }
  /**
    * Get the list of the appointment of related, if the current user is related.
    * If related = null, then this method return all appointment of all related of the current user
    * Note : the route verifies if the user has access to the appointments of these related
    */
  public async *getDataReader(related: IRelatedPerson = null, includeDeleted: boolean = false, forceGenNotif = false)
      : AsyncGenerator<IAppointment[], IAppointment[], IAppointment[]> {
    try {
      if (this.accountService.isNotRelated()) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IAppointment[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, related, includeDeleted);
        yield d;
      }
      this.dealWithNotif(forceGenNotif, d); 
      return d;
    } catch (err) {
      console.error("DrugService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: IAppointment[],
                      related: IRelatedPerson, includeDeleted: boolean) {
    try {
      let appointments = dataResult;
      // Default sort
      appointments = appointments.sort((a, b) => moment(a.start).diff(moment(b.start)));
      if (!includeDeleted) {
        appointments = appointments.filter((appointment) => {
          return appointment.actionStatus !== ACTION_STATUS_ENTITY.DELETED &&
                !appointment.entityStatus.includes(STATUS_ENTITY.DELETED);
        });
      }
      if (related) {
        return this.filterForARelated(appointments, related);
      } 
      return appointments;
    } catch (err) {
      console.error('Error while processing relatedAppointmentService data: ', err);
      return dataResult;
    }
  }

  private dealWithNotif(forceGenNotif: boolean, app: IAppointment[]) {
    if (forceGenNotif || this.needNotifGeneration || !this.lastGenNotif ||
        moment(this.lastGenNotif).add(4, "hours").isBefore(moment())) {
      this.relatedPatientsService.getFreshestData(true).then((relatedPatients) => {
        const data: { app: IAppointment[], related: IRelatedPerson }[] = [];
        relatedPatients.forEach((rel) => {
          const appOfRel = this.filterForARelated(app, rel);
          if (appOfRel.length > 0) {
            data.push({
              app: appOfRel,
              related: rel
            });
          }
        });
        this.notificationGeneratedService.generatedNotifications(data, NOTIFICATION_TYPE.RELATED_APPOINTMENT).then(() => {
          this.lastGenNotif = moment().format();
        });
      });
    }
  }
  /**
   * This will try to get the online data and refresh the service's data.
   * If the online data is not available, it will only return the local.
   */
  public async getFreshestData(related: IRelatedPerson = null, 
                               includeDeleted: boolean = false, 
                               forceGenNotif = false): Promise<IAppointment[]> {
    const dataReader = this.getDataReader(related, includeDeleted, forceGenNotif);
    let iterator = await dataReader.next();
    while (!iterator.done) { iterator = await dataReader.next(); }
    return iterator.value;
  }

  /**
   * This will return the local data or the online data if there's
   * no local data (and the online is available).
   */
  public async getFirstDataAvailable(related: IRelatedPerson = null, 
                                     includeDeleted: boolean = false, 
                                     forceGenNotif = false): Promise<IAppointment[]> {
    const dataReader = this.getDataReader(related, includeDeleted, forceGenNotif);
    const iterator = await dataReader.next();
    return iterator.value;
  }

  public update(appointment: IAppointment, generateNotif: boolean = true): Observable<IAppointment> {
    appointment.modified = moment().format();

    const savePromise = this.dataService.saveInArray<IAppointment>(
      appointment,
      (entity) => entity._id === appointment._id,
      { ...this.defaultDataParameter, method: SYNC_HTTP_METHOD.PUT }
    ).then((app: IAppointment) => {
      if (generateNotif) {
        this.relatedPatientsService.getFreshestData(true).then((relatedPatients) => {
          // search related associated to this appointment
          const rel = relatedPatients.find((_) => Appointment.isParticipant(appointment, _.patient.reference));
          this.notificationGeneratedService.updateOrCreateNotification({ app: appointment, related: rel }, 
            NOTIFICATION_TYPE.RELATED_APPOINTMENT);
        });
      }
      const appointments = this.peekData(null);
      const i = appointments.findIndex(e => e._id === app._id);
      if (i >= 0 && !app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          appointments[i] = app;
      } else if (i >= 0 && app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          appointments.splice(i, 1);
      } else if (i < 0 && !app.entityStatus.includes(STATUS_ENTITY.DELETED)) {
          appointments.push(app);
      }
      this.pokeData(appointments);
      return app;
    });
    return from(savePromise);
  }

  private filterForARelated(appointments: IAppointment[], related: IRelatedPerson): IAppointment[] {
    return appointments.filter((appointment) => {
      return Appointment.isParticipant(appointment, related.patient.reference);
    });
  }

}
