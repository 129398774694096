import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ConfigurationService } from './globalDataProvider/configuration.service';
import { ObservationDefinitionService } from './globalDataProvider/observation-definition.service';
import { ObservationService } from './globalDataProvider/observation.service';
import { Health, HealthData } from '@ionic-native/health/ngx';

import { InfoAppService } from './info-app.service';
import { last } from 'rxjs/operators';
import * as moment from 'moment';
import { IObservation, Observation } from '../helpers/observation-helper';
import { Account } from 'src/app/helpers/account-helper';
import { AppConstants } from '../appConstants';
import { ArrayHelper } from '../helpers/array-helper';
import { Tools } from '../helpers/tools-helper';




// declare var navigator; // allow use of Cordova Plugin for Google Fit / Apple HealthKit (even if in Browser mode without Cordova)
// declare var samsung;  // declare Samsung Cordova Plugin for SHealth  (even if in Browser mode without Cordova)

/**
 * Samsung SHealth data type
 */
/* export class SH_DATATYPE {
    public static get SLEEP(): any { return samsung.shealth.DATA_TYPE_SLEEP; }
    public static get HEART_RATE(): any { return samsung.shealth.DATA_TYPE_HEART_RATE; }
}*/

/**
 *  Google Fit / Apple HealthKit data type
 */
export class GA_DATATYPE {
    public static get STEPS(): string { return "steps"; }
    public static get DISTANCE(): string { return "distance"; }
    public static get HEART_RATE(): string { return "heart_rate"; }
}

export enum DataType {
    STEPS = 'steps',
    DISTANCE = 'distance',
    HEART_RATE = 'heart_rate',
    HEART_RATE_RESTING = 'heart_rate.resting',
    GLUCOSE = 'blood_glucose',
    WEIGHT = 'weight',
    BLOOD_PRESSURE = 'blood_pressure',
    BODY_TEMPERATURE = 'temperature',
    INSULIN = 'insulin',
    ACTIVITY = 'activity'
}

export interface DataAndType {
    datas: HealthData[];
    type: DataType;
}

export interface CustomDataFormat {
    linkedLoinc: string;
    value: number | string;
    date: Date;
    unit: string;
    checked: boolean;
}

/**
*  Google Fit / Apple HealthKit data request format
*/
/*export class GAdata {
  public startDate: Date;
  public endDate: Date;
  public value: any;
  public sourceBundleId: string;
  public unit: string;
}*/

/**
*
*/
export class HealthInit {
    GAstatus = false;
    GAerror: any;
    SHstatus = false;
    SHerror: any;
}

/**
* Service Helper to communicate with external Health app
*  Currently connect to Samsung SHealth (Android), Google Fit(Android) or HealthKit (IOS)
*
* GoogleFit/HealthKit:   https://github.com/dariosalvi78/cordova-plugin-health
* Samsung SHealth:
*           http://developer.samsung.com/health
* https://seap.samsung.com/sdk/cordova-plugins
*  https://seap.samsung.com/api-references/cordova-plugins/Cordova-Plugin-API/Content/Cordova-Plugin-API-Ref/Release-v1.3/S%20Health/SHealth-Cordova%20Plugin.htm
*
*/

@Injectable({
    providedIn: 'root'
})
export class HealthAppService {
    public arrGADatatypes: DataType[] = [];
    // request permission for SHealth App
    private readonly shealthPermissionRequest: {} = {
        isHeartRateReadPermissionRequired: true,
        isUvExposureReadPermissionRequired: false,
        isSleepReadPermissionRequired: true
    };

    // effective permission acquired
    private SH_permissionRequestStatus: {};
    public availableLoinc: string[] = [];
    public haveActivityObs = false;


    /**
     * constructor
     */
    constructor(
        protected platform: Platform,
        private health: Health,
        private configurationService: ConfigurationService,
        private infoAppService: InfoAppService,
        private obsService: ObservationService,
        private obsDefService: ObservationDefinitionService,
    ) {
        // super(platform);
        this.initRequests();
    }

    public async prepareAllDatasFromLoinc() {
        try {
            this.initRequests();
            const isInit = await this.initializeGA();
            const datas: DataAndType[] = [];
            if (isInit) {
                const allObs = await this.obsService.getFreshestData();
                for (const dt of this.arrGADatatypes) {
                    const lastObsSyncDate = this.findLastObsDateByLoinc(allObs, this.mapDataTypesToMasterLoinc(dt));
                    const fromDate = this.computeFromDate(lastObsSyncDate, dt);
                    const toDate = (dt === DataType.STEPS || dt === DataType.DISTANCE) ? moment().startOf("day").toDate() : moment().toDate();
                    const healthData = await this.query(dt, fromDate, toDate).catch(err => {
                        console.error(`err query ${dt} : ${err}`);
                        return [] as HealthData[];
                    });
                    if (Tools.isDefined(healthData) && Array.isArray(healthData) && healthData.length > 0) {
                        datas.push({
                            datas: healthData,
                            type: dt
                        });
                    }
                }
            }
            return datas;
        } catch (error) {
            return [] as DataAndType[];
        }
    }

    /**
     * 
     * @param lastObsSyncDate last known date in DB (maybe null)
     * @param dataType type of observation
     * @returns the date from which to synchronise with the health application. To avoid too much data recovery 
     * (which makes the application crash), we go back at most 7 days
     */
    public computeFromDate(lastObsSyncDate: moment.Moment | null, dataType: DataType): Date {
        const oneWeekBack = moment().add(-7, "day").toDate();
        if (!lastObsSyncDate) {
            return oneWeekBack;
        } else {
            const fromDate = dataType === DataType.STEPS || dataType === DataType.DISTANCE ? lastObsSyncDate.add(1, 'day').toDate() : lastObsSyncDate.add(1, 'hour').toDate();
            return oneWeekBack < fromDate ? fromDate : oneWeekBack;
        }
    }

    public findLastObsDateByLoinc(allObs: IObservation[], loinc: string): moment.Moment | null {
        const lastDate = allObs.sort((a: IObservation, b: IObservation) => moment(a.issued).isBefore(moment(b.issued)) ? 1 : -1)
            .find(o => this.ignoreSuffix(o.code.coding[0].code) === loinc)?.issued;
        return lastDate ? moment(lastDate) : null;
    }

    public initRequests() {
        this.availableLoinc = this.configurationService.getLoincFromConfiguration();
        this.haveActivityObs = this.availableLoinc?.indexOf(Account.LOINC_ACTIVITY) > -1;
        this.arrGADatatypes = this.mapLoincsToDataTypes(this.availableLoinc);
    }
    /**
     * initialize and request authorization for GoogleFit / Apple HealthKit
     */
    public async initializeGA(): Promise<boolean> {
        try {
            const available = await this.health.isAvailable();
            if (available) {
                return await this.requestGA();
            }
            return false;

        } catch (error) {
            return false;
        }
    }

    private async requestGA() {
        try {
            const res = await this.health.requestAuthorization([
                {
                    // read: ['steps', 'distance', "height", "weight", "heart_rate", "blood_pressure"],       //read only permission
                    read: this.mapLoincsToDataTypes(this.configurationService.getLoincFromConfiguration()), // read only permission
                }
            ]);
            console.info("GA request authorization success", res);
            return true;
        } catch (err) {
            if (err !== AppConstants.GOOGLE_API_NOT_READY) {
                this.infoAppService.setParamHealth(false);
            }
            console.error("GA request authorization failed:", err);
            return false;
        }
    }


    /**
     *  Gets aggregated data in a certain time window. Usually the sum is returned for the given quantity
     */
    public getDataAggregatedGA(datatype: string, startDate: Date, endDate: Date): Promise<HealthData[]> {
        console.info("GA getGADataAggregated", datatype, startDate, endDate);
        return this.health.queryAggregated({
            "startDate": startDate,
            "endDate": endDate,
            "dataType": datatype,
            bucket: 'day'
        })
            .catch((err) => {
                console.error("GA queryAggregated Error:", err);
                return null as HealthData[];
            });
    }

    private mapLoincsToDataTypes(loincs: string[]): DataType[] {
        loincs = loincs?.map(l => this.ignoreSuffix(l));
        // base for every body
        const result: DataType[] = [DataType.DISTANCE, DataType.STEPS];
        loincs?.forEach(l => {
            switch (l) {
                case Account.LOINC_GLUCOSE:
                    if (this.platform.is('ios')) {
                        result.push(DataType.GLUCOSE);
                        result.push(DataType.INSULIN);
                    }
                    break;
                case Account.LOINC_WEIGHT:
                    if (this.platform.is('ios')) {
                        result.push(DataType.WEIGHT);
                    }
                    break;
                case Account.LOINC_BLOOD_PRESSURE_GENERAL:
                    if (this.platform.is('ios')) {
                        result.push(DataType.BLOOD_PRESSURE);
                    }
                    break;
                case Account.LOINC_TEMPERATURE:
                    if (this.platform.is('ios')) {
                        result.push(DataType.BODY_TEMPERATURE);
                    }
                    break;
                case Account.LOINC_HEARTRATE:
                    if (this.platform.is('ios')) {
                        result.push(DataType.HEART_RATE_RESTING);
                    }/*else {
                        result.push(DataType.HEART_RATE);
                    }*/
                    break;
                default:
                    break;
            }
        });
        // clean duplicate
        return result.filter((el, i, self) => {
            return i === self.indexOf(el);
        });
    }

    private mapDataTypesToLoinc(dt: string): string {
        switch (dt) {
            case DataType.GLUCOSE:
                return Account.LOINC_GLUCOSE;
            case DataType.INSULIN:
                return Account.LOINC_INSULIN;
            case DataType.DISTANCE:
                return Account.LOINC_WALK_DISTANCE;
            case DataType.STEPS:
                return Account.LOINC_STEPS;
            case DataType.BODY_TEMPERATURE:
                return Account.LOINC_TEMPERATURE;
            case DataType.WEIGHT:
                return Account.LOINC_WEIGHT;
            case DataType.HEART_RATE:
                return Account.LOINC_HEARTRATE;
            case DataType.BLOOD_PRESSURE:
                return Account.LOINC_BLOOD_PRESSURE_GENERAL;
        }
    }

    private mapDataTypesToMasterLoinc(dt: string): string {
        switch (dt) {
            case DataType.GLUCOSE:
            case DataType.INSULIN:
                return Account.LOINC_GLUCOSE;
            case DataType.DISTANCE:
            case DataType.STEPS:
                return Account.LOINC_ACTIVITY;
            case DataType.BODY_TEMPERATURE:
                return Account.LOINC_TEMPERATURE;
            case DataType.WEIGHT:
                return Account.LOINC_WEIGHT;
            case DataType.HEART_RATE:
                return Account.LOINC_HEARTRATE;
            case DataType.BLOOD_PRESSURE:
                return Account.LOINC_BLOOD_PRESSURE_GENERAL;
        }
    }

    /*
    *   for exemple 86480-1-cardio became 86480-1
    */
    private ignoreSuffix(loinc: string): string {
        const arr = loinc.split('-');
        return `${arr[0]}-${arr[1]}`;
    }

    public query(datatype: string, startDate: Date, endDate: Date) {
        return this.health.query({
            startDate: startDate,
            endDate: endDate,
            dataType: datatype,
            limit: 10000,
            filtered: this.infoAppService.isAndroid() ? true : false
        }).catch(err => {
            console.error(err);
            return [] as HealthData[];
        });
    }

    public prepareToCustomFormat(value: DataAndType, needParseValue: boolean = false, coeff: number = 1): CustomDataFormat[] {

        if (Tools.isNotDefined(value)) {
            return [];
        }

        switch (value?.type) {
            case DataType.STEPS:
            // fall through
            case DataType.DISTANCE:
                // agregate by date
                return this.agregateByDate(value);
                break;

            // cases that need 2 digits after comma :
            case DataType.GLUCOSE:
            // fall through
            case DataType.WEIGHT:
            // fall through
            case DataType.BODY_TEMPERATURE:
                return value?.datas?.map(v => {
                    const valueParsed = needParseValue ? this.parseValue(v.value, value.type) : v.value;
                    return {
                        linkedLoinc: this.mapDataTypesToLoinc(value.type),
                        value: ((+valueParsed) * coeff).toFixed(2),
                        date: v.endDate,
                        unit: v.unit,
                        checked: true
                    };
                });
                break;

            case DataType.BLOOD_PRESSURE:
                return value?.datas?.map(v => {
                    const dia = Math.round((v.value as any).diastolic);
                    const sys = Math.round((v.value as any).systolic);

                    return {
                        linkedLoinc: this.mapDataTypesToLoinc(value.type),
                        value: `${sys}/${dia}`,
                        date: v.endDate,
                        unit: v.unit,
                        checked: true
                    };
                });
                break;

            // default : 0 digit after comma :
            default:
                return value?.datas?.map(v => {
                    const valueParsed = needParseValue ? this.parseValue(v.value, value.type) : v.value;
                    return {
                        linkedLoinc: this.mapDataTypesToLoinc(value.type),
                        value: ((+valueParsed) * coeff).toFixed(0),
                        date: v.endDate,
                        unit: v.unit,
                        checked: true
                    };
                });
                break;
        }
    }

    private agregateByDate(value: DataAndType): CustomDataFormat[] {
        const finalCustomDataFormat: CustomDataFormat[] = [];
        // ignore hours and remove duplicates
        const allDates = value.datas.map(v => moment(v.endDate).format("DD-MM-YYYY"))?.filter((el, i, self) => {
            return i === self.indexOf(el);
        });
        // creation of a new object to get datas by date
        const groupByDate = allDates.map(v => {
            return { dateString: v, datas: [] as HealthData[] };
        });
        // for all data, add it in custom object by date
        value?.datas?.forEach(d => {
            const dateOfvalue = moment(d.endDate, "DD-MM-YYYY");
            groupByDate.find(g => moment(g.dateString, "DD-MM-YYYY").isSame(dateOfvalue, 'day'))?.datas?.push(d);
        });
        // for each group recreate the CustomDataFormat with the addition of all value of the day 
        groupByDate.forEach(group => {
            let groupSum = 0;
            group?.datas?.forEach(v => {
                groupSum += +v.value;
            });
            finalCustomDataFormat.push({
                linkedLoinc: this.mapDataTypesToLoinc(value.type),
                value: value.type === DataType.DISTANCE ? groupSum.toFixed(2) : groupSum.toFixed(0),
                unit: 'count',
                checked: true,
                date: moment(group.dateString, "DD-MM-YYYY").toDate()
            });
        });
        return finalCustomDataFormat.reverse();
    }

    private parseValue(value: any, type: string) {
        switch (type) {
            case DataType.INSULIN:
                return value.insulin;
            case DataType.GLUCOSE:
                return value.glucose;
            default:
                return null;
        }
    }
    public prepareActivityObs(stepsData: CustomDataFormat[], distanceDatas: CustomDataFormat[]): IObservation[] {
        const template = this.obsDefService.getObservationDefinition(Account.LOINC_ACTIVITY);
        if (!template || !stepsData?.length || !distanceDatas?.length) {
            return [];
        }
        const arrObservations = [];
        if (stepsData?.length > distanceDatas?.length) {
            stepsData.forEach(d => {
                const obs = Observation.createObservation(template);
                obs.issued = d.date.toISOString();
                const stepComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_STEPS);
                const distanceComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_WALK_DISTANCE);
                stepComponent.valueQuantity.value = +d.value;
                distanceComponent.valueQuantity.value = +distanceDatas.find(dd => moment(dd.date).isSame(moment(d.date), 'day'))?.value || null;
                arrObservations.push(obs);
            });
        } else {
            distanceDatas?.forEach(d => {
                const obs = Observation.createObservation(template);
                obs.issued = d.date.toISOString();
                const stepComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_STEPS);
                const distanceComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_WALK_DISTANCE);
                distanceComponent.valueQuantity.value = +d.value;
                stepComponent.valueQuantity.value = +stepsData.find(dd => moment(dd.date).isSame(moment(d.date), 'day'))?.value || null;
                arrObservations.push(obs);
            });
        }
        return arrObservations;
    }

    public prepareGlucoseObs(glucoseDatas: CustomDataFormat[], insulinDatas: CustomDataFormat[]): IObservation[] {
        const template = this.obsDefService.getObservationDefinition(Account.LOINC_GLUCOSE);
        if (!template || !glucoseDatas?.length || !insulinDatas?.length) {
            return [];
        }
        const arrObservations = [];
        if (glucoseDatas?.length > insulinDatas?.length) {
            glucoseDatas.forEach(d => {
                const obs = Observation.createObservation(template);
                obs.issued = d.date.toISOString();
                const gluComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_GLUCOSE);
                const insuComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_INSULIN);
                gluComponent.valueQuantity.value = +d.value;
                insuComponent.valueQuantity.value = +insulinDatas.find(dd => moment(dd.date).isSame(moment(d.date), 'day'))?.value || null;
                arrObservations.push(obs);
            });
        } else {
            insulinDatas?.forEach(d => {
                const obs = Observation.createObservation(template);
                obs.issued = d.date.toISOString();
                const gluComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_GLUCOSE);
                const insuComponent = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_INSULIN);
                insuComponent.valueQuantity.value = +d.value;
                gluComponent.valueQuantity.value = +glucoseDatas.find(dd => moment(dd.date).isSame(moment(d.date), 'day'))?.value || null;
                arrObservations.push(obs);
            });
        }
        return arrObservations;
    }

    public prepareSimpleObs(datas: CustomDataFormat[]): IObservation[] {
        const template = this.obsDefService.getObservationDefinition(datas?.[0]?.linkedLoinc);
        if (!template || !datas?.length) {
            return [];
        }
        const arrObservations = [];
        datas.forEach(d => {
            const obs = Observation.createObservation(template);
            if (obs) {
                obs.issued = d.date.toISOString();
                const component = obs.component.find(c => c.code.coding[0]?.code === d.linkedLoinc);
                component.valueQuantity.value = +d.value;
                arrObservations.push(obs);
            }
        });
        return arrObservations;
    }

    public prepareBpObs(datas: CustomDataFormat[]): IObservation[] {
        const template = this.obsDefService.getObservationDefinition(Account.LOINC_BLOOD_PRESSURE_GENERAL);
        const arrObservations = [];
        if (!template || !datas?.length) {
            return [];
        }

        datas.forEach(d => {
            const obs = Observation.createObservation(template);
            if (obs) {
                obs.issued = d.date.toISOString();

                // delete heart beat component if the value does not exist
                const componentHeartRate = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_HEARTRATE);

                if (componentHeartRate && !componentHeartRate.valueQuantity.value) {
                    const index: number = obs.component.findIndex(c => c.code.coding[0]?.code === Account.LOINC_HEARTRATE);
                    obs.component.splice(index, 1);
                }

                const componentSys = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_SYSTOLIC);
                const componentDia = obs.component.find(c => c.code.coding[0]?.code === Account.LOINC_DIASTOLIC);

                componentSys.valueQuantity.value = +(d.value.toString().split('/')[0]);
                componentDia.valueQuantity.value = +(d.value.toString().split('/')[1]);

                arrObservations.push(obs);
            }
        });
        return arrObservations;
    }

    public computeNbrOfDatasToSend(datas: DataAndType[]): number {
        if (Tools.isNotDefined(datas) || !Array.isArray(datas)) {
            return 0;
        }
        const stepsDatas = this.haveActivityObs ? this.prepareToCustomFormat(datas.find(v => v.type === DataType.STEPS)) : [];
        const distanceDatas = this.haveActivityObs ? this.prepareToCustomFormat(datas.find(v => v.type === DataType.DISTANCE)) : [];
        const insulinDatas = this.prepareToCustomFormat(datas.find(v => v.type === DataType.INSULIN), true);
        const glucoseDatas = this.prepareToCustomFormat(datas.find(v => v.type === DataType.GLUCOSE), true, 18.01559);
        const hrDatas = this.prepareToCustomFormat(datas.find(v => v.type === DataType.HEART_RATE));
        const weightDatas = this.prepareToCustomFormat(datas.find(v => v.type === DataType.WEIGHT));
        const temperatureDatas = this.prepareToCustomFormat(datas.find(v => v.type === DataType.BODY_TEMPERATURE));
        const bpDatas = this.prepareToCustomFormat(datas.find(v => v.type === DataType.BLOOD_PRESSURE));

        return ArrayHelper.sumArrayLength(
            stepsDatas ? stepsDatas : [],
            distanceDatas ? distanceDatas : [],
            insulinDatas ? insulinDatas : [],
            glucoseDatas ? glucoseDatas : [],
            hrDatas ? hrDatas : [],
            weightDatas ? weightDatas : [],
            temperatureDatas ? temperatureDatas : [],
            bpDatas ? bpDatas : []
        );
    }

    /**
    * initialize samsung plugin
    */
    /*public initializeSH(): Promise<boolean> {
        //var self = this;
        console.info("SH initialization...");
        return new Promise((resolve, reject) => {
            //return Observable.create(observer => {
            try {
                if (typeof samsung === "undefined") { // not on mobile environment (browser dev mode)
                    reject("SH initializeSH not on mobile environment");
                    return;
                }
                if (!this.isAndroid()) {
                    reject("SH initializeSH not on mobile Android");
                    return;
                }
                // Request permissions callbacks
                let requestPermissionSuccessFn = (data) => {
                    console.info("SH requestPermissionSuccess");
                    this.SH_permissionRequestStatus = data;
                    resolve(true);
                };

                let requestPermissionFailedFn = (err) => {
                    console.error("SH requestPermissionFailed", err);
                    reject(err);
                };

                // Is permission acquired callbacks
                let isPermissionSuccessFn = (data) => {
                    console.info("SH isPermissionSuccess");
                    // check granted permissions
                    if (this.hasRequiredPermissions(data)) {
                        resolve(true);
                    }
                    else {
                        samsung.shealth.requestPermissions(this.shealthPermissionRequest, requestPermissionSuccessFn, requestPermissionFailedFn);
                    }
                };
                let isPermissionFailedFn = (err) => {
                    console.error("SH isPermissionFailed", err);
                    samsung.shealth.requestPermissions(this.shealthPermissionRequest, requestPermissionSuccessFn, requestPermissionFailedFn);
                };


                // connect data service callbacks
                let connectSuccessFn = (msg) => {
                    console.info("SH connectSuccess");
                    samsung.shealth.isPermissionAcquired(this.shealthPermissionRequest, isPermissionSuccessFn, isPermissionFailedFn);
                };
                let connectFailedFn = (err) => {
                    console.error("SH connectFailed", err);
                    reject(err);
                };
                // initialize data service callbacks
                let initSuccessFn = (msg) => {
                    console.info("SH initSuccess");
                    samsung.shealth.connectHealthDataStore(connectSuccessFn, connectFailedFn);
                };

                let initFailedFn = (err) => {
                    console.error("SH initFailed", err);
                    reject(err);
                };
                samsung.shealth.initializeHealthDataService(initSuccessFn, initFailedFn);
            }
            catch (ex) {
                console.error("Cannot initialize SH", ex);
                reject(ex);
            }
        });
    }*/

    /**
     * check if permission for samsung plugin is required
     */
    /*private hasRequiredPermissions(data): boolean {
        try {
            return (data.hasSleepReadPermission && data.hasHeartRateReadPermission);
        }
        catch (ex) {
            console.error("hasRequiredPermissions failed", ex);
            return false;
        }
    }*/

    /**
     * get samsung health data
     */
    /*public getDataSH(datatype: SH_DATATYPE): Observable<any> {
        return Observable.create(observer => {
            try {
                samsung.shealth.getCurrentEntry(datatype,
                    (data) => {
                        console.log("SH getCurrentEntry:", datatype, data);
                        observer.next(data);
                        observer.complete();
                    },
                    (err) => {
                        // not really an error
                        if (err.message === "No data found") {
                            console.log("SH getCurrentEntry No data found:", err);
                            observer.next(null);
                            observer.complete();
                        }
                        else {
                            console.log("SH getCurrentEntry Failed:", err);
                            observer.error(err);
                        }
                    });
            }
            catch (ex) {
                console.error("SH getCurrentEntry failed:", ex);
                observer.error(ex);
            }
        });
    }*/

    /**
 * Gets all the records of a certain data type within a certain time window
 */
    /*public getDataGA(datatype: GA_DATATYPE, startDate: Date, endDate: Date): Observable<Array<GAdata>> {
        console.info("getGAData:" + startDate + "," + endDate);
        return Observable.create(observer => {
            try {
                let querySuccessFn = function (data) {
                    //console.log("GA QUERY DATA:" + JSON.stringify(data));
                    observer.next(data);
                    observer.complete();
                };

                let queryFailedFn = function (err) {
                    console.error("GA query failed:" + err);
                    observer.error(err);
                };

                this.health.query({
                    startDate: startDate,
                    endDate: endDate,
                    dataType: datatype,
                    filtered: true
                }, querySuccessFn, queryFailedFn);
            }
            catch (ex) {
                console.error("GA query failed:" + JSON.stringify(ex));
                observer.error(ex);
            }
        });
    }*/
}

