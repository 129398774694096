import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { from, Observable } from 'rxjs';
import { AboutModalComponent } from '../components/modals/about-modal/about-modal.component';
import { AccountModalComponent } from '../components/modals/account-modal/account-modal.component';
import { AppoitmentSummaryModalComponent } from '../components/modals/appoitment-summary-modal/appoitment-summary-modal.component';
import { DrugModalComponent } from '../components/modals/drug-modal/drug-modal.component';
import { FingerprintLoginPwdModalComponent } from '../components/modals/fingerprint-login-pwd-modal/fingerprint-login-pwd-modal.component';
import { HelpModalComponent } from '../components/modals/help-modal/help-modal.component';
import { MessageModalComponent } from '../components/modals/message-modal/message-modal.component';
import { NoteModalComponent } from '../components/modals/note-modal/note-modal.component';
import { ResetPasswordModalComponent } from '../components/modals/reset-password-modal/reset-password-modal.component';
import { SettingsMenuPageModalComponent } from '../components/modals/settings-menu-page-modal/settings-menu-page-modal.component';
import { ShareModalComponent } from '../components/modals/share-modal/share-modal.component';
import { TwoFAModalComponent } from '../components/modals/two-famodal/two-famodal.component';
import { EntourageAddModalComponent } from '../components/modals/entourage-add-modal/entourage-add-modal.component';
import { IAccount } from '../helpers/account-helper';
import { Tools } from '../helpers/tools-helper';
import { IAppointment } from '../models/appointment';
import { ICommunication } from '../models/communication';
import { EntityDrug, Entitylink, IEntitylink, PARENT_TYPE } from '../models/entitylink';
import { KeyValue } from '../models/keyValue';
import { ACTION_STATUS_ENTITY } from '../models/sharedInterfaces';
import { AccountService } from './globalDataProvider/account.service';
import { DrugService } from './globalDataProvider/drug.service';
import { RewardHistoryComponent } from '../components/modals/reward-history/reward-history.component';
import { IQuestionnaire } from '../models/questionnaire';
import { QuestionnaireModalComponent } from '../components/modals/questionnaire-modal/questionnaire-modal.component';
import { QuestionnaireResponse } from '../helpers/questionnaireResponse';
import { RewardHelpComponent } from '../components/modals/reward-help/reward-help.component';
import { ConfigurationService } from './globalDataProvider/configuration.service';
import { EntouragePersonAccessModalComponent } from '../components/modals/entourage-person-access-modal/entourage-person-access-modal.component';
import { IRelatedPerson } from '../models/relatedPerson';
import { PopupService } from './popup.service';
import { MyProfilModalComponent } from '../components/modals/my-profil-modal/my-profil-modal.component';
import { DownloadObservationsModalComponent } from '../components/modals/download-observations-modal/download-observations-modal.component';
import { IObservationParam } from '../models/configuration';
import { DisplayPhotoModalComponent } from '../components/modals/display-photo-modal/display-photo-modal.component';
import { LoaderService } from './loader.service';
import { InfoAppService } from './info-app.service';


@Injectable({
  providedIn: 'root'
})
export class ModalService {


  constructor(
    private modalCtrl: ModalController,
    private accountService: AccountService,
    private drugService: DrugService,
    private configService: ConfigurationService,
    private popupService: PopupService,
    private loaderService: LoaderService,
    private infoService: InfoAppService
  ) {
    moment.locale(this.configService.getCurrentLanguage());
  }

  /**
   *  display view to create a new note and store it
   */
  public createNote(parentId: string, parentType: PARENT_TYPE): Promise<IEntitylink> {
    const caremateId = this.accountService.cachedCaremateId;
    const note = Entitylink.createNote(caremateId, parentId, parentType);
    return this.presentModalNote(note);
  }

  /**
   * display view to edit a note and store modification
   */
  public editNote(note: IEntitylink): Promise<IEntitylink> {
    const copyNote = Tools.deepCopy(note);  // make a copy in case of rollback
    return this.presentModalNote(copyNote);
  }

  /**
   * display a note in a modal view
   */
  private presentModalNote(note: IEntitylink): Promise<IEntitylink> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: NoteModalComponent,
        componentProps: {
          "note": note
        }
      });
      modal.onDidDismiss().then(
        (data) => {
          resolve(data.data as IEntitylink);
        }
      );
      await modal.present();
    });
  }

  /**
     * display 2fa prompt in a modal view
     */
  public presentModal2FA(): Observable<string> {
    return from(new Promise<string>(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: TwoFAModalComponent
      });
      modal.onDidDismiss().then(data => {
        resolve(data.data as string);
      });
      await modal.present();
    })
    );
  }

  /**
    * display Account update in a modal view
    * Notice : this.accountService.cachedAccount; must be existed for the modal work fine !!
    */
  public presentModalAccount(newPasswordRequired: boolean): Observable<IAccount> {
    return from(new Promise<IAccount>(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: AccountModalComponent,
        componentProps: {
          "newPasswordRequired": newPasswordRequired
        },
        showBackdrop: false,
        backdropDismiss: !newPasswordRequired
      });
      modal.onDidDismiss().then((data) => {
        resolve(data.data as IAccount);
      });
      await modal.present();
    })
    );
  }

  /**
   * Display a popup for creating a new drug, then store the new drug
   * @param parentId (for example the cached caremate id)
   * @param parentType the type of parent
   */
  public async createDrug(parentId: string, parentType: PARENT_TYPE): Promise<IEntitylink> {
    const drug = Entitylink.createDrug(this.accountService.cachedAccount.caremateIdentifier, parentId, parentType);
    try {
      const resultDrug = await this.presentModalDrug(drug, false);
      if (resultDrug) {
        const entityDrug: EntityDrug = resultDrug.entityData;
        if (entityDrug.cycle) {
          entityDrug.cycle.startDate = new Date(entityDrug.frequency.boundsPeriod.start);
        }
        await this.drugService.save(resultDrug);
        return resultDrug;
      } else { // simple cancel, nothing to do
        return null;
      }
    } catch (err) {
      console.error('Error while creating drug', err);
      await this.loaderService.showSavingToast(false);
      return null;
    }
  }

  /**
   * Display a popup for editing (or deleting) a Drug and store modification
   * @param drug the drug to edit
   * @param isRelated whether its a related person that wants to edit the drug or not
   */
  public async editDrug(drug: IEntitylink, isRelated: boolean): Promise<IEntitylink> {
    const copyDrug: IEntitylink = Tools.deepCopy(drug);  // make a copy  in case of rollback
    try {
      const resultDrug = await this.presentModalDrug(copyDrug, isRelated);
      if (resultDrug && resultDrug.actionStatus === ACTION_STATUS_ENTITY.MODIFIED) {
        const entityDrug: EntityDrug = resultDrug.entityData;
        if (entityDrug.cycle) {
          entityDrug.cycle.startDate = new Date(entityDrug.frequency.boundsPeriod.start);
        }
        await this.drugService.save(resultDrug);
        return resultDrug;
      } else if (resultDrug && resultDrug.actionStatus === ACTION_STATUS_ENTITY.DELETED) {
        await this.drugService.delete(resultDrug);
        return resultDrug;
      }
      return null;
    } catch (err) {
      console.error('Error while editing drug', err);
      await this.loaderService.showSavingToast(false);
      return null;
    }
  }

  /**
   * display a drug in a modal view
   */
  private presentModalDrug(drug: IEntitylink, isRelated: boolean): Promise<IEntitylink> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: DrugModalComponent,
        componentProps: {
          "drug": drug,
          "isRelated": isRelated
        }
      });
      modal.onDidDismiss().then(
        (data) => {
          resolve(data.data as IEntitylink);
        }
      );
      await modal.present();
    });
  }

  /**
     * display a knowledge in a modal view
     */
  public presentModalMessage(msg: ICommunication): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: MessageModalComponent,
        componentProps: { msg }
      });
      modal.onDidDismiss().then(
        (data) => { resolve(data.data as boolean); });
      await modal.present();
    });
  }

  /**
   * display settings menu
   * @returns Returns a promise that resolves when the modal will dismiss.
   */
  public async presentModalSettingsMenu(): Promise<any> {
    const modal = await this.modalCtrl.create({
      component: SettingsMenuPageModalComponent
    });
    await modal.present();
    return modal.onWillDismiss(); // IMPORTANT FOR THE TRANSLATION OF HOMEPAGE
  }


  /**
   * display about view
   */
  public async presentModalAbout(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: AboutModalComponent
    });
    return modal.present();
  }

  /**
   * display about view
   */
  public async presentModalHelp(isFirstLaunch: boolean = false): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: HelpModalComponent
    });
    if (isFirstLaunch) {
      modal.onDidDismiss().then(() => {
        if (this.configService.getCacheConfiguration().settings.globalSettings.askVitalSign) {
          this.popupService.showYesOrLater("application.refValuePopupTitle", "application.refValuePopupText").then(async res => {
            if (res) {
              const myProfileModal = await this.modalCtrl.create({
                component: MyProfilModalComponent,
                componentProps: { profileTab: 'datas' }
              });
              myProfileModal.present();

            }
          });
        }
      });
    }
    return modal.present();
  }

  /**
   * display share view
   */
  public async presentModalShare(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ShareModalComponent
    });
    return modal.present();
  }

  /**
   * display share view
   */
  public async presentModalRewardHistory(componentProps: any): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: RewardHistoryComponent,
      componentProps
    });
    return modal.present();
  }

  /**
   * display share view
   */
  public async presentModalRewardHelp(): Promise<void> {
    const modal = await this.modalCtrl.create({ component: RewardHelpComponent });
    return modal.present();
  }

  /**
   * display Reset Password view
   */
  public async presentModalResetPassword(user: string, lang: string): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ResetPasswordModalComponent,
      componentProps: {
        "user": user,
        "lang": lang
      }
    });
    return modal.present();
  }

  /**
   * display a list of appointments
   * return selected appointment or null
   */
  public async presentModalAppointmentSummary(accountId: string, appointments: IAppointment[]): Promise<KeyValue> {
    const modal = await this.modalCtrl.create({
      component: AppoitmentSummaryModalComponent,
      componentProps: {
        "accountId": accountId,
        "appointments": appointments
      },
      showBackdrop: false,
      backdropDismiss: false
    });
    modal.present();
    return modal.onDidDismiss().then(
      (data) => { return (data.data as KeyValue); });
  }

  /**
   * display to ask login pwd associated to fingerprint : first ask or modify
   */
  public async presentModalLoginPwdForFingerprint(): Promise<void> {

    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: FingerprintLoginPwdModalComponent,
        showBackdrop: false,
        backdropDismiss: false
      });

      modal.onDidDismiss().then(() => {
        resolve();
      },
        (err) => {
          resolve();
        });

      await modal.present();

    });
  }

  /**
   * display Add Entourage modal view
   */
  public async presentModalEntourageAdd(): Promise<IRelatedPerson> {

    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: EntourageAddModalComponent,
      });

      modal.onDidDismiss().then((data) => {
        resolve(data.data as IRelatedPerson);
      });
      await modal.present();
    });
  }

  /**
   * display Add Person access details
   */
  public async presentModalEntouragePersonAccess(relatedPerson: IRelatedPerson): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: EntouragePersonAccessModalComponent,
        componentProps: {
          "relatedPerson": relatedPerson
        }
      });
      modal.onDidDismiss().then(() => {
        resolve();
      }, (err) => {
        resolve();
      });
      await modal.present();

    });
  }

  /**
   * @param action "create" or "view" mode
   * @param questionnaire the chosen questionnaire
   * @param questionnaireResponse only when viewing already answered questionnaire
   * @description display a questionnaire in a modal view
   */
  public presentModalQuestionnaire(action: string, questionnaire: IQuestionnaire, questionnaireResponse?: QuestionnaireResponse): Promise<IQuestionnaire> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: QuestionnaireModalComponent,
        componentProps: {
          "questionnaire": questionnaire,
          "questionnaireResponse": questionnaireResponse,
          "action": action
        }
      });
      modal.onDidDismiss().then(
        (data) => {
          resolve(data.data as IQuestionnaire);
        }
      );
      await modal.present();
    });
  }

  /**
 *
 */
  public async presentModalDownloadObservations(observations: IObservationParam[]): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: DownloadObservationsModalComponent,
        componentProps: {
          "observations": observations
        }
      });
      modal.onDidDismiss().then(() => {
        resolve();
      }, (err) => {
        resolve();
      });
      await modal.present();

    });
  }

  /**
 * display an image
 * @param imageUrl
 */
  public async presentModalDisplayPhoto(imageUrl: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalCtrl.create({
        component: DisplayPhotoModalComponent,
        componentProps: {
          "imageUrl": imageUrl
        }
      });
      modal.onDidDismiss().then((data) => {
        resolve(data.data as boolean);
      }, (err) => {
        resolve(false);
      });
      await modal.present();
    });
  }
}
