import { Component, NgZone } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { ModalController } from '@ionic/angular';
import { IConfiguration } from 'src/app/models/configuration';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import * as moment from 'moment';
import { SysAccountService } from 'src/app/services/sys-account.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { CreditsHelper, ICreditAttribution } from 'src/app/models/creditsHelper';
import { IConsent } from 'src/app/helpers/account-helper';
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { ConsentModalComponent } from '../consent-modal/consent-modal.component';
import { Tools } from 'src/app/helpers/tools-helper';
import { ConnectionStatus, NetworkService } from 'src/app/services/network.service';
import { BasePage } from 'src/app/baseClasses/base-page';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-about-modal',
    templateUrl: './about-modal.component.html',
    styleUrls: ['./about-modal.component.scss'],
})
export class AboutModalComponent extends BasePage {
    public configuration: IConfiguration;
    public version: string;
    public lastSynchroDate: string;
    public lastNotifDate: string;
    public appMode: string;
    public iabActive = false;
    public isIOS: boolean;
    public consents: IConsent[];
    public credits: ICreditAttribution[];

    constructor(
        protected infoService: InfoAppService,
        protected popupService: PopupService,
        protected modalCtrl: ModalController,
        protected configService: ConfigurationService,
        private appVersion: AppVersion,
        private sysAccountService: SysAccountService,
        private iab: InAppBrowser,
        private zone: NgZone,
        private accountService: AccountService,
        private networkService: NetworkService,
        private translateService: TranslateService

    ) {
        super(translateService, configService, infoService, popupService);
        this.configuration = this.configService.getCacheConfiguration();
        this.isIOS = infoService.isIOS();
    }

    /**
     * View loaded event
     * @param account
     */
    ionViewWillEnter() {
        super.ionViewWillEnter();
        // load app Mode
        this.infoService.getCurrentMode()
            .then((result: string) => {
                this.appMode = (result.toString() === "DEV") ? "Demo" : "";
            }, (err) => { console.error("getAppMode", err); });
        // load last synchro date
        this.sysAccountService.getSysAccount()
            .then((result) => {
                if (result.lastSynchro && result.lastNotifGeneration) {
                    this.lastSynchroDate = moment(result.lastSynchro).format('ll, LT');
                    this.lastNotifDate = moment(result.lastNotifGeneration).format("DD/MM/YYYY HH:mm");
                } else {
                    // in portal there's no synchro and notif time stored
                    this.lastSynchroDate = moment().format('ll, LT');
                    this.lastNotifDate = moment().format("DD/MM/YYYY HH:mm");
                }
            });

        // load user's consents
        this.consents = (Tools.isDefined(this.accountService.cachedAccount.consent) && this.accountService.cachedAccount.consent.length > 0) ?
            this.accountService.cachedAccount.consent.sort((c1, c2) => moment(c1.consentDate).isBefore(moment(c2.consentDate)) ? 1 : -1) :
            [];

        // load Version Number
        this.appVersion.getVersionNumber()
            .then((result: string) => {
                this.version = result.toString();
            }, (err) => { console.error("getVersionNumber", err); });

        // load all credits
        this.allCredits();
    }

    /**
     * Cancel modal
     */
    public dismiss() {
        this.modalCtrl.dismiss();
    }

    /**
     * click on "Terms and privacy" button
     */
    public onTerms() {
        this.iabActive = true;
        const TermsCondURL = this.translateService.instant("about.TermsCondURL");
        const browser = this.iab.create(TermsCondURL, '_blank', 'location=yes');
        browser.on('exit')?.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
            this.zone.run(() => {
                this.iabActive = false;
            });
        });
        browser.show();
    }

    /**
     * click on website button
     */
    public onWebsite() {
        this.iab.create('http://comunicare.be', '_blank', 'location=yes');
    }

    /**
     * Credits URL
     */
    private allCredits() {
        this.credits = CreditsHelper.ALL_CREDITS;
    }

    public goTo(url: string) {
        window.open(url, "_system", "location=no");
    }

    public async openConsent(consent: IConsent) {
        if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Online) {
            const modal = await this.modalCtrl.create({
                component: ConsentModalComponent,
                componentProps: {
                    readOnly: true,
                    consent: consent
                },
                backdropDismiss: true
            });
            await modal.present();
        } else {
            this.popupService.showToast(this.translateService.instant('error.needNetwork'), 3000, "top", null, "warning");
        }
    }
}
