import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { concat, EMPTY, Subject, BehaviorSubject, from } from 'rxjs';
import { Account } from 'src/app/helpers/account-helper';
import { FHIRHelper } from 'src/app/helpers/FHIR-helper';
import { Tools } from 'src/app/helpers/tools-helper';
import { IPonderation, IRewardDefinition, REWARD_ACTION, REWARD_PAGE_NAME } from 'src/app/models/rewardDefinition';
import { IRewardScore } from 'src/app/models/rewardScore';
import { IRewardTooltip } from 'src/app/models/rewardTooltips';
import { STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { RewardToastService } from '../reward-toast.service';
import { AccountService } from './account.service';
import { ConfigurationService } from './configuration.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { RewardDefinitionsService } from './reward-definitions.service';
import { BasicSyncService } from './core/basic-sync.service';
import { FEATURES } from 'src/environments/features';
import { FeatureService } from '../featureService';

@Injectable({
  providedIn: 'root'
})
export class RewardScoreService extends BasicSyncService<IRewardScore, IRewardScore[]> {

  public isSetUp = false;
  private setupInProgress = false;

  constructor(
    protected dataService: DataService,
    private accountService: AccountService,
    private rewardDefinitionService: RewardDefinitionsService,
    private configService: ConfigurationService,
    private rewardToastService: RewardToastService,
    private featureService: FeatureService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IRewardScore[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'rewardScore_',
      entityStoreKey: 'list',
      getUrl: '/rewardScoresByPatient?onlyInProgress=true',
      setUrl: '/rewardScore',
      expirationDays: 10,
      encrypted: true,
    };
  }

  /**
   * This method will create rewardScore documents based on rewardDefinition if not exist yet
   */
  public async setup() {
    if (this.setupInProgress || !this.featureService.canActivate(FEATURES.REWARDS)) { return; }
    this.setupInProgress = true;
    const terminateSubRewardDef = new Subject();
    try {
      const allDefs = await this.rewardDefinitionService.getFreshestData();
      this.setupScores(allDefs, terminateSubRewardDef);
    } catch (err) {
      console.error('Error during setup: ', err);
      this.setupInProgress = false;
    }
  }

  private async setupScores(allDefs: IRewardDefinition[], terminator) {
    if (!this.featureService.canActivate(FEATURES.REWARDS)) { return; }
    const terminateSubRewardScore = new Subject();
    if (allDefs?.length) {
      try {
        const scores = await this.getFreshestData();
        this.computeScores(allDefs, scores, terminator, terminateSubRewardScore);
      } catch (err) {
        console.error('RewardScoreService setupScores: ', err);
        this.setupInProgress = false;
      }
    }
    else {
      this.setupInProgress = false;
      terminator.next();
    }
  }

  private computeScores(allDefs: IRewardDefinition[], scores: IRewardScore[], terminator, terminatorRewardScore) {
    if (!this.featureService.canActivate(FEATURES.REWARDS)) { return EMPTY; }
    const lsObs = allDefs.map(def => {
      const defId = FHIRHelper.getCaremateIdentifier(def);
      const targetScore = scores.find(s => s.rewardDefinitionReference.reference === defId?.value);
      // if target score not exist we create one
      if (!targetScore) {
        const { end, start } = this.computeDate(def.rewardDuration.duration, def.rewardDuration.granularity);
        const rewardScore: IRewardScore = {
          actionsHistory: [],
          start,
          end,
          currentScore: 0,
          hasBeenRewarded: false,
          rewardDefinitionReference: {
            display: defId.label,
            reference: defId.value
          },
          patientReference: {
            reference: this.accountService.cachedCaremateId,
            display: Account.getFullName(this.accountService.cachedAccount)
          },
          _id: Tools.genValidId(),
          creation: moment().format(),
          modified: moment().format(),
          entityStatus: [STATUS_ENTITY.ACTIVE]
        };
        return this.create(rewardScore);
      }
      else {
        return EMPTY;
      }
    });

    concat(lsObs).subscribe(
      (value) => { },
      (err) => {
        this.setupInProgress = false;
        this.isSetUp = true;
        terminatorRewardScore.next();
        terminator.next();
      },
      () => {
        this.setupInProgress = false;
        this.isSetUp = true;
        terminatorRewardScore.next();
        terminator.next();
      }
    );
  }

  private computeDate(duration: number, granularity: string): { end: string, start: string } {
    const startMoment = moment().startOf(granularity as moment.unitOfTime.StartOf);
    return {
      start: startMoment.format(),
      end: startMoment.add(duration, granularity as moment.unitOfTime.DurationConstructor).add(-1, 'day').endOf('day').format()
    };
  }

  public async *getDataReader(): AsyncGenerator<IRewardScore[], IRewardScore[], IRewardScore[]> {
    if (this.accountService.isOnlyRelated || !this.featureService.canActivate(FEATURES.REWARDS)) {
      yield [];
      return [];
    }
    try {
      const dataReader = super.getDataReader();
      let d: IRewardScore[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      console.error("RewardScoreService getDataReader()", err);
      yield [];
      return [];
    }
  }

  public save(score: IRewardScore) {
    if (!this.featureService.canActivate(FEATURES.REWARDS)) { return EMPTY; }

    const savePromise = this.dataService.saveInArray(score, (entity) => entity._id === score._id, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.PUT
    }).then((savedRS: IRewardScore) => {
      const rewardScores = this.peekData();
      const i = rewardScores.findIndex(e => e._id === savedRS._id);
      if (i >= 0 && !savedRS.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rewardScores[i] = savedRS;
      } else if (i >= 0 && savedRS.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rewardScores.splice(i, 1);
      } else if (i < 0 && !savedRS.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rewardScores.push(savedRS);
      }
      this.pokeData(rewardScores);
      return savedRS;
    });
    return from(savePromise);
  }

  public create(score: IRewardScore) {
    if (!this.featureService.canActivate(FEATURES.REWARDS)) { return EMPTY; }
    const savePromise = this.dataService.saveInArray(score, (entity) => entity._id === score._id, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.POST
    }).then((savedRS: IRewardScore) => {
      const rewardScores = this.peekData();
      const i = rewardScores.findIndex(e => e._id === savedRS._id);
      if (i < 0 && !savedRS.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        rewardScores.push(savedRS);
      }
      this.pokeData(rewardScores);
      return savedRS;
    });
    return from(savePromise);
  }

  public async update(pageName: REWARD_PAGE_NAME, action: REWARD_ACTION, onClickIdentifier?: string) {
    if (!this.featureService.canActivate(FEATURES.REWARDS)) { return; }

    // we assume rewardDefinitionService is already init
    const allDefs = this.rewardDefinitionService.peekData();
    if (allDefs?.length) {
      const allScores = await this.getFreshestData();
      allScores.forEach(s => {
        const targetDef = allDefs.find(d => FHIRHelper.getCaremateIdentifier(d)?.value === s.rewardDefinitionReference.reference);
        if (targetDef) {
          const targetPonderation = targetDef.rewardPonderation.find(p => p.pageName === pageName);
          const isArray = action === REWARD_ACTION.onClick;
          let targetAction: IPonderation;
          if (isArray) {
            targetAction = targetPonderation?.onClick?.find(p => p.identifier === onClickIdentifier);
          } else {
            targetAction = targetPonderation?.[action] as IPonderation;
          }
          if (targetAction) {
            const currentScoreFromThisAction = s.actionsHistory.filter(h => h.ponderationId === targetAction.identifier).reduce((acc, el) => { acc += el.score; return acc; }, 0);
            if (currentScoreFromThisAction < targetAction.max || (!targetAction.max)) {
              const toastAlreadyShow = s.actionsHistory.findIndex(h => h.ponderationId === targetAction.identifier) > -1;
              s.actionsHistory.push({
                date: moment().format('YYYY-MM-DD HH:mm'),
                ponderationId: targetAction.identifier,
                score: targetAction.score
              });
              s.currentScore += targetAction.score;
              this.save(s)
                .subscribe(() => { }, (err) => { }, () => {
                  const toastMessage: IRewardTooltip = {
                    pointsWin: targetAction.score,
                    currentScore: s.currentScore,
                    textToShow: targetAction.patientDescription[this.configService.getCurrentLanguage()],
                    targetScore: targetDef.rewardScore,
                  };
                  this.accountService.updateGlobalPoints(targetAction.score);
                  if (!toastAlreadyShow && this.configService.showToast) {
                    this.rewardToastService.show(toastMessage);
                  }
                });
            }
          }
        }
      });
    }
  }
}
