import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AppConstants } from './appConstants';
import { InfoAppService } from './services/info-app.service';
import { LocalStorageService } from './services/storage/local-storage.service';
import { SecureStorageService } from './services/storage/secure-storage.service';
import { MigrationIonic5Service } from './services/migration-ionic5.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {

    public static nowSecureStorageActive = false;
    public static nowSecureStorageNotActive = false;

    constructor(
        private router: Router,
        private infoAppService: InfoAppService,
        private secureStorageService: SecureStorageService,
        private localStorageService: LocalStorageService,
        private migrationIonic5: MigrationIonic5Service,
    ) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> { // Promise<boolean | UrlTree>

        try {
            if (!(await this.infoAppService.getSomeoneLogIn())) {
                AuthGuard.nowSecureStorageActive = false;
                AuthGuard.nowSecureStorageNotActive = false;
                return this.goToLoginPage();
            }

            /**
             * True if the secure storage is activated, false otherwise
             */
            const secureStorageActive = await this.secureStorageService.isReady().catch(() => false);

            // the last known value
            const localLastSecureStorageNotActive = await this.infoAppService.getLastSecureStorageNotActive();

            // the last known value is different from the new known value (i.e. secureStorageActive === localLastSecureStorageNotActive)
            // notice : does not treat the first launch, because in this case localLastSecureStorageNotActive = null
            // -> redirect to login page and clean the store
            if (secureStorageActive === localLastSecureStorageNotActive) {

                if (secureStorageActive) {
                    AuthGuard.nowSecureStorageActive = true;
                    AuthGuard.nowSecureStorageNotActive = false;
                }
                else {
                    AuthGuard.nowSecureStorageActive = false;
                    AuthGuard.nowSecureStorageNotActive = true;
                }

                const mode = await this.infoAppService.getCurrentMode();
                const lastLogin = await this.infoAppService.getLastLogin();
                const ionic5Clear = await this.migrationIonic5.hasBeenClearedForIonic5();

                await this.localStorageService.clearStorage();

                await this.infoAppService.setCurrentMode(mode);
                await this.infoAppService.setLastLogin(lastLogin);
                if (ionic5Clear) {
                    await this.localStorageService.setData(AppConstants.IONIC5CLEAR, 'done', false);
                }

                this.infoAppService.setLastSecureStorageNotActive(!secureStorageActive);

                return this.goToLoginPage();
            }

            // the first launch (localLastSecureStorageNotActive === null) and does not have secure storage
            if (localLastSecureStorageNotActive === null && !secureStorageActive) {
                AuthGuard.nowSecureStorageActive = false;
                AuthGuard.nowSecureStorageNotActive = true;
            }

            this.infoAppService.setLastSecureStorageNotActive(!secureStorageActive);

            return true;

        } catch (error) {
            console.error("AuthGuard", error);
            AuthGuard.nowSecureStorageActive = false;
            AuthGuard.nowSecureStorageNotActive = false;
            return this.goToLoginPage();
        }
    }

    private goToLoginPage() {
        return this.router.parseUrl("/login");
    }
}
