import { Injectable } from "@angular/core";
import { BluetoothLE, DeviceInfo, Status } from "@awesome-cordova-plugins/bluetooth-le/ngx";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { combineLatest, interval, Observable, of, Subscription } from "rxjs";
import { flatMap, timeout, map } from "rxjs/operators";
import { IExternalRessource } from "src/app/models/externalRessource";
import { InfoAppService } from "../info-app.service";
import { MedicalBluetoothData, MedicalBluetoothDataConstructor } from "./medical-bluetooth-data";
import { MedicalBluetoothError, MedicalBluetoothErrorType } from "./medical-bluetooth-error";
export { MedicalBluetoothError, MedicalBluetoothErrorType } from "./medical-bluetooth-error";
export interface MedicalBluetoothDevice {
    name: string | null;
    address: string;
    services: MedicalBluetoothService[];
    bonded: boolean;
}

export enum MedicalBluetoothService {
    HEALTH_THERMOMETER = "1809",
    BLOOD_PRESSURE = "1810",
    WEIGHT_SCALE = "181D",
    NONIN_OXIMETER = "46A970E0-0D5F-11E2-8B5E-0002A5D5C51B",
    VITALOGRAPH_LUNG_MONITOR = "FEFB",
    GLUCOSE = '1808'
}

export enum MedicalBluetoothCharacteristic {
    TEMPERATURE_MEASUREMENT = "2a1c",
    WEIGHT_MEASUREMENT = "2a9d",
    BLOOD_PRESSURE_MEASUREMENT = "2a35",
    NONIN_OXIMETRY_MEASUREMENT = "0AAD7EA0-0D60-11E2-8E3C-0002A5D5C51B",
    VITALOGRAPH_BREATH_MEASUREMENT = "00000002-0000-1000-8000-008025000000",
    GLUCOSE_MEASUREMENT = '2A18',
    GLUCOSE_MEASUREMENT_CONTEXT = '2A34',
    GLUCOSE_RECORD_ACCESS_POINT = '2A52',
    ACCU_CHEK_MEASURE = '00000000-0000-1000-1000-000000000002',
}

@Injectable({
    providedIn: 'root'
})
export class MedicalBluetooth {
    public constructor(private infoAppService: InfoAppService, public bluetoothle: BluetoothLE, public device: Device) {

    }

    public get bondedDevices(): MedicalBluetoothDevice[] {
        const local = localStorage.getItem("bondedDevices");
        return (local) ? JSON.parse(local) : [];
    }

    public addBondedDevices(device: MedicalBluetoothDevice) {
        const bondedDevices = this.bondedDevices;
        const exists = bondedDevices.find((value) => {
            return value.address === device.address;
        });
        if (!exists) {
            bondedDevices.push(device);
        }
        localStorage.setItem("bondedDevices", JSON.stringify(bondedDevices));
    }

    public removeBondedDevices(device: MedicalBluetoothDevice) {
        let bondedDevices = this.bondedDevices;
        bondedDevices = bondedDevices.filter((d) => {
            return d.address !== device.address;
        });
        localStorage.setItem("bondedDevices", JSON.stringify(bondedDevices));
    }

    /**
     * 
     * @param externalRessource 
     * @returns the MedicalBluetoothDevice already bounded associated to this externalRessource. If no exist 
     * (not already bounded for example), return undefined
     */
    public getBondedDevice(externalRessource: IExternalRessource): MedicalBluetoothDevice {
        const bleServiceCode = externalRessource.meta?.medicalBluetoothService;
        if (!bleServiceCode) {
            return undefined;
        }
        return this.bondedDevices
            .find((device) => {
                return device.services?.includes(bleServiceCode) && externalRessource.reference && device.name.indexOf(externalRessource.reference) > -1;
            });
    }

    public async ready(): Promise<void> {
        try {
            if (this.infoAppService.isAndroid()) {
                const btPermission = await this.bluetoothle.requestPermission();
                const androidVersion = Number(this.device.version?.split(".")[0]);
                let scanPermission = { requestPermission: true };
                let connectPermission = { requestPermission: true };
                if (androidVersion >= 12) { // only for android 12+
                    scanPermission = await this.bluetoothle.requestPermissionBtScan();
                    connectPermission = await this.bluetoothle.requestPermissionBtConnect();
                }
                if (!btPermission.requestPermission || !scanPermission.requestPermission || !connectPermission.requestPermission) {
                    throw new MedicalBluetoothError(
                        "Permission not granted",
                        MedicalBluetoothErrorType.PERMISSION_NOT_GRANTED,
                    );
                }
                const btLocation = await this.bluetoothle.isLocationEnabled();
                if (!btLocation.isLocationEnabled) {
                    throw new MedicalBluetoothError(
                        "Location is not enabled",
                        MedicalBluetoothErrorType.LOCATION_NOT_ENABLED,
                    );
                }
            }

            const initialization = await this.bluetoothle.isInitialized();
            if (initialization.isInitialized) {
                return;
            } else {
                const status = await new Promise<"enabled" | "disabled">((resolve, reject) => {
                    this.bluetoothle.initialize({ "request": true }).subscribe((state) => {
                        resolve(state.status);
                    });
                });

                if (status === "disabled") {
                    throw new MedicalBluetoothError(
                        "Bluetooth is not enabled",
                        MedicalBluetoothErrorType.BLUETOOTH_NOT_ENABLED,
                    );
                } else if (status === "enabled") {
                    return;
                } else {
                    throw new MedicalBluetoothError("Unknonw bluetooth status", MedicalBluetoothErrorType.UNKNOWN_STATUS);
                }
            }

        } catch (error) {
            throw new MedicalBluetoothError(
                error.message || "Unknown",
                error.type || MedicalBluetoothErrorType.UNKNOWN,
            );
        }
    }

    public async scan(services?: MedicalBluetoothService[]): Promise<Observable<MedicalBluetoothDevice>> {
        const scanningStatus = await this.bluetoothle.isScanning();
        if (scanningStatus.isScanning) {
            await this.bluetoothle.stopScan();
        }

        return this.bluetoothle.startScan({
            allowDuplicates: false,
            services,
        }).pipe(
            map((v) => {
                const d = v as unknown as DeviceInfo;
                const device: MedicalBluetoothDevice = {
                    address: d.address,
                    bonded: false,
                    name: d.name,
                    services,
                };
                return device;
            })
        );
    }

    public async stopScan() {
        const scanningStatus = await this.bluetoothle.isScanning();
        if (scanningStatus.isScanning) {
            await this.bluetoothle.stopScan();
        }
    }

    public async bond(device: MedicalBluetoothDevice, force: boolean = false): Promise<void> {
        await this.clearPreviousConnection(device);

        let subscription: Subscription;
        force = true;

        return new Promise((resolve, reject) => {
            if (force && this.infoAppService.isAndroid()) {
                this.bluetoothle.bond({ address: device.address }).subscribe((deviceInfo) => {
                    switch (deviceInfo.status as unknown as Status) {
                        case "bonded":
                            device.bonded = true;
                            this.addBondedDevices(device);
                            resolve();
                            break;
                        case "unbonded":
                            reject(new MedicalBluetoothError("Bonding timed out", MedicalBluetoothErrorType.BONDING_TIMEOUT));
                            break;
                        case "disconnected":
                            reject(new MedicalBluetoothError("Device disconnected", MedicalBluetoothErrorType.DEVICE_DISCONNECTED));
                            break;
                        default:
                            break;
                    }
                }, (err) => {
                    if (err.message === "Device already bonded") {
                        device.bonded = true;
                        this.addBondedDevices(device);
                        resolve();
                    } else {
                        reject(new MedicalBluetoothError("Bonding timed out", MedicalBluetoothErrorType.BONDING_TIMEOUT));
                    }
                });
            } else {
                subscription = combineLatest([
                    this.bluetoothle.connect({
                        address: device.address,
                        autoConnect: true,
                    }),
                    interval(1000).pipe(
                        flatMap(async () => (await this.isBonded(device)))
                    )]
                ).pipe(timeout(60000))
                    .subscribe(async ([info, bonded]: [any, boolean]) => {
                        if (info.status === "disconnected") {
                            reject(new MedicalBluetoothError(
                                "Device disconnected",
                                MedicalBluetoothErrorType.DEVICE_DISCONNECTED,
                            ));

                            if (subscription) {
                                subscription.unsubscribe();
                            }
                        } else if (info.status === "connected" && bonded) {
                            await this.bluetoothle.disconnect({
                                address: device.address,
                            });

                            await this.bluetoothle.close({
                                address: device.address,
                            });

                            device.bonded = true;
                            this.addBondedDevices(device);

                            setTimeout(() => {
                                resolve();
                            }, 6000);

                            if (subscription) {
                                subscription.unsubscribe();
                            }
                        }
                    }, (error) => {
                        reject(new MedicalBluetoothError("Bonding timed out", MedicalBluetoothErrorType.BONDING_TIMEOUT));
                        return of(error);
                    });
            }
        });
    }

    public async unbond(device: MedicalBluetoothDevice) {
        let unbonded = true;
        if (this.infoAppService.isAndroid()) {
            try {
                const bondStatus = await this.bluetoothle.isBonded({ address: device.address });
                if (bondStatus.isBonded) {
                    const deviceInfo = await this.bluetoothle.unbond({ address: device.address });
                    const di = deviceInfo as unknown as DeviceInfo;
                    unbonded = di.status === "unbonded";
                }
            } catch (e) {
                console.error("Error unbonding", JSON.stringify(e));
                unbonded = false;
            }
        }

        try {
            await this.clearPreviousConnection(device);
        } catch (e) {
            console.error("clearPreviousConnection", JSON.stringify(e));
        }

        if (unbonded) {
            this.removeBondedDevices(device);
        }

        return unbonded;
    }

    public async connect<T extends MedicalBluetoothData>(
        device: MedicalBluetoothDevice,
        factory: MedicalBluetoothDataConstructor,
    ): Promise<T> {
        await this.clearPreviousConnection(device);

        const handler = this.bluetoothle.connect({
            address: device.address,
            autoConnect: true,
        }).pipe(
            map((v: any) => {
                if (v.status?.status) {
                    return v.status as string;
                } else {
                    return v.status as unknown as string;
                }
            })
        );

        return new factory(device, handler, this.infoAppService, this.bluetoothle);
    }

    private async clearPreviousConnection(device: MedicalBluetoothDevice) {
        try {
            const connectedState = await this.bluetoothle.wasConnected({ address: device.address });
            console.log("clearPreviousConnection", JSON.stringify(connectedState));
            if (connectedState.wasConnected) {
                await this.bluetoothle.close({ address: device.address });
            }
        } catch (error) {
            console.log("clear previous connection error", JSON.stringify(error));
        }
    }

    private async isBonded(device: MedicalBluetoothDevice) {
        if (this.infoAppService.isAndroid()) {
            return (await this.bluetoothle.isBonded({ address: device.address })).isBonded;
        } else {
            try {
                const connectedDevices = await this.bluetoothle.retrieveConnected({
                    services: device.services,
                }) as unknown as DeviceInfo[];
                return connectedDevices.find((d) => d.address === device.address) !== undefined;
            } catch (e) {
                console.error("retrieveConnected error", JSON.stringify(e));
            }
        }
    }
}
