import { Injectable } from '@angular/core';

import BackgroundFetch from 'cordova-plugin-background-fetch';
import * as moment from 'moment';
import { AppConstants } from '../appConstants';
import { SysAccount } from '../models/sysaccount';
import { InfoAppService } from './info-app.service';
import { SynchronisationService } from './synchronisation.service';
import { SysAccountService } from './sys-account.service';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {

  constructor(
    private infoAppService: InfoAppService,
    private sysAccountService: SysAccountService,
    private synchronisationService: SynchronisationService
  ) {
  }

  public initBackgroundSynchro() {

    if (this.infoAppService.isCordova()) {
      const config = {
        stopOnTerminate: false,
        enableHeadless: true,
        startOnBoot: true,
        requiredNetworkType: BackgroundFetch.NETWORK_TYPE_ANY // This job requires network connectivity.
      };

      const fetchCallback = (taskId) => {
        try {
          console.log('BackgroundFetch event received: ', taskId);

          if (!this.time2BackgroundTaskSynchro(this.sysAccountService.cachedSysAccount)) {
            console.log("BackgroundFetch", "No time to synchro.");
            BackgroundFetch.finish(taskId);
          }
          else {
            this.synchronisationService.syncApp().then(() => {
              console.log("BackgroundFetch SUCCESS");
              BackgroundFetch.finish(taskId);
            },
            (err) => {
              console.error(err, "BackgroundFetch syncApp");
              BackgroundFetch.finish(taskId);
            });
          }
        } catch (error) {
          console.error(error, "BackgroundFetch try catch");
          BackgroundFetch.finish(taskId);
        }
      };

      const failureCallback = (error) => {
        console.log('BackgroundFetch failed', error);
      };

      BackgroundFetch.configure(config, fetchCallback, failureCallback);
    }
  }

  /**
  * Check if time to synchro a account
  * @param sysAccount 
  */
 public time2BackgroundTaskSynchro(sysAccount: SysAccount): Boolean {
    if (!sysAccount) {
      return false;
    }
    else if (!(sysAccount.name)) {
      return false;
    }
    else if (!(sysAccount.lastSynchro)) {
      return true;
    }
    else if (moment(sysAccount.lastSynchro).add(AppConstants.BACKGROUNDSYNCHROPLUGIN_HOUR, "hours").isSameOrBefore(moment(), "minute")) { // do not always sync: only every 3 hours
      return true;
    }
    else {
      return false;
    }
  }
}
