import { Component } from '@angular/core';
import { ModalController, PopoverController, NavController } from '@ionic/angular';
import { IConfiguration } from 'src/app/models/configuration';
import { ACTION_STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { RewardDefinitionsService } from 'src/app/services/globalDataProvider/reward-definitions.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { LoaderService } from 'src/app/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from "moment";
import { GoToPageService } from 'src/app/services/go-to-page.service';
import { HelpService } from 'src/app/services/help.service';
import { NotificationsEventsService } from 'src/app/services/notificationsService/notifications-events.service';
import { SynchronisationService, SynchronisationServiceStatus } from 'src/app/services/synchronisation.service';
import { last } from 'rxjs/operators';
import { LanguagesService } from 'src/app/services/globalDataProvider/languagesService';
import { ITranslation } from 'src/app/models/translation';
import { BasePage } from 'src/app/baseClasses/base-page';
import { Tools } from 'src/app/helpers/tools-helper';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent extends BasePage {
  public configuration: IConfiguration;
  public language = "fr";
  private originalLanguage = "fr";
  public activeToast = false;
  public rewardIsActive = false;
  public languages: ITranslation[];

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    public configService: ConfigurationService,
    private rewardDefinitionService: RewardDefinitionsService,
    protected popover: PopoverController,
    protected loaderService: LoaderService,
    protected translateSvc: TranslateService,
    protected goToPageService: GoToPageService,
    private modalController: ModalController,
    private helpService: HelpService,
    private notificationsEventsService: NotificationsEventsService,
    protected synchronisationService: SynchronisationService,
    protected languagesService: LanguagesService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.configuration = this.configService.getCacheConfiguration();
    this.activeToast = this.configService.showToast;
    // only show reward options if definitions are available for careplan
    this.rewardIsActive = this.rewardDefinitionService.peekData().length > 0;


  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    Promise.all([this.languagesService.getFreshestData(), Tools.wait(1000)]).then((l) => {
      this.languages = l[0];
      this.language = this.configService.getCurrentLanguage();
      this.originalLanguage = this.language;
      this.pageLoaded = true;
    });
  }

  /**
   *  cancel modal
   */
  public dismiss(validate?: Boolean): Promise<any> {
    return this.modalController.dismiss(validate);
  }

  /**
   * Reset help page
   */
  public async onResetHelp() {
    await this.helpService.resetHelpSeen();
    this.goToPageService.homePage();
    this.dismiss(false);
  }

  /**
   * save Registration data
   */
  public async save() {
    // set language
    this.configuration.settings.globalSettings.language = this.language;
    // set show toast param
    this.configuration.settings.globalSettings.showRewardToast = this.activeToast;
    // save modifications
    await this.loaderService.showLoading(true);
    // save session check
    this.configuration.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    // save configuration
    this.configService.save(this.configuration).subscribe(
      () => { },
      async (err) => {
        console.error("ModalSettings", "save", err);
        await this.loaderService.showLoading(false);
      },
      async () => {
        // update language dynamically
        if (this.originalLanguage !== this.language) {
          try {
            const success = await this.configService.setCurrentLanguage(this.language);
            if (success) {
              moment.locale(this.language);
              this.translateSvc.setDefaultLang(this.language);
              await this.translateSvc.use(this.language).pipe(last()).toPromise();
              this.synchronisationService.syncApp().then((synchroStatus: SynchronisationServiceStatus) => {
                if (synchroStatus !== SynchronisationServiceStatus.success) {
                  // No synchronization, so we can only re-gen the notifications
                  // Better than nothing.
                  this.notificationsEventsService.generateAll();
                }
              });
              await this.dismiss(true);
            } else {
              this.popupService.showToastWithCloseBtn('Config not loaded yet, try again', 'middle');
            }
          } catch (err) {
            console.warn('In ModalSettings methods save() -> ', err);
          }
        } else {
          await this.dismiss(false);
        }
        await this.loaderService.showLoading(false);
      }
    );
  }

}
