import { KeyValue } from "src/app/models/keyValue";
import { IEntity, SCHEDULE_PERIOD, Timing } from "src/app/models/sharedInterfaces";
import { FHIR_ActivityHelper } from "../helpers/fhirActivityHelper";
import { CycleSchema, EntityDrug } from "./entitylink";
import * as moment from "moment";
import { Tools } from "../helpers/tools-helper";
import { NOTIFICATION_TYPE } from "./notification";
import { DrugSchemaService } from "../services/globalDataProvider/drug-schema.service";

export interface Timings {
  [index: string]: number;
}
export interface ScheduledBefore {
  [index: number]: number;
}

export const FREQUENCY_OPTIONS: KeyValue[] = [
  new KeyValue("timing.fixedFrequency", "fixedFrequency"),
  new KeyValue("timing.fixedDates", "fixedDates"),
  new KeyValue("timing.asNecessary", "asNecessary"),
];

export const TIMING_OPTIONS: KeyValue[] = [new KeyValue("timing.moments", "moments"), new KeyValue("timing.fixedHours", "fixedHours")];

export const TIMING_CODES = [
  {
    value: IEntity.TIMING_RISING,
    display: "rise",
  },
  {
    value: IEntity.TIMING_MORNING,
    display: "morning",
  },
  {
    value: IEntity.TIMING_NOON,
    display: "noon",
  },
  {
    value: IEntity.TIMING_EVENING,
    display: "evening",
  },
  {
    value: IEntity.TIMING_BED,
    display: "bedtime",
  },
];

export const DAYS_OF_MONTH: number[] = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export class TimingData {
  public static fixedFreqOption = FREQUENCY_OPTIONS[0].value;
  public static fixedDaysOption = FREQUENCY_OPTIONS[1].value;
  public static fixedDaysOptionDisplay = FREQUENCY_OPTIONS[1].key;
  public static asNecessaryOption = FREQUENCY_OPTIONS[2].value;
  public static asNecessaryOptionDisplay = FREQUENCY_OPTIONS[2].key;
  public static momentTimingOption = TIMING_OPTIONS[0].value;
  public static fixedHoursTimingOption = TIMING_OPTIONS[1].value;

  public static getFreqOption(entityDrug: EntityDrug) {
    const asNecessary = entityDrug.frequency.asNecessary;
    return asNecessary
      ? this.asNecessaryOption
      : entityDrug.frequency.event && entityDrug.frequency.event.length
      ? this.fixedDaysOption
      : this.fixedFreqOption;
  }

  public static getTimingOption(entityDrug: EntityDrug) {
    return entityDrug.frequency.timeOfDay && entityDrug.frequency.timeOfDay.length ? this.fixedHoursTimingOption : this.momentTimingOption;
  }
  public static getFreqOptionFromTiming(timing: Timing) {
    const asNecessary = timing.asNecessary;
    return asNecessary ? this.asNecessaryOption : timing.event && timing.event.length ? this.fixedDaysOption : this.fixedFreqOption;
  }

  public static getTimingOptionFromTiming(timing: Timing) {
    return timing.timeOfDay && timing.timeOfDay.length ? this.fixedHoursTimingOption : this.momentTimingOption;
  }
  public static hasTiming(timing: Timing, freqOption?: string, timingOption?: string) {
    const timingCode = timing.timingCode;
    freqOption = freqOption ? freqOption : this.getFreqOptionFromTiming(timing);
    timingOption = timingOption ? timingOption : this.getTimingOptionFromTiming(timing);
    switch (freqOption) {
      case this.asNecessaryOption:
        return true;
      case this.fixedFreqOption:
      case this.fixedDaysOption:
        switch (timingOption) {
          case this.momentTimingOption:
            return !!timingCode;
          case this.fixedHoursTimingOption:
            return timing.timeOfDay && timing.timeOfDay.length > 0;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  public static getIntakesPerDay(timing: Timing, option?: string): string[] {
    const timingOption = option ? option : TimingData.getTimingOptionFromTiming(timing);
    let intakes: string[] = [];
    if (!TimingData.hasTiming(timing, null, timingOption)) { return []; }
    if (timingOption === TimingData.momentTimingOption) {
      intakes = this.getMomentsTimingFromCode(timing.timingCode);
    } else if (timingOption === TimingData.fixedHoursTimingOption) {
      intakes = timing.timeOfDay;
    }
    return intakes;
  }

  public static getMomentsTimingFromCode(timingCode: string): string[] {
    const moments: string[] = [];
    IEntity.toKeyValues(timingCode).forEach(kv => {
        if (kv.value) {
            moments.push(kv.key);
        }
    });
    return moments;
  }

  public static getMomentFromHour(hour: string, moments: string[], userTimings: Timings): string {
    for (const m of moments) {
      const timingHour = moment().startOf('day').add(userTimings[m], "minutes").format("HH:mm");
      if (hour === timingHour) {
        return m;
      }
    }
    return "";
  }

  public static getTimingInstances(
    timing: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    let instances: string[] = [];
    const freqOption = TimingData.getFreqOptionFromTiming(timing);
    const timingOption = TimingData.getTimingOptionFromTiming(timing);
    const hasTiming = TimingData.hasTiming(timing, freqOption, timingOption);
    if (!hasTiming || freqOption === TimingData.asNecessaryOption) {
      return [];
    } else if (freqOption === TimingData.fixedFreqOption) {
      switch (FHIR_ActivityHelper.getScheduledPeriod(timing)) {
        case SCHEDULE_PERIOD.HOUR: // *** HOUR scheduled ***
          instances = this.getHourlyTimingInstances(timing, from, to);
          break;
        case SCHEDULE_PERIOD.DAY: // *** DAY scheduled ***
          instances = this.getDailyTimingInstances(timing, userTimings, schedules, from, to, type);
          break;
        case SCHEDULE_PERIOD.WEEK: // *** WEEK scheduled ***
          instances = this.getWeeklyTimingInstances(timing, userTimings, schedules, from, to, type);
          break;
        case SCHEDULE_PERIOD.MONTH: // *** MONTH scheduled ***
          instances = this.getMonthlyTimingInstances(timing, userTimings, schedules, from, to, type);
          break;
      }
    } else if (freqOption === TimingData.fixedDaysOption) {
      instances = this.getFixedDaysTimingInstances(timing, userTimings, schedules, from, to, type);
    } else {
      return [];
    }
    return instances;
  }

  private static getHourlyTimingInstances(timing: Timing, from?: moment.Moment, to?: moment.Moment): string[] {
    return [];
  }
  private static getDailyTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    let instances: string[] = [];
    try {
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      const startDay = startDate.clone();
      instances = this.computeTimingInstances(startDate, startDay, frequency, userTimings, schedules, "days", endDay, from, to, null, type);
    } catch (err) {
      console.error("scheduleDrugNotificationDaily", err);
    }
    return instances;
  }
  private static getWeeklyTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    let instances: string[] = [];
    try {
      // "when" contains week days index (from 1 to 7) in an array (ex: ["1,"3"] )
      const days = JSON.parse(frequency.when) as string[];
      if (!days || days.length === 0) return []; // nothing to do
      // start of current processing
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      // end of current processing date
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each week day
      days.forEach((dayIdx) => {
        const dayIndex = Number(dayIdx);
        const startDay = startDate.clone();
        // set week day
        startDay.isoWeekday(dayIndex);
        instances.push(
          ...this.computeTimingInstances(startDate, startDay, frequency, userTimings, schedules, "weeks", endDay, from, to, null, type)
        );
      });
    } catch (err) {
      console.error("scheduleDrugNotificationWeekly", err);
    }
    return instances;
  }

  private static getMonthlyTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    let instances: string[] = [];
    try {
      // let entityDrugData = (drug.entityData as EntityDrug);
      // get days (1,2,3,...,31)
      const days = JSON.parse(frequency.when) as number[];
      if (!days || days.length === 0) return []; // nothing to do
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      // let from = startDate;
      // end of current processing date
      // let to = moment(frequency.boundsPeriod.end);
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each day in month to schedule
      days.forEach((day) => {
        const dayMonth = Number(day);
        const startDay = startDate.clone();
        // set day of the month (do not bubble up to next month)
        if (dayMonth === 31) startDay.endOf("month").startOf("day");
        else if (dayMonth > 28 && startDay.daysInMonth() < dayMonth) startDay.endOf("month").startOf("day");
        else startDay.date(dayMonth);
        instances.push(
          ...this.computeTimingInstances(startDate, startDay, frequency, userTimings, schedules, "months", endDay, from, to, dayMonth, type)
        );
      });
    } catch (err) {
      console.error("scheduleDrugNotificationMonthly", err);
    }
    return instances;
  }

  private static computeTimingInstances(
    startDate: any,
    startDay: any,
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    freqType: string,
    endDay: any,
    from?: moment.Moment,
    to?: moment.Moment,
    dayMonth?: number,
    type?: NOTIFICATION_TYPE
  ) {
    let instances: string[] = [];
    while (startDay.isSameOrBefore(endDay)) {
      // add it only if it is not before "From" date
      // if start date defined in Drug is in middle of current month, we may have to ignore some dates at the beginning of that month
      if (startDay.isSameOrAfter(startDate) && (!from || startDay.isSameOrAfter(from))) {
        const timingOption = TimingData.getTimingOptionFromTiming(frequency);
        if (timingOption === TimingData.momentTimingOption) {
          // compute for morning/noon/evening time
          instances = instances.concat(
            this.getMomentTimingInstances(frequency.timingCode, startDay, userTimings, schedules, from, to, type)
          );
        } else if (timingOption === TimingData.fixedHoursTimingOption) {
          instances = instances.concat(this.getFixedHoursTimingInstances(frequency.timeOfDay, startDay, schedules, from, to, type));
        }
      }
      // every X weeks
      startDay.add(frequency.period, freqType);
      if (freqType === "month" && dayMonth) {
        if (dayMonth === 31) startDay.endOf("month").startOf("day");
      }
    }
    return instances;
  }
  private static getFixedDaysTimingInstances(
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    let instances: string[] = [];
    try {
      const days = frequency.event;
      if (!days || days.length === 0) return []; // nothing to do
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);
      // loop on each day in event
      days.forEach((day) => {
        const startDay = moment(day).startOf("day");
        if (startDay.isSameOrBefore(endDay) && (!from || startDay.isSameOrAfter(from))) {
          const timingOption = TimingData.getTimingOptionFromTiming(frequency);
          if (timingOption === TimingData.momentTimingOption) {
            // compute for morning/noon/evening time
            instances = instances.concat(
              this.getMomentTimingInstances(frequency.timingCode, startDay, userTimings, schedules, from, to, type)
            );
          } else if (timingOption === TimingData.fixedHoursTimingOption) {
            instances = instances.concat(this.getFixedHoursTimingInstances(frequency.timeOfDay, startDay, schedules, from, to, type));
          }
        }
      });
    } catch (err) {
      console.error("scheduleNotificationOnFixedDays", err);
    }
    return instances;
  }

  public static getCycleTimingInstances(
    cycle: CycleSchema,
    frequency: Timing,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ) {
    let instances: string[] = [];
    try {
      const startDate = from
        ? moment.max(moment(frequency.boundsPeriod.start), from).startOf("day")
        : moment(frequency.boundsPeriod.start).startOf("day");
      const endDay = to ? moment.min(moment(frequency.boundsPeriod.end), to).endOf("day") : moment(frequency.boundsPeriod.end);

      const actualDayOfCycle = DrugSchemaService.getActualDayOfCycle(cycle);
      let isPaused = DrugSchemaService.isCycleInPause(cycle);
      let isPausedAndToday = DrugSchemaService.isCycleInPauseOnDate(cycle, new Date());
      const startDay = startDate.clone();
      let i = actualDayOfCycle;

      while (startDay.isSameOrBefore(endDay)) {
        if ((!isPaused && cycle.cycle[i]) || (isPaused && !isPausedAndToday && cycle.cycle[i])) {
          const timingOption = TimingData.getTimingOptionFromTiming(frequency);
          if (timingOption === TimingData.momentTimingOption) {
            // compute for morning/noon/evening time
            instances = instances.concat(
              this.getMomentTimingInstances(frequency.timingCode, startDay, userTimings, schedules, from, to, type)
            );
          } else if (timingOption === TimingData.fixedHoursTimingOption) {
            instances = instances.concat(this.getFixedHoursTimingInstances(frequency.timeOfDay, startDay, schedules, from, to, type));
          }
        }
        if (i === cycle.cycle.length - 1) {
          // end of cycle, start a new one
          i = 0;
          isPaused = false;
        } else {
          i++;
        }
        startDay.add(1, "days");
        if (isPaused && !isPausedAndToday) {
          isPausedAndToday = DrugSchemaService.isCycleInPauseOnDate(cycle, startDay.toDate());
        }
      }
    } catch (err) {
      console.error("scheduleNotificationForCycle", err);
    }
    return instances;
  }

  private static getMomentTimingInstances(
    timingCode: string,
    startDate: moment.Moment,
    userTimings: Timings,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    const instances: string[] = [];
    if (
      (!from && moment().isAfter(startDate, "day")) ||
      (from && from.isAfter(startDate, "day")) ||
      (to && to.isBefore(startDate, "day"))
    ) {
      return [];
    }

    if (!timingCode || timingCode.length === 0) {
      console.warn("TimingData", "Unable to compute timing instances: no timing defined");
      return [];
    }

    // loop over rising / morning / noon / evening / bed
    for (const timing of IEntity.toKeyValues(timingCode)) {
      if ((timing.value as boolean) === true) {
        // make a copy of base notification to update it accordingly with Timing
        // get and set morning, noon or evening hour ?
        const key = timing.key + (Tools.isWeekend(startDate) ? "_weekend" : "");
        const time = userTimings[key] ? userTimings[key] : 0;
        const instanceTime = startDate.clone().add(time, "minutes");
        if (Tools.isDefined(type)) {
          const schedBefore = schedules[type] ? schedules[type] : 0;
          const notifAt = instanceTime.subtract(schedBefore, "minutes");
          instances.push(notifAt.format());
        } else {
          instances.push(instanceTime.format());
        }
      }
    }
    return instances;
  }
  private static getFixedHoursTimingInstances(
    hours: string[],
    startDate: moment.Moment,
    schedules: ScheduledBefore,
    from?: moment.Moment,
    to?: moment.Moment,
    type?: NOTIFICATION_TYPE
  ): string[] {
    const instances: string[] = [];
    if ((!from && moment().isAfter(startDate, "day")) || (from && from.isAfter(startDate, "day")) || (to && to.isBefore(startDate, "day")))
      return [];

    if (!hours || hours.length === 0) {
      console.warn("TimingData", "Unable to compute timing instance: no fixed hours defined");
      return [];
    }
    for (const hour of hours) {
      // make a copy of base notification to update it accordingly with hour
      const h = moment.duration(hour + ":00");
      const instanceTime = startDate.clone().add(h);
      if (Tools.isDefined(type)) {
        const schedBefore = schedules[type] ? schedules[type] : 0;
        const notifAt = instanceTime.add(-schedBefore, "minutes");
        instances.push(notifAt.format());
      } else {
        instances.push(instanceTime.format());
      }
    }
    return instances;
  }
}
