import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IQuestionnaire } from 'src/app/models/questionnaire';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';

@Injectable({
  providedIn: 'root'
})
export class QuizDefinitionService extends BasicSyncService<IQuestionnaire, IQuestionnaire[]> {

  constructor(
    private accountService: AccountService,
    protected dataService: DataService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IQuestionnaire[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'quizDefinitions_',
      entityStoreKey: 'list',
      getUrl: '/quizDefinitions',
      setUrl: '/quizDefinitions',
      expirationDays: 10,
      encrypted: false
    };
  }

  public async *getDataReader(): AsyncGenerator<IQuestionnaire[], IQuestionnaire[], IQuestionnaire[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: IQuestionnaire[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      console.error("QuizDefinitionService getDataReader()", err);
      yield [];
      return [];
    }
  }
}
