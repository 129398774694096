import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/baseClasses/base-component';
import { IAttachment, ICommunication, IType } from 'src/app/models/communication';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { BasePage } from 'src/app/baseClasses/base-page';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent extends BasePage {

  public msg: ICommunication;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected translateSvc: TranslateService,
    private modalCtrl: ModalController,
    public configService: ConfigurationService,
    private file: File,
    private fileOpener: FileOpener
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());
  }

  /**
   *  Close the modal component
   */
  closeMessage(): Promise<boolean> {
    return this.modalCtrl.dismiss();
  }

  /**
   *  Delete the current message
   */
  deleteMessage(msg: ICommunication): Promise<boolean> {
    return this.modalCtrl.dismiss(msg);
  }

  public showAttach(payload: IAttachment) {
    if (this.isCordova) {
      this.onOpenAttachMobile(payload);
    } else {
      if (payload.contentType === IType.JPG || payload.contentType === IType.PNG) {
        this.openImg(payload);
      } else if (payload.contentType === IType.PDF) {
        this.openPdf(payload.data);
      } else {
        window.location = payload.data as any;
        alert("Aucun aperçu disponible, votre pièce jointe a été téléchargée");
      }
    }
  }

  public openPdf(b64: string) {
    const wa = window.open();
    wa.document.title = 'Attach';
    setTimeout(function () {
      wa.document.write('<iframe src="' + b64 + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }, 0);
  }

  public openImg(payload: IAttachment) {
    const data = payload.data;
    const img = document.createElement('img');
    img.src = data;
    img.style.margin = "auto";
    img.style.display = "block";
    img.style.height = "100vh";
    const a = document.createElement('a');
    a.setAttribute("download", payload.title);
    a.setAttribute("href", data);
    a.appendChild(img);
    const wa = window.open();
    wa.document.title = 'Attach';
    wa.document.body.style.backgroundColor = 'rgba(0,0,0, .65)';
    setTimeout(function () {
      wa.document.body.appendChild(a);
    }, 0);
  }

  private onOpenAttachMobile(payload: IAttachment) {

    const base64str = payload.data.split(',')[1];

    // decode base64 string ans remove space
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    let blob: Blob;

    switch (payload.contentType) {

      case IType.PDF:
        blob = new Blob([view], { type: IType.PDF });
        break;

      case IType.JPG:
        blob = new Blob([view], { type: IType.JPG });
        break;

      case IType.PNG:
        blob = new Blob([view], { type: IType.PNG });
        break;

      case IType.TIFF:
        blob = new Blob([view], { type: IType.TIFF });
        break;

    }

    if (this.infoService.isIOS()) {
      this.file.writeFile(this.file.tempDirectory, payload.title, blob, { replace: true }).then(res => {
        return this.fileOpener.open(this.file.tempDirectory + "/" + payload.title, payload.contentType).then(() => {
          // remove file from storage after modal is closed
          this.file.removeFile(this.file.tempDirectory, payload.title);
        });
      });
    } else if (this.infoService.isAndroid) {
      // Android alternative - temporary store the file, then open it with external app
      this.file.writeFile(this.file.externalApplicationStorageDirectory, payload.title, blob, { replace: true }).then(res => {
        return this.fileOpener.open(res.nativeURL, payload.contentType);
      });
    }

  }
}
