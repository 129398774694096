import { Component } from '@angular/core';
import { EntityNote, IEntitylink } from 'src/app/models/entitylink';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { ACTION_STATUS_ENTITY, IEntity } from 'src/app/models/sharedInterfaces';
import { Tools } from 'src/app/helpers/tools-helper';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { CameraService } from 'src/app/services/camera.service';
import { BasePage } from 'src/app/baseClasses/base-page';


@Component({
  selector: 'app-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.scss'],
})
export class NoteModalComponent extends BasePage {

  public note: IEntitylink;
  public monthNames: string[];
  public monthShortNames: string[];
  public noteTime: string;
  public noteFormGroup: FormGroup;
  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected navParams: NavParams,
    protected translateSvc: TranslateService,
    protected modalCtr: ModalController,
    protected configService: ConfigurationService,
    public accountService: AccountService,
    private cameraService: CameraService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.note = navParams.get("note");
    this.noteFormGroup = new FormGroup({
      "title": new FormControl(""),
      "date": new FormControl(undefined, [Validators.required]),
      "time": new FormControl(undefined, [Validators.required]),
      "description": new FormControl("")
    }, { validators: this.TitleOrDescription });

    moment.locale(this.configService.getCurrentLanguage());
    this.monthNames = moment.months();
    this.monthShortNames = moment.monthsShort();

    this.translateSvc.setDefaultLang(this.configService.getCurrentLanguage());

    // split datetime value into date and time widget
    const entityNote: EntityNote = this.note.entityData;
    const m = moment(entityNote.datetime);
    this.noteTime = this.construcHour(m.hours(), m.minutes());
    // set default value to datetime field
    if (this.note.actionStatus === ACTION_STATUS_ENTITY.CREATED) {
      this.note.entityData.datetime = Tools.getToday().format();
    }
    this.note.entityData.datetime = this.note.entityData?.datetime ? Tools.momentNoHours(this.note.entityData.datetime).format() : null;
    if (this.note) {
      this.noteFormGroup.get('title').setValue(this.note.entityData?.name);
      this.noteFormGroup.get('date').setValue(this.note.entityData?.datetime);
      this.noteFormGroup.get('time').setValue(this.noteTime);
      this.noteFormGroup.get('description').setValue(this.note.entityData?.data);
    } else {
      this.noteFormGroup.get('date').setValue(this.note.entityData?.datetime);
      this.noteFormGroup.get('time').setValue(this.noteTime);
    }
  }

  public construcHour(h: number, m: number): string {
    return ((h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m)).toString();
  }

  public getMinutes(noteTime: string): string {
    return noteTime[3] + noteTime[4];
  }

  public getHours(noteTime: string): string {
    return noteTime[0] + noteTime[1];
  }

  /**
     *  cancel edition
     */
  public dismiss() {
    this.modalCtr.dismiss();
  }

  /**
    * save modification
    */
  public save() {
    if (this.noteFormGroup.errors?.['TitleOrDescription']) {
      // reassemble title and data into the note
      const entityNote: EntityNote = this.note.entityData;
      if (this.noteFormGroup.get("title").value) {
        entityNote.name = this.noteFormGroup.get("title").value;
      } else {
        entityNote.name = this.noteFormGroup.get("description").value.split(" ", 3).join(" ");
      }
      entityNote.data = this.noteFormGroup.get("description").value;
      // reinit hours
      entityNote.datetime = moment(this.noteFormGroup.get("date").value).startOf('day').format();
      console.info("ModalNote Save", this.noteFormGroup.get("date").value, ">", this.noteFormGroup.get("time").value);
      const hour = this.getHours(this.noteFormGroup.get("time").value);
      const min = this.getMinutes(this.noteFormGroup.get("time").value);
      const mDatetime = moment(this.noteFormGroup.get("date").value).add(hour, "hours").add(min, "minutes");
      console.info("ModalNote", mDatetime.format());
      entityNote.datetime = mDatetime.format();

      if (!moment(entityNote.datetime).isValid()) {
        entityNote.datetime = null;
        console.error('saveNote ERR INVALID DATE :', this.noteFormGroup.get("date").value);
      }
      this.note.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
      this.note.modified = moment().format();
      this.modalCtr.dismiss(this.note);
    } else {
      this.popupService.showAlert('application.title', 'mynotes.errorEmpty');
      return;
    }
  }

  /**
   * delete note (in fact, set status to delete)
   */
  public delete() {
    this.note.actionStatus = ACTION_STATUS_ENTITY.DELETED;
    this.note.modified = moment().format();
    this.modalCtr.dismiss(this.note);
  }

  /**
   *  update private state
   */
  public private() {
    if (IEntity.isPublicShared(this.note)) IEntity.setPrivate(this.note);
    else IEntity.setShared(this.note);
    // (this.note.entityData as EntityNote).isPrivate = !(this.note.entityData as EntityNote).isPrivate;
    this.note.actionStatus = ACTION_STATUS_ENTITY.MODIFIED;
    this.note.modified = moment().format();
  }

  /**
   * Is this note public or private ?
   */
  public isPublic() {
    return IEntity.isPublicShared(this.note);
  }

  /**
   * For the browser, search a local picture
   * @param e
   */
  public addPhoto(e): void {
    const file = e.target.files[0];
    this.getPhoto(file).then(s => {
      (this.note.entityData as EntityNote).photo = s;
      // this.ref.detectChanges();
    });
  }

  /**
   * For the browser
   * @param file
   * @returns
   */
  private getPhoto(file): Promise<string> {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        res(reader.result as string);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
   * For smartphone, take a picture and save in this note
   */
  public async onPhoto() {
    try {
      const base64Picture = await this.cameraService.createPictureBase64();
      if (base64Picture) {
        (this.note.entityData as EntityNote).photo = base64Picture;
      }
    } catch (error) {
      console.error('onPhoto', error);
    }
  }

  /**
   * Erase the drug picture
   */
  public onDeletePhoto() {
    (this.note.entityData as EntityNote).photo = null;
  }

  private TitleOrDescription: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const title = control.get('title');
    const description = control.get('description');
    if (title.value !== '' || description.value !== '') {
      return { TitleOrDescription: true };
    }
    return null;
  }

}
