import { Injectable } from "@angular/core";
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';

@Injectable({
    providedIn: 'root'
})
export class CameraService {

    private defaultCameraOptions: CameraOptions = {
        correctOrientation: true,
        allowEdit: false, // required https://github.com/apache/cordova-plugin-camera/issues/718
        quality: 80,
        destinationType: this.camera.DestinationType.FILE_URI,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        targetWidth: 500,
        targetHeight: 500
    };

    constructor(
        private camera: Camera,
        private file: File,
    ) { }


    public async createPictureBase64(options: CameraOptions = this.defaultCameraOptions): Promise<string | null> {
        try {
            const imageData = await this.camera.getPicture(options);
            if (!imageData) {
                return null;
            }
            const base64Image = await this.getFileContentAsBase64(imageData);
            return base64Image;
        } catch (error) {
            console.error("CameraService", error);
            return null;
        }
    }

    /**
     * Convert local file to base64
     * @param imagePath the path to the image
     */
    private getFileContentAsBase64(imagePath: string): Promise<string> {
        const filename = imagePath.substring(imagePath.lastIndexOf('/') + 1);
        const path = imagePath.substring(0, imagePath.lastIndexOf('/') + 1);
        return this.file.readAsDataURL(path, filename);
    }

}
