import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private static loader: HTMLIonLoadingElement;
  private static toast: HTMLIonToastElement;
  private static isProcessingToast = false;

  constructor(
    private loadingCtrl: LoadingController,
    private translateSvc: TranslateService,
    private popupService: PopupService
  ) { }

  /**
   * display/hide loading animation
   * Notice : use this method with the keyword "await"
   * @param show 
   * @param msg (string) optional.
   */
  public async showLoading(show: boolean, msg?: string): Promise<any> {
    try {
      if (LoaderService.loader && show && msg) {
        // if we want to show a new loader with a different message while
        // a loader is already running, we cannot.
        return;
      }
      if (!LoaderService.loader && show) {
        const translatedMsg = msg ? this.translateSvc.instant(msg) : this.translateSvc.instant("application.waiting");
        LoaderService.loader = await this.loadingCtrl.create({ message: translatedMsg });
        LoaderService.loader.onDidDismiss().then((dis) => { LoaderService.loader = null; });
        await LoaderService.loader.present();
      }
      if (LoaderService.loader && !show) {
        await LoaderService.loader.dismiss();
        LoaderService.loader = null;
      }
    } catch (err) {
      console.error('Loader service error: ', err);
      await LoaderService.loader?.dismiss();
      LoaderService.loader = null;
    }
  }

  public async showSavingToast(show: boolean, msg?: string): Promise<void> {
    try {
      if (LoaderService.toast && show) {
        // if we want to show a new toast while
        // a toast is already running, we cannot.
        return;
      }
      if (LoaderService.isProcessingToast) {
        return;
      }
      LoaderService.isProcessingToast = true;
      if (!LoaderService.toast && show) {
        const message = msg ? msg : 'application.saving';
        LoaderService.toast = await this.popupService.createToast(message, 'bottom', 'warning');
        LoaderService.toast.onDidDismiss().then((dis) => { LoaderService.toast = null; });
        await LoaderService.toast.present();
      }
      if (LoaderService.toast && !show) {
        await LoaderService.toast.dismiss();
        LoaderService.toast = null;
      }
      LoaderService.isProcessingToast = false;
    } catch (err) {
      console.error('Loader service error: ', err);
      await LoaderService.toast?.dismiss();
      LoaderService.toast = null;
    }
  }
}
