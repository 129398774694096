import { Injectable } from '@angular/core';
import { IQuestionnaire } from 'src/app/models/questionnaire';
import { ServerResponse, SERVER_RESPONSE_TYPE } from '../helpers/server-response-helper';
import { Tools } from '../helpers/tools-helper';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FreeQuestionnaireService {

  constructor(
    private apiService: ApiService
  ) { }

  public async get(lang?: string, identifier?: string): Promise<IQuestionnaire> {
    try {
      const rep = await this.apiService.getWithPromise(`/freeQuestionnaire?identifier=${identifier}&version=${lang}`);
      const type = ServerResponse.type(rep);
      switch (type) {
        case SERVER_RESPONSE_TYPE.SUCCESS:
          const t = rep.data as IQuestionnaire;
          if (Tools.isNotDefined(t)) {
            return null;
          }
          return t;
        default:
          console.error("freeQuestionnaire error route", type);
          return null;
      }
    } catch (err) {
      console.error("freeQuestionnaire getWithPromise()", err);
      return null;
    }
  }
}
