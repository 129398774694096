import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BasePage } from 'src/app/baseClasses/base-page';
import { IRewardDefinition } from 'src/app/models/rewardDefinition';
import { IRewardScore, IRewardScoreHistory } from 'src/app/models/rewardScore';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-reward-history',
  templateUrl: './reward-history.component.html',
  styleUrls: ['./reward-history.component.scss'],
})
export class RewardHistoryComponent extends BasePage {
  public score: IRewardScore;
  public definition: IRewardDefinition;
  public histories: IRewardScoreHistory[];

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected modalCtrl: ModalController,
    translateService: TranslateService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.histories = this.score.actionsHistory.sort((a, b) => {
      return (moment(a.date, 'YYYY-MM-DD HH:mm').isSameOrBefore(moment(b.date, 'YYYY-MM-DD HH:mm')) ? 1 : -1);
    });
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }
}
