import { Injectable } from '@angular/core';
import { AutoCompleteService } from 'ionic4-auto-complete';
import { EntityDrug } from '../models/entitylink';

@Injectable({
  providedIn: 'root'
})
export class DrugQuantitiesAutoCompleteServiceService implements AutoCompleteService {
  public labelAttribute = "officialName";
  private options = EntityDrug.QUANTITTY;

  constructor() { }

  /**
   * @param keyword 
   */
  /* Notices : in general, it can return 
      - an Observable that produces an array
      - a Subject (like an Observable)
      - a Promise that provides an array
      - directly an array of values
  */
  public getResults(keyword: string): Array<string> | boolean {
    if (keyword?.length === 0) {
      return false; // false allows to the plugin do not print the "suggestion"
    }
    const result = [];
    const foundResult = this.options
      .filter(item => item.key.toLowerCase().startsWith(keyword.toLowerCase()));
    foundResult.forEach(i => result.push(i.key));
    return result;
  }
}
