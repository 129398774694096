import { Pipe, PipeTransform } from "@angular/core";
import { ITranslation } from "../models/translation";
import { ConfigurationService } from "../services/globalDataProvider/configuration.service";
import { InfoAppService } from "../services/info-app.service";

@Pipe({ name: "getTranslation" })
export class GetTranslationPipe implements PipeTransform {
    constructor(private configService: ConfigurationService) { }

    public transform(definition: ITranslation, backup: string) {
        return InfoAppService.getTranslation(definition, this.configService.getCurrentLanguage(), backup);
    }
}
