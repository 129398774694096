export enum E2E_ID_OBS {
    ADD_OBS = "addObs_for_",
    EDIT_OBS = "edit_obs_for_",
    SHOW_ENCODED_OBS = "show_encoded_obs_for_",
    NEW_OBS_CONTENT = "new_obs_for_",
    SAVE_OBS = "save_obs_btn",
    DELETE_OBS = "delete_obs_btn",
    INPUT_NUMBER_OBS = "number_field_for_",
    INPUT_NUMBER_RECOM_OBS = "number_recom_fied_for_",
    INPUT_TEXT_OBS = "text_field_for_",
    INPUT_RANGE_OBS = "range_field_for_",
    INPUT_RANGE_RECOM_OBS = "range_recom_field_for_",
    INPUT_BOOLEAN_OBS = "boolean_field_for_",
    DATE_PICKER = "add_obs_datePicker"
}

export enum E2E_ID_HELP {
    CLOSE_HELP_MODAL = "closeHelpModal",
}

export enum E2E_ID_HOMEPAGE {
    MY_TRACKERS_BTN = "myTrackersBtn",
}

export enum E2E_ID_LOGIN {
    INPUT_USERNAME = "user",
    INPUT_PASSWORD = "password",
    SUBMIT = "submit_btn",
    VERSION = "version_btn"
}
