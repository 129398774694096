import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IQuestionnaire } from 'src/app/models/questionnaire';
import { AccountService } from './account.service';
import { ConfigurationService } from './configuration.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireDefinitionService extends BasicSyncService<IQuestionnaire, IQuestionnaire[]> {

  private lastValueOfParam = '';
  private storageKey = 'QuestionnaireDefinitionLastValue';

  public get entityStoreKey() {
    return super.entityStoreKey + this.lastValueOfParam;
  }

  constructor(
    protected dataService: DataService,
    private accountService: AccountService,
    private configService: ConfigurationService,
    private localStorage: LocalStorageService) {
    super(dataService);
  }

  public getUrl() {
      return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<IQuestionnaire[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
        entityPrefix: 'questionnairesDef_',
        entityStoreKey: 'list',
        getUrl: '/questionnaires?enableWhenArray=true&lang=',
        setUrl: '/questionnaires', // not settable from application
        expirationDays: 10,
        encrypted: false,
    };
  }

  public async init() {
    try {
        super.init();
        this.lastValueOfParam = await this.localStorage.getData(this.storageKey, false);
    } catch (err) {
        this.lastValueOfParam = '';
    }
  }

  public clear() {
    super.clear();
    this.lastValueOfParam = '';
  }

  public async *getDataReader(): AsyncGenerator<IQuestionnaire[], IQuestionnaire[], IQuestionnaire[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      this.lastValueOfParam = this.configService.getCurrentLanguage();
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, false);
      const dataReader = super.getDataReader();
      let d: IQuestionnaire[] = [];
      for await (const data of dataReader) {
        d = data;
        yield d;
      }
      return d;
    } catch (err) {
      console.error("QuestionnaireDefinitionService getDataReader()", err);
      yield [];
      return [];
    }
  }
}
