import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { IRelatedPerson, RelatedHelper } from 'src/app/models/relatedPerson';
import { Coding } from 'src/app/models/sharedInterfaces';
import { RelatedPersonsService } from 'src/app/services/globalDataProvider/related-persons.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import { SysAccountService } from 'src/app/services/sys-account.service';
import { LoaderService } from 'src/app/services/loader.service';
import { BasePage } from 'src/app/baseClasses/base-page';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';

@Component({
  selector: 'app-entourage-person-access-modal',
  templateUrl: './entourage-person-access-modal.component.html',
  styleUrls: ['./entourage-person-access-modal.component.scss'],
})
export class EntouragePersonAccessModalComponent extends BasePage {

  @Input() public relatedPerson: IRelatedPerson;
  public lastSynchroDate: string;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected translateService: TranslateService,
    protected modalCtrl: ModalController,
    protected navParams: NavParams,
    protected relatedPersonsService: RelatedPersonsService,
    private sysAccountService: SysAccountService,
    private loaderService: LoaderService,
    configService: ConfigurationService
  ) {
    super(translateService, configService, infoService, popupService);
    this.relatedPerson = navParams.get("relatedPerson");
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    const sa = this.sysAccountService.cachedSysAccount;
    this.lastSynchroDate = moment(sa.lastSynchro).format('L LT');
  }

  /**
   *  close modal 
   */
  public dismiss() {
    this.modalCtrl.dismiss();
  }

  /**
* data change
*/
  public async onMainToggleSwitch(event: any) {
    // set active based on global toggle checked or not
    this.relatedPerson.active = (event.detail.checked ? true : false);
    // Save 
    try {
      await this.relatedPersonsService.save(this.relatedPerson);
    } catch (err) {
      console.error('Entourage access modal, error while saving related', err);
      await this.loaderService.showSavingToast(false);
    }
  }

  /**
   * Set RelationShip right
   * @param relationship this is a relationship of this.relatedPerson
   */
  public async onToggleSwitch(relationship: Coding, event: any) {
    // set right based on specific toggle checked or not
    relationship.code = (event.detail.checked ? RelatedHelper.SHARE_RIGHT_READONLY : RelatedHelper.SHARE_RIGHT_NOACCESS);
    // save
    try {
      await this.relatedPersonsService.save(this.relatedPerson);
    } catch (err) {
      console.error('Entourage access modal, error while saving related', err);
      await this.loaderService.showSavingToast(false);
    }
  }
}
