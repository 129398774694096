import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EntityDrug } from "../models/entitylink";
import { ActivityService } from "../services/activity.service";

/**
 *  Get human friendly description for frequency
 */
@Pipe({ name: "getScheduledFrequencyDescription" })
export class GetScheduledFrequencyDescriptionPipe implements PipeTransform {

    constructor(
        private activityService: ActivityService,
        private translateService: TranslateService
    ) { }

    public transform(drug: EntityDrug, withShortLabel: boolean): string {
        if (drug.cycle && drug.cycle.cycle?.length) {
            return this.translateService.instant("drugSchema.withCycle");
        }
        return this.activityService.getScheduledFrequencyDescription(drug.frequency, withShortLabel);
    }
}
