import { Injectable } from '@angular/core';
import { Tools } from '../helpers/tools-helper';
import { LocalStorageService } from './storage/local-storage.service';
import { Md5 } from 'ts-md5';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public static getMd5HashOfObject(object: object, keyToExclude: string[] = []): string {
    if (Tools.isNotDefined(object)) {
      return null;
    }

    object = Tools.deepCopy(object);
    keyToExclude.forEach(key => {
      delete object[key];
    });

    return Md5.hashStr(JSON.stringify(Tools.sortObjectByKeys(object))).toString();
  }

  private async getParsedHashArray(key: string): Promise<any[]> {
    let hashArray: any[] = [];
    const isStored = await this.localStorageService.isStored(key, false);
    if (isStored) {
      return hashArray = JSON.parse(await this.localStorageService.getData(key, false));
    } else { return []; }
  }

  public async checkObjectHashInStorage(object: object, key: string, keyToIgnore: string[] = []): Promise<boolean> {
    const arrayOfHash = await this.getParsedHashArray(key);
    if (arrayOfHash.length <= 0) { return false; }
    const hash = HashService.getMd5HashOfObject(object, keyToIgnore) as string;
    return arrayOfHash.includes(hash);
  }

  public storeArray(key: string, array: any[], keyToIgnore: string[] = []): Promise<any> {
    const hashes: string[] = array.map(el => HashService.getMd5HashOfObject(el, keyToIgnore) as string);
    return this.localStorageService.setData(key, JSON.stringify(hashes), false);
  }
}
