import { Pipe, PipeTransform } from "@angular/core";
import { IExternalRessource } from "../models/externalRessource";
import { MedicalBluetooth } from "../services/medical-bluetooth/medical-bluetooth.service";

@Pipe({ name: "getHardwareByCategory" })
export class GetHardwareByCategoryPipe implements PipeTransform {

    constructor() { }

    public transform(category: string, ressources: IExternalRessource[]): IExternalRessource[] {
        return ressources.filter((ressource) => ressource.categories.indexOf(category) > -1);
    }
}

@Pipe({ name: "isActiveHardwareBonded" })
export class IsActiveHardwareBondedPipe implements PipeTransform {

    constructor(protected medicalBluetoothService: MedicalBluetooth) { }

    public transform(hardware: IExternalRessource): boolean {
        if (!hardware) {
            return false;
        } else {
            return this.medicalBluetoothService.bondedDevices.find((device) => {
                return hardware.reference
                    && device.name.indexOf(hardware.reference) > -1
                    && device.bonded;
            }) !== undefined;
        }
    }
}
