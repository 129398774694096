import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, BehaviorSubject, Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Tools } from 'src/app/helpers/tools-helper';
import { IConfiguration } from 'src/app/models/configuration';
import { IEntity } from 'src/app/models/sharedInterfaces';
import { SysAccount } from 'src/app/models/sysaccount';
import { SysAccountService } from '../sys-account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { CustomParamKey } from '../../models/dataParameters';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { DataService } from './core/data.service';
import { LoaderService } from '../loader.service';
import { LanguagesService } from './languagesService';
import { ScheduledBefore, Timings } from 'src/app/models/timingData';
import { NOTIFICATION_TYPE } from 'src/app/models/notification';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService extends BasicSyncService<IConfiguration, IConfiguration> {

    private observationLoincConversionMap = Object.freeze({
        "1": "8310-5", // temperature
        "2": "55284-4", // blood pressure
        "3": "8867-4", // heart rate
        "4": "3141-9", // body weight
    });

    private lastLang: string;

    constructor(
        protected dataService: DataService,
        private sysAccountService: SysAccountService,
        private loaderService: LoaderService,
        private languagesService: LanguagesService
    ) {
        super(dataService);
        this.languagesService.getLastConfigLanguage().then((lang) => {
            this.lastLang = lang;
        });
    }

    protected setupDataParameters(): void {
        this.defaultDataParameter = {
            entityPrefix: 'configuration_',
            entityStoreKey: 'list',
            getUrl: '/configuration',
            setUrl: '/configuration',
            expirationDays: 10,
            encrypted: true,
            customParam: {
                [CustomParamKey.overwriteQueue]: true
            }
        };
    }

    protected clearWatch(): void {
        this.data$ = new BehaviorSubject<IConfiguration>(null);
    }

    protected initWatch(): void {
        this.data$.next(null);
    }

    public pokeData(data: IConfiguration): void {
        super.pokeData(data);
        if (data?.settings?.globalSettings?.language) {
            this.languagesService.setLastConfigLanguage(data.settings.globalSettings.language);
        }
    }

    public async *getDataReader(): AsyncGenerator<IConfiguration, IConfiguration, IConfiguration> {
        try {
            const dataReader = this.dataService
                .readv2<IConfiguration, IConfiguration>(this.defaultDataParameter, false, this, false, false);
            let d: IConfiguration = null;
            for await (const data of dataReader) {
                d = data;
                yield d;
            }
            return d;
        } catch (err) {
            console.error("ConfigurationService getDataReader()", err);
            yield null;
            return null;
        }
    }

    public save(data: IConfiguration) {
        const savePromise = this.dataService.save(data, {
            ...this.defaultDataParameter,
            method: SYNC_HTTP_METHOD.POST
        }).then((rep) => {
            this.pokeData(rep);
            return rep;
        });
        return from(savePromise);
    }

    public async saveWithPromise(data: IConfiguration, withToast = true): Promise<IConfiguration> {
        if (withToast) { await this.loaderService.showSavingToast(true); }
        const d = await this.dataService.save(data, {
            ...this.defaultDataParameter,
            method: SYNC_HTTP_METHOD.POST
        }).then((rep) => {
            this.pokeData(rep);
            return rep;
        });
        if (withToast) { await this.loaderService.showSavingToast(false); }
        return d;
    }

    public getCacheConfiguration(): IConfiguration {
        return this.peekData();
    }

    public async refreshConfiguration(): Promise<SysAccount> {
        await this.getFreshestData();
        const sA = await this.sysAccountService.getSysAccount();
        return sA;
    }
    /**
     * Set the current language for configuration return false if config not get yet
     * @param lang 
     */
    public async setCurrentLanguage(lang: string): Promise<boolean> {
        const config = this.peekData();
        if (config) {
            config.settings.globalSettings.language = lang;
            this.lastLang = lang;
            const success = this.saveWithPromise(config)
                .then((d) => { return true; })
                .catch((err) => {
                    console.error('ConfigurationService, error while trying to set new lang', err);
                    return false;
                });
            return success;
        } else {
            return false; // not loaded yet
        }
    }

    public setLastLangUsed(lang: string) {
        if (!this.lastLang) {
            this.lastLang = lang;
        }
    }

    /**
     * Originaly created for CMATE-501.
     * This is not used anymore
     * @deprecated
     */
    public setLastSyncActivityReport(): Observable<IConfiguration> {
        const config = this.peekData();
        if (config) {
            config.settings.lastSyncActivityReport = moment().format("DD-MM-YYYY");
            return this.save(config);
        }
    }

    /**
     * Originaly created for CMATE-501.
     * This is not used anymore
     * @deprecated
     */
    public refreshLastSyncDate(): Observable<IConfiguration> {
        const config = this.peekData();
        if (config) {
            config.settings.lastSyncActivityReport = moment().add(-8, "day").format("DD-MM-YYYY");
            return this.save(config);
        }
    }

    public getCurrentLanguage(): string {
        try {
            const config = this.peekData();
            if (config?.settings?.globalSettings?.language) {
                return config.settings.globalSettings.language;
            } else if (this.lastLang) {
                return this.lastLang;
            } else if (window.Intl && typeof window.Intl === 'object') {
                // get the phone language:
                const l = navigator.language.split('-');
                return l[0];
            }
            return 'fr';
        } catch (error) {
            return 'fr';
        }
    }

    /**
     * Checks if the phone language (if taken) is well managed by our application. If it is not the case, we return en
     * @returns 
     */
    public async getCurrentLanguageWithCheck(): Promise<string> {
        try {
            const config = this.peekData();
            if (config?.settings?.globalSettings?.language) {
                return config.settings.globalSettings.language;
            } else if (this.lastLang) {
                return this.lastLang;
            } else if (window.Intl && typeof window.Intl === 'object') {
                // get the phone language:
                const l = navigator.language.split('-');
                const listOfLang = await this.languagesService.listOfKeys();
                if (!listOfLang.includes(l[0])) {
                    return "fr";
                }
                else {
                    return l[0];
                }
            }
            return 'fr';
        } catch (error) {
            return 'fr';
        }
    }

    /**
     * Check if param exist, if not return true by default
     */
    public get showToast(): boolean {
        const param = this.peekData()?.settings?.globalSettings?.showRewardToast;
        return Tools.isValidBool(param) ? param : true;
    }

    public get currentFollowedReward(): string {
        return this.peekData()?.settings?.globalSettings?.currentRewardFollowed || '';
    }

    public set showToast(v: boolean) {
        const config = this.peekData();
        if (config) {
            config.settings.globalSettings.showRewardToast = v;
            this.saveWithPromise(config);
        }
    }

    public set currentFollowedReward(v: string) {
        const config = this.peekData();
        if (config) {
            config.settings.globalSettings.currentRewardFollowed = v;
            this.saveWithPromise(config);
        }
    }
    /**
     * Get user custom timing for morning, noon,  evening, ...
     * return minutes since 00:00
     * @param timing
     */
    public getUserTiming(timing: string, weekend: boolean): number {
        let userTiming = 0;
        const config = this.peekData();
        switch (timing) {
            case IEntity.RISING:
                userTiming = weekend ? config?.settings.notificationSettings.riseHourWeekend : config?.settings.notificationSettings.riseHourWeek;
                break;
            case IEntity.MORNING:
                userTiming = weekend ? config?.settings.notificationSettings.morningHourWeekend : config?.settings.notificationSettings.morningHourWeek;
                break;
            default:
            case IEntity.NOON:
                userTiming = weekend ? config?.settings.notificationSettings.noonHourWeekend : config?.settings.notificationSettings.noonHourWeek;
                break;
            case IEntity.EVENING:
                userTiming = weekend ? config?.settings.notificationSettings.eveningHourWeekend : config?.settings.notificationSettings.eveningHourWeek;
                break;
            case IEntity.BEDING:
                userTiming = weekend ? config?.settings.notificationSettings.bedHourWeekend : config?.settings.notificationSettings.bedHourWeek;
                break;
        }
        userTiming = (userTiming < 24) ? (userTiming * 60) : userTiming;    // handle old format where time was a full hour (without minutes)
        return userTiming;
    }
    public getUserTimings(): Timings {
        const config = this.peekData();
        if (!config) { return {}; }
        const settings = config.settings.notificationSettings;
        const timings: Timings = { }
        timings[IEntity.RISING] = this.handleOldTimingFormat(settings.riseHourWeek);
        timings[IEntity.MORNING] = this.handleOldTimingFormat(settings.morningHourWeek);
        timings[IEntity.NOON] = this.handleOldTimingFormat(settings.noonHourWeek);
        timings[IEntity.EVENING] = this.handleOldTimingFormat(settings.eveningHourWeek);
        timings[IEntity.BEDING] = this.handleOldTimingFormat(settings.bedHourWeek);

        timings[IEntity.RISING + "_weekend"] = this.handleOldTimingFormat(settings.riseHourWeekend);
        timings[IEntity.MORNING + "_weekend"] = this.handleOldTimingFormat(settings.morningHourWeekend);
        timings[IEntity.NOON + "_weekend"] = this.handleOldTimingFormat(settings.noonHourWeekend);
        timings[IEntity.EVENING + "_weekend"] = this.handleOldTimingFormat(settings.eveningHourWeekend);
        timings[IEntity.BEDING + "_weekend"] = this.handleOldTimingFormat(settings.bedHourWeekend);
        return timings;
    }
    public getNotifsSchedules(): ScheduledBefore {
        const config = this.peekData();
        if (!config) { return {}; }
        const settings = config.settings.notificationSettings;
        const timings: ScheduledBefore = { }
        timings[NOTIFICATION_TYPE.DRUG] = settings.scheduledBefore[0];
        timings[NOTIFICATION_TYPE.APPOINTMENT] = settings.scheduledBefore[3];
        timings[NOTIFICATION_TYPE.FEELING] = settings.scheduledBefore[2];
        timings[NOTIFICATION_TYPE.OBSERVATION] = settings.scheduledBefore[1];
        return timings;
    }

    private handleOldTimingFormat(timing: number) {
        return (timing < 24) ? (timing * 60) : timing;
    }

    public getLoincFromConfiguration(): string[] {
        return this.peekData()?.parameters.observationParams.map(o => (this.observationLoincConversionMap[o.type] || o.type) as string);
    }

    public getLastSyncActivityReport() {
        return this.peekData()?.settings.lastSyncActivityReport;
    }



}
