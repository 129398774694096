import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ArrayHelper } from 'src/app/helpers/array-helper';
import { FHIR_ActivityHelper } from 'src/app/helpers/fhirActivityHelper';
import { Tools } from 'src/app/helpers/tools-helper';
import { Careplan, ICareplan } from 'src/app/models/careplan';
import { IRelatedPerson } from 'src/app/models/relatedPerson';
import { AccountService } from './account.service';
import { ConfigurationService } from './configuration.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { LocalStorageService } from '../storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RelatedCareplansService extends BasicSyncService<ICareplan, ICareplan[]> {

  private currentCareplan$ = new BehaviorSubject<ICareplan | null>(null);
  
  private lastValueOfParam = '';
  private storageKey = 'RelatedCareplanLastValue';

  public get entityStoreKey() {
    return super.entityStoreKey + this.lastValueOfParam;
  }

  constructor(
    protected dataService: DataService,
    private configService: ConfigurationService,
    private accountService: AccountService,
    private localStorage: LocalStorageService) {
    super(dataService);
  }

  public getUrl() {
    return super.getUrl() + this.lastValueOfParam;
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<ICareplan[]>([]);
    this.currentCareplan$ = new BehaviorSubject<ICareplan | null>(null);
  }

  protected initWatch(): void {
    this.data$.next([]);
    this.currentCareplan$.next(null);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'relatedCareplans_',
      entityStoreKey: 'list',
      getUrl: '/relatedCareplans?lang=',
      setUrl: null,
      expirationDays: 10,
      encrypted: true
    };
  }

  /**
   * Watch the changes in the currentCareplan
   * @return a observable with the currentCareplan
   */
   public watchCurrentCareplan(): Observable<ICareplan> { return this.currentCareplan$; }
   /**
    * Returns the current state of the currentCareplan
    */
   public peekCurrentCareplan(): ICareplan { return this.currentCareplan$.value; }
   /**
    * Tell all those that watch the currentCareplan that there's a new version
    * @param currentCareplan the new currentCareplan
    */
   public pokeCurrentCareplan(careplan: ICareplan): void { this.currentCareplan$.next(careplan); }

   public async init(): Promise<void> {
    try {
      super.init();
      this.lastValueOfParam = await this.localStorage.getData(this.storageKey, false);
    } catch (err) {
      this.lastValueOfParam = '';
    }
  }

  public clear() {
    super.clear();
    this.lastValueOfParam = '';
  }
  
  public async *getDataReader(related: IRelatedPerson = null): AsyncGenerator<ICareplan[], ICareplan[], ICareplan[]> {
    try {
      if (this.accountService.isNotRelated()) {
        yield [];
        return [];
      }
  
      const paramObject = Object.assign({}, this.defaultDataParameter);
      paramObject.getUrl += this.configService.getCurrentLanguage();
      paramObject.entityStoreKey += this.configService.getCurrentLanguage();
      this.lastValueOfParam = this.configService.getCurrentLanguage();
      this.localStorage.setData(this.storageKey, this.lastValueOfParam, false);

      const dataReader = this.dataService.readv2<ICareplan, ICareplan[]>(paramObject, false, this);
      let d: ICareplan[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, related);
        yield d;
      }
      return d;
    } catch (err) {
      console.error("CareplanService getDataReader()", err);
      yield [];
      return [];
    }
  }
  private processData(dataResult: ICareplan[], related: IRelatedPerson) {
    try {
      const filteredCareplans = dataResult.filter((c) => {
        return c.status !== Careplan.CAREPLAN_STATUS_ARCHIVE;
      });
      if (related) {
        return filteredCareplans.filter((c) => {
          return (c.subject.reference === related.patient.reference);
        });
      } else {
        return filteredCareplans;
      }
    } catch (err) {
      console.error('Error while processing relatedCareplansService data: ', err);
      return [];
    }
  }

  public async listSnomedRef(onlyCareplan = false): Promise<string[]> {
    const careplans = await this.getFirstDataAvailable();
    return careplans.map((careplan) => {
      const snomedActivities = onlyCareplan ? [] as string[] : careplan.activity.map((activity) => {
        return FHIR_ActivityHelper.getActivitySnomedRef(activity);
      }).reduce((acc, it) => [...acc, ...it], []);
      return snomedActivities.concat(FHIR_ActivityHelper.getCareplanSnomedRef(careplan));
    }).reduce((acc, it) => [...acc, ...it], [])
      .filter(ArrayHelper.onlyUnique)
      .map(Tools.deleteAcccentSpecialcharacter);
  }
}
