import { Component, EventEmitter, Input, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { BaseComponent } from 'src/app/baseClasses/base-component';
import { IObservation, IObservationDefinition, Observation } from 'src/app/helpers/observation-helper';
import { IObservationParam } from 'src/app/models/configuration';
import { ObservationDefinitionService } from 'src/app/services/globalDataProvider/observation-definition.service';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';

@Pipe({ name: 'getObsDef' })
export class GetObsDefPipe implements PipeTransform {
  constructor(protected obsDefService: ObservationDefinitionService) { }

  transform(type: string): IObservationDefinition {
    return this.obsDefService.getObservationDefinition(type);
  }
}

@Pipe({ name: 'getObservations' })
export class GetObservationsPipe implements PipeTransform {
  constructor(protected obsDefService: ObservationDefinitionService) { }

  /**
   * Return the right observations array
   */
  transform(type: number | string, range: any, observations: IObservation[]): IObservation[] {
    const definition = this.obsDefService.getObservationDefinition(type.toString());
    if (!definition) return [];

    let minRange: moment.Moment;
    switch (range) {
      case "3months":
        minRange = moment().add(-3, "months");
        break;
      default:
        minRange = moment().add(-1, range);
        break;
    }

    return observations.filter(obs => {
      const obstype = Observation.getObservationType(obs);
      return (obstype === definition.loinc && moment(obs.issued).isSameOrAfter(minRange));
    });
  }
}

@Component({
  selector: 'care-observations-list',
  templateUrl: './observations-list.component.html',
  styleUrls: ['./observations-list.component.scss'],
})
export class ObservationsListComponent extends BaseComponent {
  @Input()
  params: Array<IObservationParam> = [];
  @Input()
  observations: IObservation[];
  @Input()
  range: "week" | "month" | "3months";
  @Input()
  view: 'datas' | 'charts' | 'parameters';
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onAddObservation = new EventEmitter<IObservationDefinition>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onEditObservation = new EventEmitter<IObservation>();

  public paramsToShow: Array<IObservationParam> = [];

  constructor(protected infoService: InfoAppService, protected popupService: PopupService) {
    super(infoService, popupService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.paramsToShow = [];
    this.params.forEach(p => {
      if (this.isObservationAvailable(p)) this.paramsToShow.push(p);
    });
  }

  private isObservationAvailable(observationParams: IObservationParam) {
    if (!observationParams.limitedCreationByFrequence) return true;
    if (new Date(observationParams.frequency.boundsPeriod.start) <= new Date()) return true;
    return false;
  }

}
