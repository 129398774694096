import { Component } from '@angular/core';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { IAccount, IConsent } from 'src/app/helpers/account-helper';
import { IKnowledgeBase, IKnowMedia } from 'src/app/helpers/knowledge-helper';
import { Answer, QRQuestion, QuestionnaireResponse } from 'src/app/helpers/questionnaireResponse';
import { IQuestionnaire } from 'src/app/models/questionnaire';
import { Question } from 'src/app/models/questionnaire';
import { Coding } from 'src/app/models/sharedInterfaces';
import { InfoAppService } from 'src/app/services/info-app.service';
import { PopupService } from 'src/app/services/popup.service';
import * as moment from 'moment';
import { ConnectionStatus, NetworkService } from 'src/app/services/network.service';
import { ConsentService } from 'src/app/services/consent.service';
import { AccountService } from 'src/app/services/globalDataProvider/account.service';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { RequestSenderServiceSyncStatus } from 'src/app/services/globalDataProvider/core/request-sender.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from 'src/app/services/globalDataProvider/configuration.service';
import { Tools } from 'src/app/helpers/tools-helper';
import { GoToPageService } from 'src/app/services/go-to-page.service';
import { BasePage } from 'src/app/baseClasses/base-page';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.scss'],
})
export class ConsentModalComponent extends BasePage {
  private knowledges: IKnowledgeBase[];
  private consents: IQuestionnaire[];
  public currentConsentsAndQr: { consent: IQuestionnaire, qr: QuestionnaireResponse, showQuestionnaire: boolean }[][] = [];
  public allMedia: IKnowMedia[];
  public currentMedia: IKnowMedia[] = [];
  public account: IAccount;
  public isReadonly = false;
  public hasAnswered = false;
  public hasError = true;
  public isLastMedia = false;
  public connectionStatus: ConnectionStatus;
  public CONNECTION_STATUS = ConnectionStatus;
  private consentResponses: QuestionnaireResponse[];
  public consent: IConsent;
  public isSaving = false; // true while sendConsent() in progress
  public isRefusing = false; // true while logout() in progress
  private toastShowing: boolean;
  public isLoading: boolean;

  constructor(
    protected infoService: InfoAppService,
    protected popupService: PopupService,
    protected params: NavParams,
    private modalCtrl: ModalController,
    private networkService: NetworkService,
    private accountService: AccountService,
    private consentService: ConsentService,
    private toastController: ToastController,
    private translateService: TranslateService,
    protected configService: ConfigurationService,
    private goToPage: GoToPageService
  ) {
    super(translateService, configService, infoService, popupService);
    this.isReadonly = this.params.get('readOnly');
    this.consent = this.params.get('consent');
  }

  ionViewWillEnter(): void {
    super.ionViewWillEnter();
    // when using this.accountService.peekData(), there is a few cases when account is null, so we use watch until we get the account using takeUntil
    this.accountService.watchData()
      .pipe(
        takeWhile(() => { return (this.account === null || this.account === undefined); }),
        takeUntil(this.onDestroy$))
      .subscribe(
        (account) => {
          this.account = account;
          if (!(this.account)) { return; }
          if (!this.isReadonly) {
            this.networkService.onNetworkChange().pipe(takeUntil(this.onDestroy$)).subscribe((status) => {
              this.connectionStatus = status;

              if (this.connectionStatus === this.CONNECTION_STATUS.Offline) {
                this.popupService.showToast(this.translateService.instant('consent.offline-warning'), 0, "top", null, "danger")
                  .then(() => { this.toastShowing = true; });
              } else if (this.toastShowing) {
                this.toastController.dismiss();
              }

              // load/reload data if no kwnoledge loaded to avoid loosing already completed data in case of connexion loss
              if (!this.knowledges?.length && this.connectionStatus === ConnectionStatus.Online) {
                this.initData();
              }
            });
          } else {
            this.initData();
          }
        },
        (err) => { console.error(err); },
      );
  }

  private async initData() {
    this.isLoading = true;

    const res = this.isReadonly ?
      await this.consentService.getSpecificConsent(this.consent.identifier, this.consent.publicationDate, this.account.role, this.consent.language) :
      await this.consentService.getLastConsent(this.account.role, this.configService.getCurrentLanguage());

    if (res.success) {
      this.knowledges = res.data.knowledges;
      this.consents = res.data.specificQuestionnaire;
      this.allMedia = this.knowledges.reduce((accumulator, value) => accumulator.concat(value.medias), []);

      if (this.isReadonly) {
        const response = await this.consentService.getConsentResponse();
        if (response.success) {
          this.consentResponses = response.data.filter(qr => this.consent.qrId.includes(qr.identifier.value));
          this.allMedia.forEach(media => { this.loadNextMedia(); });
        } else {
          console.error('Cannot get consent responses for consent (knowledge) : ' + this.consent.identifier);
          this.popupService.showAlert('error.general', 'error.tryagain');
        }
      } else { this.loadNextMedia(); }
    } else {
      if (this.isReadonly) {
        console.error('No knowledge found with identifier : ' + this.consent.identifier);
        this.popupService.showAlert('error.notFound', 'error.consentNotFound')
          .then(() => this.modalCtrl.dismiss());
      } else {
        console.error('Error trying to get last consent');
        this.popupService.showAlert('error.general', 'error.killApp');
      }
    }
    this.isLoading = false;
  }

  /**
   * return multiple choice values define in "questionnaire"
   */
  private getValueSetChoices(consent: IQuestionnaire, reference: string, question?: QRQuestion): Coding[] {
    if (consent.contained) {
      for (const element of consent.contained) {
        if ((element.resourceType === "ValueSet")) {
          // This part is basically only for checkbox (mutiple answers) to check if they're checked or not
          if (this.isReadonly && question) {
            element.compose.include[0].concept.forEach((a) => {
              const i = question.answer.findIndex((q) => q.valueCoding.code === a.code);
              a.checked = i > -1;
            });
          } else {
            element.compose.include[0].concept.forEach((a) => a.checked = false);
          }
          return element.compose.include[0].concept;
        }
      }
    }
    return [];
  }

  /**
  * Response choice has changed: update QuestionnaireResponse
  */
  public async onRadioChange($event: CustomEvent, question: Question, consent: IQuestionnaire, iOfMedia: number, iOfConsent, iOfQuestion: number) {
    if (!$event) return;

    const questionnaireResponse = this.currentConsentsAndQr[iOfMedia][iOfConsent].qr;
    const goodAnswers = question.answerDisplay.goodAnswers;

    const choices: Coding[] = this.getValueSetChoices(consent, question.options.reference);
    const code = $event.detail.value;
    const display = choices.find((choice: Coding) => choice.code === code)?.display;

    const answer = new Answer();
    answer.valueCoding = {
      code: code,
      display: display,
      system: null
    };

    // Set answer
    // it's a radio button so only one answer to deal with
    questionnaireResponse.group.group[0].question[iOfQuestion].answer = [answer];

    // check the answer and load next question if right answer

    if (goodAnswers.includes($event.detail.value)) {

      this.hasError = false;

      // show next question
      if (this.currentConsentsAndQr[iOfMedia][iOfConsent + 1]) {
        this.currentConsentsAndQr[iOfMedia][iOfConsent + 1].showQuestionnaire = true;
      }

      // if last question has been shown, load next media
      if (iOfQuestion === consent.group.group[0].question.length - 1
        && iOfConsent === this.allMedia[iOfMedia].specificQuestionnaire.length - 1) {
        this.loadNextMedia();
      }
    } else { this.hasError = true; }
  }

  private loadNextMedia() {
    const index = this.currentMedia.length;

    // stop when last media has been treated
    if (index >= this.allMedia.length) {
      this.isLastMedia = true;
      return;
    }
    this.currentMedia.push(this.allMedia[index]);
    this.loadNextQuestionnaires();
  }

  private loadNextQuestionnaires() {
    // working with the last media of the array
    const currentMediaIndex = this.currentMedia.length - 1;

    // if the media has no questionnaire, directly load the next media
    if (!this.currentMedia[currentMediaIndex].specificQuestionnaire?.length) {
      this.currentConsentsAndQr[currentMediaIndex] = [];
      this.loadNextMedia();
    }

    const r = [];
    this.currentMedia[currentMediaIndex].specificQuestionnaire.forEach(qId => {
      const i = this.consents.findIndex(consent => consent.identifier[0].value === qId);

      if (i > -1) {

        let qr: QuestionnaireResponse;

        if (this.consent) {
          // load the existing QuestionnaireResponse for that media
          qr = this.consentResponses.find(qres => qres.questionnaire.reference.split('/')[0] === this.consents[i].identifier[0].value);
        } else {
          qr = new QuestionnaireResponse();
          qr.fromTemplate(this.consents[i], this.account);
        }

        r.push({
          consent: this.consents[i],
          qr: qr
        });

      }
      this.currentConsentsAndQr[currentMediaIndex] = r;
    });
  }

  public sendConsent() {
    if (this.connectionStatus === ConnectionStatus.Offline) {
      this.popupService.showAlert('error.nonetwork', 'error.needNetwork');
      return;
    }

    this.isSaving = true;

    const qrToSave = this.currentConsentsAndQr.reduce((accumulator, value) => accumulator.concat(value), []).map(el => el.qr);
    Promise.all([...qrToSave.map(qr => this.consentService.save(qr)), Tools.wait(1000)]).then(async (responses) => {

      if (responses.every(res => res.success)) {

        const consent: IConsent = {
          consentDate: moment().format(),
          identifier: this.knowledges[0].identifier.value,
          publicationDate: this.knowledges[0].publicationDate,
          qrId: qrToSave.map((qr) => qr.identifier.value),
          language: this.configService.getCurrentLanguage()
        };

        this.account = await this.accountService.getFreshestData();
        this.account.consent?.length ? this.account.consent.push(consent) : this.account.consent = [consent];
        this.account.needConsent = false;
        this.accountService.setAccount(this.account, true).pipe(takeUntil(this.onDestroy$)).subscribe((d) => {
          switch (d) {
            case RequestSenderServiceSyncStatus.success:
              this.modalCtrl.dismiss(true);
              break;

            case RequestSenderServiceSyncStatus.offline:
              this.popupService.showAlert('error.nonetwork', 'error.needNetwork');
              break;

            default:
              console.error('Cannot set account while saving consent : ' + consent);
              this.popupService.showAlert('error.general', 'error.tryagain');
              break;
          }
          this.isSaving = false;
        },
          (err) => {
            console.error("sendConsent - setAccount", JSON.stringify(err));
            this.popupService.showAlert('error.general', 'error.tryagain');
            this.isSaving = false;
          });
      } else {
        this.popupService.showAlert('error.general', 'error.tryagain');
        this.isSaving = false;
      }

    })
      .catch(err => {
        console.error("sendConsent", JSON.stringify(err));
        this.popupService.showAlert('error.general', 'error.tryagain');
        this.isSaving = false;
      });
  }

  public async logout() {
    this.isRefusing = true;
    await this.goToPage.loginPage({}, true); // proper disconnection is happening after modal dismiss but we already go to login page to avoid access to home while disconnecting
    this.modalCtrl.dismiss(false);
  }

  /**
   * close modal
   */
  public dismiss() {
    if (this.isReadonly) {
      this.modalCtrl.dismiss();
    }
  }
}


