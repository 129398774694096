import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { QuestionnaireResponse } from 'src/app/helpers/questionnaireResponse';
import { ACTION_STATUS_ENTITY, STATUS_ENTITY } from 'src/app/models/sharedInterfaces';
import { AccountService } from './account.service';
import { BasicSyncService } from './core/basic-sync.service';
import { DataService } from './core/data.service';
import { SYNC_HTTP_METHOD } from './core/request-sender.service';
import { LoaderService } from '../loader.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService extends BasicSyncService<QuestionnaireResponse, QuestionnaireResponse[]> {

  constructor(
    protected dataService: DataService,
    private accountService: AccountService,
    private loaderService: LoaderService
  ) {
    super(dataService);
  }

  protected clearWatch(): void {
    this.data$ = new BehaviorSubject<QuestionnaireResponse[]>([]);
  }

  protected initWatch(): void {
    this.data$.next([]);
  }

  protected setupDataParameters(): void {
    this.defaultDataParameter = {
      entityPrefix: 'questionnaireresponses_',
      entityStoreKey: 'list',
      getUrl: '/questionnaireresponses',
      setUrl: '/questionnaireresponse',
      expirationDays: 10,
      encrypted: true,
    };
  }
  /**
   * Returns the current state of the service's data
   */
  public peekData(includeDeleted: boolean = true): QuestionnaireResponse[] {
    return this.processData(super.peekData(), includeDeleted);
  }

  public async *getDataReader(includeDeleted: boolean = false)
    : AsyncGenerator<QuestionnaireResponse[], QuestionnaireResponse[], QuestionnaireResponse[]> {
    try {
      if (this.accountService.isOnlyRelated) {
        yield [];
        return [];
      }
      const dataReader = super.getDataReader();
      let d: QuestionnaireResponse[] = [];
      for await (const data of dataReader) {
        d = this.processData(data, includeDeleted);
        yield d;
      }
      return d;
    } catch (err) {
      console.error("ObservationService getDataReader()", err);
      yield [];
      return [];
    }
  }

  private processData(dataResult: QuestionnaireResponse[], includeDeleted: boolean) {
    try {
      let responses: QuestionnaireResponse[] = dataResult;
      if (!includeDeleted) {
        responses = responses.filter((o) => {
          return o.actionStatus !== ACTION_STATUS_ENTITY.DELETED;
        });
      }
      return responses;
    } catch (err) {
      console.error('Error while processing questionnaireService data: ', err);
      return dataResult;
    }
  }

  public async save(qr: QuestionnaireResponse, withToast = true): Promise<QuestionnaireResponse> {
    const savePromise = this.dataService.saveInArray(qr, (entity) => entity._id === qr._id, {
      ...this.defaultDataParameter,
      method: SYNC_HTTP_METHOD.POST
    }).then((savedQr: QuestionnaireResponse) => {
      const responses = this.peekData(true);
      const i = responses.findIndex(e => e._id === savedQr._id);
      if (i >= 0 && !savedQr.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        responses[i] = savedQr;
      } else if (i < 0 && !savedQr.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        responses.push(savedQr);
      } else if (i >= 0 && savedQr.entityStatus.includes(STATUS_ENTITY.DELETED)) {
        responses.splice(i, 1);
      }
      this.pokeData(responses);
      return savedQr;
    });
    if (withToast) { await this.loaderService.showSavingToast(true); }
    const result = await savePromise;
    if (withToast) { await this.loaderService.showSavingToast(false); }
    return result;
  }

}
