import { Component, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasePage } from '../baseClasses/base-page';
import { ICommunication } from '../models/communication';
import { ConfigurationService } from '../services/globalDataProvider/configuration.service';
import { RewardScoreService } from '../services/globalDataProvider/reward-score.service';
import * as moment from "moment";
import { CommunicationService } from '../services/globalDataProvider/communication.service';
import { AppConstants } from "../../app/appConstants";
import { REWARD_ACTION, REWARD_PAGE_NAME } from '../models/rewardDefinition';
import { PopupService } from '../services/popup.service';
import { InfoAppService } from '../services/info-app.service';
import { GetParametersPageService } from '../services/get-parameters-page.service';
import { ModalService } from '../services/modal.service';
import { HelpService } from '../services/help.service';
import { ViewDidEnter } from '@ionic/angular';
import { Tools } from '../helpers/tools-helper';

@Pipe({ name: "isNewMsg" })
export class IsNewMsgPipe implements PipeTransform {
  constructor() { }

  public transform(message: ICommunication): boolean {
    return message.received === null;
  }
}

@Component({
  selector: 'app-mymessages',
  templateUrl: './mymessages.page.html',
  styleUrls: ['./mymessages.page.scss'],
})
export class MymessagesPage extends BasePage implements ViewDidEnter {
  public helpSeen = true;
  private communications: ICommunication[] = [];
  public filteredCommunications: ICommunication[] = [];
  public contentFilter: string;
  public dateFilterShow = false;
  public startDate: string;
  public endDate: string;
  public monthNames: string[];
  public monthShortNames: string[];
  public hasNoMsg = false;

  constructor(
    protected translateSvc: TranslateService,
    protected configService: ConfigurationService,
    protected popupService: PopupService,
    protected infoService: InfoAppService,
    protected modalService: ModalService,
    protected getParameterPageService: GetParametersPageService,
    protected communicationService: CommunicationService,
    protected rewardScoreService: RewardScoreService,
    protected helpService: HelpService
  ) {
    super(translateSvc, configService, infoService, popupService);
    this.monthNames = moment.months();
    this.monthShortNames = moment.monthsShort();
  }

  /**
   * Enter the view
   */
  ionViewDidEnter() {
    super.ionViewDidEnter();
    // Google Analytics
    // TODO ANALYTICS
    // this.appService.analyticsTrackView(AppConstants.PAGE_MESSAGES);

    // we already get message to compute badge in side menu
    if (this.getParameterPageService.isExistKeyOfActivePage("messages")) {
      this.communications = this.getParameterPageService.getValueOfActivePage("messages", null)?.sort((a: ICommunication, b: ICommunication) => {
        return (moment(a.creation).isSameOrAfter(moment(b.creation)) ? -1 : 1);
      });
      this.pageLoaded = true;
    } else {
      this.getMessages();
    }

    // help page already seen ?
    this.helpService.isHelpPageSeenOn(AppConstants.PAGE_MESSAGES).then((seen) => {
      this.helpSeen = seen;
    });
    this.rewardScoreService.update(REWARD_PAGE_NAME.mymessages, REWARD_ACTION.onConsult);
  }

  /**
   * Open the communication in external modal
   * @param msg 
   */
  public async onOpenMsg(msg: ICommunication) {
    const isDelete = await this.modalService.presentModalMessage(msg);
    if (!msg.received) {
      msg.received = new Date();
    }

    if (isDelete) {
      await this.deleteMsg(msg);
    } else {
      await this.putMsg(msg);
    }
  }


  /**
   * Ask (get) for all communications for the USER
   */
  private async getMessages() {
    try {
      const dataReader = this.communicationService.getDataReader();
      for await (const data of dataReader) {
        this.communications = data;
        this.filterCommunications();
      }
    } catch (err) {
      console.info(`No message get. err -> ${err}`);
    }
    this.hasNoMsg = this.communications.length === 0;
    this.pageLoaded = true;
  }

  /**
   * Change the entityStatus to ARCHIVED and save the communication
   * @param msg 
   */
  public async deleteMsg(msg: ICommunication) {
    try {
      await this.communicationService.delete(msg);
      this.communications = this.communications.filter((communication) => communication._id !== msg._id);
      this.filterCommunications();
      // TODO : BADGE
      // this.events.publish(AppConstants.EV_MESSAGE_UPDATED, this.communications);
    } catch (err) {
      console.error('MyMessagesPage error while deleting communication', err);
    }
  }

  /**
   * Show help page
   */
  public showHelp() {
    this.helpSeen = true;
    this.helpService.showHelp(AppConstants.PAGE_MESSAGES, "help.slide13text");
  }

  /**
   * Use put route to save the status of the communication (date of view or entityStatus to ARCHIVED ("deleted" for the user))
   * @param msg 
   */
  private async putMsg(msg: ICommunication) {
    try {
      const savedMsg = await this.communicationService.save(msg);
      console.info(`Message : ${savedMsg} saved`);
      // TODO : BADGE
      //  this.events.publish(AppConstants.EV_MESSAGE_UPDATED, this.communications);
    } catch (err) {
      console.error('MyMessagesPage error while saving communication', err);
    }
  }

  /**
   * Filter communications
   */
  public filterCommunications() {
    this.filteredCommunications = this.communications.filter((c: ICommunication) => {
      if (this.contentFilter?.length) {
        const searchValue = Tools.deleteAcccentSpecialcharacter(this.contentFilter).toLowerCase();
        const foundText = Tools.deleteAcccentSpecialcharacter(c.topic.text).toLowerCase()?.indexOf(searchValue) > -1;
        const foundContent = c.payload.filter(cs => Tools.deleteAcccentSpecialcharacter(cs.contentString ? cs.contentString : "").toLowerCase()?.indexOf(searchValue) > -1);
        return (foundText || foundContent.length > 0);
      }
      if (this.startDate && this.endDate) {
        if (moment(c.sent).isBefore(this.startDate, 'day') || moment(c.sent).isAfter(this.endDate, 'day')) {
          return false;
        }
      } else if (this.startDate) {
        if (moment(c.sent).isBefore(this.startDate, 'day')) {
          return false;
        }
      } else if (this.endDate) {
        if (moment(c.sent).isAfter(this.endDate, 'day')) {
          return false;
        }
      }
      return true;
    }).sort((a: ICommunication, b: ICommunication) => {
      return (moment(a.creation).isSameOrAfter(moment(b.creation)) ? -1 : 1);
    });
  }

  /**
   * Filter communications with searchbar
   */
  public onSearchContentChanged() {
    if (!this.contentFilter) this.filterCommunications();
    else if (this.contentFilter.length >= 3) this.filterCommunications();
  }

  /**
   * Show date filter
   */
  public onDateFilter() {
    this.dateFilterShow = !this.dateFilterShow;
    const elem = document.getElementsByClassName('filterDatePanel');
    const panel = elem[0] as HTMLElement;
    if (!this.dateFilterShow) panel.style.height = null;
    else panel.style.height = panel.scrollHeight + 'px';
  }

  /**
   * Clear date filter
   */
  public clearDateFilter(ev: string) {
    if (ev === 'startDate') {
      this.startDate = null;
    } else if (ev === 'endDate') {
      this.endDate = null;
    }
  }
}
