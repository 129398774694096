import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '../models/careplan';
import { FHIR_ActivityHelper } from '../helpers/fhirActivityHelper';

@Pipe({ name: 'productDescription' })
export class ProductDescriptionPipe implements PipeTransform {
  constructor() { }
  transform(activity: Activity): string {
    const name = FHIR_ActivityHelper.getName(activity);
    return FHIR_ActivityHelper.getProductDescription(activity, name);
  }
}
