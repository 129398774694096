import { IQuestionnaire, IValueSet } from "../models/questionnaire";
import { Answer, QRQuestion, QuestionnaireResponse } from "./questionnaireResponse";
import { Tools } from "./tools-helper";

/**
 * Gather answer and utility functions (in the future)
 */
export class Answers {

  constructor(public CURRENT: SimpleAnswer) { }
}

/**
 * Simple Answer of a questionnaire
 */
export class SimpleAnswer {
  constructor(public answer: Answer[], public valueSet: IValueSet) { }

  /**
   * returns the "number" associated to this answer.
   * Notice : at the moment, we take always this.answer[0], so this method does not manage checkbox
   */
  get value() {
    const answerCode = this.answer[0].valueCoding.code;
    return Number(this.valueSet.values.find((v) => v.key === answerCode)?.value);
  }
}

/**
 * A class to handle Answers object in a dictionnary
 */
export class DictionnaryAnswers {
  [key: string]: Answers;
}

/**
 * Management of the rule linked to a questionnaire for some valueSets given
 */
export class RuleDefinitionHelperQuestionnaireService {
  // Object used in trigger formula
  // ANSWER is a dictionnary of ANSWERS objects
  public ANSWER: DictionnaryAnswers = {};

  /**
   * Constructor
   *
   *
   */
  constructor(valueSet: IValueSet[], currentQuestionnaire: IQuestionnaire, currentQuestionnaireResponse: QuestionnaireResponse) {
    let qR: QRQuestion[] = [];

    if (Tools.isDefined(currentQuestionnaireResponse.group.group) && Array.isArray(currentQuestionnaireResponse.group.group)) {
      qR = currentQuestionnaireResponse.group.group.map((group) => group.question).reduce((a, b) => a.concat(b), []);
    }
    else if (Tools.isDefined(currentQuestionnaireResponse.group.question) && Array.isArray(currentQuestionnaireResponse.group.question)) {
      qR = currentQuestionnaireResponse.group.question;
    }

    // creation of the variable this.ANSWER used in the rules.
    // goal : this.ANSWER[linkId].CURRENT.value = the number associated to the answer of the question "linkId"
    if (Tools.isDefined(qR) && qR.length > 0) {
      qR.forEach((question) => {
        const valueSetIdentifier = this.getValueSetFromQuestionnaire(currentQuestionnaire, question.linkId);
        if (Tools.isDefined(valueSetIdentifier) && Tools.isDefined(question.answer) && question.answer.length) {
          this.ANSWER[question.linkId] = new Answers(new SimpleAnswer(question.answer, valueSet.find((v) => v.id === valueSetIdentifier)));
        }
      });

    }
  }

  /**
    * Return valueset identifier of a particular question. Can be null !
    */
  private getValueSetFromQuestionnaire(questionnaire: IQuestionnaire, linkIdQuestion: string): string {
    if (Tools.isDefined(questionnaire.group.group) && Array.isArray(questionnaire.group.group)) {
      for (const group of questionnaire.group.group) { // groups in group ?
        for (const question of group.question) {
          if (question.linkId === linkIdQuestion && question.options) {
            return question.options.reference;
          }
        }
      }
    }
    else if (Tools.isDefined(questionnaire.group.question) && Array.isArray(questionnaire.group.question)) {    // only questions in group
      for (const question of questionnaire.group.question) {
        if (question.linkId === linkIdQuestion && question.options) {
          return question.options.reference;
        }
      }
    }
    return null;    // not found
  }

  /**
   * Evaluate formula to trigger rule
   *
   * Example of formula :
   *      "ANSWER['1'].CURRENT.value + ANSWER['2'].CURRENT.value >= 90"
   */
  public triggerFormulaEvaluation(formula: string): boolean {
    try {
      const rep = this.evalFormula(formula);
      if (typeof rep === 'boolean') {
        return rep;
      }
      console.error("RuleHelper", "triggerFormulaEvaluation : is not a boolean !", rep);
      return false;

    } catch (err) { // probably not well formatted formula or patient reference value not exists
      console.warn("RuleHelper", "triggerFormulaEvaluation failed!", err);
      return false;
    }
  }

  /**
   * Configure a rule :
   * - replace ANSWER by this.ANSWER
   * @param rule
   * @returns
   */
  private configureRule(rule: string): string {
    // ANSWER object is only accessible through "this"
    rule = rule.replace(new RegExp("ANSWER", "g"), "this.ANSWER");
    console.log("RuleHelperQuestionnaireService", "configureRule", rule);
    return rule;
  }

  /**
   * eval a formula
   * @param formula a string representing a typescript code
   * @returns
   */
  private evalFormula(formula: string): any | null {
    try {
      if (Tools.isNotDefined(formula)) return null;
      formula = this.configureRule(formula);
      // tslint:disable-next-line: no-eval
      const value = eval(formula); // The force of the war!
      console.info("RuleHelperQuestionnaireService", "evalFormula result", value);
      return value;
    } catch (err) {
      console.error("RuleHelperQuestionnaireService", "evalFormula failed!", err);
      return null;
    }
  }
}

