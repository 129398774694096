import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppConstants } from 'src/app/appConstants';
import { Aes256Service } from './aes256.service';
import { Storage } from '@ionic/storage-angular';
import { LocalStorageInitService } from './local-storage-init.service';
import { LocalStorageError, LocalStorageErrorType } from './Local-storag-error.service';

export interface StoredWithTime {
    date: moment.Moment;
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService extends LocalStorageInitService {

    private static _hashCaremateIdentifier: string = null;

    public static get hashCaremateIdentifier(): string {
        return LocalStorageService._hashCaremateIdentifier;
    }
    public static set hashCaremateIdentifier(id: string) {
        LocalStorageService._hashCaremateIdentifier = id;
    }

    private specificKey = [ // list of keys to not transform
        AppConstants.SYS_ACCOUNT,
        AppConstants.PRM_LAST_LOGIN,
        AppConstants.APP_MODE,
        AppConstants.SOMEONE_LOGIN,
        AppConstants.KEY_SECURESTORAGE_NOT_ACTIVE,
        AppConstants.IONIC5CLEAR,
        AppConstants.NEW_INSTALLATION_FROM_STORE,
        AppConstants.LANGUAGES_LIST,
        AppConstants.LAST_LANGUAGE
    ];

    constructor(
        private aes256Service: Aes256Service,
        storageIonic: Storage
    ) {
        super(storageIonic);
     }

    public async setData(key: string, data: string, encrypted: boolean): Promise<any> {

        const keyT = this.keyTransform(key);

        if (await this.isReady()) {
            if (encrypted) {
                data = await this.aes256Service.encrypt(data);
            }
            return LocalStorageInitService._storage?.set(keyT, data);
        }
    }

    private keyTransform(key: string): string {
        return this.specificKey.includes(key) ? key : LocalStorageService.hashCaremateIdentifier + key;
    }

    public async getData(key: string, encrypted: boolean, expirationDays: number = 10): Promise<any> {

        const keyT = this.keyTransform(key);

        if (await this.isStored(keyT)) {
            let localData: string = await LocalStorageInitService._storage?.get(keyT);
            if (encrypted) {
                localData = await this.aes256Service.decrypt(localData);
            }
            return localData;
        } else {
            throw new LocalStorageError('Data ' + key + ' is not stored', LocalStorageErrorType.NOT_IN_STORAGE);
        }
    }

    public async isStored(key: string, alreadyTransformed = true) {
        if (await this.isReady()) {
            const keyT = alreadyTransformed ? key : this.keyTransform(key);
            const keys = await LocalStorageInitService._storage?.keys();
            return keys.includes(keyT);
        }
    }

    public async clearStorage() {
        if (await this.isReady()) {
            await LocalStorageInitService._storage?.clear();
        }
    }

    public async remove(key: string) {
        if (await this.isReady()) {
            const keyT = this.keyTransform(key);
            return LocalStorageInitService._storage?.remove(keyT);
        }
    }

    public async storeEntity(key: string, data: any, encrypted: boolean): Promise<any> {
        const dataStr = JSON.stringify({ date: moment(), data } as StoredWithTime);
        return this.setData(key, dataStr, encrypted);
    }

    public async hasLocalData(): Promise<boolean> {
        if (await this.isReady()) {
            const nbKeys = await LocalStorageInitService._storage?.length();
            if (nbKeys === null) {
                return false;
            }
            return nbKeys > 0;
        }
        return false;
    }
}
