import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AppConstants } from 'src/app/appConstants';
import { ICheckSum, ICheckSumResponse } from 'src/app/models/checksum';
import { ApiService } from '../../api.service';
import { InfoAppService } from '../../info-app.service';
import { NetworkService } from '../../network.service';
import { LocalStorageService } from '../../storage/local-storage.service';
import { RequestSenderService } from './request-sender.service';
import { IApiResponse } from 'src/app/models/iapi-response';
import { BasicSyncService } from './basic-sync.service';

@Injectable({
    providedIn: 'root'
})
export class RequiredSynchroService {

    private requiredSynchroRoute = "/requiredSynchro";
    private entityPrefix = "requiredSynchro";

    constructor(
        private localStorageService: LocalStorageService,
        private apiService: ApiService,
        private networkService: NetworkService,
        private requestSenderService: RequestSenderService,
        private infoService: InfoAppService) { }

    public async run(services: BasicSyncService<any, any[] | any>[]): Promise<Observable<ICheckSumResponse[]>> {
        const allNeedSynchro: ICheckSumResponse[] = services.map((service) => {
            return {
                nameRoute: service.getUrl(),
                needSync: true,
                requestDate: moment().format(),
                success: true
            } as ICheckSumResponse;
        });

        const allNoNeedSynchro: ICheckSumResponse[] = services.map((service) => {
            return {
                nameRoute: service.getUrl(),
                needSync: false,
                requestDate: moment().format(),
                success: true
            } as ICheckSumResponse;
        });

        if (!this.infoService.isCordova() && !AppConstants.LOCAL_DEV_MODE) {
            services.map((service) => { service.needRefresh = true; });
            return of(allNeedSynchro);
        }

        if (this.networkService.isCurrentOffline()) {
            return of(allNoNeedSynchro);
        }
        try {
            await this.requestSenderService.sync();
            const checksums: ICheckSum[] = await Promise.all(services.map(s => s.checkSum()));
            const apiRequireSyncObs: Observable<ICheckSumResponse[]> = this.createChecksumsApiRequest(checksums, allNeedSynchro);
            return apiRequireSyncObs;
        } catch (err) {
            // problem with the route : the server is surely unreachable ; we block the other routes -> no synchro
            console.error("ERROR requiredSynchro : " + JSON.stringify(err));
            const allSave = this.saveAllChecksumRespPromise(allNoNeedSynchro);
            const obsSave = from(allSave).pipe(
                map(() => { return allNoNeedSynchro; }),
                catchError(() => { return of(allNoNeedSynchro); })
            );
            return obsSave;
        }
    }

    private createChecksumsApiRequest(checkSums: ICheckSum[], allNeedSynchro: ICheckSumResponse[]): Observable<ICheckSumResponse[]> {
        const apiRequestObs: Observable<IApiResponse> = this.apiService.post(this.requiredSynchroRoute, checkSums);
        const checksumResponsesObs: Observable<ICheckSumResponse[]> = apiRequestObs.pipe(
            concatMap((response) => {
                if (response.success) {
                    const data = response.data as ICheckSumResponse[];
                    const allSave = this.saveAllChecksumRespPromise(data);
                    const obsSave = from(allSave).pipe(
                        map(() => { return data; }),
                        catchError(() => { return of(data); })
                    );
                    return obsSave;
                }
                else {
                    return of(allNeedSynchro);
                }
            })
        );
        return checksumResponsesObs;
    }

    private saveAllChecksumRespPromise(data: ICheckSumResponse[]): Promise<any[]> {
        const allSave = Promise.all(data.map((checkSumResponse) => {
            return this.save(checkSumResponse).catch((err) => {
                console.log("RequiredSynchroService error during the save : " + err);
                return null;
            });
        }));
        return allSave;
    }

    public async needSynchro(service: BasicSyncService<any, any[] | any>): Promise<boolean> {
        if (this.networkService.isCurrentOffline()) {
            return false;
        }
        const checkSumResponse = await this.getCheckSumResponse(service);
        return this.checkSumResponseNeedSynchro(checkSumResponse);
    }

    public async needSynchroAndRunIfNecessary(service: BasicSyncService<any, any[] | any>): Promise<boolean> {
        if (this.networkService.isCurrentOffline()) {
            return false;
        }
        const rep = await this.getCheckSumResponse(service);
        if (!rep || moment(rep.requestDate).add(AppConstants.REQUIRED_SYNCHRO_TIME, "minute").isSameOrBefore(moment(), "minute")) {
            return new Promise<boolean>(async (resolve, reject) => {
                let checkSum: ICheckSumResponse;
                const obsCheckSumResponse: Observable<ICheckSumResponse[]> = await this.run([service]);
                obsCheckSumResponse.subscribe(
                    (checkSumResponse) => {
                        if (checkSumResponse) {
                            checkSum = checkSumResponse[0];
                        }
                    },
                    (err) => { return reject(err); },
                    () => {
                        if (checkSum) {
                            return resolve(this.checkSumResponseNeedSynchro(checkSum));
                        }
                        else {
                            return resolve(true);
                        }
                    });
            });
        } else {
            return this.checkSumResponseNeedSynchro(rep);
        }
    }

    public async updateRequestDate(service: BasicSyncService<any, any[] | any>): Promise<any> {
        const newRep = {
            nameRoute: service.getUrl(),
            needSync: false,
            requestDate: moment().format(),
            success: true
        } as ICheckSumResponse;

        return this.save(newRep);
    }

    private checkSumResponseNeedSynchro(checkSumResponse: ICheckSumResponse): boolean {
        return checkSumResponse && checkSumResponse.needSync;
    }

    private save(checkSumResponse: ICheckSumResponse): Promise<any> {
        return this.localStorageService.setData(this.key(checkSumResponse.nameRoute), JSON.stringify(checkSumResponse), true);
    }

    private async getCheckSumResponse(service: BasicSyncService<any, any[] | any>): Promise<ICheckSumResponse> {
        try {
            const rep = await this.localStorageService.getData(this.key(service.getUrl()), true);
            return JSON.parse(rep) as ICheckSumResponse;
        } catch (err) {
            return null;
        }
    }

    private key(nameRoute: string): string {
        return this.entityPrefix + nameRoute;
    }
}
