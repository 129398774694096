import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { IApiResponse } from "../models/iapi-response";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(
        private apiService: ApiService
    ) { }

    /**
     * Reset user password
     */
    public resetPassword(identifier: string): Observable<IApiResponse> {
        return this.apiService.get("/passwordreset?identifier=" + identifier.trim()).pipe(
            catchError(() => of(null))
        );
    }

    /**
     *  send modified notifications to server (create, update, delete)
     */
    public postResetPassword(identifier: string, passcode: string): Observable<IApiResponse> {
        return this.apiService.post("/passwordreset", { "identifier": identifier, "passcode": passcode }).pipe(
            catchError(() => of(null))
        );
    }
}
